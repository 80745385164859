<script setup>
import MdiViewSplitVertical from '~icons/mdi/view-split-vertical';
import MdiViewSplitHorizontal from '~icons/mdi/view-split-horizontal';
import MdiTableRowPlusBefore from '~icons/mdi/table-row-plus-before';
import MdiTableRowPlusAfter from '~icons/mdi/table-row-plus-after';
import MdiTableRowRemove from '~icons/mdi/table-row-remove';
import MdiTableColumnPlusBefore from '~icons/mdi/table-column-plus-before';
import MdiTableColumnPlusAfter from '~icons/mdi/table-column-plus-after';
import MdiTableColumnRemove from '~icons/mdi/table-column-remove';
import MdiTableRemove from '~icons/mdi/table-remove';
import MdiTableSplitCell from '~icons/mdi/table-split-cell';
import MdiTableMergeCells from '~icons/mdi/table-merge-cells';

import { uploadToStorage } from '~/common/utils/storage-upload.util.js';
import EditorSubMenuLink from '~/common/components/organisms/hawk-wysiwyg-editor/editor-menu/editor-sub-menu-link.vue';
import EditorSubMenuAlign from '~/common/components/organisms/hawk-wysiwyg-editor/editor-menu/editor-sub-menu-align.vue';
import EditorSubMenuColor from '~/common/components/organisms/hawk-wysiwyg-editor/editor-menu/editor-sub-menu-color.vue';
import EditorSubMenuHeading from '~/common/components/organisms/hawk-wysiwyg-editor/editor-menu/editor-sub-menu-heading.vue';

const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
  plugins: {
    type: Array,
    required: false,
    default: () => ([]),
  },
  resize_config: {
    type: Object,
    default: () => ({ quality: 1, maxWidth: 800, maxHeight: 600 }),
  },
  header_level: {
    type: Number,
    required: false,
  },
});

const $t = inject('$t');

const file_input = ref(null);
const is_image_uploading = ref(false);

const state = reactive({
  is_table_menu_active: false,
  general_menu_items: [
    {
      icon: IconHawkBoldOne,
      name: 'bold',
      label: $t('Bold'),
      action: () => props.editor.chain().focus().toggleBold().run(),
      is_active: () => props.editor.isActive('bold'),
    },
    {
      icon: IconHawkItalicOne,
      name: 'italic',
      label: $t('Italic'),
      action: () => props.editor.chain().focus().toggleItalic().run(),
      is_active: () => props.editor.isActive('italic'),
    },
    {
      icon: IconHawkUnderlineOne,
      name: 'underline',
      label: $t('Underline'),
      action: () => props.editor.chain().focus().toggleUnderline().run(),
      is_active: () => props.editor.isActive('underline'),
    },
    {
      icon: IconHawkAlignLeft,
      name: 'alignment',
      label: $t('Alignment'),
      submenu: EditorSubMenuAlign,
    },
    {
      type: 'divider',
    },
    {
      name: 'foreground',
      label: `${$t('Foreground')}`,
      submenu: EditorSubMenuColor,
    },
    {
      icon: IconHawkBrushThree,
      name: 'background',
      label: `${$t('Background')}`,
      submenu: EditorSubMenuColor,
    },
    ...(props.header_level
      ? [{
          icon: IconHawkHeadingOne,
          name: 'heading',
          label: `${$t('Heading')}`,
          action: () => props.editor.chain().focus().toggleHeading({ level: props.header_level }).run(),
        }]
      : [{
          icon: IconHawkHeadingOne,
          name: 'heading',
          label: `${$t('Heading')}`,
          submenu: EditorSubMenuHeading,
        }]),
    {
      icon: IconHawkList,
      name: 'bullet List',
      label: $t('Bullet List'),
      action: () => props.editor.chain().focus().toggleBulletList().run(),
      is_active: () => props.editor.isActive('bulletList'),
    },
    {
      icon: IconHawkNumberedList,
      name: 'ordered List',
      label: $t('Ordered List'),
      action: () => props.editor.chain().focus().toggleOrderedList().run(),
      is_active: () => props.editor.isActive('orderedList'),
    },
    {
      type: 'divider',
    },
    {
      icon: IconHawkLinkTwo,
      name: 'link',
      label: $t('Link'),
      submenu: EditorSubMenuLink,
      is_active: () => props.editor.isActive('link'),
    },
    ...(props.plugins.includes('images')
      ? [{
          icon: IconHawkImageThree,
          name: 'image',
          label: $t('Image'),
          action: () => file_input.value.click(),
        }]
      : []),
    {
      icon: IconHawkTableTwo,
      name: 'table',
      label: $t('Table'),
      action: () => {
        if (!props.editor.isActive('table')) {
          props.editor.chain().focus();
          props.editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true });
        }
        state.is_table_menu_active = true;
      },
      is_active: () => props.editor.isActive('table'),
    },
    {
      type: 'divider',
    },
    {
      icon: IconHawkEraser,
      name: 'clear Format',
      label: $t('Clear Format'),
      action: () => props.editor.chain().focus().clearNodes().unsetAllMarks().run(),
    },
    {
      icon: IconHawkFlipBackward,
      name: 'undo',
      label: $t('Undo'),
      action: () => props.editor.chain().focus().undo().run(),
    },
    {
      icon: IconHawkFlipForward,
      name: 'redo',
      label: $t('Redo'),
      action: () => props.editor.chain().focus().redo().run(),
    },
  ],
  table_menu_items: [
    {
      icon: MdiViewSplitHorizontal,
      name: 'toggle header row',
      label: $t('Toggle header row'),
      classes: 'rotate-180',
      action: () => props.editor.chain().focus().toggleHeaderRow().run(),
    },
    {
      icon: MdiViewSplitVertical,
      name: 'toggle header column',
      label: $t('Toggle header column'),
      classes: 'rotate-180',
      action: () => props.editor.chain().focus().toggleHeaderColumn().run(),
    },
    {
      icon: MdiTableRowPlusBefore,
      name: 'add row before',
      label: $t('Add row before'),
      action: () => props.editor.chain().focus().addRowBefore().run(),
    },
    {
      icon: MdiTableRowPlusAfter,
      name: 'add row after',
      label: $t('Add row after'),
      action: () => props.editor.chain().focus().addRowAfter().run(),
    },
    {
      icon: MdiTableRowRemove,
      name: 'delete row',
      label: $t('Delete row'),
      action: () => props.editor.chain().focus().deleteRow().run(),
    },
    {
      icon: MdiTableColumnPlusBefore,
      name: 'add column before',
      label: $t('Add column before'),
      action: () => props.editor.chain().focus().addColumnBefore().run(),
    },
    {
      icon: MdiTableColumnPlusAfter,
      name: 'add column after',
      label: $t('Add column after'),
      action: () => props.editor.chain().focus().addColumnAfter().run(),
    },
    {
      icon: MdiTableSplitCell,
      name: 'split cell',
      label: $t('Split cell'),
      action: () => props.editor.chain().focus().splitCell().run(),
    },
    {
      icon: MdiTableMergeCells,
      name: 'merge cells',
      label: $t('Merge cells'),
      action: () => props.editor.chain().focus().mergeCells().run(),
    },
    {
      icon: MdiTableColumnRemove,
      name: 'delete column',
      label: $t('Delete column'),
      action: () => props.editor.chain().focus().deleteColumn().run(),
    },
    {
      icon: MdiTableRemove,
      name: 'delete table',
      label: $t('Delete table'),
      action: () => props.editor.chain().focus().deleteTable().run(),
      classes: 'danger',
    },
  ],
});

const menu_items = computed(() => {
  const is_table_active = props.editor.isActive('table');
  const menu_items = is_table_active && state.is_table_menu_active ? [...state.table_menu_items] : [...state.general_menu_items];

  if (is_table_active)
    menu_items.unshift({
      icon: IconHawkSwitchHorizontalOne,
      name: state.is_table_menu_active ? 'Switch to general menu' : 'Switch to table menu',
      label: state.is_table_menu_active ? $t('Switch to general menu') : $t('Switch to table menu'),
      action: () => (state.is_table_menu_active = !state.is_table_menu_active),
      is_active: () => (state.is_table_menu_active),
    },
    {
      type: 'divider',
    });

  return menu_items;
});

const disabled_items = computed(() => {
  const items = [];
  if (!props.plugins.includes('tables'))
    items.push('table');
  if (!props.editor?.can?.()?.splitCell?.())
    items.push('split cell');
  if (!props.editor?.can?.()?.mergeCells?.())
    items.push('merge cells');
  return items;
});

async function onFileInputChanged(event) {
  is_image_uploading.value = true;
  const stored = await uploadToStorage(event.target.files?.[0], props.resize_config);
  if (stored)
    props.editor.commands.setImage({ src: stored.location });
  is_image_uploading.value = false;
}
</script>

<template>
  <div class="rounded-t-lg border-b-[1px] border-b-gray-300 items-center flex flex-wrap flex-grow-0 flex-shrink-0 basis-auto">
    <template v-for="(item, index) in menu_items" :key="index">
      <div v-if="item.type === 'divider'" :key="`divider${index}`" class="bg-gray-300 ml-1 mr-2 w-[1px] h-5" />
      <IconHawkLoadingCircle
        v-else-if="item.name === 'image' && is_image_uploading && !disabled_items.includes(item.name)"
        class="animate-spin w-4 h-4 mx-2.5"
      />
      <EditorMenuItem
        v-else-if="!disabled_items.includes(item.name)"
        v-bind="item"
        :editor="editor"
      />
    </template>
    <input
      ref="file_input"
      type="file"
      accept=".png,.jpg,.jpeg"
      style="visibility:hidden;height:0px;"
      @change="onFileInputChanged"
    >
  </div>
</template>
