import { defineStore } from 'pinia';
import { assign, cloneDeep, countBy, filter, flatten, groupBy, has, isNil, isObject, isString, keyBy, mapValues, omit, orderBy, pick, remove, sortBy, trim } from 'lodash-es';
import axios from 'axios';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import { sleep } from '~/common/utils/common.utils.js';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { formatFields } from '~/system-model/utils/helper';
import { useAuthStore } from '~/auth/stores/auth.store';

function sort_group_and_projects(data) {
  return [
    ...orderBy(
      filter(data, p => p?.properties?.date),
      [p => p.properties.date],
      ['desc'],
    ),
    ...orderBy(
      filter(data, p => !p?.properties?.date),
      [p => p.name],
      ['asc'],
    ),
  ];
}
function validateCoordinates(coordinates) {
  for (const coord of coordinates)
    if (Array.isArray(coord)) {
      const [longitude, latitude] = coord;
      if (!(longitude >= -180 && longitude <= 180) || !(latitude >= -90 && latitude <= 90))
        return false;
    }

  return true;
}
function isStringified(str) {
  try {
    return JSON.parse(str);
  }
  catch (err) {
    return str;
  }
}
export function useTerraStore(id = 'terra') {
  const { preProcessGeojson, setMapboxLayersStylesByColorProperty, static_keys } = useTerraHelperComposable();

  return defineStore(id, {
    state: () => ({
      sm_instances: [],
      view_type: 'terra',
      container: null,
      selected_features: [],
      sm_instances_map: {
        feature_name_hash: {},
        all_fields_map: {},
      },
      selected_tasks_forms: {},
      inactive_feature_types: [],
      last_selected_project: null,
      pivot_config: {
        selected_projects: null,
        pivot_instance: null,
        mounted: false,
      },
      show_request_mbtiles_popup: {
        resolved_all_requests: false,
        requested_reports: {},
      },
      projects_meta_map: {},
      projects_details_map: {},
      projects_details_reports_map: {},
      projects_features_map: {},
      active_projects_map: {},
      active_group_map: {},
      is_creating_vector: false,
      ftg: null, // feature type groups
      last_selected_feature_type: null,
      map: null,
      draw: null,
      is_loading: false,
      schedules_list: null,
      polygon: '',
      tasks_cluster: {
        active: true,
        filters: {
          status: [1, 2, 3],
          group_by: 'Status',
        },
      },
      forms_cluster: {
        active: true,
        filters: {},
      },
      settings: {
        fly: true,
        display_labels: false,
        label_property_key: 'name',
        filter_task_form_with_target_element: false,
        extra_property_filters: {
          selected_key: null,
          values: [],
        },
      },
      terra_workflows: {},
      is_settings_loading: false,
      offline_edited_features: [],
      features_updated: [],
      projects_request_status: {
        total: 0,
        current: 0,
        cancel: false,
        show: false,
      },
      filters_state: {
        all_keys: [],
        ordered_keys_map: {},
        active_filter: null,
        features_on_map: [],
        key_values_data: {},
        is_mounted: false,
        filter_forms: {},
        gradient_form: null,
        quick_filter: null,
      },
      table_state: {
        search: '',
        filters: [],
        ordered_keys_map: {},
        sort: [],
      },
      update_features_on_map_flag: 0,
      show_save_view: false,
      gallery_view_state: {
        is_active: false,
        active_attachment: null,
        project_features_attachment_map: {},
        filtered_attachment_features: [],
        show_load_progress_attachments: false,
      },
    }),
    getters: {

      inactive_feature_types_map(state) {
        return keyBy(state.inactive_feature_types, a => a);
      },
      get_project_features(state) {
        return (project) => {
          const features = [];
          if (state.active_projects_map[project.uid].features_enabled && state.projects_features_map[project.uid])
            Object.keys(state.projects_features_map[project.uid]).forEach((key) => {
              features.push(...(state.projects_features_map[project.uid]?.[key] || []));
            });
          return features;
        };
      },
      features_count(state) {
        const features = [];
        if (state.container?.groups)
          Object.values(state.container.groups).forEach((group) => {
            Object.values(group.projects).forEach((project) => {
              const project_features = state.get_project_features(project);
              features.push(...project_features);
            });
          });

        return countBy(features, f => f.properties.featureTypeId);
      },
      check_permission() {
        return (permission_name) => {
          return !!(this.container?.[permission_name]);
        };
      },
      workflow_fields_hash(state) {
        function updateFieldIndex(fields_hash, data, step_index) {
          data.fields.forEach((field, index) => {
            fields_hash.fields[field.uid] = { ...field, field_index: step_index * 1000 + index * 1 };
          });
          return fields_hash;
        }
        return (options) => {
          const workflow = this.terra_workflows[options.workflow];
          if (workflow) {
            const first_step = workflow.data?.[Object.keys(workflow.data)[0]];
            let fields_hash = {
              ...workflow,
              fields: {},
              meta: {
                progress_type: first_step?.progress_type,
                weights: first_step?.weights,
              },
            };

            Object.values(fields_hash.data).forEach((data, step_index) => {
              fields_hash = updateFieldIndex(fields_hash, data, step_index);
            });
            return fields_hash;
          }
          return null;
        };
      },
      all_workflow_fields_hash(state) {
        const fields_hash = {};
        Object.keys(this.terra_workflows).forEach((workflow) => {
          Object.values(this.terra_workflows[workflow].data).forEach((data) => {
            assign(fields_hash, keyBy(data.fields, 'uid'));
          });
        });
        return fields_hash;
      },
      feature_types(state) {
        const flattendArray = flatten(state.ftg?.map(item => item.featureTypes));

        return keyBy(flattendArray, 'id');
      },
      feature_types_by_uid(state) {
        const flattendArray = flatten(state.ftg?.map(item => item.featureTypes));
        return keyBy(flattendArray, 'uid');
      },
      // To find out all the checked/enabled projects
      active_projects(state) {
        const projects = [];
        if (state.container?.groups)
          Object.values(state.container.groups).forEach((group) => {
            Object.values(group.projects).forEach((project) => {
              if (this.active_projects_map[project.uid]?.ortho_enabled || this.active_projects_map[project.uid]?.features_enabled)
                projects.push({ uid: project.uid, group_uid: project.group });
            });
          });

        return projects;
      },
      active_projects_data_map(state) {
        function getGroupProject(group, options) {
          const group_projects = [];
          Object.values(group.projects).forEach((project) => {
            if (options?.all_projects || (state.active_projects_map[project.uid]?.ortho_enabled || state.active_projects_map[project.uid]?.features_enabled))
              group_projects.push(project);
          });
          return group_projects;
        }

        return (options) => {
          const projects = [];

          if (state.container?.groups)
            Object.values(state.container.groups).forEach((group) => {
              const group_projects = getGroupProject(group, options);
              projects.push(...group_projects);
            });

          return keyBy(projects, 'uid');
        };
      },
      active_projects_bounds() {
        return (turf) => {
          const bounds = Object.values(this.active_projects_data_map() || {}).reduce((acc, cur) => {
            if (this.projects_meta_map[cur.uid]?.bounds) {
              acc[cur.uid] = turf.bboxPolygon(this.projects_meta_map[cur.uid].bounds);
            }
            else {
              const features = flatten(Object.values(this.projects_features_map[cur.uid] || {}));

              const FeatureCollection = {
                type: 'FeatureCollection',
                features,
              };
              acc[cur.uid] = turf.bboxPolygon(turf.bbox(FeatureCollection));
            }
            return acc;
          }, {});
          return bounds;
        };
      },
      feature_elements() {
        const elements = [];
        this.features.forEach((feature) => {
          if (
            feature.properties.element
            && isObject(feature.properties.element)
          )
            elements.push(feature.properties.element?.uid);
        });
        return elements;
      },
      extra_properties_group_by(state) {
        function handleCallForProcessProperties(feature, properties, processExtraProperties) {
          Object.entries(properties).forEach(([property_key, property_value]) => {
            processExtraProperties(property_key, property_value, feature);
          });
        }
        return (options = {}) => {
          const grouped_properties_filter = new Map();
          const features = options.features || this.features;
          const key_values_data = { ...this.filters_state.key_values_data };

          const processExtraProperties = (property_key, property_value, f) => {
            if (!isNil(property_value) && !property_key.startsWith('_') && !static_keys.includes(property_key) && trim(property_value) !== '') {
              const keyMap = grouped_properties_filter.get(property_key) || new Map();
              const value = String(property_value);

              let entry = keyMap.get(value);
              if (!entry) {
                entry = { features: [f], count: 0, color: null, selected: false };
                keyMap.set(value, entry);
              }
              else {
                entry.features.push(f);
              }
              entry.count = entry.features.length;

              if (!key_values_data[property_key])
                key_values_data[property_key] = { [value]: { color: null, selected: false } };
              else if (!key_values_data[property_key][value])
                key_values_data[property_key][value] = { color: null, selected: false };

              grouped_properties_filter.set(property_key, keyMap);
            }
          };
          const fields_used = Object.values(this.sm_instances_map.all_fields_map).reduce((acc, field) => {
            acc[field.name] = !['file', 'url'].includes(field.type);
            return acc;
          }, {});

          features.forEach((f) => {
            const properties = this.get_feature_properties(f, fields_used);
            handleCallForProcessProperties(f, properties, processExtraProperties);
          });

          // Set key values data
          this.filters_state.key_values_data = key_values_data;

          if (!options.features)
            this.filters_state.all_keys = Array.from(grouped_properties_filter.keys());

          // Sorting keys
          const sorted_array = sortBy(Array.from(grouped_properties_filter), ([key, value]) => {
            return this.filters_state.ordered_keys_map[key] || key.toLowerCase();
          });

          // Convert sorted array to result object
          const result = {};
          const sort_func = ([val, v]) => val.toLowerCase();
          sorted_array.forEach(([key, value]) => {
            if (!Object.keys(this.filters_state.ordered_keys_map).length || this.filters_state.ordered_keys_map[key]) {
              // Sort the values
              const sortedValues = sortBy(Array.from(value), sort_func);
              result[key] = Object.fromEntries(sortedValues);
            }
          });

          return result;
        };
      },

      features(state) {
        const features = [];
        const extra_property_filters_values_length = state.settings.extra_property_filters.values.length;
        const extra_property_filters_values_map = keyBy(
          state.settings.extra_property_filters.values,
          a => a,
        );
        if (!state?.container)
          return features;

        function getFIlteredFeatures(filtered_features_list) {
          let filtered_features = filtered_features_list;
          // extra property filter check
          if (state.settings.extra_property_filters.selected_key)
            filtered_features = filtered_features.filter((f) => {
              // if no values selected filter the features selected key present
              return (
                // validateCoordinates(f.geometry.coordinates[0])
                has(
                  f.properties.extraProperties,
                  state.settings.extra_property_filters.selected_key,
                )
                && (extra_property_filters_values_length
                  ? has(
                    extra_property_filters_values_map,
                    f.properties.extraProperties[
                      state.settings.extra_property_filters.selected_key
                    ],
                  )
                  : true)
              );
            });
          if (state.filters_state.is_mounted)
            filtered_features = filtered_features.map((f) => {
              const properties = state.get_feature_properties(f);
              if (has(properties, state.filters_state.active_filter)) {
                const filterProperties = state.filters_state.key_values_data[state.filters_state.active_filter]?.[properties[state.filters_state.active_filter]];
                if (filterProperties) {
                  f.properties.color = filterProperties?.color;
                  f.properties.line_opacity = Number.isNaN(filterProperties?.line_opacity) ? 1 : filterProperties?.line_opacity;
                  if (['Polygon', 'MultiPolygon'].includes(f.geometry.type)) {
                    f.properties.fill_color = f.properties.color;
                    f.properties.opacity = 0.2;
                  }
                }
              }
              else {
                f.properties.color = null;
                f.properties.fill_color = null;
                f.properties.line_opacity = 1;
                f.properties.opacity = 0;
              }
              return f;
            });
          // else filtered = filtered.filter((f) => {
          //   return validateCoordinates(f.geometry.coordinates[0]);
          // });
          return filtered_features;
        }

        const projects_list = Object.values(state.active_projects_data_map({ all_projects: true }) || {});
        projects_list.forEach((project) => {
          if (this.active_projects_map[project.uid].features_enabled && this.projects_features_map[project.uid])
            Object.keys(this.projects_features_map[project.uid]).forEach((key) => {
              if (!this.inactive_feature_types_map[key]) {
                let filtered_features = this.projects_features_map[project.uid]?.[key] || [];
                filtered_features = getFIlteredFeatures(filtered_features);

                features.push(...filtered_features);
              }
            });
        });

        return features;
      },
      all_features(state) {
        const features = [];
        if (state.container)
          Object.values(state.container?.groups).forEach((group) => {
            Object.values(group.projects).forEach((project) => {
              if (this.projects_features_map[project.uid])
                features.push(...flatten(Object.values(this.projects_features_map[project.uid])));
            });
          });

        return features;
      },
      features_by_projects(state) {
        function getFeaturesFromProjects(groups, options) {
          const features = [];

          function getProjectFeatures(project) {
            if (state.projects_features_map[project.uid] && options.projects.includes(project.uid))
              features.push(...flatten(Object.values(state.projects_features_map[project.uid])));
          }

          function iterateProjects(group) {
            Object.values(group.projects).forEach(getProjectFeatures);
          }

          Object.values(groups).forEach(iterateProjects);

          return features;
        }
        return (options = {}) => {
          const features = [];
          if (state.container)
            features.push(...getFeaturesFromProjects(state.container?.groups || {}, options));

          return features;
        };
      },
      features_hash(state) {
        return keyBy(this?.all_features, item => item.properties.uid);
      },
      features_on_map() {
        if (this.filters_state.is_mounted)
          return this.filters_state.features_on_map;
        return this.features;
      },
      filtered_features_hash() {
        return keyBy(this?.features_on_map, item => item.properties.uid);
      },
      formatted_project_features_attachment_map(state) {
        const { project_features_attachment_map } = state.gallery_view_state;
        const formatAttachments = (attachments, feature) => {
          return attachments.map(attachment => ({
            ...attachment,
            uid: attachment.uid || crypto.randomUUID(),
            project_uid: feature.project_uid,
            feature_uid: feature.feature_uid,
            file_name: attachment?.file_name || state.get_filename_from_key(attachment?.key),
            ...(attachment.meta || {}),
          }));
        };
        return Object.entries(project_features_attachment_map).reduce((project_map, [project_uid, features_map]) => {
          const project_features = Object.entries(features_map).reduce((features_map, [feature_uid, feature]) => {
            const all_feature_attachments = formatAttachments([...(feature.feature_attachments || []), ...flatten(feature.progress_attachments || [])], feature);
            features_map[feature_uid] = {
              ...feature,
              all_feature_attachments,
              attachments_count: all_feature_attachments.length,
            };
            return features_map;
          }, {});
          project_map[project_uid] = project_features;
          return project_map;
        }, {});
      },
      active_attachments_list(state) {
        return (options = {}) => {
          const feature_attachments = Object.entries(state.formatted_project_features_attachment_map).reduce((acc, [project_uid, features_map]) => {
            if (state.active_projects_map[project_uid]?.features_enabled) {
              const attachment_features = this.get_filtered_attachments(features_map, options);
              acc.push(...attachment_features);
            }
            return acc;
          }, []);
          return feature_attachments;
        };
      },
    },
    actions: {
      set_selected_features(value) {
        this.selected_features = value;
        this.selected_features = [...(this.selected_features || [])];
      },
      async set_container(options = {}) {
        if (options.container) {
          this.container = options.container;
        }
        else if (!this.container || options.forceUpdate) {
          let container;
          const response = await this.$services.terra_view_service.get({
            id: options.uid,
            toast: !this.$router.currentRoute.value?.query?.metadata,
          });

          container = response.data;

          if (container?.groups?.length)

            container.groups = container.groups.reduce((groups, group) => {
              group.projects = (group.projects || []).reduce(
                (projects, project) => {
                  if (
                    !project.properties?.is_disabled
                  )
                    projects.push(project);
                  return projects;
                },
                [],
              );
              groups.push(group);
              return groups;
            }, []);

          if (container?.groups?.length)
            container = this.parse_container_data({
              container,
              keep_project_map: options.keep_project_map || false,
            });

          this.container = container;
        }
      },
      parse_container_data(options = {}) {
        options.container.groups = keyBy(
          sort_group_and_projects(options.container.groups).map((group) => {
            this.active_group_map[group.uid] = {
              ortho_enabled: false,
              features_enabled: false,
            };
            return {
              ...group,

              projects: keyBy(
                sort_group_and_projects(group.projects).map((project) => {
                  if (!options.keep_project_map || !this.active_projects_map[project.uid])
                    this.active_projects_map[project.uid] = {
                      ortho_enabled: false,
                      features_enabled: false,
                    };
                  return {
                    ...project,
                    vectors: project.allFeatures || project.vectors,
                  };
                }),
                'uid',
              ),
            };
          }),
          'uid',
        );
        return options.container;
      },
      collect_project_bounds(turf) {
        const map_bounds = [];

        Object.values(this.container.groups).forEach((group) => {
          Object.values(group.projects).forEach((project) => {
            if (
              this.active_projects_map[project.uid].ortho_enabled
            && ((this.projects_meta_map[project.uid]?.bounds)
              || (project?.properties?.bounds))
            )
              map_bounds.push(
                turf.bboxPolygon(
                  project.properties?.bounds
                    ? isStringified(project.properties?.bounds)
                    : this.projects_meta_map[project.uid]?.bounds,
                ),
              );
          });
        });

        return map_bounds;
      },
      async set_polygon() {
        try {
          const turf = (await import('@turf/turf'));
          const fc = {
            features: [],
            type: 'FeatureCollection',
          };

          fc.features = this.features;

          let featuresBox;
          if (fc.features.length)
            if (
              fc.features?.length === 1
            && fc.features[0]?.geometry?.type === 'Point'
            )
              featuresBox = turf.buffer(fc, 1, { units: 'kilometers' })
                ?.features[0];

            else
              featuresBox = turf.bboxPolygon(turf.bbox(fc));

          const map_bounds = this.collect_project_bounds(turf);

          let queryBoundary;
          if (featuresBox || map_bounds.length) {
            const featureCollection = {
              type: 'FeatureCollection',
              features: [
                ...(featuresBox ? [featuresBox] : []),
                ...map_bounds,
              ],
            };

            queryBoundary = turf.bboxPolygon(turf.bbox(featureCollection));
          }
          let polygon = null;
          if (queryBoundary)
            polygon = btoa(JSON.stringify(queryBoundary.geometry));

          this.polygon = polygon;
        }
        catch (err) {
          logger.error(err);
        }
      },
      async toggle_project(options) {
        try {
          const project = options.project;
          const active = !(this.active_projects_map[project.uid].ortho_enabled && this.active_projects_map[project.uid].features_enabled);

          this.active_projects_map[project.uid] = {
            ortho_enabled: active,
            features_enabled: active,
          };
          await this.set_projects_essentials({
            projects: [project],
          });

          await this.update_map_features_and_polygon();
          if (!options.do_not_fly)
            await this.fly_to_project({ project });
          return options.project;
        }
        catch (err) {
          logger.error(err);
        }
      },
      async set_projects_essentials(options) {
        try {
          const projects = options.projects;
          const last_project = options.projects[options.projects.length - 1];
          if (this.active_projects_map[last_project?.uid].ortho_enabled || this.active_projects_map[last_project?.uid].features_enabled || options.forceUpdate)
            this.last_selected_project = last_project;

          await this.set_projects_meta({ projects, forceUpdate: options.forceUpdate });
          await this.set_projects_ortho({ projects, forceUpdate: options.forceUpdate });
          await this.set_projects_features({ projects, forceUpdate: options.forceUpdate });
          await this.set_projects_details({ projects, forceUpdate: options.forceUpdate });
          await this.set_terra_projects_details_for_reports({ projects, forceUpdate: options.forceUpdate });
          await this.set_projects_attachments({ projects, forceUpdate: options.forceUpdate });
          this.projects_request_status.current = this.projects_request_status.current + options.projects.length;
          return projects;
        }
        catch (err) {
          logger.error(err);
        }
      },
      async set_terra_projects_details_for_reports(options) {
        try {
          for (const project of options.projects)
            if (
              this.projects_details_map[project.uid]?.reports
            )
              this.projects_details_reports_map[project.uid] = {
                ...this.projects_details_map[project.uid].reports,
                signed: true,
              };

          return options.projects;
        }
        catch (err) {
          logger.error(err);
        }
      },
      async set_projects_ortho(options) {
        try {
          for (const project of options.projects) {
            if (
              !this.map.getSource(project.uid) && project?.reports?.orthotiles?.tile_url
            )
              this.map.addSource(project.uid, {
                type: 'raster',
                tiles: [
              `${project?.reports?.orthotiles?.tile_url}/{z}/{x}/{y}.png`,
                ],
                tileSize: 256,
              });

            // If project layer is not added
            if (this.map.getSource(project.uid) && !this.map.getLayer(project.uid)) {
              this.map.addLayer(
                {
                  id: project.uid,
                  type: 'raster',
                  source: project.uid,
                },

                'point_feature_layer',
              );
              this.map.addLayer(
                {
                  id: project.uid,
                  type: 'raster',
                  source: project.uid,
                },
                'polygon_feature_layer',
              );
            }
            if (this.map.getLayer(project.uid))
            // Toggle visibility
              this.map.setLayoutProperty(
                project.uid,
                'visibility',
                (this.active_projects_map[project.uid].ortho_enabled || options.forceUpdate) ? 'visible' : 'none',
              );
          }
        }
        catch (err) {
          logger.error(err);
        }
      },
      async set_sm_instances_for_features({ features = [] }) {
        try {
          const system_model_store = useSystemModelStore();
          const feature_names = features.reduce((acc, cur) => {
            if (!this.sm_instances_map[cur.properties.name])
              acc.push(cur.properties.name);
            return acc;
          }, []);
          if (features.length && feature_names.length) {
            function getFields(instance) {
              return [...(instance?.component?.category?.fields || []), ...(instance?.component?.fields || [])];
            }

            function getFieldValues(instance) {
              return [...(instance?.component?.fieldvalues || []), ...(instance?.fieldvalues || [])];
            }

            const sm_instances = await system_model_store.set_instances_by_fields({
              instance_names: feature_names,
              container_id: this.$router.currentRoute.value.params.id,
            });
            const sm_instances_hash = {};
            const sm_instances_fields = {};
            sm_instances.forEach(((item) => {
              if (item.component.fields?.length || item.component.fieldvalues?.length) {
                const fields = formatFields(getFields(item), getFieldValues(item));

                const properties = {};
                fields.forEach((item) => {
                  properties[item.name] = item.value;
                  sm_instances_fields[item.name] = item;
                });
                sm_instances_hash[item.name] = { ...properties };
              }
            }));
            this.sm_instances_map = { feature_name_hash: { ...sm_instances_hash }, all_fields_map: sm_instances_fields };
          }
        }
        catch (error) {

        }
      },
      async set_projects_features(options) {
        const auth_store = useAuthStore();
        try {
          let non_enabled_projects = [];
          if (!options.forceUpdate)
            for (const project of options.projects) {
              if (this.active_projects_map[project.uid].features_enabled && !this.projects_features_map[project.uid])
                non_enabled_projects.push(project.uid);
            }
          // If forceUpdate is sent then no need to check for existing project features in the hashmap, all the projects sent through param has to get force updated
          else non_enabled_projects = options.projects.map(project => project.uid);
          if (non_enabled_projects.length) {
            const response = await this.$services.features.post({
              attribute: `container/${this.container.uid}/projects`,
              body: {
                projects: non_enabled_projects,
              },
              query: {
                attachments: false,
                notes: false,
              },
            });
            // Response is combined array of features of all the projects, hence grouping it again to map it to their respective projects
            const project_features_hash = groupBy(response.data.features, item => item.properties.project);

            Object.keys(project_features_hash).forEach((project) => {
              let features = [];
              features = project_features_hash[project].map((f) => {
                f.properties.featureTypeId
                = f.properties.class_id ?? f.properties.featureTypeId;

                f.properties.opacity = Number(f.properties.opacity ?? 0.0);
                if (isString(f.properties.dataProperties))
                  f.properties.dataProperties = JSON.parse(f.properties.dataProperties);

                return f;
              });
              this.projects_features_map[project] = groupBy(
                features,
                f => f.properties.featureTypeId,
              );
            });
            if (auth_store.check_split('merge_sm_data_with_terra_features'))
              await this.set_sm_instances_for_features({ features: this.features });
          }
        }
        catch (err) {
          logger.error(err);
        }
      },
      async set_projects_details(options) {
        try {
          let non_enabled_projects = [];

          if (!options.forceUpdate)
            for (const project of options.projects) {
              if (!this.projects_details_map[project.uid])
                non_enabled_projects.push(project.uid);
            }
          // If forceUpdate is sent then no need to check for existing project details in the hashmap, all the projects sent through param has to get force updated
          else non_enabled_projects = options.projects.map(project => project.uid);
          if (non_enabled_projects.length) {
            const response = await this.$services.common.post(
              {
                url: `${import.meta.env.VITE_APP_CORE_API_HOST}/list-projects`,

                body: {
                  projects: non_enabled_projects,
                  reports: true,

                },
              },
            );
            response.data.result.forEach((project) => {
              this.projects_details_map[project.uid] = project;
            });
          }

          return options.projects;
        }
        catch (err) {
          logger.error(err);
        }
      },
      async set_projects_meta(options) {
        try {
          for (const project of options.projects) {
            let meta = this.projects_meta_map[project.uid];
            // Check if tile_url present and meta is not fetched
            if (meta || !project?.reports?.orthotiles?.tile_url) {
              if (this.active_projects_map[project?.uid].ortho_enabled || this.active_projects_map[project?.uid].features_enabled)
                this.show_request_mbtiles_popup = {
                  ...this.show_request_mbtiles_popup,
                };
              return project;
            }

            const response = await this.$services.common.getAll({
              url: project.reports.orthotiles.tile_url,
            });

            if (response.data.filesize === 0)
              this.show_request_mbtiles_popup = {
                resolved_all_requests: false,
                requested_reports: {
                  ...(this.show_request_mbtiles_popup?.requested_reports || {}),
                  [project.uid]: response.data.id,
                },
              };

            meta = response.data;
            if (meta?.center)
              meta.location = meta.center.slice(0, 2);
            this.projects_meta_map[project.uid] = meta;
          }
          this.show_request_mbtiles_popup.resolved_all_requests = true;
          return options.projects;
        }
        catch (err) {
          logger.error(err);
        }
      },
      async fly_to_first_feature(options) {
        const turf = (await import('@turf/turf'));
        if (options.is_fly !== false && this.active_projects_map[options.project?.uid]?.features_enabled) {
          const features = Object.values(this.projects_features_map[options.project.uid] || {});
          if (features?.length) {
            const centroid = turf.centroid(features[0][0]);
            this.fly_to({ location: centroid.geometry.coordinates });
          }
        }
      },
      async fly_to_project(options) {
        const project
        = this.container?.groups[options.project.group]?.projects[
          options.project.uid
        ];

        if (!this.projects_meta_map[project?.uid]?.location && !project?.properties?.location)
          await this.fly_to_first_feature({ project });

        else
          this.fly_to({
            location: project?.properties?.location
              ? isStringified(project?.properties?.location)
              : this.projects_meta_map[options.project.uid]?.location,

          });
      },
      fly_to(options) {
        if ((this.settings.fly && options.location))
          this.map.flyTo({
            center: options.location,
            zoom: options.zoom || 15,
            speed: options.speed || 4,
            pitch: 0, // resets tilt on the map
            bearing: 0, // resets rotation on the map
          });
      },
      async update_map_features_and_polygon(
        options = { set_polygon: true },
      ) {
        const features = options.features || this.features_on_map;
        this.map.getSource('all_features_source').setData({
          type: 'FeatureCollection',
          features,
        });
        // For updating features with active filter color
        if (this.filters_state.active_filter)
          setMapboxLayersStylesByColorProperty();
        else
          this.update_features_styles();

        if (!this.is_creating_vector)
          this.clear_gl_draw();

        if (options.set_polygon)
          await this.set_polygon();
      },
      async set_ftg_and_update_features_styles(

        options = {},
      ) {
        if (!this.ftg)
          if (options.ftg) {
            this.ftg = options.ftg;
          }
          else {
            const response = await this.$services.feature_type_groups.getAll({
              query: {
                pk: true,
                container: options.uid,
              },
            });

            this.ftg = response.data;
          }
        this.update_features_styles(options);
      },
      load_patterns() {
        for (const id in this.feature_types)
          if (this.feature_types[id].properties?.fill_pattern)
            this.map.loadImage(
              this.feature_types[id].properties?.fill_pattern,
              (err, image) => {
                if (err)
                  throw err;
                if (this.map.hasImage(this.feature_types[id].uid))
                  this.map.removeImage(this.feature_types[id].uid);
                this.map.addImage(this.feature_types[id].uid, image);
              },
            );

        this.update_patterns_on_map();
      },
      /* ---------------- Add patterns to the map --------------- */
      update_patterns_on_map(data = {}) {
        const property = 'featureTypeId';
        window.map = this.map;
        if (Object.keys(data).length)
          this.map.loadImage(data.data, (err, image) => {
            if (err)
              throw err;
            if (this.map.hasImage(data.feature_id))
              this.map.removeImage(data.feature_id);
            this.map.addImage(data.feature_id, image);
          });
        try {
          let stops = this.ftg.reduce((arr, item) => {
            arr.push(
              ...item.featureTypes.map(f => [
                f.id,
                f.properties?.fill_pattern ? f.uid : 'pattern-0',
              ]),
            );
            return arr;
          }, []);
          stops = stops.sort((a, b) => a[0] - b[0]);

          const stops_config = {
            property,
            stops,
            default: 'pattern-0',
          };
          const gl_draw_config = {
            property: 'user_featureTypeId',
            stops,
            default: 'pattern-0',
          };

          const layers = [
            'gl-draw-polygon-fill-active.cold',
            'gl-draw-polygon-fill-active.hot',
            'gl-draw-polygon-fill-inactive.cold',
            'gl-draw-polygon-fill-inactive.hot',
          ];

          layers.forEach((layer) => {
            if (!this.map.getLayer('image-pattern-layer-hot'))
              this.map.addLayer(
                {
                  id: 'image-pattern-layer-hot',
                  type: 'fill',
                  source: 'mapbox-gl-draw-hot',
                },
                layer,
              );
            if (!this.map.getLayer('image-pattern-layer-cold'))
              this.map.addLayer(
                {
                  id: 'image-pattern-layer-cold',
                  type: 'fill',
                  source: 'mapbox-gl-draw-cold',
                },
                layer,
              );
          });
          this.map.setPaintProperty(
            'image-pattern-layer-hot',
            'fill-pattern',
            gl_draw_config,
          );
          this.map.setPaintProperty(
            'image-pattern-layer-cold',
            'fill-pattern',
            gl_draw_config,
          );

          this.map.setPaintProperty(
            'image-pattern-layer',
            'fill-pattern',
            stops_config,
          );
        }
        catch (err) {
          logger.error(err);
        }
      },

      update_features_styles(options = { gl_draw: false }) {
        const property = 'featureTypeId';
        try {
          const stops = Object.keys(this.feature_types).reduce((arr, cur) => {
            arr.push(this.feature_types[cur].id);
            arr.push(this.feature_types[cur].properties.color || '#222');
            return arr;
          }, []);

          const opacity_stops = this.ftg.reduce((arr, item) => {
            arr.push(
              ...item.featureTypes.map(f => [f.id, f.properties.opacity || 0.0]),
            );
            return arr;
          }, []);
          const fill_color_stops = this.ftg.reduce((arr, item) => {
            arr.push(
              ...item.featureTypes.map(f => [
                f.id,
                f.properties.fill_color || 'transparent',
              ]),
            );
            return arr;
          }, []);

          if (!stops.length)
            return;
          const gl_draw_config = [
            'match',
            ['get', 'user_featureTypeId'],
            ...stops,
            '#222',

          ];
          const stops_config = options.styles ?? [
            'match',
            ['get', property],
            ...stops,
            '#222',
          ];

          // POLYGON LINE STYLES
          this.map.setPaintProperty(
            'gl-draw-polygon-stroke-inactive.cold',
            'line-color',
            gl_draw_config,
          );

          this.map.setPaintProperty(
            'gl-draw-polygon-stroke-inactive.hot',
            'line-color',
            gl_draw_config,
          );

          this.map.setPaintProperty(
            'gl-draw-polygon-stroke-active.cold',
            'line-color',
            gl_draw_config,
          );

          this.map.setPaintProperty(
            'gl-draw-polygon-stroke-active.hot',
            'line-color',
            gl_draw_config,
          );

          // LINE STYLES
          this.map.setPaintProperty(
            'gl-draw-line-inactive.cold',
            'line-color',
            gl_draw_config,
          );

          this.map.setPaintProperty(
            'gl-draw-line-inactive.hot',
            'line-color',
            gl_draw_config,
          );

          this.map.setPaintProperty(
            'gl-draw-line-active.cold',
            'line-color',
            gl_draw_config,
          );

          this.map.setPaintProperty(
            'gl-draw-line-active.hot',
            'line-color',
            gl_draw_config,
          );

          // points colors
          this.map.setPaintProperty(
            'gl-draw-point-active.cold',
            'circle-color',
            [
              'match',
              ['get', 'user_featureTypeId'],
              ...stops,
              'yellow',
            ],
          );
          this.map.setPaintProperty(
            'gl-draw-point-active.hot',
            'circle-color',
            [
              'match',
              ['get', 'user_featureTypeId'],
              ...stops,
              'yellow',
            ],
          );
          this.map.setPaintProperty(
            'gl-draw-point-inactive.cold',
            'circle-color',
            [
              'match',
              ['get', 'user_featureTypeId'],
              ...stops,
              'yellow',
            ],
          );
          this.map.setPaintProperty(
            'gl-draw-point-inactive.hot',
            'circle-color',
            [
              'match',
              ['get', 'user_featureTypeId'],
              ...stops,
              'yellow',
            ],
          );

          // point radius
          this.map.setPaintProperty(
            'gl-draw-point-active.cold',
            'circle-radius',
            {
              property: 'user_elevation',
              stops: [
                [0, 5],
                [1, 10],
              ],
            },
          );
          this.map.setPaintProperty(
            'gl-draw-point-active.hot',
            'circle-radius',
            {
              property: 'user_elevation',
              stops: [
                [0, 5],
                [1, 10],
              ],
            },
          );
          this.map.setPaintProperty(
            'gl-draw-point-inactive.cold',
            'circle-radius',
            {
              property: 'user_elevation',
              stops: [
                [0, 5],
                [1, 10],
              ],
            },
          );
          this.map.setPaintProperty(
            'gl-draw-point-inactive.hot',
            'circle-radius',
            {
              property: 'user_elevation',
              stops: [
                [0, 5],
                [1, 10],
              ],
            },
          );

          // FOR BOUNDS
          this.map.setPaintProperty(
            'gl-draw-polygon-fill-active.hot',
            'fill-color',
            {
              property: 'user_bounds',
              stops: [[1, 'yellow']],
            },
          );
          this.map.setPaintProperty(
            'gl-draw-polygon-fill-active.cold',
            'fill-color',
            {
              property: 'user_bounds',
              stops: [[1, 'yellow']],
            },
          );

          this.map.setPaintProperty(
            'gl-draw-polygon-stroke-active.cold',
            'line-width',
            {
              property: 'user_edit_mode',
              stops: [[1, 4]],
              default: 2,
            },
          );
          this.map.setPaintProperty(
            'gl-draw-polygon-stroke-active.hot',
            'line-width',
            {
              property: 'user_edit_mode',
              stops: [[1, 4]],
              default: 2,
            },
          );
          this.map.setPaintProperty(
            'gl-draw-polygon-stroke-inactive.cold',
            'line-width',
            {
              property: 'user_edit_mode',
              stops: [[1, 4]],
              default: 2,
            },
          );
          this.map.setPaintProperty(
            'gl-draw-polygon-stroke-inactive.hot',
            'line-width',
            {
              property: 'user_edit_mode',
              stops: [[1, 4]],
              default: 2,
            },
          );

          // FOR POLYGONS
          const layers = [
            'gl-draw-polygon-fill-active.cold',
            'gl-draw-polygon-fill-active.hot',
            'gl-draw-polygon-fill-inactive.cold',
            'gl-draw-polygon-fill-inactive.hot',
          ];
          layers.forEach((layer) => {
            this.map.setPaintProperty(layer, 'fill-color', {
              property: 'user_featureTypeId',
              stops: fill_color_stops.sort((a, b) => a[0] - b[0]),
              default: 'transparent',
            });

            this.map.setPaintProperty(layer, 'fill-opacity', {
              property: 'user_featureTypeId',
              stops: opacity_stops.sort((a, b) => a[0] - b[0]),
              default: 0.0,
            });
          });

          window.map = this.map;

          if (this.map.getLayer('linestring_feature_layer'))
            this.map.setPaintProperty(
              'linestring_feature_layer',
              'line-color',
              stops_config,
            );

          if (this.map.getLayer('polygon_feature_layer')) {
            this.map.setPaintProperty('polygon_feature_layer', 'fill-color', {
              property,
              stops: orderBy(fill_color_stops, item => item[0], ['asc']),
              default: 'transparent',
            });
            this.map.setPaintProperty(
              'polygon_feature_layer',
              'fill-opacity',
              {
                property,
                stops: orderBy(opacity_stops, item => item[0], ['asc']),
                default: 0,
              },
            );
          }
          if (this.map.getLayer('point_feature_layer'))
            this.map.setPaintProperty(
              'point_feature_layer',
              'circle-color',
              stops_config,
            );
        }
        catch (err) {}
      },
      async update_project_in_container(options = {}) {
        const container = { ...this.container };
        container.groups[options.project.group].projects[options.project.uid]
        = options.project;
        return container;
      },
      /* -------------------------- set_group_ortho -------------------------- */
      async set_group(options) {
        try {
          const projects_data = Object.values(options.group.projects);
          this.projects_request_status = {
            ...this.projects_request_status,
            total: projects_data.length,
            current: 0,
            cancel: false,
          };

          let updated_projects = [];
          const controller = new AbortController();
          const signal = controller.signal;
          const chunkSize = (this.active_group_map[options.group.uid].features_enabled || this.active_group_map[options.group.uid].ortho_enabled) ? 50 : projects_data.length;
          for (let index = 0; index < projects_data.length; index += chunkSize) {
            const chunk = projects_data.slice(index, index + chunkSize);
            chunk.forEach((project) => {
              this.active_projects_map[project.uid].ortho_enabled = this.active_projects_map[project.uid].features_enabled = this.active_group_map[options.group.uid].features_enabled || this.active_group_map[options.group.uid].ortho_enabled;
            });
            // Keeping an array against set_project_essentials because it's a pending promise and .all requires an array of promises
            const res = await axios.all([this.set_projects_essentials({ projects: chunk })], { signal });
            // Accessing the additional array at index 0
            updated_projects.push(...res[0]);

            if (this.projects_request_status.cancel) {
              controller.abort();
              updated_projects.push(...projects_data.slice(index, projects_data.length));
              break;
            }
          }

          updated_projects = sort_group_and_projects(updated_projects);
          options.group.projects = keyBy(updated_projects, 'uid');

          return options.group;
        }
        catch (err) {
          logger.error('🚀 ~ file: terra.store.js:1103 ~ set_group ~ err:', err);
        }
      },

      async assign_ftg_to_view({ container_uid, feature_group_uid }) {
        await this.$services.feature_type_groups.assign_to_view({
          id: container_uid,
          body: {
            featureTypeGroups: [feature_group_uid],
          },
        });
      },

      async moveFeatureTypes(data) {
        const hashMap = keyBy(this.ftg, 'uid');
        const featureTypeMap = keyBy(
          hashMap[data.element.featureTypeGroup].featureTypes,
          'uid',
        );
        const { data: responseData } = await this.$services.feature_types.move({
          body: {
            featureTypeUids: hashMap[
              data.element.featureTypeGroup
            ].featureTypes.map(item => item.uid),
          },
        });
        responseData.forEach((item) => {
          featureTypeMap[item.uid] = { ...featureTypeMap[item.uid], ...item };
        });
        hashMap[data.element.featureTypeGroup].featureTypes = Object.values(
          featureTypeMap,
        );
        this.ftg = Object.values(hashMap);
      },
      async syncApiForInspectionForms(form, options = {}) {
        try {
          const response = await this.$services.terra.post({
            attribute: 'metadata/intg_101',
            body: (options.payload && Array.isArray(options.payload) ? options.payload : null) ?? [
              {
                event: options.event,
                feature: form.properties.integration?.feature?.uid,
                field: form.properties.integration?.field?.uid,
                form: {
                  uid: form.uid,
                  status: options.status,
                },
              },
            ],
          });
          if (this.$router.currentRoute.value.name === 'terra-viewer')
            await this.update_features_in_state({
              features: response?.data?.features,
              properties_to_update: ['workflowProgress', 'workflow', 'featureTypeId', 'featureType'],
              clearSelectedFeatures: false,
            });
          return response;
        }
        catch (err) {
          logger.error(err);
        }
      },
      async update_ftg(
        { action, data, group_uid = null, container_uid = null },
      ) {
        const asset = this.$router.currentRoute.value.params.asset_id;
        const hashMap = keyBy(this.ftg, 'uid');
        const ftgs = cloneDeep(this.ftg);
        let response;
        try {
          switch (action) {
            case 'add':
              response = await this.$services.feature_type_groups.post({
                body: { ...data, asset },
              });
              hashMap[response.data.uid] = response.data;

              this.assign_ftg_to_view({
                container_uid,
                feature_group_uid: response.data.uid,
              });
              break;

            case 'update':
              hashMap[group_uid] = { ...hashMap[group_uid], ...data };
              await this.$services.feature_type_groups.patch({
                id: group_uid,
                body: data,
              });
              break;
            case 'delete':
              delete hashMap[group_uid];
              await this.$services.feature_type_groups.delete({
                id: group_uid,
              });
              break;
          }
          this.ftg = Object.values(hashMap);
        }
        catch (err) {
          this.ftg = ftgs;
        }
        if (response?.data?.uid)
          return response.data.uid;
      },

      async delete_features(options = { skip_API: false }) {
        try {
          if (!options?.skip_API)
            await this.$services.features.delete(
              {
                attribute: `container/${this.container.uid}`,
                body: this.selected_features.map(f => ({ uid: f.properties.uid })),
              },
              {
                type: 'success',
                text: 'Feature deleted successfully!',
              },
            );

          for (const element of this.selected_features) {
            const feature = element;
            if (!feature.properties.uid)
              continue;
            if (feature.properties.featureTypeId) {
              remove(
                this.projects_features_map[feature.properties.project]?.[
                  feature.properties.featureTypeId
                ],
                f => f.properties.uid === feature.properties.uid,
              );
            }
            else {
              const key = this.projects_features_map[feature.properties.project]?.null ? 'null' : 'undefined';
              remove(
                this.projects_features_map[feature.properties.project]?.[key],
                f => f.properties.uid === feature.properties.uid,
              );
            }
          }
          if (this.gallery_view_state.is_active)
            this.gallery_view_state.active_attachment = null;
          this.selected_features = [];
          this.update_features_on_map_flag += 1; // trigger watcher in terra filter
          this.clear_gl_draw();
          await this.update_map_features_and_polygon();
        }
        catch (err) {
          logger.error(err);
        }
      },
      remove_old_feature_from_project_map(i, options, project_id, feature) {
        if (options.oldResponse?.length && options.oldResponse[i]) {
          const oldFeature = options.oldResponse[i];
          // if feature_type is changed delete the feature from old feature_type list
          if (
            oldFeature
          && (oldFeature.properties.oldfeatureTypeId
            || (oldFeature.properties.oldfeatureTypeId === null || oldFeature.properties.oldfeatureTypeId === undefined))
          )
            if (oldFeature.properties.oldfeatureTypeId) {
              remove(
                this.projects_features_map[project_id]?.[
                  oldFeature.properties.oldfeatureTypeId
                ],
                f => f.properties.uid === feature.properties.uid,
              );
            }
            else {
              const key = this.projects_features_map[feature.properties.project]?.null ? 'null' : 'undefined';
              remove(
                this.projects_features_map[feature.properties.project]?.[key],
                f => f.properties.uid === feature.properties.uid,
              );
            }
        }
      },
      async update_features_in_state(options = {}) {
        if (!options?.features)
          return;
        const total = options.features.length;
        for (let i = 0; i < total; i++) {
          const feature = options.features[i];

          const project_id
            = feature.properties.project || feature.properties.projectUid;
          this.remove_old_feature_from_project_map(i, options, project_id, feature);
          const features = this.projects_features_map[project_id]?.[feature.properties.featureTypeId] || [];

          const featureIndex = features?.findIndex(
            item => item.properties.uid === feature.properties.uid,
          );

          if (featureIndex !== -1) {
            if (options.properties_to_update)
              feature.properties = {
                ...features[featureIndex].properties,
                ...pick(feature.properties, options.properties_to_update),
              };

            features[featureIndex] = feature;
          }
          else { features.push(feature); }

          if (!this.projects_features_map[project_id])
            this.projects_features_map[project_id] = {};

          this.projects_features_map[project_id][
            feature.properties.featureTypeId
          ] = features;
        }

        this.update_features_on_map_flag += 1; // trigger watcher in terra filter
        if (!options.do_not_update_features_on_map)
          this.update_map_features_and_polygon();

        if (options.clearSelectedFeatures) {
          this.selected_features = [];
          this.clear_gl_draw();
        }
      },
      clear_gl_draw() {
        this?.draw?.deleteAll();
        this.map.setFilter('polygon_feature_layer', null);
        this.map.setFilter('linestring_feature_layer', null);
        this.map.setFilter('point_feature_layer', [['==', '$type', 'Point']]);
      },
      async create_or_update_selected_features(
      options = { clearSelectedFeatures: false, updateFeatureRequest: true },
      ) {
        const selected_features_uids = {};
        const payload = groupBy(this.selected_features, f =>
          f.properties.uid ? 'update' : 'create',
        );
        let response;
        let oldResponse;
        // Update features
        if (payload?.update?.length) {
          payload.update.forEach((el) => {
            selected_features_uids[el.properties.uid] = el;
          });
          try {
            if (options.updateFeatureRequest && !options.save_on_create_form_task) {
              const payload_update_clone = cloneDeep(payload.update);
              const project_features_map = groupBy(payload_update_clone, f => f.properties.project);
              const promises = [];
              Object.keys(project_features_map).forEach((project_id) => {
                const features = project_features_map[project_id].map((f) => {
                  f.properties = omit(f.properties, ['attachments', 'notes']);
                  return f;
                });
                promises.push(this.$services.features.put({
                  body: { type: 'FeatureCollection', features },
                  attribute: `container/${this.container.uid}/project/${project_id}`,
                }));
              });
              const responses = await Promise.all(promises);
              responses.forEach((res) => {
                if (!response?.data?.features)
                  response = { data: { features: [], type: 'FeatureCollection' } };
                response.data.features.push(...res.data.features);
              });
            }
            oldResponse = !response ? payload.update : orderBy(payload.update, feature => response.data.features.map(f => f.properties.uid).indexOf(feature.properties.uid));
          }
          catch (err) {
            logger.error(err);
          }
        }
        // Create features
        if (payload?.create?.length) {
          payload.create = payload.create.map((f) => {
            f.properties.project = this.last_selected_project.uid;

            return f;
          });
          try {
            response = await this.$services.features.post({
              body: { type: 'FeatureCollection', features: payload.create },
              attribute: `container/${this.container.uid}/project/${this.last_selected_project.uid}`,
            });
            if (response?.data?.features)
              response.data.features.forEach((el) => {
                selected_features_uids[el.properties.uid] = el;
              });
            oldResponse = payload.create;
          }
          catch (err) {
            logger.error(err);
          }
        }
        await this.handle_features_update(response, oldResponse, payload, options);
      },
      async handle_features_update(data_response, old_response, payload, options) {
        let response = data_response;
        if (!options.updateFeatureRequest) {
          await new Promise(resolve => setTimeout(resolve, 1000));
          response = payload.update;
        }
        else if (response?.data?.features) {
          response = response.data.features;
        }
        else { response = response?.data; }
        this.features_updated = [];
        if (response)

          await this.update_features_in_state({
            features: response,
            oldResponse: old_response,
            clearSelectedFeatures: options.clearSelectedFeatures,
          });
        if (options.reselectFeatures)
          this.selected_features = response;
      },
      async get_feature_details(feature) {
        try {
          const { data } = await this.$services.features.get({
            attribute: `${feature.properties.uid}/container/${this.container.uid}`,
          });
          return data;
        }
        catch (error) {
          logger.error(error);
          return null;
        }
      },

      async update_feature_type(
        { group_uid, feature_type_uid = null, data, action },
      ) {
        const asset = this.$router.currentRoute.value.params.asset_id;
        const ftgs = cloneDeep(this.ftg);
        const hashMap = keyBy(this.ftg, 'uid');
        try {
          switch (action) {
            case 'add':
              try {
                const response = await this.$services.feature_types.post({
                  body: { ...data, asset },
                });
                hashMap[group_uid].featureTypes.push(response.data);
              }
              catch (error) {
                logger.error('Error adding feature type:', error);
              }
              break;
            case 'update':
              hashMap[group_uid].featureTypes.forEach((item, index) => {
                if (item.uid === feature_type_uid)
                  hashMap[group_uid].featureTypes[index] = {
                    ...hashMap[group_uid].featureTypes[index],
                    ...data,
                  };
              });
              await this.$services.feature_types.patch({
                id: feature_type_uid,
                body: data,
              });
              break;
            case 'delete':
              hashMap[group_uid].featureTypes.forEach((item, index) => {
                if (item.uid === feature_type_uid)
                  hashMap[group_uid].featureTypes.splice(index, 1);
              });
              await this.$services.feature_types.delete({
                id: feature_type_uid,
              });
              break;
          }
          this.ftg = Object.values(hashMap);
        }
        catch (err) {
          this.ftg = ftgs;
        }
      },

      // WORKFLOW
      async set_terra_workflows(options) {
        const response = await this.$services.terra_workflow_service.getAll({
          query: {
            asset: options.asset_id,
          },
        });
        this.terra_workflows = keyBy(response.data, 'uid');
      },
      async delete_workflow(options) {
        await this.$services.terra_workflow_service.delete(options);
        delete this.terra_workflows[options.id];
        this.terra_workflows = { ...this.terra_workflows };
      },
      async create_workflow(options) {
        await this.$services.terra_workflow_service.post(options);
        await this.set_terra_workflows({ asset_id: options?.asset_id });
      },
      async update_workflow(options) {
        await this.$services.terra_workflow_service.patch(options);
        await this.set_terra_workflows({ asset_id: options?.asset_id });
      },

      // GROUPS
      async update_groups({ payload, group_uid, action }) {
        const asset_id = this.$router.currentRoute.value.params.asset_id;
        switch (action) {
          case 'add':
            await this.$services.common.post({
              baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
              url: `assets/${asset_id}/containers/${this.container.uid}/groups`,
              body: {
                project_group: { ...payload },
              },
            });
            break;
          case 'update':
            await this.$services.common.patch({
              baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
              url: `assets/${asset_id}/containers/${this.container.uid}/groups/${group_uid}`,
              body: {
                project_group: { ...payload },
              },
            });
            break;
          case 'delete':
            await this.$services.common.delete({
              baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
              url: `assets/${asset_id}/containers/${this.container.uid}/groups/${group_uid}`,
            });
            break;
        }
        await sleep(3000);
        await this.set_container({ uid: this.container.uid, forceUpdate: true, keep_project_map: true });
        if (action === 'delete')
          this.update_features_on_map_flag += 1; // trigger watcher in terra filter
      },

      // PROJECTS
      async update_projects({ payload, group_uid, project_uid, geojson_data, action }) {
        const asset_id = this.$router.currentRoute.value.params.asset_id;
        let project_response = {};
        switch (action) {
          case 'add':
            project_response = await this.$services.common.post({
              baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
              url: `assets/${asset_id}/containers/${this.container.uid}/groups/${group_uid}/projects`,
              body: {
                project: { ...payload },
              },
            });
            break;
          case 'update':
            await this.$services.common.patch({
              baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
              url: `assets/${asset_id}/containers/${this.container.uid}/groups/${group_uid}/projects/${project_uid}`,
              body: {
                project: { ...payload },
              },
            });
            break;
          case 'delete':
            await this.$services.common.delete({
              baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
              url: `assets/${asset_id}/containers/${this.container.uid}/groups/${group_uid}/projects/${project_uid}`,
            });
            break;
        }
        await sleep(3000);

        const project_id = project_response?.data?.project?.uid;
        await this.set_container({ uid: this.container.uid, forceUpdate: true, keep_project_map: true });
        if (action === 'add' && geojson_data)
          await this.$services.features.post({
            uid: project_id,
            body: preProcessGeojson(geojson_data),
            attribute: `container/${this.container.uid}/project/${project_id}`,
          });

        if (action === 'add')
          await this.toggle_project({ project: this.active_projects_data_map({ all_projects: true })[project_id] });
        this.update_features_on_map_flag += 1; // trigger watcher in terra filter
      },

      get_feature_properties(feature, fields_used = null) {
        const extra_properties = feature.properties.extraProperties || {};
        const additional_properties = this.get_feature_additional_properties(feature);
        let sm_instance_properties = { ...(this.sm_instances_map.feature_name_hash[feature.properties.name] || {}) };
        sm_instance_properties = Object.fromEntries(Object.entries(sm_instance_properties).filter(([k, v]) => !fields_used || fields_used[k]));

        return {
          ...additional_properties,
          ...extra_properties,
          ...sm_instance_properties,
        };
      },

      get_feature_additional_properties(feature) {
        const project_id = feature.properties.project || feature.properties.projectUid;
        const project = this.active_projects_data_map({ all_projects: true })?.[project_id];
        const group = this.container.groups?.[project?.group];
        return { Layer: group?.name, Sublayer: project?.name };
      },

      terra_track_events(event_name, properties = {}) {
        const default_properties = {
          container: this.container.name,
        };

        this.$track_event(event_name, {
          ...default_properties,
          ...properties,
        });
      },

      async set_projects_attachments(options) {
        try {
          let { project_features_attachment_map } = this.gallery_view_state;
          const filtered_projects = options.projects.filter(project => !project_features_attachment_map[project.uid]);
          if (!filtered_projects.length)
            return;

          const { data } = await this.$services.features.post({
            attribute: `container/${this.container?.uid}/attachments`,
            body: {
              projects: filtered_projects.map(project => project.uid),
            },
          });
          const formatted_data = data.map((value) => {
            const feature = this.features_hash[value.feature_uid];
            const project_uid = feature.properties.project || feature.properties.projectUid;

            return {
              ...value,
              project_uid,
            };
          });
          const group_by_project = groupBy(formatted_data, 'project_uid');
          project_features_attachment_map = {
            ...project_features_attachment_map,
            ...mapValues(group_by_project, attachment_features => keyBy(attachment_features, 'feature_uid')),
          };
          this.gallery_view_state.project_features_attachment_map = project_features_attachment_map;
        }
        catch (error) {
          logger.error(error);
        }
      },
      set_gallery_state(key, value) {
        this.gallery_view_state[key] = value;
      },
      get_filtered_attachments(features_map) {
        return Object.values(features_map).filter(({ feature_uid }) => this.filtered_features_hash[feature_uid]);
      },
      get_filename_from_key(key) {
        if (!key)
          return '';
        const extension = key.split('.').pop();
        return `Untitled.${extension}`;
      },
    },

  })();
}
