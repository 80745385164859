<script setup>
import DocumentSidebar from '~/dms/components/documents/document-sidebar.vue';
import { useDashboardDMSStore } from '~/dashboard/store/dashboard-dms.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { getRandomKey } from '~/common/utils/common.utils';

const props = defineProps({
  folders: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close']);

const selected_items = ref([]);
const dashboard_dms_store = useDashboardDMSStore();
const { set_widget_configuration } = useDashboardStore();

const tree_data = ref([]);
const active_nodes = ref([]);

function randomNodeKey(id) {
  return `${id}+${getRandomKey()}`;
}
function keyify(items) {
  return items.map((item) => {
    if (item?.children)
      return {
        ...item,
        hasChildren: true,
        children: keyify(item.children),
        nodeKey: randomNodeKey(item.uid),
      };
    else
      return {
        ...item,
        nodeKey: randomNodeKey(item.uid),
      };
  });
}

function save() {
  const folders = selected_items.value.map(item => ({ ...item, uid: item.key.split('+')[0] }));
  dashboard_dms_store.update_dms_configuration({ ...dashboard_dms_store.dms_configuration, folders });
  set_widget_configuration({
    ...dashboard_dms_store.dms_configuration, folders,
  });
}

function getActiveNodeKeys(tree_items) {
  const activeNodeKeys = [];

  function searchTree(treeItems, activeInstances) {
    treeItems.forEach((item) => {
      if (activeInstances.includes(item.uid))
        activeNodeKeys.push(item.nodeKey); // Object found, add it to the list
      if (item.hasChildren && item?.children?.length)
        searchTree(item.children, activeInstances);
    });
  }

  searchTree(tree_items, dashboard_dms_store.dms_configuration.folders.map(node => node.key.split('+')[0]));
  return activeNodeKeys;
}

onBeforeMount(() => {
  tree_data.value = keyify(props.folders);
  if (dashboard_dms_store.dms_configuration.folders?.length)
    active_nodes.value = getActiveNodeKeys(tree_data.value);
});
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-header @close="$emit('close')">
      <template #left>
        Folders
      </template>
    </hawk-modal-header>
    <hawk-modal-content :is_scroll="false">
      <DocumentSidebar
        :multi="true"
        :active_folder="props.folders[0]"
        :data="tree_data"
        sidebar_class="w-full max-h-[400px]"
        :default_checked="active_nodes"
        @selected="(e) => selected_items = e"
      />
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end gap-4">
          <HawkButton type="default" @click="save">
            {{ $t('Save') }}
          </HawkButton>
          <HawkButton type="outlined" @click="emit('close')">
            {{ $t('Close') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
