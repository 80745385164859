<script setup>
import { isDate } from 'lodash-es';

import { getFormsFilterConfig, getFormsListFilterConfig } from '~/dashboard/components/filters/composables/forms-filters';
import { getScheduleFilterConfig } from '~/dashboard/components/filters/composables/schedule-filters';
import { getAssetsFilterConfig } from '~/dashboard/components/filters/composables/assets-filters';
import { getDMSFilterConfig } from '~/dashboard/components/filters/composables/dms-filters';
import { isDateIntervalOperator } from '~/dashboard/components/filters/composables/filters-config';

import FormFieldTree from '~/forms/molecules/form-field-tree.vue';

const props = defineProps({
  // keys for the filters to be used (ex. status, assignees, due_date, ...)
  filters: {
    type: Array,
    default: () => [],
  },
  initial_state: {
    type: Array,
    default: () => [],
  },
  // custom fields that can be used as filters, used in forms
  custom_fields: {
    type: Array,
    default: () => [],
  },
  service: {
    type: String,
    default: null,
  },
  is_workflow: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update']);

const filter_options = computed(() => {
  const res = {};

  if (!props.service)
    props.filters.forEach((key) => {
      res[key] = getFilterConfig(key);
    });

  else if (props.service === 'forms_list')
    props.filters.forEach((key) => {
      res[key] = getFormsListFilterConfig(key);
    });

  else if (props.service === 'forms')
    props.custom_fields.forEach((field) => {
      res[field.value] = getFormsFilterConfig(field);
    });

  else if (props.service === 'schedule')
    props.custom_fields.forEach((field) => {
      res[field.value] = getScheduleFilterConfig(field);
    });

  else if (props.service === 'assets')
    props.filters.forEach((key) => {
      res[key] = getAssetsFilterConfig(key);
    });
  else if (props.service === 'dms')
    props.filters.forEach((key) => {
      res[key] = getDMSFilterConfig(key);
    });
  return res;
});

const form$ = ref(null);
const selected_filters = ref([]);
const prevent_field_reset = ref(false);
const form_data = ref({});

const available_filters = computed(() => {
  // also include filter_type, so forms with similar names won't get mixed
  // for example status and form_status
  return Object.keys(filter_options.value || {}).map((key) => {
    const item = filter_options.value[key];
    return {
      ...item,
      filter_type: key,
      disabled: selected_filters.value.includes(key),
    };
  });
});

const all_filters_valid = computed(() => {
  if (!form_data.value?.filter_list?.length)
    return true;

  const empty_filter = form_data.value?.filter_list?.find((f) => {
    if (!f)
      return true;
    if (!f.filter_type)
      return true;
    // anything but 'others' filter (which is checkbox input), must have operator
    if (f.filter_type !== 'others' && !f.operator)
      return true;
    // anything but 'others' filters and interval date operators, must have a value
    if (f.filter_type !== 'others' && !isDateIntervalOperator(f.operator)) {
      let is_empty_value = false;
      if (!f.value?.length && !isDate(f.value) && typeof f.value !== 'boolean' && typeof f.value !== 'number')
        is_empty_value = true;

      return is_empty_value;
    }
    return false;
  });

  return typeof empty_filter === 'undefined';
});

const form_controls = computed(() => {
  let can_add = true;
  let can_remove = true;

  if (form_data.value?.filter_list?.length)
    can_add = all_filters_valid.value;

  else can_remove = false;

  return {
    add: can_add,
    remove: can_remove,
    sort: true,
  };
});

async function changeFilterTypeHandler(newValue, index) {
  if (selected_filters.value[index])
    selected_filters.value[index] = newValue;
  else
    selected_filters.value.push(newValue);

  form_data.value.filter_list[index].filter_type = newValue;
  form_data.value.filter_list[index].operator = null;
  form_data.value.filter_list[index].value = [];
}

function fetchOperator(index) {
  const filter_name = form_data.value.filter_list?.[index]?.filter_type;
  const operator = filter_options.value?.[filter_name]?.operator || null;
  return operator;
}

function fetchFilterFields(index) {
  const filter_name = form_data.value.filter_list?.[index]?.filter_type;
  const operator_value = form_data.value.filter_list?.[index]?.operator;

  let filter_fields = filter_options.value?.[filter_name]?.default_fields || [];

  const operator = filter_options.value?.[filter_name]?.operator || null;

  if (operator?.custom_fields?.[operator_value])
    filter_fields = operator?.custom_fields?.[operator_value];
  return filter_fields;
}

async function removeFromSelection(index) {
  if (selected_filters.value[index]) {
    const item_index = selected_filters.value.indexOf(selected_filters.value[index]);
    if (item_index !== -1) {
      prevent_field_reset.value = true;
      selected_filters.value.splice(item_index, 1);
    }
  }

  emitUpdates();

  // enable setFieldInitialData() again
  setTimeout(() => {
    prevent_field_reset.value = false;
  }, 300);
}

function handleFormChange(data) {
  form_data.value = { ...data };
  emitUpdates();
}

function emitUpdates() {
  if (all_filters_valid.value) {
    const filtered_filters = form_data.value?.filter_list?.filter(f => !!f?.filter_type) || [];
    emit('update', filtered_filters);
  }
}

function setFieldInitialData(index, field_name, field_default) {
  // resets the value each time the filters change
  // this is needed because for example, date range field needs a [] default, and date field needs 'null'
  // so we hard reset them

  // if we are editing the widget, prevent reseting until data is set
  // if we are deleting another filter, prevent reseting until deleting and reordering is done
  if (
    typeof field_default !== 'undefined'
    && !prevent_field_reset.value
  )
    form_data.value.filter_list[index][field_name] = field_default;
}

function setData() {
  const filter_values = props.initial_state || [];

  if (!filter_values.length)
    return;

  filter_values.forEach((filter) => {
    selected_filters.value.push(filter.filter_type);
  });

  form_data.value = {
    filter_list: filter_values,
  };

  prevent_field_reset.value = true;

  // enable setFieldInitialData() again
  setTimeout(() => {
    prevent_field_reset.value = false;
  }, 300);
}
</script>

<template>
  <div>
    <Vueform
      ref="form$"
      v-model="form_data"
      :sync="true"
      :float-placeholders="false"
      :display-errors="false"
      size="sm"
      @change="handleFormChange($event)"
      @mounted="setData"
    >
      <ListElement
        name="filter_list"
        :add-text="`+ ${'Add filter'}`"
        :presets="['repeatable_list']"
        :initial="0"
        :controls="form_controls"
        :add-classes="{
          ListElement: {
            remove: ['z-10'],
          },
        }"
        :remove-classes="{
          ListElement: {
            remove: ['z-999'],
          },
        }"
        @remove="removeFromSelection"
      >
        <template #default="{ index }">
          <ObjectElement
            :name="index"
            :columns="{
              default: { container: 11, label: 12, wrapper: 12 },
              sm: { container: 11, label: 12, wrapper: 12 },
              md: { container: 11, label: 12, wrapper: 12 },
            }"
          >
            <FormFieldTree
              v-if="props.service === 'forms'"
              :data="available_filters"
              :tree_config="{
                options: {
                  name: 'filter_type',
                  object: false,
                  search: true,
                  native: false,
                  placeholder: 'Select Type',
                  canClear: false,
                  hideSelected: true,
                  canDeselect: false,
                  removeClasses: {
                    SelectElement: {
                      select: {
                        optionSelectedDisabled: 'form-color-on-primary form-bg-primary',
                      },
                    },
                  },
                },
                is_workflow,
                select_type: 'LEAF_PRIORITY',
                select_view: 'SELECT',
                show_expanded_search: true,
              }"
              @onSelect="(value) => changeFilterTypeHandler(value?.[0]?.uid, index)"
            />
            <SelectElement
              v-else
              :items="available_filters"
              :native="false"
              name="filter_type"
              placeholder="Select Type"
              value-prop="filter_type"
              :can-clear="false"
              @select="(value) => changeFilterTypeHandler(value, index)"
            />
            <template
              v-if="fetchOperator(index)"
            >
              <component
                :is="fetchOperator(index).component"
                v-bind="{
                  ...fetchOperator(index),
                }"
              />
            </template>
            <template
              v-for="(field, field_index) in fetchFilterFields(index)"
              :key="`${field.name || 'static'}-${field_index}-${index}-${field.refresh_component_key}`"
            >
              <component
                :is="field.component"
                v-bind="{
                  ...field,
                }"
                @beforeMount="setFieldInitialData(index, field.name, field.default)"
              >
                <template v-if="field.component === 'TagsElement'" #tag="{ option, handleTagRemove }">
                  <div class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
                    <p class="text-gray-700 w-auto truncate inline-block">
                      <HawkText :content="option.label" :length="20" />
                    </p>

                    <div class="cursor-pointer ml-1" @mousedown.prevent="handleTagRemove(option, $event)">
                      <IconHawkXClose class="text-gray-400 w-4 h-4" />
                    </div>
                  </div>
                </template>
              </component>
            </template>
          </ObjectElement>
        </template>
      </ListElement>
    </Vueform>
  </div>
</template>

<style scoped>
  :deep(input[type="number"]) {
    -moz-appearance: auto !important;
    appearance: auto !important;
  }
</style>
