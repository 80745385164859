<script setup>
import { useFormDetailStore } from '~/forms/store/form-detail.store.js';
import { getFieldComponentData, getSubmittedFieldData } from '~/forms/composables/form-field-schema.composable.js';
import { useFieldSectionVisibility } from '~/forms/composables/form-detail-composable';

const props = defineProps({
  sections: {
    type: Object,
  },
  has_steps: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
  },
});

const form_detail_store = useFormDetailStore(`dashboard_${props.id}`);
const { get_field_visibility, get_section_visibility } = useFieldSectionVisibility(form_detail_store, true);

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

function getSections() {
  const sections = props?.sections;
  return markRaw(sections || []);
}

function getSignatureData(value) {
  const signature = value?.[0]?.location || value?.[0] || value?.location;
  return signature;
}

const field_value_map = computed(() => form_detail_store.field_value_map);

const checklist_status_map = {
  resolved: 'Yes',
  rejected: 'No',
  onHold: 'NA',
};
</script>

<template>
  <div>
    <!---  Section --->
    <div v-for="(section, section_index) in getSections()" :key="section.uid" :class="get_section_visibility(section)">
      <div v-if="section.status === 'active'" class="border-b mb-8">
        <div class="font-semibold text-sm py-2 px-4 pl-6 bg-gray-100 flex justify-between border sticky z-40" :class="[has_steps ? 'top-[40px]' : '-top-[16px]']">
          {{ $t('Section') }} {{ (section?.section_index || section_index) + 1 }}:   {{ section.name }}
          <div class="pointer-events-auto cursor-pointer">
            <icon-hawk-plus-circle v-if="section.hide" @click="section.hide = false" />
            <icon-hawk-minus-circle v-else @click="section.hide = true" />
          </div>
        </div>

        <div v-if="!section.hide" class="text-sm text-gray-600 font-regular">
          <!---  Signature section --->
          <div v-if="section.type === 'signature'">
            <div v-for="field in section.fields.filter(field => field.status === 'active')" :key="field.uid" class="p-2 px-6">
              {{ field.name }}
              <div v-if="!getSignatureData(field_value_map[field.uid])">
                -
              </div>
              <hawk-signature v-else :model-value="getSignatureData(field_value_map[field.uid])" class="pointer-events-none" />
            </div>
          </div>

          <!---  Checklist section --->
          <div v-else-if="section.type === 'checklist'">
            <div v-for="field in section.fields.filter(field => field.status === 'active')" :key="field.uid">
              <div v-for="(item, index) in (field_value_map[field.uid] || [])" :key="item.uid" class="grid grid-cols-12 border-b">
                <div class="col-span-1 w-[4rem] p-2 px-6 border-r">
                  {{ index + 1 }}.
                </div>
                <div class="col-span-9 p-2 px-6">
                  {{ item.name }}
                </div>
                <div class="col-span-1 p-2 px-6 border-l">
                  {{ checklist_status_map[item.status] }}
                </div>
              </div>
            </div>
          </div>

          <!---  TableV2 section --->
          <div v-else-if="section.type === 'tablev2'" class="grid auto-cols-max grid-flow-col overflow-auto scrollbar">
            <div v-for="field in section.fields.filter(field => field.status === 'active' && get_field_visibility(section, field) !== 'hidden')" :key="field.uid" class="border-r border-b ">
              <div class="p-2 px-6 bg-gray-50 border-b">
                {{ field.name }}
              </div>
              <div v-for="(item, index) in (field_value_map[field.uid] || field.properties.value || [])" :key="index" class="p-2 px-6 border-b">
                {{ getSubmittedFieldData(field, item) }}
              </div>
            </div>
          </div>

          <!---  Basic section --->
          <div v-else>
            <div v-for="(field, index) in section.fields.filter(field => field.status === 'active')" :key="field.uid" class="grid grid-cols-12 border-b" :class="get_field_visibility(section, field)">
              <div class="col-span-1 w-[4rem] p-2 px-6 border-r">
                {{ index + 1 }}.
              </div>
              <div class="col-span-4 p-2 px-6">
                {{ field.name }}
              </div>
              <div class="col-span-7 p-2 px-6">
                <HawkWysiwygEditorComponent v-if="field.properties.type === 'info'" v-model="field.config.placeholder" :editor_enabled="false" :view="{ no_padding: true }" class="!p-0" editor_classes="!p-0 [&>*]:m-0" />
                <HawkWysiwygEditorComponent v-else-if="field.type === 'long_text'" :model-value="field_value_map[field.uid] || '-'" :editor_enabled="false" :view="{ no_padding: true }" class="!p-0" editor_classes="!p-0 [&>*]:m-0" />
                <div v-else-if="field.type === 'member' && getSubmittedFieldData(field, field_value_map[field.uid]) !== '-' " class="flex flex-wrap">
                  <HawkMembers
                    :members="[...getSubmittedFieldData(field, field_value_map[field.uid]).members,
                               ...getSubmittedFieldData(field, field_value_map[field.uid]).teams]"
                    type="label" size="badge"
                    :max_badges_to_display="20"
                  />
                </div>
                <hawk-signature v-else-if="field.properties.type === 'signature'" :model-value="field_value_map[field.uid]?.[0]" class="pointer-events-none" :placeholder="true" />
                <hawk-sheet-input v-else-if="field.type === 'multi_text'" v-bind="getFieldComponentData(field, { is_submitted: true, is_builder: false })" :editable="false" :model-value="field_value_map[field.uid]" />
                <hawk-attachments-list v-else-if="field.type === 'attachment' && Array.isArray(field_value_map[field.uid]) && field_value_map[field.uid].length > 0" :items="getSubmittedFieldData(field, field_value_map[field.uid])" :can_delete="false" @download="file => download_file_url(file.service.url)" />
                <div v-else-if="(field.type === 'attachment' || field.type === 'member')">
                  -
                </div>
                <div v-else-if="!(['long_text', 'member', 'attachment'].includes(field.type))">
                  {{ getSubmittedFieldData(field, field_value_map[field.uid]) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---  Basic section End --->
    </div>
  </div>
</template>
