import { isEmpty } from 'lodash-es';
import { ANNOTATION_TYPES, CUSTOM_ANNOTATION_TYPES, MEASUREMENT_PRESET } from '../constants';
import { useAnnotationsStore } from '../store/annotations.store';
import { createArrayFromCount, downloadBlob, extractAttrFromXML } from '../utils/helper';
import { useCustomAnnotationHandlers } from './useCustomAnnotationHandlers';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store.js';

export function useDocumentTools(document_viewer_instance) {
  const annotations_store = useAnnotationsStore();
  const common_store = useCommonStore();
  const auth_store = useAuthStore();

  const { getCommentData } = useCustomAnnotationHandlers({});

  /**
   * Get color instance
   * @param {[r,g,b,a]} color array contains [r,g,b,a]
   * @returns {Object} color instance
   */
  const get_color_format = color => new window.Core.Annotations.Color(...color);

  /**
   * Set annotations styles
   * @param {Array} annotations array of annotations
   * @param {Object} style style config object
   */
  const set_annotations_style = (annotations, style) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();

    annotations.forEach((annotation) => {
      annotation_manager.setAnnotationStyles(annotation, style);
    });
  };

  function setToolStyle(tool, config = {}) {
    tool.setStyles({
      StrokeColor: get_color_format([...annotations_store.annotations_edit_config.outline_color.rgb, annotations_store.annotations_edit_config.outline_opacity / 100]),
      TextColor: get_color_format([...annotations_store.annotations_edit_config.outline_color.rgb, annotations_store.annotations_edit_config.outline_opacity / 100]),
      FillColor: get_color_format([...annotations_store.annotations_edit_config.fill_color.rgb, annotations_store.annotations_edit_config.fill_opacity / 100]),
      StrokeThickness: config.stroke_thickness ?? annotations_store.annotations_edit_config.stroke_thickness,
      Style: annotations_store.annotations_edit_config.stroke_style,
      Dashes: ['2', '2'],
      FontSize: `${annotations_store.annotations_edit_config.font_size}pt`,
      TextAlign: annotations_store.annotations_edit_config.text_x_align,
      TextVerticalAlign: annotations_store.annotations_edit_config.text_y_align,
    });
  }

  const zoom_in = () => {
    document_viewer_instance.value.zoomTo(
      document_viewer_instance.value.getZoomLevel() + 0.25,
    );
  };

  const zoom_out = () => {
    document_viewer_instance.value.zoomTo(
      document_viewer_instance.value.getZoomLevel() - 0.25,
    );
  };

  const undo = () => {
    const annotationHistory
      = document_viewer_instance.value.getAnnotationHistoryManager();

    annotationHistory.undo();
  };

  const pan = (immediate_selection = false) => {
    const panTool = document_viewer_instance.value?.getTool(window.Core.Tools.ToolNames.PAN);
    panTool.enableImmediateActionOnAnnotationSelection(immediate_selection);
    document_viewer_instance.value.setToolMode(
      panTool,
    );
  };

  const select = () => {
    document_viewer_instance.value.setToolMode(
      document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.EDIT),
    );
  };

  const create_rectangle = () => {
    const rect_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.RECTANGLE);
    setToolStyle(rect_tool);

    document_viewer_instance.value.setToolMode(rect_tool);
  };

  const create_rectangle_two_clicks = () => {
    const rect_tool = new window.Core.Tools.RectangleCreateTool(document_viewer_instance.value);
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();

    const rectangleToolMouseLeftDown = rect_tool.mouseLeftDown;
    const rectangleToolMouseLeftUp = rect_tool.mouseLeftUp;
    let is_mouse_down = false;
    let two_click = false;
    let timer;
    rect_tool.mouseLeftDown = function (...args) {
      const [event] = args;
      const annotation = annotation_manager.getAnnotationByMouseEvent(event);

      if (annotation) {
        create_rectangle();
        return;
      }

      if (two_click)
        return;

      rectangleToolMouseLeftDown.apply(this, args);
      timer = setTimeout(() => (is_mouse_down = true), 300);
    };
    rect_tool.mouseLeftUp = function (...args) {
      const [event] = args;
      const annotation = annotation_manager.getAnnotationByMouseEvent(event);

      if (!annotation)
        annotation_manager.deselectAllAnnotations();

      if (two_click) {
        rectangleToolMouseLeftUp.apply(this, args);
        two_click = false;
        return;
      }
      clearTimeout(timer);
      if (is_mouse_down) {
        rectangleToolMouseLeftUp.apply(this, args);
        is_mouse_down = false;
      }
      else { two_click = true; }
    };

    setToolStyle(rect_tool);
    document_viewer_instance.value.setToolMode(rect_tool);
  };

  const create_line = () => {
    const line_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.LINE);
    setToolStyle(line_tool);

    document_viewer_instance.value.setToolMode(line_tool);
  };

  const create_arrow = () => {
    const arrow_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.ARROW);
    setToolStyle(arrow_tool);

    document_viewer_instance.value.setToolMode(arrow_tool);
  };

  const create_brush = (stroke_thickness) => {
    const freehand_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.FREEHAND);
    freehand_tool.setStyles({
      StrokeThickness: stroke_thickness,
    });
    setToolStyle(freehand_tool, {
      stroke_thickness: annotations_store.annotations_edit_config.is_initial_stroke ? stroke_thickness : annotations_store.annotations_edit_config.stroke_thickness,
    });

    document_viewer_instance.value.setToolMode(freehand_tool);
  };

  const create_polyline = () => {
    const polyline_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.POLYLINE);
    setToolStyle(polyline_tool);

    document_viewer_instance.value.setToolMode(polyline_tool);
  };

  const create_cloud = () => {
    const cloud_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.POLYGON_CLOUD);
    setToolStyle(cloud_tool);

    document_viewer_instance.value.setToolMode(cloud_tool);
  };

  const create_polygon = () => {
    const polygon_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.POLYGON);
    setToolStyle(polygon_tool);

    document_viewer_instance.value.setToolMode(polygon_tool);
  };

  const create_ellipse = () => {
    const ellipse_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.ELLIPSE);
    setToolStyle(ellipse_tool);

    document_viewer_instance.value.setToolMode(ellipse_tool);
  };

  const create_text = () => {
    const text_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.FREETEXT);
    setToolStyle(text_tool);
    text_tool.setStyles({
      StrokeThickness: 0,
    });

    document_viewer_instance.value.setToolMode(text_tool);
  };

  const create_highlight = () => {
    const highlight_tool = document_viewer_instance.value.getTool(window.Core.Tools.ToolNames.HIGHLIGHT);
    setToolStyle(highlight_tool);

    document_viewer_instance.value.setToolMode(highlight_tool);
  };

  const export_annotations = async (annotations = [], options = {}, separated = false) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annotations.forEach((annotation) => {
      const custom_stroke_opacity = annotation.getCustomData('CustomStrokeOpacity');
      const custom_fill_opacity = annotation.getCustomData('CustomFillOpacity');
      annotation.setCustomData('CustomStrokeOpacity', custom_stroke_opacity || '1');
      annotation.setCustomData('CustomFillOpacity', custom_fill_opacity || '0');
    });
    if (annotations.length) {
      // Get array of annotaiton xml string for given annotations array
      if (separated) {
        const xfdf_annotations = await Promise.all(annotations.map(annotation => annotation_manager.exportAnnotations({
          ...options,
          annotList: [annotation],
        })));

        return xfdf_annotations;
      }

      // Get one single annotation xml string for given annotations array
      const xfdf_annotation = await annotation_manager.exportAnnotations({
        ...options,
        annotList: annotations,
      });

      return xfdf_annotation;
    }

    // Returns all the annotations in the document when annotations is empty array
    return await annotation_manager.exportAnnotations(annotations);
  };

  const import_annotations = (xfdf_annotation) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annotation_manager.importAnnotations(xfdf_annotation).then((annotations) => {
      // For nested annotations
      annotations.forEach((annotation) => {
        if (annotation.Subject === ANNOTATION_TYPES.NOTE)
          update_stamp_image(annotation, ANNOTATION_TYPES.NOTE, null, false);

        const custom_stroke_opacity = annotation.getCustomData('CustomStrokeOpacity');
        const custom_fill_opacity = annotation.getCustomData('CustomFillOpacity');

        custom_stroke_opacity && (annotation.StrokeColor.A = Number(custom_stroke_opacity));
        custom_stroke_opacity && annotation.TextColor && (annotation.TextColor.A = Number(custom_stroke_opacity));
        custom_fill_opacity && annotation.FillColor && (annotation.FillColor.A = Number(custom_fill_opacity));
        annotation_manager.redrawAnnotation(annotation);
      });
    });
  };

  const measure_area = (measurement_preset) => {
    const area_measure_tool = document_viewer_instance.value.getTool(
      window.Core.Tools.ToolNames.AREA_MEASUREMENT,
    );
    setToolStyle(area_measure_tool);
    area_measure_tool.setStyles(measurement_preset);
    area_measure_tool.setSnapMode(document_viewer_instance.value.SnapMode.e_DefaultSnapMode | document_viewer_instance.value.SnapMode.POINT_ON_LINE);

    document_viewer_instance.value.setToolMode(area_measure_tool);
  };

  const calibrate = (page_scale, world_scale) => {
    const scale = [[page_scale.value, page_scale.unit], [world_scale.value, world_scale.unit]];
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    const annotations = annotation_manager.getAnnotationsList();
    const measurement_annotations = annotations.filter(
      annotation => annotation.Measure,
    );

    set_annotations_style(measurement_annotations, {
      Scale: scale,
    });
  };

  const measurement_calibration = ({
    open_calibration_modal,
    patch_calibration_options,
  }) => {
    const distance_measure_tool = new window.Core.Tools.DistanceMeasurementCreateTool(document_viewer_instance.value);
    setToolStyle(distance_measure_tool);
    distance_measure_tool.setStyles(MEASUREMENT_PRESET); // When calibrating or re-calibrating need the default scale
    distance_measure_tool.setSnapMode(document_viewer_instance.value.SnapMode.DEFAULT | document_viewer_instance.value.SnapMode.POINT_ON_LINE);

    distance_measure_tool.addEventListener('annotationAdded', (annotation) => {
      if (annotation) {
        // Page scale value and unit
        const measurement = Number(annotation.getContents().replace(/[^0-9.]+/g, ''));
        const unit = annotation.DisplayUnits[0].replace(/[^a-z]+/g, '');

        patch_calibration_options({
          attrs: {
            measurement,
            unit,
            annotation,
          },
        });
        open_calibration_modal();
      }
    });

    document_viewer_instance.value.setToolMode(distance_measure_tool);
  };

  const measure_distance = (measurement_preset) => {
    const distance_measure_tool = document_viewer_instance.value.getTool(
      window.Core.Tools.ToolNames.DISTANCE_MEASUREMENT,
    );
    setToolStyle(distance_measure_tool);
    distance_measure_tool.setStyles(measurement_preset);
    distance_measure_tool.setSnapMode(document_viewer_instance.value.SnapMode.DEFAULT | document_viewer_instance.value.SnapMode.POINT_ON_LINE);

    document_viewer_instance.value.setToolMode(distance_measure_tool);
  };

  /**
   * Creates a pdf document instance without rendering it
   * @param {*} url file path or file obj
   * @param {Boolean} get_pdf_doc_instance since doc instance and pdfDoc instance are different
   * @returns {Promise} resolved pdf doc instance or doc instance
   */
  const create_pdf_document = async (url, get_pdf_doc_instance = true) => {
    const newDoc = await window.Core.createDocument(url, { licenseKey: import.meta.env.VITE_APP_PDF_TRON_LICENSE_KEY, extension: 'pdf' });
    return get_pdf_doc_instance ? await newDoc.getPDFDoc() : newDoc;
  };

  const get_page_arr = async (doc) => {
    const arr = [];
    const itr = await doc.getPageIterator(1);

    for (itr; await itr.hasNext(); itr.next()) {
      const page = await itr.current();
      arr.push(page);
    }

    return arr;
  };

  const get_all_annotations = () => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();

    return annotation_manager.getAnnotationsList();
  };

  const get_annotation_by_id = (id) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();

    return annotation_manager.getAnnotationById(id);
  };

  /**
   * Gets all selected annotations based on filter
   * @param {String[]} filter array of annotation subject name
   * @param {Boolean} only_include if true it will only include the filter items, if false it will contain the the filtered out items
   * @returns {Object[]}
   */
  const get_selected_annotations = (filter = [], only_include = true) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    const selected_annotations = annotation_manager.getSelectedAnnotations(); // contains all type of annotations
    const filtered_selected_annotations = selected_annotations.filter((annotation) => {
      return only_include ? filter.includes(annotation.Subject) : !filter.includes(annotation.Subject);
    }); // filtered annotation type based on params

    return filter.length ? filtered_selected_annotations : selected_annotations;
  };

  const delete_annotations = (annotations, option) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annotation_manager.deleteAnnotations(annotations, option);
  };

  const compare_pdf = async (url1, url2) => {
    await window.Core.PDFNet.initialize();

    const [doc1, doc2] = await Promise.all([
      create_pdf_document(url1),
      create_pdf_document(url2),
    ]);

    const [doc1Pages, doc2Pages] = await Promise.all([
      get_page_arr(doc1),
      get_page_arr(doc2),
    ]);

    const textDiffOptions = await window.Core.PDFNet.PDFDoc.createTextDiffOptions();
    textDiffOptions.setColorA(new window.Core.Annotations.Color(34, 197, 94)); // Deletion color
    textDiffOptions.setColorB(new window.Core.Annotations.Color(239, 68, 68)); // Addition color

    const newDoc = await window.Core.PDFNet.PDFDoc.create();
    newDoc.lock();

    // we'll loop over the doc with the most pages
    const biggestLength = Math.max(doc1Pages.length, doc2Pages.length);

    // we need to do the pages in order, so lets create a Promise chain
    const chain = Promise.resolve();

    for (let i = 0; i < biggestLength; i++)
      chain.then(async () => {
        let page1 = doc1Pages[i];
        let page2 = doc2Pages[i];

        // handle the case where one document has more pages than the other
        if (!page1)
          page1 = new window.Core.PDFNet.Page(0); // create a blank page

        if (!page2)
          page2 = new window.Core.PDFNet.Page(0); // create a blank page

        return newDoc.appendVisualDiff(page1, page2, textDiffOptions);
      });

    await chain; // wait for our chain to resolve
    newDoc.unlock();

    return newDoc;
  };

  const extract_pages = async (withAnnots, pagesToExtract, file_name = 'extracted_page') => {
    const doc = document_viewer_instance.value.getDocument();
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    const custom_annotation_names = Object.values(CUSTOM_ANNOTATION_TYPES);
    let xfdfString = null;

    if (withAnnots) {
      // only include annotations on the pages to extract
      const annotList = annotation_manager
        .getAnnotationsList()
        .filter(annot => !custom_annotation_names.includes(annot.Subject) && pagesToExtract.includes(annot.PageNumber));

      xfdfString = await annotation_manager.exportAnnotations({
        annotList,
        widgets: false,
        links: false,
        fields: false,
        generateInlineAppearances: false,
      });
    }
    const data = await doc.extractPages(pagesToExtract, xfdfString);
    const arr = new Uint8Array(data);

    // optionally save the blob to a file or upload to a server
    const blob = new Blob([arr], { type: 'application/pdf' });
    downloadBlob(blob, 'pdf', file_name);
  };

  const download_pdf = async (withAnnots = true) => {
    const totalPages = document_viewer_instance.value.getPageCount();
    const pagesArr = createArrayFromCount(totalPages);
    pagesArr.length && extract_pages(withAnnots, pagesArr);
  };

  const disable_annotations = () => {
    document_viewer_instance.value.disableAnnotations();
  };

  const disable_read_only_mode = () => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annotation_manager.disableReadOnlyMode();
  };

  const enable_annotations = () => {
    document_viewer_instance.value.enableAnnotations();
  };

  const enable_read_only_mode = () => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annotation_manager.enableReadOnlyMode();
  };

  // Custom edit and readonly mode setter based on auth roles
  const set_editable_permission = (is_editable) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annotation_manager.setPermissionCheckCallback((author, annotation) => {
      return is_editable;
    });
  };

  const scroll_to_zoom = () => {
    let scale = document_viewer_instance.value.getZoomLevel() || 1;
    const min_zoom = 0.1;
    const max_zoom = 99.99;

    function zoom(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      scale += event.deltaY * -0.01;

      // Restrict scale
      scale = Math.min(Math.max(min_zoom, scale), max_zoom);

      document_viewer_instance.value.zoomToMouse(scale, 0, 170, event);
    }

    document_viewer_instance.value.getScrollViewElement().addEventListener('wheel', zoom, { passive: false });
  };

  // Note: This function is sort of harcoded for text extraction only from 2 rect annotation
  // as we cannot get a particular annotation from getPDFDoc(), used in plan upload flow
  const extract_text_from_annot = async (doc, index) => {
    let desc_text = null;
    let title_text = null;
    let page_details = null;
    await window.Core.PDFNet.initialize();

    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    const annots = await annotation_manager.exportAnnotations();
    const is_desc_hidden = extractAttrFromXML(annots, 'flags').includes('hidden,');
    // Run PDFNet methods with memory management
    await window.Core.PDFNet.runWithCleanup(async () => {
      // lock the document before a write operation
      // runWithCleanup will auto unlock when complete
      doc.lock();
      const fdf_doc = await window.Core.PDFNet.FDFDoc.createFromXFDF(annots);
      await doc.fdfUpdate(fdf_doc);

      const page = await doc.getPage(1);
      const rect = await page.getCropBox();
      const desc_annot = await page.getAnnot(0);
      const title_annot = await page.getAnnot(1);

      const text_extractor = await window.Core.PDFNet.TextExtractor.create();
      text_extractor.begin(page, rect);

      desc_text = is_desc_hidden ? '' : await text_extractor.getTextUnderAnnot(desc_annot);
      title_text = await text_extractor.getTextUnderAnnot(title_annot);
      let page_label = await doc.getPageLabel(1);
      page_label = await page_label.getLabelTitle(1);
      title_text = (title_text || page_label).trim();

      page_details = await Promise.all([page.getPageHeight(), page.getPageWidth(), page.getRotation()]);
    });
    const [page_height, page_width, page_roataion] = page_details;

    return { desc_text, title_text: title_text || `${index}`, page_height, page_width, page_roataion };
  };

  const get_doc_from_page = async (doc) => {
    const page_count = await doc.getPageCount();
    const page_count_arr = createArrayFromCount(page_count);

    const arr = Promise.all(page_count_arr.map(async (page_number) => {
      const newDoc = await window.Core.PDFNet.PDFDoc.create();
      newDoc.lock();
      newDoc.insertPages(1, doc, page_number, page_number, 0);
      newDoc.unlock();
      return newDoc;
    }));
    return arr;
  };

  function mergeDocuments(doc_arr, nextCount = 1, doc = null) {
    const create_and_insert_page = (resolve, reject) => {
      if (!doc)
        doc = doc_arr[0].file;

      if (doc_arr.length === 1) {
        resolve({
          next: doc_arr.length - 1 > nextCount,
          doc,
        });

        return;
      }

      const newDoc = doc_arr[nextCount].file;
      const newDocPageCount = newDoc.getPageCount();

      // create an array containing 1…N
      const pages = createArrayFromCount(newDocPageCount);
      const pageIndexToInsert = doc.getPageCount() + 1;
      doc.insertPages(newDoc, pages, pageIndexToInsert).then(result =>
        resolve({
          next: doc_arr.length - 1 > nextCount,
          doc,
        }),
      );
    };

    return new Promise(create_and_insert_page).then((res) => {
      return res.next
        ? mergeDocuments(doc_arr, nextCount + 1, res.doc)
        : res.doc;
    });
  }

  const merge_and_download_pages = async (doc_arr, on_complete) => {
    const doc_arr_with_file = await Promise.all(doc_arr.map(async (document_obj) => {
      const file = await create_pdf_document(document_obj.url, false);
      return {
        ...document_obj,
        file,
      };
    }));

    // recursive function with promise
    mergeDocuments(doc_arr_with_file).then(async (merged_pdf) => {
      const data = await merged_pdf.getFileData();
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: 'application/pdf' });
      on_complete?.();
      downloadBlob(blob, 'pdf', 'merged_document');
    });
  };

  const update_comment_annotation = (annotation, comment_data) => {
    if (!comment_data)
      return;

    const selected_comment_annotations = get_selected_annotations([CUSTOM_ANNOTATION_TYPES.COMMENT]);
    const is_annotation_selected = !!selected_comment_annotations.find(comment_annotatoin => annotation.Id === comment_annotatoin.Id);

    annotation.X = comment_data.annotation_position.X;
    annotation.Y = comment_data.annotation_position.Y;

    const image = getCommentImage(is_annotation_selected, comment_data.reply_count + 1);
    annotation.setImageData(image, { keepAsSVG: true, willReadFrequently: true });
    annotation.Rotation = document_viewer_instance.value?.getDocument()?.getPageRotation(1) || 0;

    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annotation_manager.redrawAnnotation(annotation);
  };

  const update_stamp_image = (annotation, type, status, is_selected = false) => {
    const image = getImage(type, status, is_selected, null, annotation);
    annotation.setImageData(image, { keepAsSVG: true, willReadFrequently: true });
    annotation.Rotation = document_viewer_instance.value?.getDocument()?.getPageRotation(1) || 0;

    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annotation_manager.redrawAnnotation(annotation);
  };

  function getImage(type, status, is_selected = false, comment_count = null, annotation = {}) {
    const image_map = {
      [CUSTOM_ANNOTATION_TYPES.TASK]: {
        1: new URL(`/static/annotation/task/1${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
        2: new URL(`/static/annotation/task/2${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
        3: new URL(`/static/annotation/task/3${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
        4: new URL(`/static/annotation/task/4${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
        5: new URL(`/static/annotation/task/5${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
      },
      [CUSTOM_ANNOTATION_TYPES.COMMENT]: getCommentImage(is_selected, comment_count),
      [ANNOTATION_TYPES.NOTE]: getNoteImage(is_selected, annotation?.Author),
      [CUSTOM_ANNOTATION_TYPES.FORM]: {
        open: new URL(`/static/annotation/form/open-form${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
        draft: new URL(`/static/annotation/form/drafts-form${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
        submitted: new URL(`/static/annotation/form/submitted-form${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
        filled: new URL(`/static/annotation/form/submitted-form${is_selected ? '-selected' : ''}.png`, import.meta.url).href,
      },
    };
    const image = image_map[type][status] || image_map[type];
    return image;
  }

  function getCommentImage(is_selected, count) {
    const message_count = count > 9 ? '9+' : count;
    const count_x_pos = count > 9 ? 24 : 27;
    const count_svg = `${count
      ? `<circle cx="30" cy="8" r="8" fill="#1570EF" stroke="white"/><text style="white-space: pre; fill: white; font-family: Arial, sans-serif; font-size: 12px;" x="${count_x_pos}" y="12">${message_count}</text>`
      : ''}`;
    const normal_comment_svg = `
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32H0V16Z" fill="#344054"/>
      <path d="M13 16H13.0067M16 16H16.0067M19 16H19.0067M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 16.7981 10.1558 17.5598 10.4387 18.2563C10.4928 18.3897 10.5199 18.4563 10.532 18.5102C10.5438 18.5629 10.5481 18.6019 10.5481 18.6559C10.5481 18.7111 10.5381 18.7713 10.5181 18.8916L10.1228 21.2635C10.0814 21.5119 10.0607 21.6361 10.0992 21.7259C10.1329 21.8045 10.1955 21.8671 10.2741 21.9008C10.3639 21.9393 10.4881 21.9186 10.7365 21.8772L13.1084 21.4819C13.2287 21.4619 13.2889 21.4519 13.3441 21.4519C13.3981 21.4519 13.4371 21.4562 13.4898 21.468C13.5437 21.4801 13.6103 21.5072 13.7437 21.5613C14.4402 21.8442 15.2019 22 16 22ZM13.3333 16C13.3333 16.1841 13.1841 16.3333 13 16.3333C12.8159 16.3333 12.6667 16.1841 12.6667 16C12.6667 15.8159 12.8159 15.6667 13 15.6667C13.1841 15.6667 13.3333 15.8159 13.3333 16ZM16.3333 16C16.3333 16.1841 16.1841 16.3333 16 16.3333C15.8159 16.3333 15.6667 16.1841 15.6667 16C15.6667 15.8159 15.8159 15.6667 16 15.6667C16.1841 15.6667 16.3333 15.8159 16.3333 16ZM19.3333 16C19.3333 16.1841 19.1841 16.3333 19 16.3333C18.8159 16.3333 18.6667 16.1841 18.6667 16C18.6667 15.8159 18.8159 15.6667 19 15.6667C19.1841 15.6667 19.3333 15.8159 19.3333 16Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      ${count_svg}
      </svg>`;
    const normal_comment_base_64 = `data:image/svg+xml;base64,${btoa(normal_comment_svg)}`;
    const selected_comment_svg = `
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32H0V16Z" fill="#1570EF"/>
    <path d="M13 16H13.0067M16 16H16.0067M19 16H19.0067M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 16.7981 10.1558 17.5598 10.4387 18.2563C10.4928 18.3897 10.5199 18.4563 10.532 18.5102C10.5438 18.5629 10.5481 18.6019 10.5481 18.6559C10.5481 18.7111 10.5381 18.7713 10.5181 18.8916L10.1228 21.2635C10.0814 21.5119 10.0607 21.6361 10.0992 21.7259C10.1329 21.8045 10.1955 21.8671 10.2741 21.9008C10.3639 21.9393 10.4881 21.9186 10.7365 21.8772L13.1084 21.4819C13.2287 21.4619 13.2889 21.4519 13.3441 21.4519C13.3981 21.4519 13.4371 21.4562 13.4898 21.468C13.5437 21.4801 13.6103 21.5072 13.7437 21.5613C14.4402 21.8442 15.2019 22 16 22ZM13.3333 16C13.3333 16.1841 13.1841 16.3333 13 16.3333C12.8159 16.3333 12.6667 16.1841 12.6667 16C12.6667 15.8159 12.8159 15.6667 13 15.6667C13.1841 15.6667 13.3333 15.8159 13.3333 16ZM16.3333 16C16.3333 16.1841 16.1841 16.3333 16 16.3333C15.8159 16.3333 15.6667 16.1841 15.6667 16C15.6667 15.8159 15.8159 15.6667 16 15.6667C16.1841 15.6667 16.3333 15.8159 16.3333 16ZM19.3333 16C19.3333 16.1841 19.1841 16.3333 19 16.3333C18.8159 16.3333 18.6667 16.1841 18.6667 16C18.6667 15.8159 18.8159 15.6667 19 15.6667C19.1841 15.6667 19.3333 15.8159 19.3333 16Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    ${count_svg}
    </svg>`;
    const selected_comment_base_64 = `data:image/svg+xml;base64,${btoa(selected_comment_svg)}`;

    return is_selected ? selected_comment_base_64 : normal_comment_base_64;
  }

  const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];

  function stringToNumber(string) {
    if (!string)
      return;
    let total = 0;
    for (const str of string) total += Number.parseInt(str.charCodeAt(0));

    return color_set[total % color_set.length];
  }

  function getNoteImage(is_selected, author) {
    const logged_in_user_id = auth_store?.logged_in_user_details?.user_id;
    const user_name = common_store.get_user_or_team_name(logged_in_user_id || author) || 'S';
    const normal_note_svg = `
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <filter id='shadow' color-interpolation-filters="sRGB">
      <feDropShadow dx="2" dy="2" stdDeviation="1" flood-opacity="0.5"/>
      </filter>
      <path filter="url(#shadow)" transform="scale(1.1)" d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32H0V16Z" fill="#344054"/>
      <circle fill="${stringToNumber(user_name)}" cx="18" cy="17" r="13"/>
      <text x="18" y="23" font-size="18" font-family="Arial, sans-serif" fill="white" text-anchor="middle">
      ${user_name.charAt(0)} 
       </text>
      </svg>`;
    const normal_note_base_64 = `data:image/svg+xml;base64,${btoa(normal_note_svg)}`;
    const selected_note_svg = `
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <filter id='shadow' color-interpolation-filters="sRGB">
    <feDropShadow dx="2" dy="2" stdDeviation="1" flood-opacity="0.5"/>
    </filter>
    <path  filter="url(#shadow)" transform="scale(1.1)" d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32H0V16Z" fill="#1570EF"/>
    <circle fill="${stringToNumber(user_name)}" cx="18" cy="17" r="13"/>
    <text x="18" y="23" font-size="18" font-family="Arial, sans-serif" fill="white" text-anchor="middle">
      ${user_name.charAt(0)} 
      </text>
    </svg>`;
    const selected_note_base_64 = `data:image/svg+xml;base64,${btoa(selected_note_svg)}`;

    return is_selected ? selected_note_base_64 : normal_note_base_64;
  }
  const create_and_add_stamp_annotation = (options) => {
    const { uid, x, y, page_number, type, status, comment_count = null } = options;
    const annot = new window.Core.Annotations.StampAnnotation({
      PageNumber: page_number,
      X: x,
      Y: y,
      Width: type === CUSTOM_ANNOTATION_TYPES.COMMENT ? 30 : 20,
      Height: type === CUSTOM_ANNOTATION_TYPES.COMMENT ? 30 : 20,
    });
    const image = getImage(type, status, false, comment_count);
    annot.setImageData(image, { willReadFrequently: true }); // Base64 URL or SVG, default is png
    annot.NoZoom = true;
    annot.NoResize = true;
    annot.Rotation = document_viewer_instance.value?.getDocument()?.getPageRotation(1) || 0;
    annot.MaintainAspectRatio = true;
    annot.disableRotationControl();
    annot.Subject = type ?? annot.Subject;
    uid && annot.setCustomData('uid', uid);

    annot.Hidden = type === CUSTOM_ANNOTATION_TYPES.COMMENT && status === 'resolved'; // hide the resolved comments

    const annotation_manager = document_viewer_instance.value.getAnnotationManager();

    annotation_manager.addAnnotation(annot);
    annotation_manager.redrawAnnotation(annot);

    return annot;
  };

  const pin_stamp_annotation = (options) => {
    const zoom = document_viewer_instance.value.getZoomLevel();
    const { x, y, page_number } = options;
    const page_rotation = document_viewer_instance.value?.getDocument()?.getPageRotation(1) || 0;
    const annot = new window.Core.Annotations.StampAnnotation({
      PageNumber: page_number,
    });
    const image = new URL('/static/annotation/location.svg', import.meta.url).href;
    annot.setImageData(image, { keepAsSVG: true, willReadFrequently: true }); // Base64 URL or SVG, default is png
    annot.NoZoom = true;
    annot.NoResize = true;
    annot.Rotation = page_rotation;
    switch (page_rotation) {
      case 0:
      case 180:
        annot.Width = 20;
        annot.Height = 25;
        annot.X = zoom > 1 ? x - ((annot.Width / 2) / zoom) : x - ((annot.Width / 2));
        annot.Y = zoom > 1 ? y - ((annot.Height / 2) / zoom) : y - ((annot.Height / 2));
        break;
      case 90:
      case 270:
        annot.Width = 25;
        annot.Height = 20;
        annot.X = zoom > 1 ? x - ((annot.Width / 2) / zoom) : x - ((annot.Width / 2));
        annot.Y = zoom > 1 ? y - ((annot.Height / 2) / zoom) : y - ((annot.Height / 2));
        break;
    }
    annot.MaintainAspectRatio = true;
    annot.disableRotationControl();
    annot.Subject = CUSTOM_ANNOTATION_TYPES.LOCATION;
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();

    annotation_manager.addAnnotation(annot);
    annotation_manager.redrawAnnotation(annot);

    annotations_store.location_marker_config = {
      is_visible: true,
      pos_x: x,
      pos_y: y,
    };
  };

  const note_stamp_annotation = (options) => {
    const { x, y, page_number, type, status } = options;
    const annot = new window.Core.Annotations.StampAnnotation({
      PageNumber: page_number,
      X: x,
      Y: y,
      Width: 30,
      Height: 30,
    });
    const image = getImage(type, status, false);
    annot.setImageData(image, { willReadFrequently: true }); // Base64 URL or SVG, default is png
    annot.NoZoom = true;
    annot.NoResize = true;
    annot.Rotation = document_viewer_instance.value?.getDocument()?.getPageRotation(1) || 0;
    annot.MaintainAspectRatio = true;
    annot.disableRotationControl();
    annot.Subject = type;

    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    annot.Author = annotation_manager.getCurrentUser();
    annotation_manager.addAnnotation(annot);
    annotation_manager.redrawAnnotation(annot);

    return annot;
  };

  const create_stamp_tool = (type, modal_config) => {
    const {
      onToolActivated,
      router,
      route,
    } = modal_config;
    const stampTool = new window.Core.Tools.StampCreateTool(
      document_viewer_instance.value,
    );

    stampTool.mouseLeftDown = function (event) {
      const page_point = this.pageCoordinates[1];
      const page_number = page_point.pageNumber;
      const annotation_position = {
        page_number,
        x: page_point.x,
        y: page_point.y,
      };

      if (type === CUSTOM_ANNOTATION_TYPES.COMMENT) {
        const annotation = create_and_add_stamp_annotation({ ...annotation_position, type });
        router.push({ ...route, query: { sheet_comment: `annot_${annotation.Id}` } });
        onToolActivated('');
        pan();
      }
      else if (type === ANNOTATION_TYPES.NOTE) {
        const annotation = note_stamp_annotation({ ...annotation_position, type });
        router.replace({ ...route, query: { note: `new_${annotation.Id}` } });
        onToolActivated('');
        pan();
      }
      else {
        pin_stamp_annotation({ ...annotation_position, type });
        pan();
      }
    };

    document_viewer_instance.value.setToolMode(stampTool);
  };

  const apply_hot_keys = (event) => {
    const custom_annotation_names = Object.values(CUSTOM_ANNOTATION_TYPES);
    const selected_annotations = get_selected_annotations(custom_annotation_names, false); // Filtered out custom annotations (Comment, Form and Task)

    const is_ctrl_or_cmd = (event.metaKey || event.ctrlKey);
    const key_pressed = event.key?.toLowerCase();

    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    const annotation_history = document_viewer_instance.value.getAnnotationHistoryManager();

    // Copy
    if (selected_annotations.length && is_ctrl_or_cmd && key_pressed === 'c')
      annotation_manager.updateCopiedAnnotations();

    // Paste
    if (selected_annotations.length && is_ctrl_or_cmd && key_pressed === 'v')
      annotation_manager.pasteCopiedAnnotations();

    // Undo
    if (is_ctrl_or_cmd && !event.shiftKey && key_pressed === 'z')
      annotation_history.canUndo() && annotation_history.undo();

    // Redo
    if (is_ctrl_or_cmd && event.shiftKey && key_pressed === 'z')
      annotation_history.canRedo() && annotation_history.redo();
  };

  const set_draw_mode = (type, hasAnnotationTwoClicks) => {
    if (!hasAnnotationTwoClicks)
      return;
    const selected_tool = document_viewer_instance.value.getToolMode();
    if (selected_tool?.setDrawMode)
      selected_tool.setDrawMode(type);
  };

  const filter_comments = (config, comment_annotations) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();

    comment_annotations.forEach((annotation) => {
      const comment_data = getCommentData(annotation.getCustomData('uid'));
      annotation.Hidden = !(config.comments && comment_data.status !== 'resolved');

      if (config.comments && config.comments_radio && config.comments_radio === 'show_resolved')
        annotation.Hidden = comment_data.status !== 'resolved';
      if (config.comments && config.comments_radio && config.comments_radio === 'assigned_to_me')
        annotation.Hidden = config.current_user_id !== comment_data.assignee;

      annotation_manager.redrawAnnotation(annotation);
    });
  };
  const filter_annotations = (config) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    const all_annotations = get_all_annotations();

    all_annotations.forEach((annotation) => {
      if (annotation.Subject === CUSTOM_ANNOTATION_TYPES.TASK)
        annotation.Hidden = !config.tasks;

      if (annotation.Subject === CUSTOM_ANNOTATION_TYPES.FORM)
        annotation.Hidden = !config.forms;

      // Handle normal markup annotations
      if (![CUSTOM_ANNOTATION_TYPES.TASK, CUSTOM_ANNOTATION_TYPES.FORM, CUSTOM_ANNOTATION_TYPES.COMMENT].includes(annotation.Subject))
        if (config.markups) {
          annotation.Hidden = !config.markups;

          if (config.markups_radio && config.markups_radio === 'created_by_me')
            annotation.Hidden = config.current_user_id !== annotation.Author;
        }
        else {
          annotation.Hidden = !config.markups;
        }

      annotation_manager.redrawAnnotation(annotation);
    });

    const comment_annotations = all_annotations.filter(annotation => annotation.Subject === CUSTOM_ANNOTATION_TYPES.COMMENT);
    filter_comments(config, comment_annotations);
  };

  const adjustFreeTextBoundingBox = (annotation) => {
    const { FreeTextAnnotation } = window.Core.Annotations;
    if (annotation instanceof FreeTextAnnotation && annotation.getAutoSizeType() !== FreeTextAnnotation.AutoSizeTypes.NONE) {
      const doc = document_viewer_instance.value.getDocument();
      const pageNumber = annotation.PageNumber;
      const pageInfo = doc.getPageInfo(pageNumber);
      const pageMatrix = doc.getPageMatrix(pageNumber);
      const pageRotation = doc.getPageRotation(pageNumber);
      annotation.fitText(pageInfo, pageMatrix, pageRotation);
    }
  };

  const set_text_annot_opacity = (annotation, opacity) => {
    const { FreeTextAnnotation } = window.Core.Annotations;
    // Info: make sure to update the ".jh" if pdftron version is updated
    // PDFTron does not have currently any direct way to set text opacity for FreeTextAnnotation
    if (annotation instanceof FreeTextAnnotation && !isEmpty(annotation?.jh))
      for (const key in annotation.jh)
        if (Object.hasOwn(annotation.jh[key], 'color'))
          annotation.jh[key].color = annotation.TextColor.toHexString() + Math.round(opacity * 255).toString(16).padStart(2, '0');
  };

  return {
    zoom_in,
    zoom_out,
    undo,
    pan,
    create_rectangle,
    create_rectangle_two_clicks,
    create_line,
    create_arrow,
    create_brush,
    create_cloud,
    create_ellipse,
    create_highlight,
    create_polyline,
    create_polygon,
    create_text,
    compare_pdf,
    create_pdf_document,
    delete_annotations,
    extract_text_from_annot,
    get_all_annotations,
    get_annotation_by_id,
    get_doc_from_page,
    get_page_arr,
    get_selected_annotations,
    download_pdf,
    disable_annotations,
    disable_read_only_mode,
    enable_annotations,
    enable_read_only_mode,
    import_annotations,
    export_annotations,
    calibrate,
    measure_area,
    measurement_calibration,
    measure_distance,
    select,
    get_color_format,
    set_annotations_style,
    scroll_to_zoom,
    merge_and_download_pages,
    create_and_add_stamp_annotation,
    create_stamp_tool,
    update_comment_annotation,
    note_stamp_annotation,
    update_stamp_image,
    apply_hot_keys,
    set_draw_mode,
    filter_annotations,
    adjustFreeTextBoundingBox,
    set_text_annot_opacity,
    set_editable_permission,
  };
}
