<script setup>
const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
});

const state = reactive({
  items: [
    {
      level: 1,
      size: 30,
      classes: 'text-3xl',
    },
    {
      level: 2,
      size: 24,
      classes: 'text-2xl',
    },
    {
      level: 3,
      size: 20,
      classes: 'text-xl',
    },
    {
      level: 4,
      size: 18,
      classes: 'text-lg',
    },
  ],
});

function onClick(level) {
  props.editor.chain().focus().toggleHeading({ level }).run();
}
</script>

<template>
  <div class="flex flex-col w-64">
    <div
      v-for="item of state.items"
      :key="item.level"
      class="h-14 flex rounded-lg items-center cursor-pointer p-2 hover:bg-gray-50"
      :class="{ 'bg-gray-100': editor.isActive('heading', { level: item.level }) }"
      @click="onClick(item.level)"
    >
      <span class="font-bold" :class="item.classes">{{ `${$t('Heading')} ${item.level}` }}</span>
    </div>
  </div>
</template>
