<script setup>
const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const state = reactive({
  items: [
    {
      color: '#101828',
      label: 'Black',
    },
    {
      color: '#FFFFFF',
      label: 'White',
    },
    {
      color: '#2E90FA',
      label: 'Blue',
    },
    {
      color: '#15B79E',
      label: 'Teal',
    },
    {
      color: '#12B76A',
      label: 'Green',
    },
    {
      color: '#A6EF67',
      label: 'Grass',
    },
    {
      color: '#FEC84B',
      label: 'Yellow',
    },
    {
      color: '#EF6820',
      label: 'Orange',
    },
    {
      color: '#F04438',
      label: 'Red',
    },
    (props.name === 'foreground'
      ? {
          color: '#875BF7',
          label: 'Violet',
        }
      : {
          color: 'transparent',
          label: 'No color',
        }
    ),
  ],
});

const active_color = computed(() => {
  if (props.name === 'foreground') {
    for (const item of state.items)
      if (props.editor.isActive('textStyle', { color: item.color }))
        return true;
    return false;
  }
  else if (!props.editor.isActive('table')) {
    for (const item of state.items)
      if (props.editor.isActive('highlight', { color: item.color }))
        return true;
    return false;
  }
  return false;
});

function onClick(color) {
  if (props.name === 'foreground')
    props.editor.chain().focus().setColor(color).run();
  else if (props.editor.isActive('table'))
    props.editor.chain().focus().setCellAttribute('backgroundColor', color).run();
  else
    props.editor.commands.setHighlight({ color });
}
</script>

<template>
  <div class="grid grid-cols-5">
    <div
      v-for="item of state.items"
      :key="item.color"
      v-tippy="{ content: item.label, placement: 'top' }"
      class="flex rounded-lg items-center justify-between cursor-pointer p-2 text-gray-600 hover:bg-gray-50"
      :class="{ 'bg-gray-100': active_color === item.color }"
      @click="onClick(item.color)"
    >
      <span
        class="w-4 h-4 rounded-full overflow-hidden"
        :class="{ 'border-2 border-gray-200': item.color === '#FFFFFF' }"
        :style="{ 'background-color': item.color }"
      >
        <svg v-if="item.color === 'transparent'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
          <rect x="0.666016" y="0.667969" width="4.16667" height="4.16667" fill="#D0D5DD" />
          <rect x="4.83398" y="4.83203" width="4.16667" height="4.16667" fill="#D0D5DD" />
          <rect x="0.666016" y="9" width="4.16667" height="4.16667" fill="#D0D5DD" />
          <rect x="4.83398" y="13.168" width="4.16667" height="4.16667" fill="#D0D5DD" />
          <rect x="9" y="0.667969" width="4.16667" height="4.16667" fill="#D0D5DD" />
          <rect x="13.166" y="4.83203" width="4.16667" height="4.16667" fill="#D0D5DD" />
          <rect x="9" y="9" width="4.16667" height="4.16667" fill="#D0D5DD" />
          <rect x="13.166" y="13.168" width="4.16667" height="4.16667" fill="#D0D5DD" />
          <rect x="4.83398" y="0.667969" width="4.16667" height="4.16667" fill="white" />
          <rect x="0.666016" y="4.83203" width="4.16667" height="4.16667" fill="white" />
          <rect x="4.83398" y="9" width="4.16667" height="4.16667" fill="white" />
          <rect x="0.666016" y="13.168" width="4.16667" height="4.16667" fill="white" />
          <rect x="13.166" y="0.667969" width="4.16667" height="4.16667" fill="white" />
          <rect x="9" y="4.83203" width="4.16667" height="4.16667" fill="white" />
          <rect x="13.166" y="9" width="4.16667" height="4.16667" fill="white" />
          <rect x="9" y="13.168" width="4.16667" height="4.16667" fill="white" />
        </svg>
      </span>
    </div>
  </div>
</template>
