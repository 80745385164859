<script setup>
import { computed } from 'vue';
import { omit } from 'lodash-es';

import { Types as custom_fields_types } from './custom-fields.composable.js';
import HawkCustomFieldSingleSelectInput from './hawk-custom-field-single-select-input.vue';
import HawkCustomFieldMultiSelectInput from './hawk-custom-field-multi-select-input.vue';

import HawkAssigneeInput from '~/common/components/vueform/hawk-assignee-input.vue';

const props = defineProps({
  name: {
    type: String,
    default: 'custom_fields',
  },
  value: {
    type: Object,
    default: () => {},
  },
  configuration: {
    type: Array,
    default: () => ([]),
  },
  disable_edit: {
    type: Boolean,
    default: false,
  },
  columns: {
    type: Object,
    default: () => ({
      default: { container: 10, label: 4, wrapper: 12 },
      sm: { container: 10, label: 4, wrapper: 12 },
      md: { container: 10, label: 4, wrapper: 12 },
    }),
  },
});

const emit = defineEmits(['edit']);
const route = useRoute();

function format_signature_data(name, file) {
  if (!file)
    return { [name]: null };

  const file_object = file.service_object ? { service: file.service_object, file_name: file.name, file_size: file.size, mime_type: file.type, meta: file.meta } : file;
  return {
    [name]: file?.file_name
      ? file
      : file_object,
  };
}

function format_signature_load(file) {
  return file?.file_name
    ? {
        ...file,
        service: {
          ...file.service,
          url: file.url,
        },
      }
    : (file || null);
}

function getSignatureData(value) {
  return value?.[0] || value;
}

function format_members(members) {
  return members?.length ? members.map(member => member?.uid || member) : [];
}

function format_members_data(name, value) {
  return {
    [name]: value.map(member => member?.uid || member),
  };
}

const custom_fields_value = computed(() => props.value);

function parse_rules(rules = {}) {
  const parsed_rules = [];
  for (const rule in rules) {
    const value = rules[rule];
    if (value !== false)
      parsed_rules.push(rule + (typeof value === 'boolean' ? '' : `:${value}`));
  }
  return parsed_rules;
}

const custom_fields = computed(() => {
  return props.configuration.map((custom_field) => {
    const attrs = {
      name: custom_field.uid,
      label: custom_field.label,
      placeholder: `Enter ${custom_field.label}`,
      rules: parse_rules(custom_field.rules),
      description: custom_field.description,
      default: custom_field.default,
    };

    if (custom_field.items)
      attrs.items = custom_field.items;

    const component = 'TextElement';

    switch (custom_field.type) {
      case custom_fields_types.EMAIL.value:
        attrs.inputType = 'email';
        attrs.rules.push(...['email']);
        return {
          ...custom_field,
          attrs,
          component,
        };
      case custom_fields_types.URL.value:
        attrs.inputType = 'url';
        attrs.rules.push(...['url']);
        attrs.floating = false;
        attrs.placeholder = 'eg. http(s)://domain.com';
        return {
          ...custom_field,
          attrs,
          component,
        };
      case custom_fields_types.YES_NO_NA.value:
        return {
          ...custom_field,
          attrs,
          component: 'CheckboxTristateElement',
        };
      case custom_fields_types.NUMBER.value:
        attrs.inputType = 'number';
        attrs.rules.push(...['numeric']);
        return {
          ...custom_field,
          attrs,
          component,
        };
      case custom_fields_types.DROPDOWN.value:
        attrs.placeholder = `Select ${custom_field.label}`;
        attrs.search = true;
        attrs.native = false;
        attrs.inputType = 'search';
        attrs.autocomplete = 'off';
        return {
          ...custom_field,
          attrs,
          component: 'SelectElement',
        };
      case custom_fields_types.SINGLE_SELECT.value:
        attrs.placeholder = `Select ${custom_field.label}`;
        attrs.search = true;
        attrs.native = false;
        attrs.inputType = 'search';
        attrs.autocomplete = 'off';
        return {
          ...custom_field,
          attrs,
          component: HawkCustomFieldSingleSelectInput,
        };
      case custom_fields_types.MULTI_SELECT.value:
        return {
          ...custom_field,
          attrs,
          component: HawkCustomFieldMultiSelectInput,
        };
      case custom_fields_types.DATE.value:
        attrs.extendOptions = {
          appendToBody: true,
        };
        return {
          ...custom_field,
          attrs,
          component: 'DateTimeElement',
        };
      case custom_fields_types.PHONE_NUMBER.value:
        attrs.inputType = 'tel';
        return {
          ...custom_field,
          attrs,
          component,
        };
      case custom_fields_types.CHECKBOX.value:
      case custom_fields_types.LABELS.value:
      case custom_fields_types.CHECKBOXES.value:
        return {
          ...custom_field,
          attrs,
          component: 'CheckboxgroupElement',
        };
      case custom_fields_types.MEMBER.value:
        return {
          ...custom_field,
          attrs: {
            options: {
              ...omit(attrs, ['items']),
              'object': false,
              'extend-options': {
                appendToBody: true,
              },
            },
            format_load: true,
            multi: false,
            has_teams: true,
            asset_id: route.params?.asset_id,
          },
          component: HawkAssigneeInput,
        };
      case custom_fields_types.MEMBERS.value:
        return {
          ...custom_field,
          attrs: {
            options: {
              ...omit(attrs, ['items']),
            },
            format_load: true,
            has_teams: true,
            multi: true,
            asset_id: route.params?.asset_id,
          },
          component: HawkAssigneeInput,
        };
      case custom_fields_types.FILE.value:
      case custom_fields_types.SIGNATURE.value:
        attrs.formatData = format_signature_data;
        attrs.formatLoad = format_signature_load;
        attrs.auto = false;
        attrs.drop = true;
        attrs.onMounted = (data) => {
          const signature = getSignatureData(data.value);
          data.el$.load(signature, true);
        };
        return {
          ...custom_field,
          attrs,
          component: custom_fields_types.FILE.value === custom_field.type ? 'MultifileElement' : 'SignatureElement',
        };
      case custom_fields_types.RADIO.value:
        return {
          ...custom_field,
          attrs,
          component: 'RadiogroupElement',
        };
      case custom_fields_types.TEXT.value:
      default:
        return {
          ...custom_field,
          attrs,
          component,
        };
    }
  });
});
</script>

<template>
  <ObjectElement
    :name="props.name"
  >
    <template v-for="custom_field in custom_fields" :key="custom_field.uid">
      <component
        :is="custom_field.component" v-bind="custom_field.attrs"
        :columns="props.columns"
        class="mb-3"
      >
        <template #after>
          <HawkButton v-if="!disable_edit" icon type="text" @click="emit('edit', custom_field.uid)">
            <IconHawkEditOne class="w-3 h-3" />
          </HawkButton>
        </template>
      </component>
    </template>
  </ObjectElement>
</template>
