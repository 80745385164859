<script setup>
import { watch } from 'vue';

import { isEqual } from 'lodash-es';

import { useDashboardThermStore } from '~/dashboard/store/dashboard-therm.store.js';

import GraphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
});

const dashboard_therm_store = useDashboardThermStore();

const $services = inject('$services');
const graph_data = ref(null);
const loading = ref(false);

const chart_config = computed(() => {
  return props.data?.data?.chart_config;
});
const chart_type = computed(() => {
  let type;
  switch (props.data?.data?.type) {
    case 'defects_by_inverter':
      type = chart_config.value?.chart_display_mode === 'Unstacked' ? 'mscolumn2d' : 'stackedcolumn2d';
      break;
    case 'temperature_histogram':
      type = 'column2d';
      break;
    case 'severity_graph':
      type = 'doughnut2d';
      break;
  }
  return type;
});

const getXAxisName = computed(() => {
  if (chart_config.value?.x_label)
    return chart_config.value.x_label;

  const is_temperature_histogram = props.data.data.type === 'temperature_histogram' ? 'Temperature difference' : 'Severity';
  return props.data.data.type === 'defects_by_inverter' ? 'Inverters' : is_temperature_histogram;
});

const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: chart_type.value || '',
  dataSource: {
    chart: {
      xAxisName: getXAxisName.value,
      yAxisName: chart_config.value?.y_label || 'No. of defects',
      subCaption: chart_config.value?.description
      || props.data.data.subCaption || '',

    },
    dashboard_index: props.data.i,
    chart_type: props.data.data.type,
    ...graph_data.value,
  },
}));

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  try {
    const payload = dashboard_therm_store.parse_therm_form_to_server_format(props.data.data);
    const { data } = await $services.therm.get_graph({ body: payload });
    graph_data.value = data;
    loading.value = false;
  }
  catch (err) {
    loading.value = false;
  }
}

if (props.id === 'preview')
  watch(() => props.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });

if (props.id !== 'preview')
  watch(() => props.data.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });
</script>

<template>
  <div class="w-full h-full">
    <div class="widget-header group">
      <slot name="header-title" />
      <tippy
        v-if="props.data?.data?.type === 'severity_graph'"
        class="ml-auto"
        :class="{ 'mr-3': props.id !== 'preview' }"
        arrow
        interactive
        :placement="props.id !== 'preview' ? 'auto' : 'bottom-start'"
        :hide-on-click="false"
      >
        <template #default>
          <div class="flex">
            <IconHawkInfoCircle class="h-5 w-5" />
          </div>
        </template>
        <template #content>
          <ul>
            <li class="text-sm">
              {{ $t('Severity 1') }}: {{ $t('The anomaly is not severe enough to warrant immediate action. For example - Dirt, Shadow, Vegetation, Suspected Soiling.') }}
            </li> <br>
            <li class="text-sm">
              {{ $t('Severity 2') }}: {{ $t(`These are anomalies whose temperature difference < 40°C and not under severity level 1. It is necessary to conduct an inspection to identify root cause and rectify the anomaly within a short period of time.`) }}
            </li>  <br>
            <li class="text-sm">
              {{ $t('Severity 3') }}: {{ $t('These are anomalies which are') }} &gt; 40<sup>o</sup>C {{ $t('and are safety hazards. These need to be addressed immediately.') }}
            </li>
          </ul>
        </template>
      </tippy>
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="loading" />
    <GraphComponent v-else-if="graph_config?.renderAt" :id="props.id" :configuration="graph_config" :chart_config="chart_config" :group_key="props.data?.data?.type === 'defects_by_inverter' && 'defects'" />
  </div>
</template>
