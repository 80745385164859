// eslint-disable-next-line unicorn/prefer-node-protocol
import { Buffer } from 'buffer';

// html manipulation
function stringToNode(html) {
  const temp = document.createElement('template');
  html = html.trim();
  temp.innerHTML = html;
  return temp.content.firstChild;
}

function nodeToString(node) {
  return node.outerHTML;
}

// string operations
function getIndicesOf(searchStr, str, caseSensitive = false) {
  const searchStrLen = searchStr.length;
  if (searchStrLen === 0)
    return [];

  let startIndex = 0;
  const indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  let index = str.indexOf(searchStr, startIndex);
  while (index > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
    index = str.indexOf(searchStr, startIndex);
  }
  return indices;
}

function renderRichText(rich_text) {
  // find all images and make nodes for them them
  // normally prosemirror would take care of this
  const image_div_indexes = getIndicesOf(
    '<div class="imagePluginRoot"',
    rich_text,
  );
  const replace_groups = [];

  image_div_indexes.forEach((div_index) => {
    let div_str = rich_text.slice(div_index);
    const end_index = div_str.indexOf('></div>');
    div_str = div_str.slice(0, end_index + 7);

    const image_container = stringToNode(div_str);

    if (!image_container)
      return;

    const image_maxwidth = image_container?.getAttribute(
      'imageplugin-maxwidth',
    );

    const imgage_node = document.createElement('img');
    imgage_node.setAttribute(
      'src',
      image_container.getAttribute('imageplugin-src'),
    );
    if (image_maxwidth)
      imgage_node.style.maxWidth = `${image_maxwidth}px`;

    image_container.appendChild(imgage_node);

    replace_groups.push({
      old: div_str,
      new: nodeToString(image_container),
    });
  });

  let widget_content = rich_text;

  replace_groups.forEach((g) => {
    widget_content = widget_content.replace(g.old, g.new);
  });

  return `
    <div class="prosemirror-main">
      <div class="prosemirror-editor">
        <div class="ProseMirror ProseMirror-example-setup-style">
          ${widget_content}
        </div>
      </div>
    </div>
  `;
}

function encodeBase64(data) {
  return Buffer.from(decodeURIComponent(encodeURIComponent(data))).toString(
    'base64',
  );
}

// number operations
function setNumberDecimals(number, decimals = 1) {
  return Number.isInteger(number) ? number : Number.parseFloat(number).toFixed(decimals);
}

export {
  stringToNode,
  nodeToString,
  renderRichText,
  setNumberDecimals,
  encodeBase64,
};
