<script setup>
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  transmittal: {
    type: Object,
  },
});

const dms_settings_store = useDMSSettingsStore();
const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => !field.deleted));
const transmittal_store = useTransmittalsStore();
</script>

<template>
  <div class="bg-gray-50 p-4 rounded-lg grid gap-6 grid-cols-4">
    <div class="text-sm">
      <span class="font-semibold">{{ $t('Req number') }}:</span> {{ transmittal.number || '-' }}
    </div>
    <div class="text-sm ">
      <span class="font-semibold">{{ $t('Issue purpose') }}:</span>  {{ transmittal.issue_purpose || '-' }}
    </div>
    <div class="text-sm flex">
      <span class="font-semibold  mr-2">{{ $t('Priority') }}:</span>
      <TaskPriority v-if="transmittal.priority" :priority="transmittal_store.priority_value(transmittal.priority)" class="!items-start" />
      <span v-else>
        {{ '-' }}</span>
    </div>
    <div class="text-sm">
      <span class="font-semibold mr-1"> {{ $t('Category') }}:</span>
      <span v-if="transmittal?.category_name">
        {{ transmittal?.category_name }}
      </span>
      <HawkBadge v-else-if="transmittal?.category" color="gray">
        <hawk-category-name :uid="transmittal.category" />
      </HawkBadge>
      <span v-else>
        {{ '-' }}
      </span>
    </div>
    <div v-if="transmittal?.due_date" class="text-sm">
      <span class="font-semibold"> {{ $t('Due date') }}:</span>  {{ $date(transmittal.due_date, 'DATE_MED') || '-' }}
    </div>
    <div v-for="field in custom_fields" :key="field.uid" class="text-sm flex items-center">
      <span class="font-semibold mr-1">{{ field.label }}:</span> <hawk-custom-field :field="field" :value="transmittal?.field_values?.[field.uid]" collection="dms" />
    </div>
  </div>
</template>
