<script setup>
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useClipboard } from '@vueuse/core';

import { useAuthStore } from '~/auth/stores/auth.store';

import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DuplicateTask from '~/tasks/components/organisms/duplicate-task.vue';
import TemplatePopup from '~/tasks/components/organisms/template-popup.vue';
import Geofencing from '~/forms/atoms/geofencing.vue';
import DependencyPopup from '~/tasks/components/organisms/dependency-popup.vue';
import TaskSchedule from '~/tasks/components/organisms/task-schedule.vue';
import HawkAddReminders from '~/common/components/organisms/hawk-reminders/hawk-add-reminders.vue';
import HawkConfirmationPopup from '~/common/components/organisms/hawk-confirmation-popup.vue';

import { bulkTasksExporter, useTasksPermissions } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
  location: {
    type: String,
    required: true,
    validator: (value) => { return ['task_list', 'task_details_header'].includes(value); },
  },
});

const emit = defineEmits(['click', 'closePopup', 'close', 'open', 'viewTask', 'copyTask', 'duplicateTask', 'addDependencies', 'convertToSubtask', 'archiveTask', 'deleteTask']);

const authStore = useAuthStore();

const route = useRoute();

const { checkTaskPermission } = useTasksPermissions();

const $services = inject('$services');
const $t = inject('$t');
const $toast = inject('$toast');
const is_template = inject('is-template');
const task_store = inject('task_store');

const is_pdf_exporting = ref(false);

const current_organization = authStore.current_organization;

const can_modify_task = computed(() => {
  return checkTaskPermission({ permission: 'can_modify', instance: props.task });
});

const can_create_tasks = computed(() => {
  return checkTaskPermission({ permission: 'can_create_tasks' });
});

const can_modify_templates = computed(() => {
  return checkTaskPermission({ permission: 'can_modify_templates' });
});
/* -------- DUPLICATE TASK -------- */
const { open: openDuplicateTaskPopup, close: closeDuplicateTaskPopup, patchOptions: patchDuplicateTaskPopup } = useModal({
  component: DuplicateTask,
});
function duplicateTaskHandler() {
  patchDuplicateTaskPopup({
    attrs: {
      task_store,
      task: props.task,
      onClose() {
        closeDuplicateTaskPopup();
      },
    },
  });
  openDuplicateTaskPopup();
}

/* -------- TEMPLATE POPUP -------- */
const { open: openTemplatePopup, close: closeTemplatePopup, patchOptions: patchTemplatePopup } = useModal({
  component: TemplatePopup,
});
function loadFromTemplateHandler() {
  patchTemplatePopup({
    attrs: {
      task_store,
      task: props.task,
      type: 'load',
      onClose() {
        closeTemplatePopup();
      },
    },
  });
  openTemplatePopup();
}
function saveAsTemplateHandler() {
  patchTemplatePopup({
    attrs: {
      task_store,
      task: props.task,
      type: 'save',
      onClose() {
        closeTemplatePopup();
      },
    },
  });
  openTemplatePopup();
}

/* -------- DELETE TASK -------- */
const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});
function deleteTaskHandler() {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Task'),
        content: `Are you sure you want to delete ${props.task.name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            emit('closePopup');
            await task_store.remove_tasks([props.task.uid]);
            $toast({
              title: 'Task deleted successfully',
              text: 'Your changes have been saved and your task is removed from the list',
              type: 'success',
            });
            closeDeletePopup();
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

/* -------- GEOFENCE POPUP -------- */
const { open: openGeofencingPopup, close: closeGeofencingPopup, patchOptions: patchGeofencingPopup } = useModal({
  component: Geofencing,
});
function addGeofenceHandler() {
  patchGeofencingPopup({
    attrs: {
      data: { geofencing_distance: props.task.geofencing_distance },
      disable_footer: true,
      onClose() {
        closeGeofencingPopup();
      },
      async submit(form, data) {
        const geofencing_distance = Number(data.data.geofencing_distance);
        const previous_geofencing_distance = Number(props.task.geofencing_distance);
        await task_store.update_tasks([props.task.uid], { geofencing_distance });
        if (!previous_geofencing_distance && geofencing_distance)
          task_store.task_track_events('Geofencing added', {}, props.task.uid);
        if (previous_geofencing_distance && !geofencing_distance)
          task_store.task_track_events('Geofencing removed', {}, props.task.uid);
        closeGeofencingPopup();
      },
      description: $t('Allow updates to the task(on mobile) only if the user is within the given proximity of the task location'),
    },
  });
  openGeofencingPopup();
}

/* -------- DEPENDENCY POPUP -------- */
const { open: openDependencyPopup, close: closeDependencyPopup, patchOptions: patchDependencyPopup } = useModal({
  component: DependencyPopup,
});
function addDependencyHandler() {
  patchDependencyPopup({
    attrs: {
      task_store,
      task_uid: props.task.uid,
      onClose() {
        closeDependencyPopup();
      },
      async onUpdate(payload) {
        try {
          await task_store.update_tasks([props.task.uid], payload);
          const relations = [];
          if (payload.blocked_by.length)
            relations.push('Blocking');
          if (payload.blocking.length)
            relations.push('Waiting');
          if (payload.linked_uids.length)
            relations.push('Linked');
          task_store.task_track_events('Dependencies updated', { relation: relations.join(', ') }, props.task.uid);
        }
        catch (err) {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
            position: 'bottom-right',
          });
        }
      },
    },
  });
  openDependencyPopup();
}

/* -------- SCHEDULE POPUP -------- */
const { open: openSchedulePopup, close: closeSchedulePopup, patchOptions: patchSchedulePopup } = useModal({
  component: TaskSchedule,
});
function setScheduleHandler() {
  patchSchedulePopup({
    attrs: {
      task_uid: props.task.uid,
      schedule: { ...props.task.schedule, duration: props.task.duration },
      start_date: props.task.start_date,
      on_update: async (payload) => {
        await task_store.update_tasks([props.task.uid], payload);
        task_store.task_track_events(payload.schedule ? 'Scheduled' : 'Unscheduled', {}, props.task.uid, ['associated_with']);
      },
      onClose() {
        closeSchedulePopup();
      },
    },
  });
  openSchedulePopup();
}

const { open: openReminderForm, close: closeReminderForm, patchOptions: patchReminderForm } = useModal({
  component: HawkAddReminders,
});
function addReminderHandler() {
  patchReminderForm({
    attrs: {
      options: {
        member_options: [
          {
            label: $t('Assignees'),
            value: 'assignees',
          },
          {
            label: $t('Watchers'),
            value: 'watchers',
          },
          {
            label: $t('Creator'),
            value: 'creator',
          },
          {
            label: $t('Custom'),
            value: 'custom',
          },
        ],
        status_values: task_store.status_values,
        asset: props.task?.element_asset || null,
        date_options: [
          {
            value: 'startDate',
            label: $t('Start Date'),
            disabled: !props.task.start_date,
          },
          {
            value: 'dueDate',
            label: $t('Due Date'),
            disabled: !props.task.due_date,
          },
        ],
        load_from_trigger_property: props.task?.due_date ? 'dueDate' : 'startDate',
        create_reminder: async (body) => {
          await $services.tasks.create_reminder({
            id: props.task.uid,
            body,
          });
        },
        update_reminder: async (body) => {
          await $services.tasks.update_reminder({
            id: props.task.uid,
            body,
          });
        },
      },
      onClose() {
        closeReminderForm();
      },
    },
  });
  openReminderForm();
}

function copyTaskURLHandler() {
  const encoded_uid = btoa(JSON.stringify({
    id: props.task.uid,
    store_key: task_store.$id,
  }));
  let path = 'tasks';
  let query_param = 'task';
  if (is_template) {
    path = 'tasks/templates/tasks';
    query_param = 'template';
  }
  const task_path = `${window.location.origin}/${path}?${query_param}=${encoded_uid}&organization=${current_organization.uid}`;
  const { copy } = useClipboard({ source: task_path });
  copy();
  $toast({
    title: 'Url copied successfully',
    type: 'success',
    position: 'bottom-right',
  });
}

/* --------- TASK ARCHIVE --------- */
const { open: openConfirmationPopup, close: closeConfirmationPopup, patchOptions: patchConfirmationPopup } = useModal({
  component: HawkConfirmationPopup,
});
function archiveTaskHandler() {
  const action = props.task.archive ? 'unarchived' : 'archived';
  patchConfirmationPopup(
    {
      attrs: {
        header: $t(`${action.charAt(0).toUpperCase() + action.slice(1, -1)} Task`),
        content: `Are you sure you want to ${action.slice(0, -1)} task ${props.task.name}?`,
        onClose() {
          closeConfirmationPopup();
        },
        onConfirm: async () => {
          try {
            emit('closePopup');
            await task_store.archive_tasks([props.task.uid], !props.task.archive);
            if (!task.archive)
              task_store.task_track_events('Archived', { mode: 'Single', count: 1 }, props.task.uid);
            $toast({
              title: `Task ${action} successfully`,
              text: `Your changes have been saved and your task is ${action} from the list`,
              type: 'success',
              position: 'bottom-right',
            });
            closeConfirmationPopup();
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openConfirmationPopup();
}

async function exportTaskAsPdf() {
  await bulkTasksExporter([props.task.uid], () => is_pdf_exporting.value = false, {}, props.task.target_element.asset);
  task_store.task_track_events('Exported', { output_format: 'PDF', count: 1, with_summary: false }, props.task.uid, ['view']);
}

function taskPdfExportHandler() {
  if (is_pdf_exporting.value)
    is_pdf_exporting.value = false;
  nextTick(() => {
    is_pdf_exporting.value = true;
  });
}

const task_list_options = computed(() => [
  {
    label: 'View Details',
    on_click: () => emit('viewTask', props.task),
  },
  {
    label: 'Copy URL',
    on_click: () => emit('copyTask', props.task),
  },
  {
    label: 'Duplicate',
    on_click: () => emit('duplicateTask', props.task),
    disabled: !can_create_tasks.value,

  },
  {
    label: 'Add Dependencies',
    on_click: () => emit('addDependencies', props.task),
    disabled: !can_modify_task.value,
  },
  {
    label: 'Convert to Subtask',
    on_click: () => emit('convertToSubtask', props.task),
    disabled: !can_modify_task.value,
  },
  {
    label: props.task.archive ? 'Unarchive' : 'Archive',
    on_click: () => emit('archiveTask', props.task),
  },
  {
    label: 'Delete Task',
    on_click: () => emit('deleteTask', props.task),
    disabled: !can_modify_task.value,
  },
]);

const task_details_options = computed(() => [
  {
    label: 'Copy URL',
    on_click: copyTaskURLHandler,
  },
  {
    label: 'Duplicate',
    on_click: duplicateTaskHandler,
    disabled: !can_create_tasks.value,
  },
  {
    label: props.task.geofencing_distance ? 'Update Geofencing' : 'Add Geofencing',
    on_click: addGeofenceHandler,
    disabled: !can_modify_task.value,
  },
  {
    label: 'Add Dependencies',
    on_click: addDependencyHandler,
    disabled: !can_modify_task.value,
  },
  {
    label: props.task?.schedule?.rrule ? 'Update Schedule' : 'Set Schedule',
    on_click: setScheduleHandler,
    disabled: !can_modify_task.value,
  },
  {
    label: 'Add reminder',
    on_click: addReminderHandler,
    disabled: !checkTaskPermission({ permission: 'can_add_reminders', instance: props.task }),
    tooltip: can_modify_task.value ? $t('A reminder can be configured only if the start/due date is set') : '',
  },
  {
    label: 'Save as template',
    on_click: saveAsTemplateHandler,
    disabled: !can_modify_templates.value,
  },
  {
    label: 'Load from template',
    on_click: loadFromTemplateHandler,
    disabled: !can_modify_task.value,
  },
  {
    label: 'Export as PDF',
    on_click: taskPdfExportHandler,
  },
  {
    label: props.task.archive ? 'Unarchive' : 'Archive',
    on_click: archiveTaskHandler,
  },
  {
    label: 'Delete',
    on_click: deleteTaskHandler,
    disabled: !can_modify_task.value,
  },
]);

const template_details_options = computed(() => [
  {
    label: 'Copy URL',
    on_click: copyTaskURLHandler,
  },
  {
    label: props.task.geofencing_distance ? 'Update Geofencing' : 'Add Geofencing',
    on_click: addGeofenceHandler,
    disabled: !can_modify_templates.value,
  },
  {
    label: 'Delete',
    on_click: deleteTaskHandler,
    disabled: !can_modify_templates.value,
  },
]);

const dropdown_items = computed(() => {
  if (props.location === 'task_list')
    return task_list_options.value;
  else if (props.location === 'task_details_header')
    if (is_template)
      return template_details_options.value;
    else
      return task_details_options.value;
});
</script>

<template>
  <hawk-menu
    v-if="props.task"
    additional_trigger_classes="!ring-0 hover:bg-gray-50"
    position="fixed"
    :items="dropdown_items"
    @open="emit('open')"
    @close="emit('close')"
    @click.stop=""
  >
    <template #trigger>
      <slot name="trigger" />
    </template>
  </hawk-menu>
  <HawkExportToast v-if="is_pdf_exporting" :submit="exportTaskAsPdf" @cancel="is_pdf_exporting = false" @close="is_pdf_exporting = false" />
</template>
