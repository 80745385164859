<script setup>
const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
  icon: {
    type: Object,
    required: true,
  },
  submenu: {
    type: Object,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  classes: {
    type: String,
  },
  action: {
    type: Function,
  },
  is_active: {
    type: Function,
    default: null,
  },
});

const state = reactive({
  is_submenu_visible: false,
});

const is_item_active = computed(() => {
  if (props.submenu)
    return state.is_submenu_visible;
  return props?.is_active?.();
});

function handleClick() {
  if (!props.submenu)
    props.action();
}
</script>

<template>
  <tippy
    :arrow="false"
    :hide-on-click="true"
    :trigger="submenu ? 'click' : 'manual'"
    :popper-options="{ strategy: 'fixed' }"
    interactive
    theme="editor-tippy"
    placement="bottom-start"
    @state="$event => state.is_submenu_visible = $event.isVisible"
  >
    <template #default>
      <div
        v-tippy="{ content: label, placement: 'top' }"
        class="rounded-md border-0 p-2 mr-1 text-gray-600 flex justify-center items-center cursor-pointer hover:bg-gray-200"
        :class="{
          'bg-gray-100': is_item_active,
          'bg-red-100 hover:bg-red-200': classes?.includes?.('danger'),
        }"
        @click="handleClick"
      >
        <span
          v-if="name === 'foreground'"
          class="w-3.5 h-3.5 rounded-full"
          :class="{ 'border border-gray-200': ['white', '#FFFFFF', '#FFF'].includes(editor.getAttributes('textStyle').color) }"
          :style="{ 'background-color': editor.getAttributes('textStyle').color || '#101828' }"
        />
        <component :is="icon" v-else class="w-4 h-4" :class="classes" />
      </div>
    </template>

    <template #content="{ hide }">
      <component
        :is="submenu"
        v-if="submenu"
        :key="state.is_submenu_visible"
        :name="name"
        :editor="editor"
        @close="hide()"
      />
    </template>
  </tippy>
</template>
