<script setup>
import { useRoute } from 'vue-router';

const props = defineProps({
  step_name: {
    type: String,
    default: '',
  },
  step_index: {
    type: Number,
    default: 0,
  },
  step_uid: {
    type: Object,
    default: null,
  },
  is_preview_mode: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['copyToClipboard', 'paste']);
const route = useRoute();
const form_detail_store = inject('form_detail_store');
const step = ref(null);
const $t = inject('$t');

function get_step_visibility() {
  if (props.is_preview_mode && !route.query.submission_history)
    return true;
  const completed_steps = form_detail_store?.form_detail?.completed_steps?.map(
    step => +step.index,
  );
  const allowed_steps = [
    ...(completed_steps || []),
    ...(route.query.submission_history
      ? []
      : [+form_detail_store?.form_detail?.status?.index || 1]),
  ];
  // Irrespective of whether the form is submitted or not, we should only display
  // the steps that are either completed or the ones in the current step. The deleted steps i.e
  // step.active=false is only checked while previewing the form but not in the submission view since
  // we should not consider whether a step is deleted or not for the completed ones and the current step
  // will never be set to a deleted step.
  return allowed_steps.includes(props.step_index);
}

const current_clipboard_obj = ref({});
onMounted(() => {
  if (+form_detail_store?.form_detail?.status?.index === props.step_index)
    step.value?.scrollIntoView(true);
});

const is_clicked = ref(true);
const step_submission = form_detail_store?.form_submissions?.step_submissions?.[props.step_index];

function copyToClipboard(el$) {
  emit('copyToClipboard');
  el$.target?._tippy?.setContent('copied');
  el$.target?._tippy.show();
  setTimeout(() => {
    el$.target?._tippy?.setContent('copy');
  }, 2000);
}

function showPasteIcon() {
  current_clipboard_obj.value = {};
  try {
    if (document.hasFocus())
      navigator.clipboard.readText().then((text) => {
        try {
          const obj = JSON.parse(text);
          current_clipboard_obj.value = obj;
        }
        catch (e) {}
      });
  }
  catch (e) {}
}
</script>

<template>
  <div v-if="get_step_visibility()" ref="step" class="group" @mouseenter="showPasteIcon">
    <div class="mb-4 bg-[#F2F4F7] p-3 rounded-md cursor-pointer" @click="step_submission ? is_clicked = !is_clicked : null">
      <div class="flex justify-between items-center">
        <h1 class="text-lg font-semibold">
          {{ props.step_name }}
        </h1>
        <div v-if="step_submission" class="flex">
          <icon-hawk-copy-two
            v-tippy="'copy'"
            class="mr-2 hidden group-hover:block" @click.stop="copyToClipboard"
          />
          <icon-hawk-minus-circle v-if="is_clicked" />
          <icon-hawk-plus-circle v-else />
        </div>
        <div v-else-if="current_clipboard_obj.step_name === step_name" class="flex">
          <icon-hawk-sticker-square
            v-tippy="'paste'"
            class="mr-2 hidden group-hover:block" @click.stop="emit('paste')"
          />
        </div>
      </div>
      <div v-if="step_submission" class="flex items-center">
        <div class="content-center text-[#667085] mr-2 text-sm">
          {{ $t('Submitted by') }}:
        </div> <div><HawkMembers class="bg-gray-200 mr-1 !flex" :members="step_submission?.submitted_by" type="badge" /> </div> <div class="text-sm font-normal">
          on {{ $date(step_submission?.submitted_at, "L_DATETIME_LONG") }}
        </div>
      </div>
    </div>
    <div v-if="is_clicked">
      <slot name="step_sections" :is_step_submitted="step_submission" />
    </div>
  </div>
</template>
