import { groupBy } from 'lodash-es';
import { useSheetStore } from '../store/sheet.store';
import { CUSTOM_ANNOTATION_TYPES } from '../constants';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useFormsStore } from '~/forms/store/forms.store';
import { useChatStore } from '~/common/stores/chat.store';
import { useAuthStore } from '~/auth/stores/auth.store';

export function useCustomAnnotationHandlers({ route, router }) {
  const sheet_store = useSheetStore();
  const tasks_store = useTasksStore('plans-task');
  const forms_store = useFormsStore('plans-form');
  const chat_store = useChatStore();
  const auth_store = useAuthStore();

  const user_id = auth_store.logged_in_user_details?.user_id;

  const scroll_view_elem = document.getElementById('scroll-view');

  const clear_route = () => {
    router.replace({
      ...route,
      query: {},
    });
  };

  const fetch_tasks = async () => {
    await tasks_store.set_tasks({
      page_size: Number.MAX_SAFE_INTEGER,
      page_number: 1,
      associated_with: 'plan',
      descendant: true,
      element: sheet_store.sheet.element,
      linked: true,
    });
  };

  const fetch_forms = async () => {
    await forms_store.set_forms({
      query: {
        asset_uid: route.params.asset_id,
        public: false,
        is_child: true,
        element: sheet_store.sheet.element,
        all_access: true,
      },
    });
  };

  const fetch_comments = async () => {
    await chat_store.initialize();
    await chat_store.set_data(`sheet-${sheet_store.sheet.uid}`, {
      name: sheet_store.sheet.name,
      members: [user_id],
    });
  };

  const add_fetched_custom_annotations = (create_and_add_stamp_annotation) => {
    tasks_store.tasks().forEach((task) => {
      const { x, y, page_number } = task.properties.annotation_position;
      const options = {
        uid: task.uid,
        x,
        y,
        page_number,
        type: CUSTOM_ANNOTATION_TYPES.TASK,
        status: task.status,
      };
      create_and_add_stamp_annotation(options);
    });

    forms_store.forms.forEach((form) => {
      const { x, y, page_number } = form.properties.annotation_position;
      const options = {
        uid: form.uid,
        x,
        y,
        page_number,
        type: CUSTOM_ANNOTATION_TYPES.FORM,
        status: form?.status?.submission_status || 'open',
      };
      create_and_add_stamp_annotation(options);
    });

    chat_store.comments.forEach((comment) => {
      const { X, Y, PageNumber } = comment.annotation_position;

      if (comment.type !== 'deleted') {
        const options = {
          uid: comment.id,
          x: X,
          y: Y,
          page_number: PageNumber,
          type: CUSTOM_ANNOTATION_TYPES.COMMENT,
          status: comment.status,
          comment_count: comment.reply_count + 1,
        };
        create_and_add_stamp_annotation(options);
      }
    });
  };

  const post_custom_annotation = async (type, data, annotation_position, create_and_add_stamp_annotation) => {
    if (type === CUSTOM_ANNOTATION_TYPES.TASK) {
      const payload = {
        ...data,
        properties: { annotation_position, reference_name: sheet_store.sheet.name },
        target_element: sheet_store.sheet.element,
      };

      const task = await tasks_store.create_tasks({ tasks: [payload] }, { view: 'Plan', method: 'Direct' });
      const task_uid = Object.keys(task)[0];

      create_and_add_stamp_annotation({ uid: task_uid, x: annotation_position.x, y: annotation_position.y, page_number: annotation_position.page_number, type });
      router.push({ ...route, query: { task: task_uid, teleport_to: '#viewer-container' } });
    }

    if (type === CUSTOM_ANNOTATION_TYPES.FORM) {
      const body = {
        form_uid: data.form_uid,
        forms: {
          add: [
            {
              ...data.forms.add[0],
              target_element: sheet_store.sheet.element,
              properties: {
                reference_name: sheet_store.sheet.name,
                annotation_position,
              },
            },
          ],
        },
      };
      const { forms } = await forms_store.create_form({
        body,
        router_query_param: {
          teleport_to: '#viewer-container',
        },
      }, true);
      const form = forms.added[0];

      create_and_add_stamp_annotation({ uid: form.uid, x: annotation_position.x, y: annotation_position.y, page_number: annotation_position.page_number, type });
    }
  };

  const handleCustomAnnotChange = (custom_annotations, action, options) => {
    if (options.source === 'watcher')
      return;
    const annotaiton_type_map = groupBy(custom_annotations, 'Subject');
    switch (action) {
      case 'modify': {
        if (annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.TASK]?.length)
          Promise.all(annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.TASK].map(annot => tasks_store.update_tasks([annot.getCustomData('uid')], {
            uid: annot.getCustomData('uid'),
            properties: {
              annotation_position: {
                page_number: annot.getPageNumber(),
                x: annot.getX(),
                y: annot.getY(),
              },
              data_uid: annot.Id,
              reference_name: sheet_store.sheet.name,
            },
          })));

        if (annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.FORM]?.length) {
          const forms = annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.FORM].map(annot => ({
            uid: annot.getCustomData('uid'),
            properties: {
              annotation_position: {
                page_number: annot.getPageNumber(),
                x: annot.getX(),
                y: annot.getY(),
              },
              data_uid: annot.Id,
              reference_name: sheet_store.sheet.name,
            },
          }));

          forms_store.update_forms({ body: { forms: { update: forms } } }, 'bulk');
        }

        if (annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.COMMENT]?.length) {
          const payloads = annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.COMMENT].map((annot) => {
            const comment_data = chat_store.comments.find(comment => annot.getCustomData('uid') === comment.id);

            return {
              ...comment_data,
              annotation_position: {
                PageNumber: annot.getPageNumber(),
                X: annot.getX(),
                Y: annot.getY(),
              },
            };
          });

          Promise.all(payloads.map(payload => chat_store.updateMessage({ ...payload, service: 'plans' })));
        }

        break;
      }
      case 'delete': {
        if (annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.TASK]?.length) {
          const task_uids = annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.TASK].map(annot => annot.getCustomData('uid'));
          tasks_store.remove_tasks(task_uids);
        }

        if (annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.FORM]?.length) {
          const form_uids = annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.FORM].map(annot => annot.getCustomData('uid'));
          forms_store.remove_forms(form_uids);
        }
        if (annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.COMMENT]?.length)
          Promise.all(annotaiton_type_map[CUSTOM_ANNOTATION_TYPES.COMMENT].map(annot => annot.getCustomData('uid') && chat_store.chat.client.deleteMessage(annot.getCustomData('uid'))));

        break;
      }
      default:
        break;
    }
  };

  const show_custom_comment_annotation = (annotation, display_mode, custom_annot_context_menu) => {
    const comment_data = chat_store.comments.find(comment => annotation.getCustomData('uid') === comment.id);

    if (!comment_data)
      return;

    const scroll_top = scroll_view_elem.scrollTop;
    const scroll_left = scroll_view_elem.scrollLeft;
    const offset_y = 0;
    const window_coordinates = display_mode.pageToWindow({
      x: annotation.getX(),
      y: annotation.getY(),
    }, 1);

    custom_annot_context_menu.pos_x = window_coordinates.x - scroll_left;
    custom_annot_context_menu.pos_y = window_coordinates.y - scroll_top - offset_y;
    custom_annot_context_menu.data = comment_data;
    custom_annot_context_menu.annotation = annotation;
    custom_annot_context_menu.type = CUSTOM_ANNOTATION_TYPES.COMMENT;
  };

  const handleCustomAnnotHover = (annotation, display_mode, custom_annot_context_menu) => {
    const task_data = tasks_store.tasks_map[annotation.getCustomData('uid')];
    const form_data = forms_store.forms_map[annotation.getCustomData('uid')];

    if (annotation.Subject === CUSTOM_ANNOTATION_TYPES.TASK) {
      if (!task_data)
        return;

      const scroll_top = scroll_view_elem.scrollTop;
      const scroll_left = scroll_view_elem.scrollLeft;
      const offset_y = 200;
      const window_coordinates = display_mode.pageToWindow({
        x: annotation.getX(),
        y: annotation.getY(),
      }, 1);

      custom_annot_context_menu.pos_x = window_coordinates.x - scroll_left;
      custom_annot_context_menu.pos_y = window_coordinates.y - scroll_top - offset_y;
      custom_annot_context_menu.data = task_data;
      custom_annot_context_menu.type = CUSTOM_ANNOTATION_TYPES.TASK;
    }
    if (annotation.Subject === CUSTOM_ANNOTATION_TYPES.FORM) {
      if (!form_data)
        return;

      const scroll_top = scroll_view_elem.scrollTop;
      const scroll_left = scroll_view_elem.scrollLeft;
      const offset_y = 200;
      const window_coordinates = display_mode.pageToWindow({
        x: annotation.getX(),
        y: annotation.getY(),
      }, 1);

      custom_annot_context_menu.pos_x = window_coordinates.x - scroll_left;
      custom_annot_context_menu.pos_y = window_coordinates.y - scroll_top - offset_y;
      custom_annot_context_menu.data = form_data;
      custom_annot_context_menu.type = CUSTOM_ANNOTATION_TYPES.FORM;
    }
    if (annotation.Subject === CUSTOM_ANNOTATION_TYPES.COMMENT)
      show_custom_comment_annotation(annotation.getCustomData('uid'), display_mode, custom_annot_context_menu);
  };

  function getContexPositionStyle(custom_annot_context_menu) {
    const container_center = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    const style_x
      = custom_annot_context_menu.pos_x < container_center.x ? { left: `${10}px` } : { right: `${10}px` };
    const style_y
      = custom_annot_context_menu.pos_y < container_center.y
        ? { top: `${0}px` }
        : { bottom: `${-100}px` };

    return { position: 'absolute', ...style_x, ...style_y };
  }

  function getCommentData(comment_id) {
    const comment_data = chat_store.comments.find(comment => comment_id === comment.id);
    return comment_data;
  }

  function handleCustomAnnotSelection(action, selected_annotations, update_stamp_image, update_comment_annotation) {
    selected_annotations.forEach((annotation) => {
      const uid = annotation.getCustomData('uid');
      const get_annot_status = {
        [CUSTOM_ANNOTATION_TYPES.FORM]: () => forms_store.forms_map[uid]?.status?.submission_status || 'open',
        [CUSTOM_ANNOTATION_TYPES.TASK]: () => tasks_store.tasks_map[uid]?.status,
        [CUSTOM_ANNOTATION_TYPES.COMMENT]: () => getCommentData(uid)?.status,
      };

      const type = annotation.Subject;
      if ([CUSTOM_ANNOTATION_TYPES.FORM, CUSTOM_ANNOTATION_TYPES.TASK].includes(type))
        update_stamp_image(annotation, type, get_annot_status[type]?.(), action === 'selected');
      else
        update_comment_annotation(annotation, getCommentData(uid));
    });
  }

  return {
    fetch_tasks,
    fetch_forms,
    fetch_comments,
    add_fetched_custom_annotations,
    clear_route,
    post_custom_annotation,
    show_custom_comment_annotation,
    handleCustomAnnotChange,
    handleCustomAnnotHover,
    getContexPositionStyle,
    getCommentData,
    handleCustomAnnotSelection,
  };
}
