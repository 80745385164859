<script setup>
import { useModal } from 'vue-final-modal';
import { onClickOutside } from '@vueuse/core';
import { Parser } from 'hot-formula-parser';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { useFormFieldConfiguration } from '~/forms/composables/form-field-schema.composable.js';
import { useTableSummaryValues } from '~/forms/composables/form-builder-table-summary.composable.js';
import FormBuilderTableValuesPopup from '~/forms/components/form-builder/form-builder-table-values-popup.vue';
import FormBuilderTableFieldPopup from '~/forms/components/form-builder/form-builder-table-field-popup.vue';
import FormFieldCopySlug from '~/forms/components/form-field-copy-slug.vue';
import TrashIcon from '~icons/hawk/trash-three';
import SettingsIcon from '~icons/hawk/settings-icon';

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  value_map: {
    type: Object,
  },
  disabled: {
    type: Boolean,
  },
  options: {
    type: Object,
    default: () => ({
      add_section: true,
      fields_mandatory: false,
      edit_prefilled_data: true,
      disable_summary_calculation: true,
      update_field_properties: true,
      add_rows: true,
      disabled: false,
      delete_row: true,
    }),
  },
});

const emit = defineEmits(['update_values', 'update_field', 'header_clicked', 'footerNameSlug']);

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const $services = inject('$services');

const row_parser = new Parser();
let current_value_index = 0;

const show_summary = ref(true);
const form_template_detail_store = inject('form_template_detail_store');
const max_length = ref(1);

const section = computed(() => props.section);
const fields = section.value?.fields?.filter(field => props.options.visibility ? props.options.visibility(section.value, field) !== 'hidden' : field.status === 'active') || [];
const field_value_map = fields.reduce((map, field) => {
  map[field.uid] = field;
  return map;
}, {});
const field_values = ref([]);
const selected_row = ref(null);
const selected_column = ref(null);
const section_summary = computed(() => section.value?.properties?.section_summary);
const deleting_row = ref(null);
const table_container = ref(null);

const columns = ref([
  {
    accessorKey: 'table_s_no',
    header: '',
    id: 's_no',
    size: 1,
    ...(section.value.properties?.column_width_config?.s_no ? { size: section.value.properties.column_width_config.s_no.size } : {}),
    enableSorting: false,
    disable_dnd: true,
  },
  ...fields.map(field => ({
    accessorKey: field.uid,
    header: field.name,
    id: field.uid,
    ...(section.value.properties?.column_width_config?.[field.uid] ? { size: section.value.properties.column_width_config[field.uid].size } : {}),
    enableSorting: false,
  })),
],
);

if (props.options.add_section || props.options.add_rows)
  columns.value.push({
    accessorKey: '',
    header: '',
    id: 'New',
    size: 2,
    enableResizing: false,
    enableSorting: false,
    disable_dnd: true,
    show_on_hover: true,
  });

const form_field_configuration_map = useFormFieldConfiguration();
const non_deletable_rows = {};
if (props.value_map)
  fields.forEach((field) => {
    field.properties = field.properties || {};
    field.properties._value = field?.properties?.value || [];
    if (!props.options.edit_prefilled_data)
      field.properties._value.forEach((value, index) => {
        non_deletable_rows[index] = props.delete_row && (!!value || non_deletable_rows[index]);
      });
    field.properties.value = props.value_map[field.uid] || (field?.properties?.value ? [...field.properties.value] : []);
  });

field_values.value = fields.map(field => props.value_map?.[field.uid] || field?.properties?.value || []);
max_length.value = Math.max(...field_values.value.map(values => (values || []).length));

function get_table_data_values(field, config, value, data, i) {
  if (field?.config?.type === 'formula') {
    if (props?.options?.disable_summary_calculation && value)
      return data[i][field.uid] = value ? `${field?.config?.prefix || ''} ${value}` : '-'; ;
    const field_result = row_parser.parse(field?.config?.formula).result || '-';
    field.properties.value = field.properties.value || [];
    if (field_result)
      field.properties.value[i] = field_result || '-';
    data[i][field.uid] = (field_result && field?.config?.prefix) ? `${field?.config?.prefix || ''} ${field_result}` : field_result;
  }
  else {
    const final_value = (config?.get_submitted_value(value) || '-');
    data[i][field.uid] = final_value;
  }
}

const table_data = computed(() => {
  const data = [];
  for (let i = 0; i < max_length.value; i++) {
    data[i] = {};
    fields.forEach((field, field_index) => {
      const configuration_map = form_field_configuration_map[field?.properties?.type || field.type];
      const config = configuration_map?.(field);
      const value = field_values.value?.[field_index]?.[i];
      current_value_index = i;
      get_table_data_values(field, config, value, data, i);
    });
  }
  return data;
});

const table_instance = ref(null);

onClickOutside(table_container, () => {
  selected_row.value = null;
  selected_column.value = null;
});

const { open: openValuesModal, close: closeValuesModal } = useModal({
  component: FormBuilderTableValuesPopup,
  attrs: {
    section,
    fields,
    form_data: form_template_detail_store?.form_template_detail,
    mandatory: props.options.fields_mandatory,
    is_data_editable: props.options.edit_prefilled_data,
    update_field_properties: props.options.update_field_properties,
    row: selected_row,
    field_values,
    is_disabled: props.options.disabled,
    visibility: props.options.visibility,
    onClose() {
      closeValuesModal();
    },
    onRemove(row_index) {
      deleteRow(row_index);
    },
    async submit(form, row) {
      const index = row.index;
      if (props.options.update_field_properties) {
        const payload = section.value.fields.filter(field => field.status === 'active').map((field, field_index) => {
          field.properties.value = field?.properties?.value || [];
          field.properties.value[index] = form.requestData[fields[field_index]?.uid];
          return {
            uid: field.uid,
            properties: {
              ...field.properties,
            },
          };
        });
        emit('update_field');
        await $services.forms.patch({
          attribute: 'fields',
          body: { form_uid: form_template_detail_store?.form_template_detail.uid, update_fields: payload },
        });
      }
      if (props.value_map)
        field_values.value.forEach((values, field_index) => values[index] = form?.requestData?.hasOwnProperty(fields[field_index]?.uid) ? form.requestData[fields[field_index]?.uid] : values[index]);
      emit('update_values', field_values);
      closeValuesModal();
    },
  },
});

async function deleteRow(index) {
  try {
    deleting_row.value = index;
    max_length.value--;

    field_values.value.forEach(values => values?.splice(index, 1));
    const payload = fields.map((field, field_index) => {
      field.properties.value = field_values.value[field_index];
      return {
        uid: field.uid,
        properties: {
          ...field.properties,
        },
      };
    });
    if (props.options.update_field_properties)
      await $services.forms.patch({
        attribute: 'fields',
        body: { form_uid: form_template_detail_store?.form_template_detail?.uid, update_fields: payload },
      });
    selected_row.value = null;
    emit('update_values', field_values);
    closeValuesModal();
  }
  catch (e) {
    logger.log(e);
  }
  deleting_row.value = null;
}

const { open: openFieldsModal, close: closeFieldsModal } = useModal({
  component: FormBuilderTableFieldPopup,
  attrs: {
    fields,
    section: props.section,
    selected_column,
    onClose() {
      closeFieldsModal();
    },
    onDelete(selected_field) {
      const section = props.section;
      const index = section.fields.findIndex(field => field.uid === selected_field.uid);
      section.fields.splice(index, 1);
      emit('update_field');
      closeFieldsModal();
    },
    submit(response_field) {
      const index = section.value.fields.findIndex(field => field.uid === response_field.uid);
      if (index >= 0)
        section.value.fields[index] = response_field;
      else
        section.value.fields.push(response_field);
      emit('update_field');
      closeFieldsModal();
    },
  },
});

function cellClicked(cell) {
  selected_row.value = cell.row;
  openValuesModal();
}

function addNewField() {
  openFieldsModal();
}

function updateField(field) {
  selected_column.value = field;
  openFieldsModal();
}

async function moveField(event) {
  const new_index = event.newIndex - 1;
  const old_index = event.oldIndex - 1;
  if (new_index === old_index)
    return;
  const dragged_field = fields.splice(old_index, 1);
  fields.splice(new_index, 0, dragged_field[0]);
  const moved_field = fields[
    new_index
  ];

  if (new_index - 1 >= 0)
    moved_field.previous_field = fields[new_index - 1].uid;
  else
    moved_field.previous_field = null;
  const response = await $services.forms.patch({
    attribute: `fields/${moved_field.uid}`,
    body: { field: moved_field },
  });
  section.value.fields.splice(old_index, 1);
  section.value.fields.splice(new_index, 0, response.data.field);
  emit('update_field');
}

function addNewValue() {
  max_length.value++;
  setTimeout(() => {
    const rows = table_instance.value.getAllRows();
    selected_row.value = rows[rows.length - 1];
    openValuesModal();
  }, 100);
}

const { footerFunction, formatData, setHotParserVariables } = useTableSummaryValues(section_summary, fields, props.value_map?.summaries?.[section.value.uid], props.options);

function loadRowParserEvents(parser) {
  parser.on('callVariable', (name, done) => {
    const field_uid = parser.getVariable(name);
    const field = field_value_map[field_uid];
    const values = field.properties?.value?.map(val => formatData(field, val));
    const final_value = values[current_value_index];

    done(Number(final_value) ? Number(final_value).toFixed(2) : final_value);
  });
}
setHotParserVariables(row_parser);
loadRowParserEvents(row_parser);
const download_file_url = url => url ? window.open(url, '_blank') : '';

function getSignatureValue(data) {
  return Array.isArray(data) ? data[0] : data;
}

async function columnResized(current_header_data, col_header_width_map) {
  if (props.options.update_field_properties) {
    const { data } = await $services.forms.patch({
      attribute: `sections/${section.value.uid}`,
      body: {
        section: {
          properties: { ...section.value.properties, column_width_config: col_header_width_map },
        },
      },
    });

    form_template_detail_store.form_template_detail.sections.forEach((template_section) => {
      if (template_section.uid === section.value.uid)
        template_section.properties = data.section.properties;
    });
  }
}
</script>

<template>
  <div ref="table_container">
    <div class="overflow-scroll scrollbar pointer-events-auto" :class="{ '!pointer-events-none': props.disabled }">
      <HawkTable
        :columns="columns"
        :is_gapless="true"
        :data="table_data"
        :sticky_group_label="true"
        :show_menu_header="false"
        :show_footer="!!section_summary?.summary_type && show_summary && table_data.length"
        :footer_type="section_summary?.summary_type === 'column_summary' ? 'column' : 'row'"
        :footer_offset="(props.options.add_section || props.options.add_rows) ? 1 : 0"
        :footer_function="footerFunction"
        :enable_dnd="props.options.add_section"
        @colDragEnd="moveField"
        @tableInstance="table_instance = $event"
        @cell-click="cellClicked"
        @columnResized="columnResized"
      >
        <template v-for="field in fields" #[`${field.uid}Header`]>
          <div class="flex justify-between w-full items-center group">
            <span class="pointer-events-auto flex items-center gap-2">
              {{ field.name }}
              <FormFieldCopySlug @copyClick="() => emit('header_clicked', field)" />
            </span>
            <hawk-button v-if="props.options.add_section" class="invisible group-hover:visible" type="text" @click="updateField(field)">
              <SettingsIcon class="-mr-2 text-gray-600" />
            </hawk-button>
          </div>
        </template>
        <template #[`s_no`]="field_props">
          {{ +(field_props?.data?.row?.index || 0) + 1 }}
        </template>
        <template v-for="field in fields" #[`${field.uid}`]="field_props">
          <div class="flex justify-between w-full items-center group pointer-events-auto">
            <span>
              <HawkWysiwygEditorComponent v-if="field.properties.type === 'info'" v-model="field.config.placeholder" :editor_enabled="false" :view="{ no_padding: true }" class="!p-0" editor_classes="!p-0" />
              <HawkWysiwygEditorComponent v-else-if="field.type === 'long_text'" :model-value="field_props.data.row.original[field.uid] || '-'" :editor_enabled="false" :view="{ no_padding: true }" class="!p-0" editor_classes="!p-0" />
              <div v-else-if="field.type === 'member' && [...(field_props.data.row.original[field.uid]?.members || []), ...(field_props.data.row.original[field.uid]?.teams || [])]">
                <HawkMembers
                  :members="[...(field_props.data.row.original[field.uid]?.members || []), ...(field_props.data.row.original[field.uid]?.teams || [])]"
                  size="badge" type="label"
                  :max_badges_to_display="5"
                />
              </div>
              <template v-else-if="field.properties.type === 'signature'">
                <hawk-signature v-if="getSignatureValue(field_props.data.row.original[field.uid]) && getSignatureValue(field_props.data.row.original[field.uid]) !== '-'" :model-value="getSignatureValue(field_props.data.row.original[field.uid])" variant="mini" class="pointer-events-none" :placeholder="true" />
                <div v-else>
                  -
                </div>
              </template>
              <hawk-attachments-list v-else-if="field.type === 'attachment' && field_props.data.row.original[field.uid]?.filter?.(file => !!file)?.length" variant="mini" :items="field_props.data.row.original[field.uid]?.filter?.(file => !!file)" :can_delete="false" :enable_description="true" class="pointer-events-auto" :can_download="false" @download="file => download_file_url(file.service.url)" />
              <div v-else-if="!(['long_text', 'member', 'attachment'].includes(field.type))">
                {{ field_props.data.row.original[field.uid] }}
              </div>
              <div v-else>
                -
              </div>
            </span>
          </div>
        </template>
        <template #New="field">
          <hawk-button v-if="!non_deletable_rows[field.data.row.index]" type="plain" size="sm" class="cursor-pointer !p-0 !h-[20px]" :loading="deleting_row === field.data.row.index" @click.stop="deleteRow(field.data.row.index)">
            <TrashIcon />
          </hawk-button>
        </template>
        <template #NewHeader>
          <div v-if="props.options.add_section" class="cursor-pointer" @click="addNewField">
            <icon-hawk-plus />
          </div>
        </template>
        <template #footerAddon="{ footerAddonProp }">
          <FormFieldCopySlug @copyClick="() => emit('footerNameSlug', footerAddonProp.name) " />
        </template>
      </HawkTable>
    </div>
    <div class="flex justify-between mt-4">
      <hawk-button v-if="props.options.add_rows" type="text" @click.stop="addNewValue">
        <icon-hawk-plus />
        <span>
          {{ $t('Add row') }}
        </span>
      </hawk-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #hawk_table {
    :deep(.hawk_table_loader_no_data_container) {
      @apply h-14
    }

    :deep(.hawk_table_no_data) {
      @apply h-14
    }
  }
</style>
