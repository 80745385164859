<script setup>
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import minMax from 'dayjs/plugin/minMax';
import { keyBy, mean, round, sum } from 'lodash-es';
import dayjsBusinessDays from 'dayjs-business-days2';
import { useCommonStore } from '~/common/stores/common.store';
import { import_fusioncharts } from '~/common/utils/package.utils.js';
import { useTerraStore } from '~/terra/store/terra.store';
import { bDuration, safeDivision, safeMultiplication, safeSubtraction } from '~/terra/utils/terra.utils';
import { useCommonImports } from '~/common/composables/common-imports.composable';

const props = defineProps({
  options: {
    type: Object,
  },
  terra_store_name: {
    type: String,
    default: 'terra',
  },
});
dayjs.extend(minMax);
dayjs.extend(dayjsBusinessDays);
dayjs.extend(timezone);

const state = reactive({
  fc_instance: null,
  loading: false,
});

const common_store = useCommonStore();
const terra_store = useTerraStore(props.terra_store_name);
const $services = inject('$services');
const route = useRoute();
const { $t, auth_store } = useCommonImports();
// Temporary until we come up with a better solution than $date
if (auth_store?.logged_in_user_details?.timezone)
  dayjs.tz.setDefault(auth_store?.logged_in_user_details?.timezone);
const workflow_project_hash = computed(() => keyBy(props.options.selected_workflow_data, 'project'));
watch(() => [props.options.selected_project, props.options.selected_field, props.options.plot_by], (val, old) => {
  if (val?.[0] !== old?.[0] || val?.[1] !== old?.[1] || val?.[2] !== old?.[2])
    getData();
},
{
  immediate: true,
});
function parseNameToUids() {
  const workflow = Object.values(terra_store.terra_workflows).find(item => item.name === props.options.selected_workflow);
  const workflow_fields = terra_store.workflow_fields_hash({ workflow: workflow?.uid });
  const field = Object.values(workflow_fields.fields).find(item => item.name === props.options.selected_field);
  const project = workflow_project_hash.value[props.options.selected_project]?.project_uid;
  return {
    project_uid: project,
    field_uid: field.uid,
    workflow_uid: workflow.uid,
  };
}
function getDatesArray(startDate, endDate) {
  const datesArray = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(dayjs(endDate)) || currentDate.isSame(dayjs(endDate))) {
    datesArray.push(currentDate.format('DD-MMM-YY'));
    currentDate = currentDate.add(1, 'day');
  }

  return datesArray;
}
function pushNonNullItems(array, item, format_date = true) {
  if (item)
    array.push(format_date ? dayjs(item) : item);
  return array;
}
function calculateAndUpdateActual(value, planned_work, prev_value) {
  const calculated_value = props.options.plot_by === 'Work complete'
    ? value
    : safeMultiplication(safeDivision(value, planned_work), 100);

  return prev_value + calculated_value;
}
async function getData() {
  const selected_field_and_project = { ...props.options.selected_field_project };
  const uids = parseNameToUids();
  if (props.options.selected_project === 'Overall progress') {
    const meta = {
      actual_finish: [],
      planned_start: [],
      planned_finish: [],
      latest_date: [],
      percent_complete: [],
      est_finish: [],
      scheduled_percent_complete: [],
      actual_start: [],
      planned_work: [],
      remaining_work: [],
      actual_work_rate: [],
      actual_work: [],
      planned_work_rate: [],
    };

    uids.project_uid = props.options.unique_projects.reduce((acc, project) => {
      const project_uid = workflow_project_hash.value[project]?.project_uid;
      const field_project = props.options.field_project_metrics[`${props.options.selected_workflow}:${project}:${props.options.selected_field}`];

      if (field_project) {
        pushNonNullItems(meta.actual_finish, field_project.actual_finish);
        pushNonNullItems(meta.planned_start, field_project.planned_start);
        pushNonNullItems(meta.planned_finish, field_project.planned_finish);
        pushNonNullItems(meta.latest_date, field_project.latest_date);
        pushNonNullItems(meta.percent_complete, field_project.percent_complete, false);
        pushNonNullItems(meta.est_finish, field_project.est_finish);
        pushNonNullItems(meta.scheduled_percent_complete, field_project.scheduled_percent_complete, false);
        pushNonNullItems(meta.actual_start, field_project.actual_start);
        pushNonNullItems(meta.planned_work, field_project.planned_work, false);
        pushNonNullItems(meta.remaining_work, field_project.remaining_work, false);
        pushNonNullItems(meta.planned_work_rate, field_project.planned_work_rate, false);
        pushNonNullItems(meta.actual_work_rate, field_project.actual_work_rate, false);
        pushNonNullItems(meta.actual_work, field_project.actual_work, false);
      }

      if (project_uid)
        acc.push(project_uid);
      return acc;
    }, []);
    selected_field_and_project.actual_finish = dayjs.max(meta.actual_finish);
    selected_field_and_project.actual_start = dayjs.min(meta.actual_start);
    selected_field_and_project.planned_start = dayjs.min(meta.planned_start);
    selected_field_and_project.planned_finish = dayjs.max(meta.planned_finish);
    selected_field_and_project.latest_date = dayjs.max(meta.latest_date);
    selected_field_and_project.percent_complete = mean(meta.percent_complete);
    selected_field_and_project.est_finish = dayjs.max(meta.est_finish);
    selected_field_and_project.scheduled_percent_complete = mean(meta.scheduled_percent_complete);
    selected_field_and_project.planned_work = sum(meta.planned_work);
    selected_field_and_project.remaining_work = mean(meta.remaining_work);
    selected_field_and_project.planned_work_rate = mean(meta.planned_work_rate);
    selected_field_and_project.actual_work_rate = mean(meta.actual_work_rate);
    selected_field_and_project.actual_work = mean(meta.actual_work);
    logger.log('selected_field_and_project', selected_field_and_project);
  }
  try {
    state.loading = true;
    const response = await $services.terra.post({
      attribute: 'reports',
      body: {
        filters: {
          organization: auth_store.current_organization?.uid,
          asset: common_store.active_asset?.uid,
          container: terra_store?.container?.uid,
          workflow: uids.workflow_uid,
          projects: Array.isArray(uids.project_uid) ? uids.project_uid : [uids.project_uid],
          fields: [uids.field_uid],
        },
        chart: 'workflow_pivot_table',
        x: {
          key: 'project',
        },
        y: {
          key: 'date',
          timerange: {
            range: null,
            interval: 'daily',
          },
        },

      },

    });
    const { FusionCharts } = await import_fusioncharts();
    const data_store = new FusionCharts.DataStore();
    const schema = [
      {
        name: 'Date',
        type: 'date',
      },
      {
        name: 'Actual',
        type: 'number',
      },
      {
        name: 'Scheduled',
        type: 'number',
      },
      {
        name: 'Projected',
        type: 'number',
      },
    ];
    const data_hash = {};

    const end_dates = [];
    const start_dates = [];
    if (selected_field_and_project.planned_finish)
      end_dates.push(dayjs(selected_field_and_project.planned_finish));
    if (selected_field_and_project.actual_finish)
      end_dates.push(dayjs(selected_field_and_project.actual_finish));
    if (selected_field_and_project.est_finish)
      end_dates.push(dayjs(selected_field_and_project.est_finish));
    if (selected_field_and_project.planned_start)
      start_dates.push(dayjs(selected_field_and_project.planned_start));
    else start_dates.push(dayjs());
    if (selected_field_and_project.actual_start)
      start_dates.push(dayjs(selected_field_and_project.actual_start));
    else start_dates.push(dayjs());

    const end_date = dayjs.max(end_dates);
    const start_date = dayjs.min(start_dates);
    const dates = getDatesArray(start_date, end_date);
    let actual_prev_value = 0;
    const response_hash = {};
    const dates_array = [];
    if (props.options.selected_project === 'Overall progress')
      response.data.data.forEach((item) => {
        const date = dayjs(item.interval, 'DD-MM-YY').format('DD-MMM-YY');
        dates_array.push(dayjs(date));
        response_hash[date] = [
          ...(response_hash[date] || []),
          item,
        ];
      });

    else
      response.data.data.forEach((item) => {
        const date = dayjs(item.interval, 'DD-MM-YY').format('DD-MMM-YY');
        dates_array.push(dayjs(date));
        response_hash[date] = item;
      });
    const max_date = dayjs.max(dates_array);
    dates.forEach((item) => {
      data_hash[item] = {
        date: item,
        actual: null,
        scheduled: null,
        projected: null,
      };

      if (response_hash[item]) {
        const values = Array.isArray(response_hash[item]) ? response_hash[item].map(item => item.value) : [response_hash[item].value];
        const sum_of_values = sum(values);
        data_hash[item].actual = calculateAndUpdateActual(sum_of_values, selected_field_and_project.planned_work, actual_prev_value);
        actual_prev_value = data_hash[item].actual;
      }
      else if (dayjs(item).isBefore(max_date)) {
        data_hash[item].actual = actual_prev_value;
      }
    });
    let value;

    const dataSet = [];
    // TODO undefined as parameter is working to overcome dayjs null issue, make this change everywhere
    let scheduled_start_date = dayjs(selected_field_and_project.planned_start || undefined).format('DD-MMM-YY');
    let projected_start_date = dayjs(selected_field_and_project.latest_date || undefined).format('DD-MMM-YY');
    projected_start_date = dayjs(projected_start_date, 'DD-MMM-YY').nextBusinessDay().format('DD-MMM-YY');
    let rate;
    if (props.options.plot_by === 'Work complete')
      if (props.options.selected_project === 'Overall progress')
        rate = round(safeDivision(selected_field_and_project.planned_work, bDuration(selected_field_and_project.planned_start, selected_field_and_project.planned_finish)), [2]) || 0;

      else rate = round(selected_field_and_project.planned_work_rate || 0);

    else rate = round(safeDivision(100, bDuration(selected_field_and_project.planned_start, selected_field_and_project.planned_finish)), [2]) || 0;
    const projected_rate = props.options.plot_by === 'Work complete' ? round(safeDivision(selected_field_and_project.remaining_work, selected_field_and_project.actual_work_rate)) : round(safeDivision(safeSubtraction(100, round(selected_field_and_project.percent_complete * 100)), bDuration(selected_field_and_project.latest_date, selected_field_and_project.est_finish)), [2]) || 0;
    let scheduled_prev_value = 0;

    let projected_prev_value = props.options.plot_by !== 'Work complete' ? round(selected_field_and_project.percent_complete * 100) : selected_field_and_project.actual_work;

    while (true) {
      if (dayjs(scheduled_start_date, 'DD-MMM-YY').isBusinessDay()) {
        value = scheduled_prev_value + rate;
      }
      else {
        scheduled_start_date = dayjs(scheduled_start_date, 'DD-MMM-YY').nextBusinessDay().format('DD-MMM-YY');
        continue;
      };
      const next_business_day = dayjs(scheduled_start_date, 'DD-MMM-YY').nextBusinessDay();
      const dates_bw = getDatesArray(dayjs(scheduled_start_date, 'DD-MMM-YY'), next_business_day);
      if (data_hash[scheduled_start_date])
        data_hash[scheduled_start_date].scheduled = value;
      dates_bw.forEach((date) => {
        if (data_hash[date])
          data_hash[date].scheduled = value;
      });
      scheduled_start_date = next_business_day.format('DD-MMM-YY');
      if ((props.options.plot_by === 'Work complete' ? value >= selected_field_and_project.planned_work : value >= 100) || rate <= 0)
        break;
      scheduled_prev_value = value;
    }
    if (round(selected_field_and_project.percent_complete * 100) < 100)
      while (true) {
        value = projected_prev_value + projected_rate;
        const next_business_day = dayjs(projected_start_date, 'DD-MMM-YY').nextBusinessDay();
        const dates_bw = getDatesArray(dayjs(projected_start_date, 'DD-MMM-YY'), next_business_day);
        dates_bw.forEach((date) => {
          if (data_hash[date])
            data_hash[date].projected = value;
        });
        projected_start_date = dayjs(projected_start_date, 'DD-MMM-YY').nextBusinessDay().format('DD-MMM-YY');
        if ((props.options.plot_by === 'Work complete' ? value >= selected_field_and_project.planned_work : value >= 100) || projected_rate <= 0)
          break;
        projected_prev_value = value;
      }

    Object.values(data_hash).forEach((item) => {
      dataSet.push([item.date, item.actual, item.scheduled, item.projected]);
    });
    const data_source = {
      chart: {
        paletteColors: '#5252c9, #8DDBD8, #b9b9e8', // 1st hex code is for first series and so on
      },
      series: 'Progress',
      xaxis: {
        plot: 'Time',
        binning: {
          month: [],
          day: [1],
          hour: [],
        },
        timemarker: [
          {
            start: dayjs().format('DD-MMM-YY'),
            timeformat: '%d-%b-%y',
            type: 'full',
            label: 'Today',
          },
        ],
      },
      yaxis: [

        {
          format: {
            defaultFormat: 0,
          },
          referenceLine: [{
            label: props.options.plot_by === 'Work complete' ? 'Scheduled work' : 'Scheduled percent complete',
            value: props.options.plot_by === 'Work complete' ? selected_field_and_project.scheduled_work : round(selected_field_and_project.scheduled_percent_complete * 100),
            style: {
              marker: {
                'stroke-dasharray': '4 3',
              },
            },
          }],
          plot: [
            {
              value: 'Actual',
              type: 'area',
              connectNullData: true,
              style: {
                'plot.null': {
                  'stroke-dasharray': 'none',
                },
              },

            },
            {
              value: 'Scheduled',
              type: 'area',
              connectNullData: true,
              style: {
                'plot.null': {
                  'stroke-dasharray': 'none',
                },
              },
            },
            {
              value: 'Projected',
              type: 'line',
              connectNullData: true,
              style: {
                plot: {
                  'stroke-dasharray': '5 2',

                },
              },
            },

          ],
          title: props.options.plot_by,
        },
      ],

    };

    data_source.data = data_store.createDataTable(dataSet, schema);
    state.loading = false;
    plotChart(data_source);
  }
  catch (error) {
    state.loading = false;
  }
}
async function plotChart(data_source) {
  const { VueFusionChartsComponent, FusionCharts, TimeSeries } = await import_fusioncharts();

  VueFusionChartsComponent(FusionCharts, TimeSeries);
  state.fc_instance = new FusionCharts({
    type: 'timeseries',
    renderAt: 'terra-timeseries-charts',
    width: '100%',
    height: '100%',
    dataSource: data_source,
  });
  state.fc_instance.render();
}
</script>

<template>
  <div>
    <HawkLoader v-if="state.loading" />

    <div v-show="!state.loading" id="terra-timeseries-charts" class="h-[100%]" />
  </div>
</template>
