<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { $date } from '~/common/utils/date.util';

import HawkActivities from '~/common/components/organisms/hawk-activities/hawk-activities.vue';
import TransmittalDetailDocumentToggle from '~/dms/components/transmittals/transmittal-detail/transmittal-detail-document-toggle.vue';
import TransmittalDetailMemberFeedback from '~/dms/components/transmittals/transmittal-detail/transmittal-detail-member-feedback.vue';
import SheetNotesTimeline from '~/plans/components/sheet-notes-timeline.vue';
import { useTransmittalSidebarConfiguration } from '~/dms/composables/transmittal-sidebar-configuration.composable.js';

const props = defineProps({
  document_details: {
    type: Object,
    default: null,
  },
  show_menu: {
    type: Boolean,
    default: true,
  },
  show_close: {
    type: Boolean,
    default: true,
  },
  container_classes: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['attachmentClick', 'close', 'refresh']);

const route = useRoute();

const $t = inject('$t');

const state = reactive({
  active_details_tab: 'details',
});

const transmittals_store = useTransmittalsStore();
const common_store = useCommonStore();

const configuration = useTransmittalSidebarConfiguration(transmittals_store.transmittal, props.document_details);

const grouped_reviews = computed(() => {
  const review_map = {};
  const {
    consolidator_review, delegation,
    has_delegate,
    has_additional_reviewers,
    has_consolidator,
    reviewer_feedback, additional_reviews,
  } = props.document_details;

  if (delegation?.user && has_delegate)
    review_map.delegation = delegation ? [delegation] : [];

  if (additional_reviews?.length && has_additional_reviewers)
    review_map.additional_review = modifyFeedback(additional_reviews, reviewer_feedback);

  const additional_reviews_map = {};
  if (additional_reviews?.length && has_additional_reviewers)
    additional_reviews.forEach(review => additional_reviews_map[review.from] = true);

  const reviewers_feedback = Object.entries(reviewer_feedback || []).map(([key, value]) => {
    if (!additional_reviews_map[key])
      return { ...value, user: { uid: key } };
    return {};
  }).filter(feedback => feedback?.status);

  if (reviewers_feedback.length > 0)
    review_map.reviewers_feedback = reviewers_feedback;

  if (consolidator_review?.user && has_consolidator)
    review_map.consolidator_review = consolidator_review?.user ? [consolidator_review] : [];

  return review_map;
});

watchEffect(() => {
  if (props.document_details?.uid)
    state.active_details_tab = 'details';
});

function getReviewTitle(key) {
  const titles = {
    delegate: $t('Delegate\'s feedback'),
    additional_reviewer: $t('Additional reviewer\'s feedback'),
    reviewer: $t('Reviewer\'s feedback'),
    consolidator: $t('Consolidator\'s feedback'),
  };
  return titles[key];
}

function modifyFeedback(review = [], feedback = {}) {
  return review?.map((review) => {
    review.feedback = feedback?.[review?.from] || null;
    return review;
  });
}

const transmittal_feedback = ref(null);
async function getReviewersFeedback() {
  transmittal_feedback.value = await transmittals_store.transmittal_feedback({
    transmittal_uid: transmittals_store.transmittal.uid,
    document_uid: props.document_details.uid,
  });
}
getReviewersFeedback();

const $activities = ref(null);
function showActivities() {
  state.active_details_tab = 'activities';
  $activities.value.getData({ resource_path: `transmittals/${transmittals_store.transmittal.uid}`, sort: '-createdAt' });
}

function getAnnotationPayload() {
  const transmittal = transmittals_store.transmittal;
  const document = props.document_details;
  const document_status_map = transmittals_store.transmittal?.review_status_set;
  const asset = common_store.get_asset(transmittal.asset);
  const category = common_store.get_category(transmittal.category);
  const user_name = common_store.get_user_or_team_name(transmittal.owner);
  return {
    name: transmittal?.name || '-',
    number: transmittal?.number || '-',
    asset: {
      name: asset?.name || '-',
      code: asset?.code || '-',
    },
    category: category?.name || '-',
    priority: transmittal?.priority || '-',
    issue_purpose: transmittal?.issue_purpose || '-',
    due_date: transmittal?.due_date ? $date(transmittal.due_date, 'DATE_MED') : '-',
    created_by: user_name || '-',
    created_at: $date(transmittal?.created_at, 'DATETIME_MED') || '-',
    document: {
      number: document?.number || '-',
      name: document?.name || '-',
      revisions: document.versions?.length.toString() || '-',
      status: document_status_map[document.status] || document?.status || '-',
    },
  };
}
</script>

<template>
  <div class="relative h-full flex flex-col bg-white shadow-xl flex-1 pb-4" :class="container_classes">
    <transmittal-sidebar-heading :document_details="document_details" :show_close="show_close" :show_menu="show_menu" :active_tab="active_details_tab" @close="emit('close')" @activities="showActivities" @details="state.active_details_tab = 'details'" />
    <div class="overflow-y-scroll !overflow-x-hidden scrollbar h-full mt-4 px-6">
      <div v-if="state.active_details_tab === 'details'">
        <transmittal-sidebar-preview v-if="show_close" :document_details="document_details" @close="emit('close')" />
        <transmittal-sidebar-warning v-if="configuration?.components?.warning" class="mt-4">
          <transmittal-sidebar-message :text="configuration.warning_data" />
        </transmittal-sidebar-warning>
        <transmittal-sidebar-info v-if="configuration?.components?.info && configuration?.info_data" class="mt-4" :text="configuration.info_data">
          <transmittal-sidebar-message :text="configuration.info_data" />
        </transmittal-sidebar-info>
        <div class="relative grid gap-4 mt-4">
          <transmittal-sidebar-submission v-if="configuration?.components?.submission" :document_details="configuration?.submission_data?.() || document_details" @close="emit('close')" @refresh="emit('refresh')" />
          <transmittal-sidebar-sync v-if="configuration?.components?.sync" :document_details="document_details" @refresh="emit('refresh')" />
          <div
            v-if="transmittal_feedback?.feedbacks?.length > 0"
          >
            <hawk-accordion
              :items="[
                { uid: 'feedback', title: $t('Feedback') },
              ]"
              type="plain"
            >
              <template #header="{ item, is_open }">
                <div class="font-semibold gap-2 mb-2 text-gray-900 text-sm flex">
                  <IconHawkChevronDown v-if="is_open" />
                  <IconHawkChevronRight v-else />
                  {{ item.title }}
                </div>
              </template>
              <template #content>
                <div class="relative">
                  <div class="absolute left-5 h-full border-l" />
                  <div v-for="feedback in transmittal_feedback?.feedbacks" :key="feedback" class="bg-white relative z-1">
                    <TransmittalDetailMemberFeedback
                      v-if="feedback?.feedback?.length"
                      :title="getReviewTitle(feedback.type) || feedback.name"
                      :feedback="feedback"
                      :reviews="feedback?.feedback"
                      :document="document_details"
                    />
                  </div>
                </div>
              </template>
            </hawk-accordion>
          </div>
          <SheetNotesTimeline :transmittal="transmittals_store.transmittal" :payload="getAnnotationPayload()" />
          <div>
            <transmittal-sidebar-document-details :document_details="document_details" />
          </div>
          <div
            v-if="document_details?.versions?.length"
          >
            <hawk-accordion
              :items="[
                { uid: 'revisions', title: $t('Revisions') },
              ]"
              type="plain"
            >
              <template #header="{ item, is_open }">
                <div class="font-semibold gap-2 mb-2 text-gray-900 text-sm flex">
                  <IconHawkChevronDown v-if="is_open" />
                  <IconHawkChevronRight v-else />
                  {{ item.title }}
                </div>
              </template>
              <template #content>
                <transmittal-sidebar-revisions :document_details="document_details" />
              </template>
            </hawk-accordion>
          </div>
        </div>
      </div>
      <div v-show="state.active_details_tab === 'activities'">
        <div class="flex items-center cursor-pointer hover-group mb-4 mt-2 -ml-4" @click="state.active_details_tab = 'details'">
          <hawk-button type="text">
            <icon-hawk-chevron-left class="text-blue-600 text-xl" />
          </hawk-button>
          <span>{{ $t("Back") }}</span>
        </div>
        <HawkActivities ref="$activities" module="DMS" :resource_uid="props.document_details?.uid" :can_initialize="false" resource_type="transmittal_document" :is_timeline="true" />
      </div>
      <div v-if="route.name === 'document-markup'">
        <TransmittalDetailDocumentToggle @close="emit('close')" />
      </div>
    </div>
  </div>
</template>
