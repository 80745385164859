<script setup>
import { v4 as uuidv4 } from 'uuid';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  integration: {
    type: Object,
    required: true,
  },
  select_all: {
    type: Boolean,
    default: false,
  },
  submit: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['close', 'save']);

const tree_data = ref([]);

const data = ref([]);

const initial_data = ref([]);
const share_point_tree_path_map = ref({});

const { $services, $toast } = useCommonImports();

const folder_hierarchy = ref([]);
async function set_hierarchy() {
  const response = await $services.documents.getAll({
    query: {
      hierarchy: true,
      children: true,
      uid: props.item.uid,
    },
  });
  if (response.data.folders)
    folder_hierarchy.value = response.data.folders;
};

async function fetchDestinations() {
  const response = await $services.common.getAll({
    url: '/integrations/sharepoint/fileAndFolders',
    query: {
      site: props.integration.metadata?.site || props.integration.metadata?.site_id,
      integration_id: props.integration?.id,
      filter_by: 'Document',
      parent_id: props.integration.metadata?.destination_id,
    },
  });
  tree_data.value = response.data.children;
}

function generate_folder_path_map(folders, parent_path = '', map = {}) {
  if (folders.length)
    folders.forEach((folder) => {
      folder.path = parent_path + folder.name;
      map[folder.path] = folder;
      if (folder.children && folder.children.length)
        generate_folder_path_map(folder.children, `${folder.path}/`, map);
    });
  return map;
}

function update_tree_data(tree_data, map, enabled_items = []) {
  tree_data.forEach((item) => {
    if (item.children && item.children.length) {
      update_tree_data(item.children, map, enabled_items);
      const enabled_children = item.children.filter(child => !child.disabled);
      item.children.sort((a, b) => b.children.length - a.children.length);
      item.disabled = enabled_children.length === 0;
    }
    else { item.disabled = !!map[item.path]; }
    if (!item.disabled)
      enabled_items.push(item.path);
  });
  return enabled_items;
}

async function sync() {
  const sync_id = uuidv4();
  const payload = data.value.filter(item => !share_point_tree_path_map.value[item]?.disabled).map(item => ({
    sync_id,
    path: item,
    site_id: props.integration.metadata?.site || props.integration.metadata?.site_id,
    integration_id: props.integration?.id,
    item_id: share_point_tree_path_map.value[item]?.id,
    folder_id: props.item.uid,
  }));
  await props.submit({ sync_data: payload });
}

const is_loading = ref(false);
onMounted(async () => {
  try {
    is_loading.value = true;
    const p1 = fetchDestinations();
    const p2 = set_hierarchy();
    await Promise.all([p1, p2]);
    const map = generate_folder_path_map(folder_hierarchy.value?.[0]?.children || []);
    share_point_tree_path_map.value = generate_folder_path_map(tree_data.value);
    const enabled_items = update_tree_data(tree_data.value, map);
    if (props.select_all)
      initial_data.value = enabled_items;
    tree_data.value.sort((a, b) => b?.children?.length - a?.children?.length);
    is_loading.value = false;
  }
  catch (e) {
    logger.err(e);
    $toast({
      title: 'Something went wrong',
      text: 'Please try again later',
      type: 'error',
      position: 'bottom-right',
    });
  }
});
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header>
      <div class="flex items-center justify-between p-4">
        <div class="text-lg font-semibold">
          {{ $t('Sync from sharepoint') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <hr>
    </template>
    <div class="flex flex-col justify-between w-[600px]">
      <div v-if="is_loading" class="w-full h-auto min-h-[240px] max-h-[420px] border border-gray-300 border-solid rounded-lg flex justify-center items-center">
        <hawk-loader />
      </div>
      <HawkTreeSelect
        v-else-if="tree_data.length"
        :key="initial_data?.length"
        show_tree_select
        :options="{
          name: 'data',
          label: $t('Fields'),
        }"
        :data="tree_data"
        :expand_icon_on_right="true"
        :multi_select="true"
        :initial_state="initial_data"
        children_key="children"
        label_key="name"
        value_key="path"
        select_type="LEAF_PRIORITY"
        add_classes="!h-auto min-h-[240px] max-h-[420px] max-[1366px]:max-h-[320px]"
        @updateForm="data = $event"
      >
        <template #multi_select_text="slot_props">
          <div class="flex items-center">
            <div
              v-if="slot_props.item.children.length"
              class="rounded-lg bg-gray-50 group-hover:bg-gray-100 min-w-[40px] min-h-[40px] w-10 h-10 flex justify-center items-center"
            >
              <IconHawkFolder class="h-[20px] w-[20px] text-gray-800" />
            </div>

            <div v-else class="w-10 h-10 min-w-[40px] min-h-[40px] bg-gray-100 rounded-lg group flex justify-center relative items-center">
              <HawkFileIcon :file_name="slot_props.item.name" />
            </div>
            <HawkText class="ml-2" :content="`${slot_props.item.name}` || '-'" />
          </div>
        </template>
      </HawkTreeSelect>
      <div v-else class="w-full h-auto min-h-[240px] max-h-[420px] border border-gray-300 border-solid rounded-lg">
        <div class="text-sm font-medium text-center text-gray-700 pt-4">
          {{ $t('No data available') }}
        </div>
      </div>
    </div>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="sync"
          >
            {{ $t('Sync now') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
