<script setup>
// --------------------------------- Imports -------------------------------- //
import { keyBy } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { useDocumentStore } from '~/dms/store/document.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

// ---------------------------------- Props --------------------------------- //

const props = defineProps({
  document_details: {
    type: Object,
  },
});

// ---------------------------------- Emits --------------------------------- //

const emit = defineEmits(['close']);

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const dms_documents_settings_store = useDMSSettingsStore('documents');
const document_store = useDocumentStore();
const router = useRouter();
const route = useRoute();
// ------------------------ Variables - Local - refs ------------------------ //

const state = reactive({
  document_details: null,
});
// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const document_custom_fields = computed(() => dms_documents_settings_store.custom_fields.filter(field => !field.deleted));
const document_details_map = computed(() => {
  const document_field_map = keyBy(document_custom_fields.value, 'uid');

  if (state.document_details) {
    const { status, category, tags, field_values = [] } = state.document_details;
    const custom_fields_detail = field_values.map(field_value => ({
      ...document_field_map[field_value.field],
      value: field_value.value,
    }));
    return {
      Location: state.document_details,
      Status: status,
      Category: category || null,
      Tags: tags,
      Custom: custom_fields_detail,
    };
  }
  return null;
});
// -------------------------------- Functions ------------------------------- //
async function getDocumentDetails() {
  if (props.document_details?.uid) {
    await dms_documents_settings_store.fetch_all_statuses();
    const response = await document_store.fetch_document(props.document_details?.uid);
    if (response?.details[0]?.uid)
      state.document_details = response.details[0];
  }
}

function onDocumentBreadcrumbClicked() {
  router.push({ name: 'files-documents-all-files', params: { asset_id: route.params.asset_id }, query: { document: state.document_details.uid, folder: state.document_details.parent || state.document_details.asset || 'null' } });
}

// -------------------------------- Watchers -------------------------------- //
watch(() => props.document_details?.uid, (uid) => {
  if (uid)
    getDocumentDetails();
}, { immediate: true });
// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div v-if="document_details_map">
    <hawk-accordion
      :items="[
        { uid: 'document_details', title: $t('Document details') },
      ]"
      type="plain"
    >
      <template #header="{ item, is_open }">
        <div class="font-semibold gap-2 mb-2 text-gray-900 text-sm flex">
          <IconHawkChevronDown v-if="is_open" />
          <IconHawkChevronRight v-else />
          {{ item.title }}
        </div>
      </template>
      <template #content>
        <div v-for="(value, key, index) in document_details_map" :key="index" class="grid grid-cols-12 auto-rows-auto mt-4  ml-3">
          <template v-if="key === 'Custom'">
            <template v-for="(custom_field_val, custom_field_index) in value" :key="custom_field_index">
              <div class="col-span-12 flex items-center flex-wrap ">
                <span class="text-sm font-medium mb-2 text-gray-500">
                  {{ custom_field_val.label }}
                </span>
              </div>
              <div class="col-span-12 flex items-center flex-wrap mb-4">
                <hawk-custom-field class="text-sm" :field="custom_field_val" :value="custom_field_val?.value" />
              </div>
            </template>
          </template>
          <template v-else-if="key === 'Category'">
            <div class="col-span-12 flex items-center flex-wrap ">
              <span class="text-sm font-medium mb-2 text-gray-500">
                {{ key }}
              </span>
            </div>
            <div class="col-span-12 flex items-center flex-wrap ">
              <hawk-category-name :uid="value" />
            </div>
          </template>
          <template v-else-if="key === 'Tags'">
            <div class="col-span-12 flex items-center flex-wrap ">
              <span class="text-sm font-medium mb-2 text-gray-500">
                {{ key }}
              </span>
            </div>
            <div class="col-span-12 flex items-center flex-wrap ">
              <div v-if="value?.length">
                <hawk-tags-name :tags="value" />
              </div>
              <div v-else>
                -
              </div>
            </div>
          </template>
          <template v-else-if="key === 'Location'">
            <div class="col-span-12 flex items-center flex-wrap ">
              <span class="text-sm font-medium mb-2 text-gray-500">
                {{ key }}
              </span>
            </div>
            <div class="col-span-12 flex items-center flex-wrap ">
              <DocumentBreadcrumbs :active_item="value" :is_file_details="true" @tabClick="onDocumentBreadcrumbClicked" />
            </div>
          </template>
          <template v-else>
            <div class="col-span-12 flex items-center flex-wrap ">
              <span class="text-sm font-medium mb-2 text-gray-500">
                {{ key }}
              </span>
            </div>
            <div class="col-span-12 flex items-center flex-wrap ">
              <HawkBadge v-if="value?.name" :custom_color="dms_documents_settings_store.document_status_map[value?.uid]?.color">
                {{ value.name }}
              </HawkBadge>
              <span v-else>-</span>
            </div>
          </template>
        </div>
      </template>
    </hawk-accordion>
  </div>
</template>
