<script setup>
import { computed, inject, ref } from 'vue';

import DocumentTableItemPath from '~/dms/components/documents/table/document-table-item-path.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  is_filtered_view: {
    type: Boolean,
    required: true,
  },
  ancestors: {
    type: Array,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  is_public_folder: {
    type: Boolean,
    default: false,
  },
  document: {
    type: Object,
    default: () => {},
  },
});

const display_filters_ref = inject('display_filters_ref');
const { router } = useCommonImports();
const document_store = useDocumentStore();
const last_index = ref(0);

const items = computed(() => {
  const data = [];
  props.ancestors.forEach((ancestor, index) => {
    data.push(ancestor.name);
    if (index === props.ancestors.length - 1)
      last_index.value = index;
  });
  return data;
});

function navigateItem() {
  display_filters_ref.value?.resetFilters?.();
  document_store.search_query = null;
  router.push({
    name: 'files-documents-all-files',
    query: {
      folder: props.document?.parent,
      document: props.document?.uid,
    },
  });
}
</script>

<template>
  <div class="flex flex-col ml-2" :class="{ 'min-h-[40px] justify-between': items.length && is_filtered_view }">
    <DocumentTableItemPath
      v-if="is_filtered_view"
      :items="items"
      :last_index="last_index"
      @click.stop="navigateItem"
    />
    <span v-tippy="name?.length > 43 ? name : ''" class="flex items-center gap-2 text-gray-900 font-medium max-w-[350px] truncate">
      {{ name }}
      <span v-if="is_public_folder" v-tippy="document?.asset ? $t('Shared with asset') : $t('Shared with organization')">
        <IconHawkGlobeTwo class="text-gray-600 w-4 h-4" />
      </span>
      <span v-if="document?.active_transmittal?.uid" v-tippy="$t('This document is in an active transmittal.')">
        <IconHawkInfoCircle class="text-gray-600 w-4 h-4" />
      </span>
    </span>
  </div>
</template>
