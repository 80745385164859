<script setup>
import { getFileIcon } from '~/common/utils/pretty-file-icons.util.js';

const props = defineProps({
  file_name: {
    type: String,
    default: '',
  },
  alt_text: {
    type: String,
    default: '',
  },
  icon_classes: {
    type: String,
    default: 'w-8 h-8',
  },
});

const alternate_text = computed(() => {
  return props.alt_text || props.file_name || '';
});

const imgUrl = new URL(`/assets/file-type-icons/${getFileIcon(props.file_name)}.svg`, import.meta.url).href;
</script>

<template>
  <img
    :src="imgUrl"
    :alt="alternate_text"
    :class="icon_classes"
  >
</template>
