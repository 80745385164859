<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    };
  },

  watch: {
    items() {
      this.selectedIndex = 0;
    },
    selectedIndex(index) {
      const element = this.$refs[this.items[index].id][0];
      element.scrollIntoView();
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length;
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index) {
      const item = this.items[index];

      if (item)
        this.command({ id: item.id, label: item.label });
    },
  },
};
</script>

<template>
  <div class="suggestion-item-container max-h-52 overflow-auto">
    <template v-if="items.length">
      <div
        v-for="(item, index) in items"
        :ref="item.id"
        :key="index"
        class="suggestion-item w-full flex p-2 rounded-lg hover:bg-gray-200 cursor-pointer"
        :class="{ 'bg-gray-200': index === selectedIndex }"
        @click="selectItem(index)"
      >
        <img v-if="item.avatar" :src="item.avatar" alt="mention-item-avatar" class="w-5 h-5 rounded-full">
        <div
          v-else-if="item.bg_color"
          class="flex justify-center items-center w-5 h-5 rounded-full text-white"
          :style="`background-color:${item.bg_color}`"
        >
          {{ String(item.label[0]).toUpperCase() }}
        </div>
        <span class="ml-2">{{ item.label }}</span>
      </div>
    </template>
    <div v-else class="item">
      {{ $t('No Results') }}
    </div>
  </div>
</template>

<style scoped>
.suggestion-item-container::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
.suggestion-item-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #D0D5DD;
  background-clip: padding-box;
}
.suggestion-item-container:hover::-webkit-scrollbar-thumb {
  background: #D0D5DD;
}
.suggestion-item-container:hover::-webkit-scrollbar-thumb:hover {
  background: #98A2B3;
}
</style>
