<script setup>
import dayjs from 'dayjs';
import { forEach, groupBy, isEqual, sortBy, toLower } from 'lodash-es';

import { useCommonStore } from '~/common/stores/common.store.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import useEmitter from '~/common/composables/useEmitter';
import InventoryLocationName from '~/inventory/components/inventory-reports/inventory-location-name.vue';
import InventoryCustomField from '~/inventory/components/inventory-custom-fields/inventory-custom-field.vue';
import InventoryReportsTableFilters from '~/inventory/components/inventory-reports/inventory-reports-table-filters.vue';

import { exportTableAsXLSX } from '~/common/utils/table.utils.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
});

const $t = inject('$t');
const emitter = useEmitter();
const route = useRoute();
const force_update = ref(0);
const dashboard_store = useDashboardStore();
const inventory_store = useInventoryStore();
const common_store = useCommonStore();
const prevent_watcher = ref(false);
const reports_data = ref([]);
const columns = ref([]);
const form$ = ref({});
const open = ref(true);
const table_instance = ref(null);
const custom_fields = ref({});

const filters = ref({
  dates: [],
  date: null,
  group_by: null,
});
const filters$ = ref(null);
const loading = ref(false);

const get_workflow = computed(() => {
  return uid => inventory_store.workflows_map[uid] || {};
});

const asset_name = computed(() => {
  return uid => common_store.get_asset(uid)?.name || '-';
});
watch(() => props.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getData();
    force_update.value++;
  }
}, { deep: true });
async function applyFilter(e) {
  await getData();
}

function toFromStockParser(obj) {
  return inventory_store.get_location_details({
    uid: obj.uid,
    type: obj.type,
  })?.name || '';
}

function formatMemberDetails(member_uid) {
  const member_details = common_store.get_user(member_uid);
  return member_details.first_name
    ? `${member_details.first_name} ${member_details.last_name} (${member_details.email})`
    : member_details.email;
}
const columns_widths_map = computed(() => props.data.data.columns_widths || {});

function getDateFilterRange(filters) {
  if (props.data.data.type === 'adjustment')
    return filters.dates;
  if (Array.isArray(filters.date))
    return filters.date;
  else if (filters.date)
    return [dayjs(filters.date).startOf('day').toISOString(), dayjs(filters.date).endOf('day').toISOString()];
  else
    return [null, null];
}
async function getData() {
  loading.value = true;
  const filters = filters$.value?.get_filters();
  if (props.data.data.type !== 'transaction') {
    const payload = {
      workflow: filters?.workflow,
      group_by:
        (props.data.data.type === 'adjustment' && filters.group_by)
          ? filters.group_by
          : [],
      type: props.data.data.type,
      filters: { dates: getDateFilterRange(filters) },
    };

    const res = await inventory_store.$services.inventory_reports.post({
      body: payload,
      query: {
        asset: route.params.asset_id,
      },
    });
    reports_data.value = res.data.result;
    columns.value = parse_columns();
    reports_data.value = sortBy(parse_data(res.data.result), item => toLower(item.name));
  }
  else {
    const query = {
      'include[]': ['adjustment.*', 'item.uom.*'],
      'report': true,
      'asset': route.params.asset_id,
      'startDate': filters.dates?.[0]?.toISOString(),
      'endDate': filters.dates?.[1]?.toISOString(),
      ...(filters?.workflow?.length && { workflow: filters.workflow }),
    };

    const res = await inventory_store.$services.inventory_reports.get_adjustment_lines({
      query: {
        ...query,
      },
    });
    reports_data.value = res.data.results;
    columns.value = parse_columns();
    reports_data.value = sortBy(parse_data(res.data.result), item => toLower(item.name));
  }
  loading.value = false;
}

function get_custom_field_name(key) {
  const filters = filters$.value?.get_filters();
  const uid = filters?.group_by?.[0]?.split('.')[1];
  const field = inventory_store.get_custom_field(uid);

  if (field.type === 'date')
    return dayjs(key).format('YYYY-MM-DD');

  if (field.type === 'member') {
    const user = common_store.users_map[key] || {};
    return `${user.first_name} ${user.last_name}`;
  }

  if (field.type === 'members')
    return key
      .split(',')
      .map(uid => common_store.users_map[uid] || {})
      .map(user => `${user.first_name} ${user.last_name}`)
      .join(', ');

  return key === 'null' ? 'NA' : key;
}

function parse_columns() {
  const filters = filters$.value?.get_filters();
  let columns = [
    {
      id: 'name',
      header: $t('Name'),
      accessorKey: 'name',
      minSize: 200,
      height: 30,
      render_as: {
        field_type: 'text',
        options: {
          text_wrap: true,
          classes: 'break-all',
        },
      },
    },
    {
      id: 'number',
      header: $t('Number'),
      accessorKey: 'number',
      height: 30,
    },
    {
      id: 'description',
      header: $t('Description'),
      accessorKey: 'description',
      size: 300,
      height: 30,
      render_as: {
        field_type: 'text',
        options: {
          truncate_config: {
            type: 'adaptive',
            style: 'truncate',
          },
          text_wrap: true,
        },
      },
    },
    {
      id: 'uom',
      header: $t('Unit of measure'),
      accessorKey: 'uom',
      height: 30,
    },
  ];
  if (props.data.data.type === 'adjustment')
    if (filters?.group_by?.[0]) {
      let grouped_by = {};
      if (filters.group_by?.[0] === 'date')
        grouped_by = groupBy(reports_data.value, (item) => {
          return item.date;
        });
      else if (filters.group_by?.[0] === 'warehouse')
        grouped_by = groupBy(reports_data.value, (item) => {
          return item.warehouse?.uid;
        });
      else if (filters.group_by?.[0] === 'asset')
        grouped_by = groupBy(reports_data.value, item => item.asset_uid);
      else if (filters.group_by?.[0]?.startsWith('custom_field'))
        grouped_by = groupBy(reports_data.value, item => item.custom_field);

      forEach(grouped_by, (g1_value, g1_key) => {
        const inner_columns = [];
        const grouped_workflow = groupBy(g1_value, item => item.workflow.uid);

        Object.values(grouped_workflow).forEach((g2_value) => {
          const workflow = get_workflow.value(g2_value[0].workflow.uid);
          inner_columns.push({
            id: `${g1_key}_${workflow.uid}`,
            accessorKey: `${g1_key}_${workflow.uid}`,
            cell: info => info.getValue(),
            header: workflow.name,
          });
        });
        if (filters.group_by?.[0] === 'date' || filters.group_by?.[0] === 'warehouse')
          inner_columns.push({
            id: `${g1_key}_stock_in_hand`,
            accessorKey: `${g1_key}_stock_in_hand`,
            cell: info => info.getValue(),
            header: 'Stock in hand',
          });

        columns.push({
          id: `${g1_key}`,
          accessorKey: `${g1_key}`,
          header:
            filters.group_by?.[0] === 'asset'
              ? asset_name.value(g1_key)
              : filters.group_by?.[0] === 'warehouse'
                ? g1_value[0][filters.group_by?.[0]]?.name
                : filters.group_by?.[0] === 'date'
                  ? g1_key
                  : filters.group_by?.[0]?.startsWith('custom_field')
                    ? get_custom_field_name(g1_key)
                    : g1_key,
          columns: inner_columns,
        });
      });

      return columns;
    }
    else {
      const grouped_workflow = groupBy(reports_data.value, item => item.workflow?.uid);

      Object.keys(grouped_workflow).forEach((key) => {
        columns.push({
          id: key,
          header: `${grouped_workflow[key][0].workflow.name} quantity`,
          accessorKey: key,
          enableSorting: false,
          enableResizing: false,
        });
      });
    }

  if (props.data.data.type === 'to_status') {
    const grouped_statuses = groupBy(reports_data.value, item => item.status_obj?.uid);

    Object.keys(grouped_statuses).forEach((key) => {
      columns.push({
        id: key,
        header: `${grouped_statuses[key][0].status_obj.name}`,
        accessorKey: key,
      });
    });
  }

  if (props.data.data.type === 'transaction') {
    columns.push(
      ...[
        {
          id: 'adj_date',
          header: $t('Date'),
          accessorKey: 'adj_date',
        },
        {
          id: 'adj_workflow_name',
          header: $t('Type'),
          accessorKey: 'adj_workflow_name',
        },
        {
          id: 'adj_number',
          header: `#${$t('Number')}`,
          accessorKey: 'adj_number',
        },
        {
          id: 'from_stock',
          header: $t('From'),
          accessorKey: 'from_stock',
        },
        {
          id: 'to_stock',
          header: $t('To'),
          accessorKey: 'to_stock',
        },
        {
          id: 'quantity',
          header: $t('Quantity'),
          accessorKey: 'quantity',
        },
      ],
    );

    custom_fields.value = reports_data.value.reduce((custom_fields, curr) => {
      return {
        ...custom_fields,
        ...curr.adj_custom_fields.reduce((fields, field) => {
          return {
            ...fields,
            [field.uid]: field,
          };
        }, {}),
      };
    }, {});

    columns.push(
      ...Object.values(custom_fields.value).map((cf) => {
        return {
          id: cf.uid,
          accessorKey: cf.uid,
          header: inventory_store.get_custom_field(cf.uid)?.name,
        };
      }),
    );
  }
  columns = columns.map(item => ({
    ...item,
    size: columns_widths_map.value[item.id] || 150,
  }));
  return columns;
}

function parse_data(val) {
  const filters = filters$.value?.get_filters();
  if (props.data.data.type === 'to_status') {
    const grouped_data = groupBy(val, item => item.item_obj.uid);
    const data = [];
    Object.keys(grouped_data).forEach((key) => {
      const item = {
        ...grouped_data[key][0].item_obj,
        uom: grouped_data[key][0].uom_obj.name,
      };
      grouped_data[key].forEach((status) => {
        item[status.status_obj.uid] = status.stock_quantity;
      });

      data.push(item);
    });

    return data;
  }
  if (props.data.data.type === 'adjustment') {
    const grouped_data = groupBy(val, item => item.item.uid);

    const data = [];
    Object.keys(grouped_data).forEach((key) => {
      const item = {
        ...grouped_data[key][0].item,

        uom: grouped_data[key][0].uom.name,
      };
      grouped_data[key].forEach((status) => {
        const pre_key = filters.group_by?.[0]
          ? `${
              status[filters.group_by?.[0]]?.uid
              || status.asset_uid
              || status.custom_field
              || status.date
              || 'null'
            }_`
          : '';
        item[pre_key + status.workflow.uid] = status.stock_quantity;
        item[`${pre_key}stock_in_hand`] = status.stock_in_hand;
      });

      data.push(item);
    });

    return data;
  }
  if (props.data.data.type === 'transaction')
    return reports_data.value.map((item) => {
      return {
        ...item,
        name: item.item_name,
        number: item.item_number,
        description: item.item_description,
        uom: item.item_uom,
        ...item.adj_custom_fields.reduce((res, cf) => {
          return { ...res, [cf.uid]: cf.value };
        }, {}),
        quantity: `${item.item_quantity}`,
      };
    });
}

function getCustomFieldStringfiers() {
  return Object.keys(inventory_store.custom_fields_map).reduce((acc, uid) => {
    acc[uid] = (custom_field_value) => {
      const custom_field_details = inventory_store.get_custom_field(uid);
      if (custom_field_details.type === 'checkboxes')
        return custom_field_value.join(', ');
      else if (custom_field_details.type === 'member')
        return formatMemberDetails(custom_field_value);
      else if (custom_field_details.type === 'members')
        return custom_field_value.map(member => formatMemberDetails(member)).join(' | ');
      else if (custom_field_details.type === 'date')
        return dayjs(custom_field_value).format('D MMMM, YYYY');
      else if (custom_field_details.type === 'signature')
        return $t('Signature');
      return custom_field_value;
    };
    return acc;
  }, {});
}

function onExportClicked() {
  const stringifier_map = {
    from_stock: obj => toFromStockParser(obj),
    to_stock: obj => toFromStockParser(obj),
    ...getCustomFieldStringfiers(),
  };

  exportTableAsXLSX(table_instance.value, props.data?.data?.name, true, stringifier_map);
}
function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}
onMounted(() => {
  if (props.id !== 'preview')
    emitter.on('export-inventory-report', (_uid) => {
      onExportClicked();
    });
  setTimeout(async () => {
    await getData();
  }, 100);
});
onUnmounted(() => {
  if (props.id !== 'preview')
    emitter.off('export-inventory-report');
});
</script>

<template>
  <div>
    <div class="my-4 flex w-full justify-between">
      <div class="flex">
        <InventoryReportsTableFilters
          v-if="data?.data?.type === 'to_status'"
          ref="filters$"
          :visible_filters="['date', 'type']"
          :max_date="new Date()"
          @apply="applyFilter($event)"
        />
        <InventoryReportsTableFilters
          v-else-if="data?.data?.type !== 'to_status'"
          ref="filters$"
          :visible_filters="data?.data?.type === 'adjustment' ? ['date_range', 'group_by'] : ['date_range']"
          :max_date_range="new Date().toString()"
          @apply="applyFilter($event)"
        />
      </div>
    </div>

    <div class="scrollbar w-full overflow-auto rounded-lg border" :class="id === 'preview' ? 'h-[400px]' : 'max-h-[calc(100vh-220px)]'">
      <HawkTable
        :key="columns"
        is_gapless
        :data="reports_data"
        :header_styles="{ height: '20px', paddingTop: '0px', paddingBottom: '0px', textAlign: 'center', backgroundColor: 'rgb(249 250 251) !important', color: 'rgb(0, 0, 0)', fontWeight: '500' }"
        :columns="columns"
        cell_height="30px"
        :is_loading="loading"
        :sticky_group_label="true"
        show_column_borders
        :pagination_config="{ totalRows: reports_data.length, pageSize: 50 }"
        @column-resized="columnResized"
        @tableInstanceCreated="$event => table_instance = $event"
      >
        <template #adj_date="adj_date">
          {{ $date(adj_date.data.row.original.adj_date, 'DATE_MED') }}
        </template>
        <template #from_stock="from_stock">
          <InventoryLocationName
            :uid="from_stock.data.row.original.from_stock.uid"
            :type="from_stock.data.row.original.from_stock.type"
          />
        </template>
        <template #to_stock="to_stock">
          <InventoryLocationName
            :uid="to_stock.data.row.original.to_stock.uid"
            :type="to_stock.data.row.original.to_stock.type"
          />
        </template>

        <template v-for="(value, key) in custom_fields" #[key]="cf" :key="key">
          <InventoryCustomField
            :field="inventory_store.get_custom_field(key)"
            :value="cf.data.row.original[key]"
          />
        </template>
        <template #noData>
          <HawkIllustrations type="no-results" />
        </template>
      </HawkTable>
    </div>
  </div>
</template>
