<script setup>
import { pick } from 'lodash-es';
import RepresentationBlock from '~/forms/components/form-workflows/sidebar/conditional-block/representation-block.vue';
import FormFieldTree from '~/forms/molecules/form-field-tree.vue';

const props = defineProps({
  name: {
    type: String,
    default: 'rules',
  },
  rules: {
    default: () => [],
  },
  options: {
    type: Object,
    default: () => {},
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  workflow: {
    type: Boolean,
    default: true,
  },
  fields: {
    type: Array,
    default: () => [],
  },
  is_required: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['invalid', 'updateRules', 'getFilterPayload']);

const rules = ref(props.rules || []);
const field_type_options = ref({
  email: {
    name: 'Email',
    representation: 'text',
  },
  text: {
    type: 'text',
    representation: 'text',
  },
  auto_number: {
    type: 'auto_number',
    representation: 'text',
  },
  url: {
    type: 'url',
    representation: 'text',
  },
  short_text: {
    type: 'short_text',
    representation: 'text',
  },
  long_text: {
    type: 'long_text',
    representation: 'long_text',
  },
  phone_number: {
    type: 'phone_number',
    representation: 'phone_number',
  },
  number: {
    type: 'number',
    representation: 'number',
  },
  decimal: {
    type: 'decimal',
    representation: 'number',
  },
  users: {
    type: 'users',
    representation: 'dropdown',
  },
  member: {
    type: 'member',
    representation: 'dropdown',
  },
  dropdown: {
    type: 'dropdown',
    representation: 'dropdown',
  },
  label: {
    type: 'label',
    representation: 'label_dropdown',
  },
  date_time: {
    type: 'date_time',
    representation: 'date_time',
  },
  attachment: {
    type: 'attachment',
    representation: 'attachment',
  },
});
const operators = ref({
  text: {
    operators: [
      {
        name: 'equal',
        value: 'equal',
      },
      {
        name: 'not equal',
        value: 'notEqual',
      },
      {
        name: 'contains',
        value: 'stringContains',
      },
      {
        name: 'starts with',
        value: 'startsWith',
      },
      {
        name: 'ends with',
        value: 'endsWith',
      },
    ],
    operator: 'equal',
    value: null,
  },
  long_text: {
    operators: [
      {
        name: 'contains',
        value: 'stringContains',
      },
      {
        name: 'does not contains',
        value: 'stringDoesNotContains',
      },
    ],
    operator: 'stringContains',
    value: null,
  },
  number: {
    operators: [
      {
        name: 'equal',
        value: 'numberEquals',
      },
      {
        name: 'not equal',
        value: 'numberNotEquals',
      },
      {
        name: 'Greater Than',
        value: 'greaterThan',
      },
      {
        name: 'Greater Than Inclusive',
        value: 'greaterThanInclusive',
      },
      {
        name: 'Less Than',
        value: 'lessThan',
      },
      {
        name: 'Less Than Inclusive',
        value: 'lessThanInclusive',
      },
    ],
    operator: 'numberEquals',
    value: null,
  },
  phone_number: {
    operators: [
      {
        name: 'equal',
        value: 'numberEquals',
      },
      {
        name: 'not equal',
        value: 'numberNotEquals',
      },
    ],
    operator: 'numberEquals',
    value: null,
  },
  users: {
    operators: [
      {
        name: 'equals',
        value: 'equal',
      },
      {
        name: 'not equals',
        value: 'notEqual',
      },
      {
        name: 'in',
        value: 'arrayEquals',
      },
      {
        name: 'not in',
        value: 'arrayNotEquals',
      },
    ],
    operator: 'equal',
    value: null,
  },
  member: {
    operators: [
      {
        name: 'equals',
        value: 'equal',
      },
      {
        name: 'not equals',
        value: 'notEqual',
      },
      {
        name: 'in',
        value: 'arrayEquals',
      },
      {
        name: 'not in',
        value: 'arrayNotEquals',
      },
    ],
    operator: 'equal',
    value: null,
  },
  dropdown: {
    operators: [
      {
        name: 'equals',
        value: 'equal',
      },
      {
        name: 'not equals',
        value: 'notEqual',
      },
      {
        name: 'is any of',
        value: 'arrayEquals',
      },
      {
        name: 'is not',
        value: 'arrayNotEquals',
      },
    // { name: "contains", value: "contains" },
    // {
    //   name: "does not contain",
    //   value: "doesNotContain",
    // },
    ],
    operator: 'equal',
    value: null,
  },
  label_dropdown: {
    operators: [
      {
        name: 'contains',
        value: 'contains',
      },
      {
        name: 'does not contains',
        value: 'doesNotContain',
      },
      {
        name: 'in',
        value: 'arrayIn',
      },
      {
        name: 'not in',
        value: 'arrayNotIn',
      },
    ],
    operator: 'contains',
    value: null,
  },
  date_time: {
    operators: [
      {
        name: 'earlier than',
        value: 'earlierThan',
      },
      {
        name: 'later than',
        value: 'laterThan',
      },
      {
        name: 'between',
        value: 'between',
      },
      {
        name: 'not between',
        value: 'notBetween',
      },
    ],
    operator: 'earlierThan',
    value: null,
  },
  attachment: {
    operators: [
      {
        name: 'has attachments',
        value: 'has attachments',
      },
    ],
    operator: 'has attachments',
    value: true,
  },
});
const force_update = ref(0);
const fields_options = computed(() => {
  return props.fields.map((field) => {
    return ({ ...field, disabled: rules.value.map(val => val.fact).includes(field.uid) });
  });
});
const get_available_field_options = computed(() => {
  return fields_options.value.filter(field => !field.disabled);
});
const field_by_uid = computed(() => {
  return fields_options.value.reduce((acc, curr) => {
    acc[curr.uid] = curr;
    return acc;
  }, {});
});
const invalid_rules = computed(() => {
  return rules.value.filter((rule, i) => {
    if (getField(i)?.representation === 'number' && rule.operator === 'between') {
      if (rule.value)
        if ((rule.value[0] === null || rule.value[1] === null))
          return true;
        else
          return +rule.value[0] >= +rule.value[1];
      return true;
    }
    if (typeof rule?.value === 'boolean')
      return false;
    return (operators.value[getField(i)?.representation]?.value === rule.value || !rule.value);
  }).length;
});

function get_filters_data() {
  return rules.value.map((rule) => {
    const field = field_by_uid.value[rule.fact];
    return {
      ...rule,
      name: field.name,
      data: field.column?.data,
      type: field.type,
      field: rule.fact,
      ...(field.path && { path: field.path }),
    };
  });
}

function get_unmapped_conditions() {
  return rules.value.map((rule, i) => {
    const field = field_by_uid.value[rule.fact];
    const operators = getOperators(i);
    return {
      representation: ['users', 'members'].includes(field.type) ? field.type : getField(i)?.representation,
      operators: operators.map(operator => ({
        action: 'update',
        name: operator.name,
        value: {
          action: 'update',
          ...operator,
        },
      })),
      operator: operators.filter(operator => operator.value === rule.operator)[0],
      value: rule.value,
      ...pick(field, [
        'name',
        'column',
        'uid',
        'type',
        'path',
        'config',
        'order_index',
      ]),
    };
  });
}

function getField(i) {
  return (field_type_options.value[field_by_uid.value[rules.value[i]?.fact]?.type] || {});
}
function getOperators(i) {
  const form_field = field_by_uid.value[rules.value[i]?.fact];
  if (form_field?.type === 'number')
    return [...(operators.value[getField(i)?.representation]?.operators || []), { name: 'between', value: 'between' }];
  return operators.value[getField(i)?.representation]?.operators.filter((operator) => {
    return !(form_field.type === 'date_time' && form_field.config?.type === 'time' && !operator?.name?.endsWith('than'));
  });
}
function setDefaultOperator(i, value) {
  if (rules.value[i]) {
    if (value)
      rules.value[i].fact = value;
    rules.value[i].operator = operators.value[getField(i)?.representation]?.operator;
    rules.value[i].value = operators.value[getField(i)?.representation]?.value;
    force_update.value = force_update.value + 1;
  }
}
function setDefaultValue(i, value = null) {
  if (!rules.value[i])
    return;
  if (value)
    rules.value[i].operator = value;
  if (['dropdown', 'label_dropdown'].includes(getField(i)?.representation))
    if (rules.value[i].operator.endsWith('equal') || rules.value[i].operator.includes('contain'))
      rules.value[i].value = null;
    else
      rules.value[i].value = [];
  const form_field = field_by_uid.value[rules.value[i].fact];
  if (form_field?.type === 'date_time' && !rules.value[i].operator.endsWith('than'))
    rules.value[i].value = [];
  if (getField(i)?.representation === 'number' && rules.value[i].operator === 'between')
    rules.value[i].value = [null, null];
  force_update.value = force_update.value + 1;
}

function addNewField() {
  rules.value.push({ fact: get_available_field_options.value[0]?.uid });
  setDefaultOperator(rules.value.length - 1);
  force_update.value = force_update.value + 1;
}
function init() {
  rules.value = props.rules.map((rule) => {
    const field_data = fields_options.value.find(field => field.uid === rule.fact);
    // Previously for label_dropdown the default operator was 'equal' which was incorrect, and got fixed in https://app.clickup.com/t/3620712/TICKET-17820
    // But Database already have some exisiting templates with the 'equal' operator,
    // To give a fallback support the below condition check for label_dropdown with equal operator and make it 'contains'
    // Info: remove this after BE have written a DB migration for the same.
    if (rule.operator === 'equal' && field_type_options.value?.[field_data?.type]?.representation === 'label_dropdown')
      rule.operator = 'contains';

    return rule;
  }) || [];
  force_update.value = force_update.value + 1;

  emit('getFilterPayload', {
    filters: get_filters_data,
    unmapped_conditions: get_unmapped_conditions,
  });
}

init();

watch(invalid_rules, (val) => {
  emit('invalid', val);
});

watch(force_update, () => {
  emit('updateRules', rules.value);
});
</script>

<template>
  <div>
    <ListElement
      :name="name"
      v-bind="options"
      :rules="is_required ? 'required' : ''"
      :controls="{ add: false, remove: false, sort: false }"
    >
      <template #default="{ index }">
        <ObjectElement
          :name="index"
        >
          <div class="col-span-12">
            <div :class="{ 'flex items-start': !workflow }">
              <div>
                <FormFieldTree
                  :class="[workflow ? 'mt-6' : 'w-56 mr-2']"
                  :data="fields_options"
                  :tree_config="{
                    options: {
                      name: 'fact',
                      object: false,
                      search: true,
                      native: false,
                      canClear: false,
                      hideSelected: true,
                      canDeselect: false,
                      removeClasses: {
                        SelectElement: {
                          select: {
                            optionSelectedDisabled: 'form-color-on-primary form-bg-primary',
                          },
                        },
                      },
                    },
                    select_type: 'LEAF_PRIORITY',
                    select_view: 'SELECT',
                    show_expanded_search: true,
                  }"
                  @onSelect="setDefaultOperator(index, $event?.[0]?.uid)"
                />
              </div>
              <div class="flex" :class="{ 'mt-4': workflow }">
                <SelectElement
                  name="operator"
                  :class="[workflow ? 'w-[180px]' : 'w-36']"
                  :items="getOperators(index)"
                  :native="false"
                  :can-clear="false"
                  :can-deselect="false"
                  label-prop="name"
                  value-prop="value"
                  track-by="name"
                  :add-classes="{
                    SelectElement: {
                      select: {
                        wrapper: 'capitalize',
                        option: 'capitalize',
                      },
                    },
                  }"
                  @select="setDefaultValue(index, $event)"
                />
                <div>
                  <RepresentationBlock
                    :key="`${getField(index).type}-${index}`"
                    :class="[workflow ? 'w-[180px] ml-4' : 'w-36 ml-2']"
                    :representation="getField(index).representation"
                    :field_name="getField(index).type"
                    :operator="rules[index].operator"
                    :field="field_by_uid[rules[index].fact]"
                    :value="rules[index] && rules[index].value"
                    @change="($event) => {
                      if (rules[index])
                        rules[index].value = $event
                    }"
                  />
                </div>
                <div :class="[workflow ? 'w-10' : 'w-14']">
                  <div class="flex items-center justify-end mt-2">
                    <div
                      class="cursor-pointer"
                      @click="() => {
                        rules.splice(index, 1);
                        emit('updateRules', rules);
                      }"
                    >
                      <IconHawkTrashOne class="text-gray-600 w-4.5 h-4.5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ObjectElement>
      </template>
    </ListElement>
    <HawkButton type="link" class="my-4" :disabled="!get_available_field_options.length || invalid_rules" @click="addNewField()">
      <IconHawkPlus
        class="text-primary-700 w-5 h-4.75 mx-1"
      />
      <div class="font-semibold">
        {{ $t('Add Field') }}
      </div>
    </HawkButton>
  </div>
</template>
