<script setup>
import { ref } from 'vue';
import { useTagsStore } from '~/common/stores/tags.store.js';

const props = defineProps({
  tag: {
    type: Object,
    default: () => {},
  },
  action_type: {
    type: String,
    default: 'add',
  },
});
const emit = defineEmits(['close', 'created']);

const $toast = inject('$toast');
const tags_store = useTagsStore();

const form$ = ref(null);
const form = ref({
  name: '',
});
const is_loading = ref(false);

async function save() {
  if (!form.value.name)
    return;
  try {
    is_loading.value = true;
    if (props.action_type === 'edit') {
      await tags_store.update_tag({
        ...props.tag,
        name: form.value.name,
      });
      emit('close');
    }
    else {
      const response = await tags_store.add_tag(form.value.name);
      if (response) {
        form.value.name = null;
        is_loading.value = false;
        emit('created', response);
        emit('close');
      }
    }
  }
  catch (error) {
    is_loading.value = false;
    $toast({
      title: 'Something went wrong',
      text: error.data?.message?.[0] ?? 'Please try again',
      type: 'error',
    });
  }
}
onMounted(() => {
  form.value = {
    name: props?.action_type === 'edit' ? props?.tag?.name : null,
  };
  form$.value.load(form.value, true);
});
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ props.action_type === 'edit' ? $t('Update Tag') : $t('Create Tag') }}
          </template>
        </hawk-modal-header>

        <hawk-modal-content class="max-h-[calc(100vh-40vh)] w-[600px] overflow-auto scrollbar ">
          <TextElement
            name="name"
            :label="$t('Name')"
            :placeholder="$t('Enter tag name')"
            class="mb-4"
            rules="required"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600" name="submit" :submits="true"
              >
                {{ props.action_type === 'edit' ? $t('Update') : $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
