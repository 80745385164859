import { readAndCompressImage } from 'browser-image-resizer';
import { isFileExtensionAllowed } from '~/common/utils/common.utils.js';

async function resizeImage(file, resize_config) {
  if (!resize_config)
    return file;

  return await readAndCompressImage(file, resize_config);
}

/* This utility function uploads a file to https://api.sensehawk.com/storage/upload using FormData */
/* make sure to pass resize = false if it's not an image or it'll fail */
export function uploadToStorage(file, resize_config = { quality: 1, maxWidth: 800, maxHeight: 600 }, progressCallback = () => {}) {
  if (!isFileExtensionAllowed(file?.name))
    return null;

  return resizeImage(file, resize_config).then((resized_image) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', resized_image, `${crypto.randomUUID()}.jpg`);

      const xhr = new XMLHttpRequest();
      xhr.responseType = 'json';
      xhr.open('POST', 'https://api.sensehawk.com/storage/upload', true);

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          progressCallback(percentComplete);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4)
          if (xhr.status === 200)
            resolve(xhr.response);
          else
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              status: xhr.status,
              statusText: xhr.statusText,
            });
      };

      xhr.send(formData);
    });
  });
}
