import { acceptHMRUpdate, defineStore } from 'pinia';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';
import { getDateInterval, isDateIntervalOperator } from '~/dashboard/components/filters/composables/filters-config';

export const useDashboardInventoryStore = defineStore('dashboard_inventory', {
  state: () => ({
    inventory_configuration: {},
    type_options: [
      ['material_tracking', 'Material tracking', 'Track information across various items (or material) for the defined columns'],
      ['stock_tracking', 'Stock tracking', 'Track stock information in a pivot table for the items that are tracked using serial numbers'],
      ['adjustment', 'Stock quantities by transaction', 'View the stock quantities breakdown by their transactions in the selected time period'],
      ['transaction', 'Transactions', 'View all published transactions within the selected time range'],
      ['to_status', 'Stock quantities by status', 'View the stock quantities of various items as on a particular date'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
        description: item[2],
      };
    }),
    timerange_type_options: [
      ['all_time', 'All time'],
      ['today', 'Today'],
      ['last_7_days', 'Last 7 days'],
      ['last_14_days', 'Last 14 days'],
      ['last_30_days', 'Last 30 days'],
      ['this_week', 'This week'],
      ['this_month', 'This month'],
      ['year_to_date', 'This year'],
    ].map(item => ({
      value: item[0],
      label: item[1],
    })),
    timerange_interval_options: [
      ['daily', 'Daily'],
      ['weekly', 'Weekly'],
      ['monthly', 'Monthly'],
    ].map(item => ({
      value: item[0],
      label: item[1],
    })),
    items_with_serial_number_tracking_enabled: [],
    data_set: false,
  }),
  getters: {
    inventory_widget_type: (state) => {
      return state.inventory_configuration.type || state.type_options[0].value;
    },
    inventory_track_stock_uid: (state) => {
      return state.inventory_configuration.track_stock_uid || state.transaction_types[0].value;
    },
    warehouses: () => {
      const { warehouses } = useInventoryStore();
      return warehouses;
    },
    transaction_types: () => {
      const { workflows } = useInventoryStore();
      return workflows.map(wf => ({
        label: wf.name,
        value: wf.uid,
      }));
    },
    track_stock_field_options: (state) => {
      const { custom_fields } = useInventoryStore();
      const fields = custom_fields.filter((cf) => {
        if (cf.type !== 'date')
          return false;

        let is_item_workflow_attached = false;
        cf.attached_to.forEach((attached_to) => {
          if (attached_to.type === 'item_workflow' && attached_to.uid === state.inventory_configuration.track_stock_uid && attached_to.active)
            is_item_workflow_attached = true;
        });
        return is_item_workflow_attached;
      }).map(cf => ({
        label: cf.name,
        value: cf.uid,
      }));
      return [{ label: 'Transaction date', value: 'date' }, ...fields];
    },
    inventory_schema_1: (state) => {
      if (!state.data_set)
        return {};

      const inventory_store = useInventoryStore();

      return {
        type: {
          type: 'select',
          items: state.type_options,
          search: true,
          native: false,
          label: 'Type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: ['required'],
          default: state.inventory_configuration.type || state.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        ...(state.inventory_configuration.type === 'material_tracking' && {
          warehouses: {
            type: 'tags',
            label: 'Warehouses',
            items: inventory_store.warehouses.map(warehouse => ({ uid: warehouse.uid, name: warehouse.name })),
            search: true,
            trackBy: 'name',
            native: false,
            canClear: true,
            canDeselect: true,
            inputType: 'search',
            autocomplete: 'off',
            labelProp: 'name',
            valueProp: 'uid',
            object: true,
            closeOnSelect: false,
            default: state.inventory_configuration.warehouses || [],
          },
        }),
      };
    },
    inventory_schema_2: (state) => {
      if (!state.data_set)
        return {};

      return {
        ...(state.inventory_configuration.type === 'material_tracking' && {
          track_stock_quantities: {
            type: 'toggle',
            native: false,
            label: 'Track stock quantities',
            description: 'Track day, week or month wise procured quantities for the selected transaction type',
            default: state.inventory_configuration.track_stock_quantities || false,
          },
          ...(state.inventory_configuration.track_stock_quantities && {
            track_stock_uid: {
              type: 'select',
              items: state.transaction_types,
              search: true,
              native: false,
              label: 'Transaction',
              canClear: false,
              canDeselect: false,
              inputType: 'search',
              autocomplete: 'off',
              default: state.inventory_configuration.track_stock_uid || state.transaction_types[0].value,
              slots: {
                option: SelectOptionTemplate,
              },
            },
            track_stock_field: {
              type: 'select',
              items: state.track_stock_field_options,
              search: true,
              native: false,
              label: 'Field',
              canClear: false,
              canDeselect: false,
              inputType: 'search',
              autocomplete: 'off',
              default: state.inventory_configuration.track_stock_field || state.track_stock_field_options[0]?.value || null,
              slots: {
                option: SelectOptionTemplate,
              },
            },
            track_stock_timerange_type: {
              type: 'select',
              items: state.timerange_type_options,
              search: true,
              native: false,
              label: 'Time range',
              canClear: false,
              canDeselect: false,
              inputType: 'search',
              autocomplete: 'off',
              default: state.inventory_configuration.track_stock_timerange_type || state.timerange_type_options[0].value,
              slots: {
                option: SelectOptionTemplate,
              },
            },
            track_stock_timerange_interval: {
              type: 'select',
              items: state.timerange_interval_options,
              search: true,
              native: false,
              label: 'Interval',
              canClear: false,
              canDeselect: false,
              inputType: 'search',
              autocomplete: 'off',
              default: state.inventory_configuration.track_stock_timerange_interval || state.timerange_interval_options[0].value,
              slots: {
                option: SelectOptionTemplate,
              },
            },
          }),
          bom_enabled: {
            type: 'toggle',
            native: false,
            label: 'Bill Of Material (BOM)',
            description: 'Track block-level material requirements for the selected warehouses',
            default: state.inventory_configuration.bom_enabled || false,
          },
        }),
        ...(state.inventory_configuration.type === 'stock_tracking' && {
          items: {
            type: 'select',
            label: 'Item',
            items: state.items_with_serial_number_tracking_enabled,
            search: true,
            native: false,
            canClear: false,
            canDeselect: true,
            inputType: 'search',
            autocomplete: 'off',
            labelProp: 'name',
            valueProp: 'uid',
            object: true,
            rules: ['required'],
            default: state.items_with_serial_number_tracking_enabled[0] || null,
          },
        }),
        module: {
          type: 'hidden',
          default: 'inventory',
        },
      };
    },
    get_inventory_schema: (state) => {
      return {
        ...state.inventory_schema_1,
        ...state.inventory_schema_2,
      };
    },
  },
  actions: {
    async get_items_with_serial_number_tracking_enabled() {
      if (this.items_with_serial_number_tracking_enabled?.length)
        return;
      const { data } = await this.$services.inventory_items.getAll({
        query: {
          limit: Number.MAX_VALUE,
        },
      });

      this.items_with_serial_number_tracking_enabled = data.items.filter(item => item.is_serial_number).map(item => ({ name: item.name, uid: item.uid }));
    },
    update_inventory_configuration(data) {
      this.inventory_configuration = { ...data };
    },
    update_relative_date_filters(filters) {
      const filters_payload = { ...filters.filters };
      if (filters.report_filters && filters.report_filters.length)
        filters.report_filters.forEach((f) => {
          if (isDateIntervalOperator(f.operator)) {
            const [first_day_date, last_day_date] = getDateInterval(f.operator);

            filters_payload[`${f.filter_type}_start`] = first_day_date.toISOString();
            filters_payload[`${f.filter_type}_end`] = last_day_date.toISOString();
          }
        });

      return {
        filters: filters_payload,
        report_filters: filters.report_filters,
      };
    },
    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
      ) {
        this.data_set = true;
        this.inventory_configuration = {
          ...this.inventory_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      this.inventory_configuration = { ...widget_configuration };

      this.data_set = true;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardInventoryStore, import.meta.hot));
