<script setup>
import { groupBy, isEmpty, map } from 'lodash-es';
import { computed, ref } from 'vue';
import HawkTreeselect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  tree_config: {
    type: Object,
    required: true,
  },
  option_id: {
    type: String,
    default: '',
  },
});

const emits = defineEmits(['onSelect', 'setDefaultVal']);

const form_template_detail_store = useFormTemplateDetailStore();

const initial_state = ref([]);
function get_children(item) {
  return item.subRows;
}

const is_workflow = computed(() => form_template_detail_store.form_template_detail.workflow || props.tree_config?.is_workflow);

/**
 * To filter out data that does not contain "form_block_id" or "section_id" based on workflow type
 * @param {Array} field_data
 */
function getFilteredData(field_data) {
  let filtered_data = {};

  if (is_workflow.value)
    filtered_data = groupBy(field_data, field => field.form_block_id ? 'tree_data' : 'root_data');
  else
    filtered_data = groupBy(field_data, field => field.section_id ? 'tree_data' : 'root_data');

  return filtered_data;
}

const get_formatted_structure = computed(() => {
  const result = [];

  const { root_data = [], tree_data = [] } = getFilteredData(props.data);

  root_data.forEach((field) => {
    result.push({
      ...field,
      label: field.name || field.label,
      id: field[props.option_id] || field.uid || field.key,
      uid: field[props.option_id] || field.uid || field.key,
      disabled: field?.disabled,
    });
  });

  if (is_workflow.value) {
    const grouped_by_form_id = groupBy(tree_data, 'form_block_id');
    const structured_data = map(grouped_by_form_id, (items, form_block_id) => {
      const form = {
        label: items[0].form_block_name,
        id: form_block_id,
        uid: form_block_id,
        subRows: [],
      };

      const grouped_by_section_id = groupBy(items, 'section_id');

      form.subRows = map(grouped_by_section_id, (fields, section_id) => {
        return {
          label: fields[0].section_name,
          id: section_id,
          uid: section_id,
          subRows: fields.map(field => ({
            ...field,
            label: field.name || field.label,
            id: field[props.option_id] || field.uid || field.key,
            uid: field[props.option_id] || field.uid || field.key,
            disabled: field?.disabled,
          })),
        };
      });

      return form;
    });
    result.push(...structured_data);
  }
  else {
    const grouped_by_section_id = groupBy(tree_data, 'section_id');
    const structured_data = map(grouped_by_section_id, (items, section_id) => {
      const section = {
        label: items[0].section_name,
        id: section_id,
        uid: section_id,
        subRows: [],
      };

      section.subRows = items.map(field => ({
        ...field,
        label: field.name || field.label,
        id: field[props.option_id] || field.uid || field.key,
        uid: field[props.option_id] || field.uid || field.key,
        disabled: field?.disabled,
      }));

      return section;
    });
    result.push(...structured_data);
  }

  return result;
});

onMounted(() => {
  emits('setDefaultVal');
});
</script>

<template>
  <HawkTreeselect
    :disable_branch_nodes="true"
    :multi_select="false"
    :options="tree_config.options"
    children_key="subRows"
    data_type="object"
    :label_key="tree_config?.label_key || 'label'"
    :value_key="tree_config?.value_key || 'id'"
    :get_children_func="get_children"
    :data="get_formatted_structure"
    :select_type="tree_config.select_type"
    :select_view="tree_config.select_view"
    :option_text_length="tree_config.option_text_length"
    :show_expanded_search="tree_config.show_expanded_search"
    @updateForm="($event) => !isEmpty($event) && emits('onSelect', $event)"
  />
</template>
