<script setup>
import { keyBy, sortBy, toLower } from 'lodash-es';
import { useDashboardInventoryStore } from '~/dashboard/store/dashboard-inventory.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import HawkHandsontable from '~/common/components/organisms/hawk-handsontable/hawk-handsontable.vue';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';
import { csvInjectionProtector } from '~/common/utils/common.utils.js';
import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
});
const $services = inject('$services');

const dashboard_inventory_store = useDashboardInventoryStore();
const dashboard_store = useDashboardStore();
const emitter = useEmitter();

const state = reactive({
  is_loading: true,
  response_data: null,
  nested_headers: null,
  columns: [],
});

const column_name_map = computed(() => keyBy(props.data?.data?.fields || [], 'name'));

const { getFormattedDate } = useFamConstants();

watch(() => props.data, async () => {
  await getData();
}, { immediate: true, deep: true });

function flattenObject(obj, parentKey = '', res = {}) {
  for (const key in obj)
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key]))
      if (key === 'track_stock_data')
        for (const subKey in obj[key]) {
          const newKey = `track_stock_${subKey}`;
          res[newKey] = obj[key][subKey];
        }
      else if (key === 'bom')
        for (const subKey in obj[key]) {
          const newKey = `bom_${subKey}`; // subKey is warehouse
          Object.values(obj[key][subKey]).forEach((item) => {
            res[`${newKey}_${item.block}`] = item.quantity;
          });
        }

      else
        flattenObject(obj[key], key, res);

    else if (column_name_map.value[key]?.label)
      res[column_name_map.value[key].label] = obj[key];
    else
      res[key] = obj[key];

  return res;
}

async function getData() {
  try {
    state.is_loading = true;
    state.nested_headers = [];
    const { data } = await $services.inventory_reports.inventory_dynamic_report({ body: parseDateRanges(props.data.data) });
    if (data.items?.results?.length) {
      const flatten_data = data.items?.results?.map(item => flattenObject(item));

      const columns = props.data?.data?.fields?.map(field => ({ data: field.label, readOnly: true, text: field.label })) || [];

      if (data.time_interval?.length)
        data.time_interval.forEach((interval) => {
          columns.push({
            data: `track_stock_${interval}`,
            readOnly: true,
            text: interval,
          });
        });
      if (Object.keys(data.bom_warehouses || {})?.length) {
        let nestedHeaders = [];
        const first_level = [{ label: '', colspan: columns.length }];
        const second_level = [...columns.map(column => ({ label: column.text, colspan: 1 }))];
        Object.keys(data.bom_warehouses || {}).forEach((warehouse) => {
          first_level.push({ label: warehouse, colspan: data.bom_warehouses[warehouse].length });
          data.bom_warehouses[warehouse].forEach((item) => {
            second_level.push({ label: item.block, colspan: 1 });
            columns.push({
              data: `bom_${warehouse}_${item.block}`,
              readOnly: true,
              text: item.block,
            });
          });
        });
        nestedHeaders = [first_level, second_level];
        state.nested_headers = nestedHeaders;
      }
      state.columns = columns;
      state.response_data = sortBy(flatten_data, item => toLower(item.Name));
    }
    state.is_loading = false;
  }
  catch (err) {
    logger.error({ err });
    state.is_loading = false;
  }
}
const colHeaders = function (index) {
  return state.columns[index].text;
};

function hotSettings() {
  return {
    rowHeaders: true,
    rowHeights: 26,
    viewPortRowRenderingOffset: 100,
  };
}

function afterGetColHeader(col, TH) {
  if (state.columns[col]?.data?.startsWith('track_stock_'))
    Handsontable.dom.addClass(TH, 'track-stock-header');
}
function parseDateRanges(data) {
  const parsed_payload = { ...data };
  if (data?.track_stock_quantities)
    parsed_payload.track_stock_timerange_range = getFormattedDate(data.track_stock_timerange_type) || [];

  if (data?.fields?.length)
    parsed_payload.fields = data.fields.map((field) => {
      if (field?.timerange_type)
        return { ...field, timerange_range: getFormattedDate(field.timerange_type) || [] };
      return field;
    });

  return parsed_payload;
}
async function exportExcel() {
  try {
    const ExcelJS = await import('exceljs');
    const { saveAs } = await import('file-saver');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    worksheet.columns = state.columns.map((column) => {
      let header = csvInjectionProtector(column.data.split(`_${column.text}`)?.[0] || '');
      if (['track_stock'].includes(header) || column.data === header)
        header = '';
      if (header.startsWith('bom_'))
        header = header.slice(4);
      return { header, id: column.data, key: column.data, width: 20 };
    });

    // Merging columns
    const header_values = worksheet.getRow(1).values.slice(1);
    const merged_cells = {};
    header_values.forEach((header, index) => {
      if (!merged_cells[header])
        merged_cells[header] = { start: index + 1 };
      else
        merged_cells[header].end = index;
    });
    for (const [header, range] of Object.entries(merged_cells))
      if (range.end) {
        worksheet.mergeCells(1, range.start, 1, range.end + 1);
        worksheet.getCell(1, range.start).value = header;
      }
    // Styling parent columns
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      if (cell?._value?.value) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd9d9d9' },
        };
        cell.border = { style: 'thin', color: { argb: 'd9d9d9' } };
        cell.font = { bold: true };
      }
    });
    // Adding children columns
    const children_columns = state.columns.reduce((acc, curr) => {
      acc[curr.data] = csvInjectionProtector(curr.text);
      return acc;
    }, {});
    worksheet.addRow(children_columns);

    // Styling children columns
    worksheet.getRow(2).eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: cell?._column?._key?.startsWith('track_stock_') ? 'FEF0C7' : 'd9d9d9' },
      };
      cell.border = { style: 'thin', color: { argb: 'd9d9d9' } };
      cell.font = { bold: true };
    });

    // Adding data to excel
    state.response_data.forEach((val) => {
      const formatted_data = state.columns.reduce((acc, curr) => {
        acc[curr.data] = csvInjectionProtector(String(val?.[curr?.data] || ''));
        return acc;
      }, {});
      worksheet.addRow(formatted_data);
    });

    const merged_cells_headers = Object.keys(merged_cells);
    if (merged_cells_headers.length === 1 && merged_cells_headers[0] === '')
      worksheet.spliceRows(1, 1);

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${props.data?.data?.name}.xlsx`);
  }
  catch (error) {
    logger.error('Failed to export', error);
  }
}
onMounted(() => {
  if (props.id !== 'preview')
    emitter.on('export-inventory-report', () => {
      exportExcel();
    });
});
onUnmounted(() => {
  if (props.id !== 'preview')
    emitter.off('export-inventory-report');
});
</script>

<template>
  <div>
    <HawkLoader v-if="state.is_loading" container_class="m-1" />
    <div
      v-else-if="!state.is_loading && data.type === 'material_tracking' || data?.data?.type === 'material_tracking'"
      class="h-[calc(100vh-150px)]"
      :class="{ 'h-[calc(100vh-320px)]': id === 'preview' }"
    >
      <HawkHandsontable
        :data="state.response_data"
        :columns="state.columns"
        :hot_settings="hotSettings()"
        :hot_table_id="id"
        :col_headers="colHeaders" :height="id !== 'preview' ? '100%' : '450px'"
        :after_get_col_header="afterGetColHeader"
        :nested_headers="state.nested_headers"
      />
    </div>
  </div>
</template>

<style>
.handsontable THEAD TH.track-stock-header {
  background-color: rgba(254, 240, 199, 1) !important;
}
</style>
