<script setup>
const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const state = reactive({ form_data: {} });

const current_values = computed(() => {
  const { view, state } = props.editor;
  const { from, to } = view.state.selection;
  const text = state.doc.textBetween(from, to, '');
  return {
    text,
    link: props.editor.getAttributes('link').href,
  };
});

function applyLink() {
  const { text, link } = state.form_data;
  if (link === '') {
    props.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .unsetLink()
      .run();

    emit('close');
    return;
  }

  props.editor
    .chain()
    .focus()
    .extendMarkRange('link')
    .setLink({ href: link })
    .run();

  if (!current_values.text && text)
    props.editor
      .chain()
      .focus()
      .insertContent(text)
      .run();

  props.editor
    .chain()
    .focus()
    .unsetMark('link')
    .insertContent(' ')
    .run();
  emit('close');
}
</script>

<template>
  <div class="flex flex-col w-64 p-1">
    <Vueform v-model="state.form_data" size="sm">
      <TextElement
        name="link"
        label="Link"
        :default="current_values.link"
        :columns="{ sm: { container: 12, label: 12, wrapper: 12 } }"
        @keydown.enter.prevent.stop="applyLink"
        @keydown.esc.prevent.stop="$emit('close')"
      />
      <TextElement
        name="text"
        label="Text"
        :default="current_values.text"
        :columns="{ sm: { container: 12, label: 12, wrapper: 12 } }"
        @keydown.enter.prevent.stop="applyLink"
        @keydown.esc.prevent.stop="$emit('close')"
      />
      <div class="flex justify-end w-full col-span-12">
        <HawkButton @click="applyLink">
          Apply
        </HawkButton>
      </div>
    </Vueform>
  </div>
</template>
