import { VueRenderer } from '@tiptap/vue-3';
import tippy from 'tippy.js';

import EditorSuggestionList from '~/common/components/organisms/hawk-wysiwyg-editor/editor-suggestion-list.vue';

export function suggestionListRenderer() {
  let component;
  let popup;

  return {
    onStart: (props) => {
      component = new VueRenderer(EditorSuggestionList, {
        props,
        editor: props.editor,
      });

      if (!props.clientRect)
        return;

      popup = tippy('body', {
        theme: 'editor-tippy',
        getReferenceClientRect: props.clientRect,
        appendTo: () => props.editor.options?.appendMentionsTo ?? document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start',
      });
    },

    onUpdate(props) {
      component.updateProps(props);

      if (!props.clientRect)
        return;

      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      });
    },

    onKeyDown(props) {
      if (props.event.key === 'Escape') {
        popup[0].hide();

        return true;
      }

      return component.ref?.onKeyDown(props);
    },

    onExit() {
      popup[0].destroy();
      component.destroy();
    },
  };
}
