import { defineStore } from 'pinia';
import { cloneDeep, groupBy, keyBy } from 'lodash-es';
import { useFormsStore } from './forms.store';
import { useFieldSectionVisibility } from '~/forms/composables/form-detail-composable';
import { useTerraStore } from '~/terra/store/terra.store';

function getTableSectionValues(current_value, map) {
  if (!map[current_value.field.uid])
    return { [current_value.field.uid]: [current_value.value] };
  else
    map[current_value.field.uid].push(current_value.value);
}

function getValues(current_value, map) {
  if (current_value.field.multi_value)
    return getTableSectionValues(current_value, map);
  else
    return { [current_value.field.uid]: current_value.value };
}

function get_step_visibility(form_detail_store, step_index) {
  const completed_steps = form_detail_store?.form_detail?.completed_steps?.map(
    step => +step.index,
  );
  const allowed_steps = [...(completed_steps || []), ...[+form_detail_store?.form_detail?.status?.index || 1]];

  return !!allowed_steps.includes(step_index);
}

const get_step_submission = (form_detail_store, step_index) => form_detail_store?.form_submissions?.step_submissions?.[step_index];

export function useFormDetailStore(key) {
  return defineStore(key ? `${key}-detail` : 'form-detail', {
    state: () => ({
      is_slug_mode: false,
      form_detail: {},
      field_values: {},
      form_submissions: {},
      form_template: {},
      forms_list_store: useFormsStore(key),
      invalid_fields: {},
      priority_values: [
        {
          name: 'critical',
          be_key: 'critical',
          label: 'Critical',
          value: 1,
        },
        {
          name: 'high',
          be_key: 'high',
          label: 'High',
          value: 2,
        },
        {
          name: 'medium',
          be_key: 'medium',
          label: 'Medium',
          value: 3,
        },
        {
          name: 'low',
          be_key: 'low',
          label: 'Low',
          value: 4,
        },
        {
          name: 'not_set',
          be_key: 'not_set',
          label: 'Not set',
          value: 5,
        },
      ],
    }),
    getters: {
      sections: (state) => {
        return state.form_template.sections;
      },
      field_value_map: (state) => {
        return state.field_values;
      },
      steps_with_sections: (state) => {
        if (state?.form_detail?.isChild && !state?.form_detail?.workflow)
          return {};
        const steps_with_sections = { ...(state.form_template?.steps || {}) };
        const form_template = state.form_template;

        const sections_hash = groupBy(
          form_template?.sections,
          item => item.step_index ?? '1',
        );

        Object.keys(state.form_template?.steps || {}).forEach((item) => {
          steps_with_sections[item].sections = sections_hash[item] ?? [];
          steps_with_sections[item].collapse = false;
        });
        return steps_with_sections;
      },
      current_step: (state) => {
        if (
          (state?.form_detail?.is_child ? state?.form_detail?.workflow : true)
          && state?.form_detail?.steps
          && state.form_detail?.step_status
        )
          return state?.form_detail?.steps[state.form_detail?.step_status];

        else return null;
      },
      get_all_visible_data(state) {
        const { get_field_visibility, get_section_visibility } = useFieldSectionVisibility(state, true);
        const field_value_map = state.field_value_map;

        const filter_fields = (section) => {
          section.fields = section.fields.filter(field => field.status === 'active').filter(field => get_field_visibility(section, field) !== 'hidden');
          section.fields.forEach((field) => {
            field.value = field_value_map[field.uid] || '-';
          });
        };

        function filter_sections_fields(sections) {
          const filtered_sections = sections.filter(section => section.status === 'active').filter(section => get_section_visibility(section) !== 'hidden');
          filtered_sections.forEach(filter_fields);
          return filtered_sections;
        }

        if (Object.keys(this.steps_with_sections).length > 0) {
          const steps = Object.values(this.steps_with_sections).filter((step) => {
            return get_step_visibility(state, step.index);
          }).map((step) => {
            step.submission_data = get_step_submission(state, step.index);
            const cloned_step = cloneDeep(step);
            cloned_step.sections = filter_sections_fields(cloned_step.sections);
            return cloned_step;
          });
          return steps;
        }
        else {
          const sections = cloneDeep(state.form_detail.template.sections);
          return filter_sections_fields(sections);
        }
      },
      priority_map: (state) => {
        return keyBy(state.priority_values, 'be_key');
      },
    },
    actions: {
      reset() {
        this.form_detail = {};
        this.field_values = {};
        this.form_submissions = {};
        this.form_template = {};
      },
      update_invalid_fields(uid, value) {
        this.invalid_fields[uid] = value;
      },
      enable_slug_mode() {
        this.is_slug_mode = true;
      },
      disable_slug_mode() {
        this.is_slug_mode = false;
      },
      async set_form(req) {
        try {
          const { data } = await this.$services.forms.get(req);
          this.form_detail = data?.form || {};
          this.field_values = data?.form?.field_values || [];
          this.form_submissions = data?.form?.submission || {};
          this.form_template = data?.form?.template || {};
        }
        catch (error) {
          logger.error(error);
          throw error;
        }
      },
      async set_submission_history(req) {
        try {
          const { data } = await this.$services.forms.getAll(req);
          this.form_detail = data?.submission_history?.form || {};
          this.form_detail.template = data?.submission_history?.template || this.form_detail.template;
          this.form_template = data?.submission_history?.template;
          this.field_values = data?.submission_history?.values || {};
          this.form_submissions = data?.submission_history?.submission || {};
          this.form_submissions.active_fields = data?.submission_history.active_fields;
          this.form_submissions.active_sections = data?.submission_history.active_sections;
          this.form_submissions.completed_steps = data?.submission_history.completed_steps;
        }
        catch (error) {
          logger.error(error);
          throw error;
        }
      },
      async update_form_details(req) {
        const form_template = Object.assign(this.form_detail, {});
        this.form_detail = { ...this.form_detail, ...req.body };
        try {
          const { data } = await this.$services.forms.post({
            body: {
              forms: {
                update: [{
                  uid: this.form_detail.uid,
                  ...req.body,
                }],
              },
            },
          });
          if (this.forms_list_store.forms_map[this.form_detail.uid] && data?.forms?.updated?.length)
            this.forms_list_store.forms_map[this.form_detail.uid] = { ...this.form_detail, ...req.body };
        }
        catch (err) {
          this.form_template_detail = { ...form_template };
          this.$toast({ text: err?.data?.message || 'Form Updating failed!', type: 'error' });
          logger.log(err);
          throw err;
        }
      },
      async save_form(form_uid, req) {
        try {
          const { data } = await this.$services.forms.post(
            {
              body: req?.body,
              attribute: `${form_uid}/submission`,
            },
          );
          this.form_detail = data?.form || {};
          this.field_values = data?.form?.field_values || [];
          this.form_submissions = data?.form?.submission || {};
          if (this.forms_list_store.forms_map[form_uid])
            this.forms_list_store.forms_map[form_uid] = this.form_detail;

          this.$toast({ text: 'Form Saved Successfully', type: 'success' });
          this.forms_list_store.forms_track_events('Saved', form_uid, { mode: 'Single', count: 1 });
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: error.data?.message ?? 'Form saving failed!', type: 'error' });
          throw error;
        }
      },
      async submit_form(form_uid, req) {
        try {
          await this.$services.forms.post(req);
          if (this.forms_list_store.forms_map[form_uid]) {
            this.forms_list_store.forms_map[form_uid] = this.form_detail;
            try {
              const response = await this.$services.forms.get({ id: form_uid, toast: false });
              if (response.data.form) {
                const latest_form = response.data.form;
                latest_form.template = latest_form.template.uid;
                this.forms_list_store.forms_map[form_uid] = latest_form;
              }
              if (this.forms_list_store.forms_map[form_uid]?.properties?.integration?.type === 'INTG_101') {
                const terra_store = useTerraStore();
                if (this.forms_list_store.forms_map[form_uid].status.submission_status === 'submitted')
                  await terra_store.syncApiForInspectionForms(this.forms_list_store.forms_map[form_uid], { event: 'FORMS_SUBMITTED', status: 'submitted' });
              }
              this.forms_list_store.forms_track_events('Submitted', form_uid, { mode: 'Single', count: 1 });
            }
            catch (e) {
              delete this.forms_list_store.forms_map[form_uid];
            }
          }
          this.$toast({ title: 'Form submitted successfully!', text: 'You will receive a copy of your submission in your email.', type: 'success' });
        }
        catch (error) {
          this.$toast({ text: error?.data?.message || 'Form submission failed!', type: 'error' });
          throw error;
        }
      },
      async reopen_form(form_uid, req) {
        try {
          const { data } = await this.$services.forms.post(req);
          this.form_detail = data?.form || {};
          if (this.forms_list_store.forms_map[form_uid])
            this.forms_list_store.forms_map[form_uid] = data?.form;
          if (this.forms_list_store.forms_map[form_uid]?.properties?.integration?.type === 'INTG_101') {
            const terra_store = useTerraStore();
            await terra_store.syncApiForInspectionForms(this.forms_list_store.forms_map[form_uid], { event: 'FORMS_REOPENED', status: 'pending' });
          }
          this.forms_list_store.forms_track_events('Reopened', form_uid, { with_comment: !!req?.body?.comment?.length });
          this.$toast({ text: 'Form Reopened Successfully', type: 'success' });
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: 'Form Reopening failed!', type: 'error' });
          throw error;
        }
      },
      async rollback_form(form_uid, req) {
        try {
          const { data } = await this.$services.forms.post(req);
          this.form_detail = data?.form || {};
          if (this.forms_list_store.forms_map[form_uid])
            this.forms_list_store.forms_map[form_uid] = data?.form;
          if (this.forms_list_store.forms_map[form_uid]?.properties?.integration?.type === 'INTG_101') {
            const terra_store = useTerraStore();
            await terra_store.syncApiForInspectionForms(this.forms_list_store.forms_map[form_uid], { event: 'FORMS_REOPENED', status: 'pending' });
          }
          this.forms_list_store.forms_track_events('Rolledback', form_uid, { with_comment: !!req?.body?.comment?.length });
          this.$toast({ text: 'Form Successfully Rolled Back', type: 'success' });
        }
        catch (error) {
          this.$toast({ text: 'Form Failed to Rollback!', type: 'error' });
          throw error;
        }
      },
      async approve_form(form_uid, req) {
        try {
          await this.$services.forms.approval(req);
          try {
            await this.set_form({ id: form_uid, toast: false });
            if (this.forms_list_store.forms_map[form_uid]) {
              this.forms_list_store.forms_map[form_uid] = this.form_detail;
              const with_comment = !!req.body.comment.length;
              if (req.body.action === 'approve')
                this.forms_list_store.forms_track_events('Approved', form_uid, { with_comment });
              else if (req.body.action === 'deny')
                this.forms_list_store.forms_track_events('Rejected', form_uid, { with_comment });
            }
          }
          catch (e) {
            delete this.forms_list_store.forms_map[form_uid];
          }
          this.$toast({ text: `Form ${req.body?.action === 'approve' ? 'Approved' : 'Rejected'} Successfully`, type: 'success' });
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: 'Form Failed to Approve!', type: 'error' });
          throw error;
        }
      },
      async set_geofencing_distance(distance) {
        try {
          await this.$services.forms.post({
            body: {
              forms: {
                update: [{
                  uid: this.form_detail.uid,
                  geofencing_distance: distance,
                }],
              },
            },
            toast: { message: 'Form boundaries updated successfully' },
          });
          this.form_detail.geofencing_distance = distance;
          this.$toast({ text: 'Geofencing Distance Saved Successfully!', type: 'success' });
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: 'Geofencing Distance update failed!', type: 'error' });
          throw error;
        }
      },
      async retry_document_generation(payload = {}) {
        await this.$services.forms.post({
          body: payload.body,
          attribute: 'document/retry',
        });
      },
      async set_schedule({ schedule, duration }) {
        const old_schedule = this.form_detail.schedule;
        const old_duration = this.form_detail.duration;
        try {
          this.form_detail.schedule = schedule;
          this.form_detail.duration = duration;
          const response = await this.$services.forms.post({
            body: {
              forms: {
                update: [{
                  uid: this.form_detail.uid,
                  schedule,
                  duration,
                  recurring: true,
                }],
              },
            },
          });
          const updated_form = response.data.forms?.updated[0];
          this.form_detail = { ...this.form_detail, due_date: updated_form.due_date };
          if (this.forms_list_store.forms_map[this.form_detail.uid] && updated_form?.form)
            this.forms_list_store.forms_map[this.form_detail.uid] = this.form_detail;
        }
        catch (error) {
          this.form_detail.schedule = old_schedule;
          this.form_detail.duration = old_duration;
          this.$toast({ text: 'Schedule update failed!', type: 'error' });
          throw error;
        }
      },
    },
  })();
}
