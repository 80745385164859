<script setup>
import { useRouter } from 'vue-router';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';

const props = defineProps({
  document_details: {
    type: Object,
  },
});
const emit = defineEmits(['close']);

const router = useRouter();
const transmittals_store = useTransmittalsStore();

const state = reactive({
  show_viewer: false,
});

async function download(document) {
  if (document.presigned_url)
    window.open(document.presigned_url, '_blank');
}

function addMarkup(document) {
  if (document?.actions?.can_review) {
    router.replace({ name: 'document-markup', params: { document_uid: document?.uid, transmittal_uid: transmittals_store?.transmittal.uid } });
    emit('close');
  }
}
</script>

<template>
  <DocumentViewerWrapper
    v-slot="{ is_attachment_supported }"
    :document="document_details"
    :show_viewer="state.show_viewer"
    @closeAttachment="state.show_viewer = false"
  >
    <div class="flex flex-col items-center">
      <div
        class="flex-none relative cursor-pointer group border rounded-lg"
        @click="state.show_viewer = true"
      >
        <img
          v-if="document_details?.thumbnail_xsmall"
          alt="thumbnail"
          :src="document_details?.thumbnail_xsmall"
          class="w-40 h-40 rounded-lg object-cover"
        >
        <HawkFileIcon v-else icon_classes="w-20 h-20 rounded-lg object-cover" :file_name="document_details.file_name " />
        <div v-if="is_attachment_supported" class="absolute top-0  place-items-center hidden group-hover:grid">
          <div class="h-40 w-40 bg-black rounded-lg bg-opacity-20" />
          <div class="text-white absolute">
            <IconHawkEye />
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center mt-4">
        <hawk-button v-if="document_details?.actions?.can_review && is_attachment_supported" type="outlined" class="mr-3" @click.stop="addMarkup(document_details)">
          <IconHawkPenToolTwo />
        </hawk-button>
        <hawk-button v-if="is_attachment_supported" type="outlined" class="mr-3" @click="state.show_viewer = true">
          <IconHawkMaximizeOne />
        </hawk-button>
        <hawk-button v-if="document_details?.presigned_url" type="outlined" class="flex" @click.stop="download(document_details)">
          <IconHawkDownloadTwo class="mr-1" /> {{ $t('Download') }}
        </hawk-button>
      </div>
    </div>
  </DocumentViewerWrapper>
</template>
