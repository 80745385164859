<script setup>
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  steps: {
    type: Object,
  },
  outcome_strategy_list: {
    type: Object,
  },
  default_statuses: {
    type: Array,
  },
  published: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
});

const emit = defineEmits(['toggle-active', 'mounted']);

const workflow_ref = ref(null);
const outcome_strategy_ref = ref(null);

const dms_settings_store = useDMSSettingsStore();

onMounted(() => {
  dms_settings_store.fetch_all_statuses();
});
</script>

<template>
  <ObjectElement ref="workflow_ref" name="workflow" @mounted="emit('mounted', workflow_ref)">
    <div class="col-span-12">
      <div class="max-w-[700px] pr-4">
        <SelectElement
          name="entry_status"
          :label="$t('Entry status')"
          :description="$t('Select a status to set to the document on starting the review flow.')"
          :native="false"
          :can-deselect="false"
          :can-clear="false"
          :disabled="disabled"
          :add-classes="{
            SelectElement: {
              select: {
                dropdown: ['scrollbar'],
              },
            },
          }" class="mt-3" :items="dms_settings_store.active_document_status" label-prop="name" value-prop="name" :rules="['required']"
        />
      </div>
      <StaticElement name="steps" :wrap="false">
        <transmittal-workflow-graph :steps="steps" :published="published" :disabled="disabled" :outcome_strategy_ref="outcome_strategy_ref" />
      </StaticElement>
      <div class="max-w-[600px]">
        <div class="mt-6">
          <div class="text-lg font-semibold">
            {{ $t('Outcome strategy') }}
          </div>
          <div class="text-xs">
            {{ $t('Craft your workflow outcome strategy to determine the document status') }}
          </div>
        </div>
        <RadiogroupElement
          ref="outcome_strategy_ref"
          class="mt-3"
          name="outcome_strategy"
          :rules="['required']"
          :disabled="disabled"
          :items="outcome_strategy_list"
        />
        <div class="mt-6">
          <div class="text-lg font-semibold">
            {{ $t('View feedback') }}
          </div>
          <div class="text-xs">
            {{ $t('Allow members to view the feedback, status, annotations of other members') }}
          </div>
          <CheckboxgroupElement
            name="feedback_visibility"
            :native="false"
            class="mt-2"
            :disabled="disabled"
            :items="[
              { value: 'reviewer', label: 'Reviewers' },
              { value: 'submitter', label: 'Submitter' },
            ]"
            :default="['reviewer']"
          />
        </div>
      </div>
      <div class="mt-6">
        <div class="text-lg font-semibold">
          {{ $t('Map review statuses') }}
        </div>
        <div class="text-xs">
          {{ $t('Map the review statuses that should automatically be mapped to the corresponding document statuses.') }}
        </div>
        <div class="mt-3">
          <transmittal-workflow-status-map
            :default_statuses="default_statuses" :disabled="disabled"
            @toggle-active="({ index, status }) => emit('toggle-active', { index, status })"
          />
        </div>
      </div>
    </div>
  </ObjectElement>
</template>
