<script setup>
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import InventoryMaterialTrackingWidget from '~/dashboard/components/widgets/inventory-widgets/inventory-material-tracking.widget.vue';
import InventoryStockTrackingWidget from '~/dashboard/components/widgets/inventory-widgets/inventory-stock-tracking.widget.vue';
import InventoryReportsTableWidget from '~/dashboard/components/widgets/inventory-widgets/inventory-reports-table.widget.vue';

const dashboard_store = useDashboardStore();

const widget_config = computed(() => Object.values(dashboard_store.current_dashboard.report_data || {})?.[0]);

const widget = computed(() => {
  const widget_type = widget_config.value?.data?.type;
  switch (widget_type) {
    case 'material_tracking' :
      return InventoryMaterialTrackingWidget;
    case 'stock_tracking' :
      return InventoryStockTrackingWidget;
    case 'adjustment':
    case 'transaction':
    case 'to_status':
      return InventoryReportsTableWidget;
  }
});
</script>

<template>
  <div class="mx-4 mt-4 h-[calc(100vh-150px)]">
    <component
      :is="widget"
      :id="widget_config.uid "
      :data="widget_config"
    />
  </div>
</template>
