<script setup>
import { isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useDashboardTasksStore } from '~/dashboard/store/dashboard-tasks.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import DashboardTasksFilter from '~/dashboard/components/filters/dashboard-tasks-filters.vue';

const dashboard_tasks_store = useDashboardTasksStore();
const dashboard_store = useDashboardStore();

const {
  tasks_schema,
  tasks_configuration,
} = storeToRefs(dashboard_tasks_store);

const {
  update_tasks_configuration,
  set_data,
} = dashboard_tasks_store;

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;

function updateTasksConfiguration(data, key = null) {
  if (!key) {
    update_tasks_configuration({
      ...tasks_configuration.value,
      ...data,
    });
  }
  else {
    const copy = {
      ...tasks_configuration.value,
    };

    copy[key] = data;
    update_tasks_configuration(copy);
  }

  validateForm();
}

const form$ = ref(null);

async function validateForm() {
  // sometimes changes take longer
  await new Promise(resolve => setTimeout(resolve, 100));
  form$.value.validate();
  const is_valid = !form$.value.hasErrors;
  set_form_valid(is_valid);
  if (is_valid)
    set_widget_configuration({
      ...tasks_configuration.value,
    });
}

watch(widget_asset, (new_val, old_val) => {
  if (!isEqual(new_val, old_val))
    updateTasksConfiguration(widget_asset.value, 'asset_id');
});

onMounted(() => {
  set_data();
  validateForm();
});

onBeforeUnmount(() => {
  dashboard_tasks_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <Vueform
    ref="form$"
    size="sm"
    :schema="tasks_schema"
    :presets="['supress_errors']"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
    :should_validate_on_mount="false"
    :display-errors="false"
    class="mb-6"
    @change="updateTasksConfiguration($event)"
  />
  <DashboardTasksFilter
    @update="updateTasksConfiguration($event, 'filters')"
  />
</template>
