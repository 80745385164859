<script setup>
const props = defineProps({
  folder_data: {
    type: Object,
    default: () => {
      return {};
    },
  },
  on_submit: {
    type: Function,
    required: true,
  },
  folder_name_regex: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['close']);

const form$ = ref(null);
const loading = ref(false);

const invalid_characters_str = '\\/:*?\'\'<>|';

// Popup save
async function handleSave() {
  loading.value = true;
  const form_data = form$.value.data;
  await props.on_submit(form_data);
  loading.value = false;
  emit('close');
}

onMounted(() => {
  if (props.folder_data)
    form$.value.load(props.folder_data, true);
});
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :should_validate_on_mount="false" :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="handleSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              <div v-if="folder_data && Object.keys(folder_data).length" class="flex flex-col justify-start">
                {{ $t('Edit Folder') }}
              </div>
              <div v-else class="flex flex-col justify-start">
                {{ $t('New Folder') }}
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <div class="p-1 max-h-[575px]">
            <TextElement
              :rules="['required', folder_name_regex ? folder_name_regex : null]"
              :messages="{ regex: `${$t('A folder name can not contain any of the following characters')}: ${invalid_characters_str}` }"
              :placeholder="$t('Folder name')" :label="$t('Name')" name="name"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="$slots.left_footer" #left>
            <slot name="left_footer" />
          </template>
          <!-- Footer -->
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button class="mr-3" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-submit-button :form$="form$ || {}">
                {{ $t('Save') }}
              </hawk-submit-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
