import parser from 'ua-parser-js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { getSubmittedFieldData } from '~/forms/composables/form-field-schema.composable.js';
import { $date } from '~/common/utils/date.util';
import { getUserFullName } from '~/common/utils/common.utils';
import { nodeToString, renderRichText, stringToNode } from '~/dashboard/print/utilities.js';
import { createColumnGroups, createTables } from '~/dashboard/print/charts/table.js';

function getUserAvatarName(uid = null) {
  if (!uid)
    return 'Unknown';

  const common_store = useCommonStore();

  const organization_user = common_store.get_user(uid);
  const internal_user = common_store.internal_users_map?.[uid];

  return getUserFullName(organization_user || internal_user || null);
}

function getTeamAvatarName(uid = null) {
  const common_store = useCommonStore();
  return common_store.teams_map?.[uid]?.name || 'Unknown';
}

function getSignatureMeta(data = {}) {
  let user_agent_info = '';
  if (data.user_agent) {
    const result = parser(data.user_agent);
    user_agent_info += result?.browser?.name ? `${result?.browser?.name}` : '';
    user_agent_info += result?.device?.model ? `, ${result?.device?.model}` : '';
  }

  const author_text = data?.owner?.uid ? `Signed by: ${getUserAvatarName(data?.owner?.uid)}` : '';

  return `
    <div>
      <div>
        ${author_text}
      </div>
      <div> 
        On: ${$date(data?.created_at, 'DATETIME_MED')}
      </div>
    </div>
  `;
}

function getSingleFormFieldAttachment(field = {}, value_map = {}) {
  const files_data = getSubmittedFieldData(field, value_map[field.uid]) || [];

  const container = document.createElement('div');

  if (!files_data?.length || files_data === '-')
    container.innerHTML = '-';
  else
    files_data?.forEach((file) => {
      if (file?.service?.url) {
        const a = document.createElement('a');
        a.setAttribute('href', file.service.url);
        a.setAttribute('target', '_blank');
        a.innerHTML = file.file_name;
        container.appendChild(a);
        const br = document.createElement('br');
        container.appendChild(br);
      }
    });

  return container;
}

function getSingleFormFieldValue(field = {}, section_type = '', value_map = {}) {
  if (section_type === 'signature') {
    const signature_data
      = value_map?.[field.uid]?.[0]?.location
      || value_map?.[field.uid]?.[0]
      || value_map?.[field.uid]?.location
      || null;

    if (!signature_data)
      return '-';

    const img_src = signature_data?.service?.url || signature_data?.location || signature_data || null;

    return `
      <div>
        <img class="single-form-signature" src="${img_src}" />
        ${getSignatureMeta(signature_data.meta)}
      </div>
      `;
  }

  if (section_type === 'checklist') {
    const checklist_status_map = {
      resolved: 'Yes',
      rejected: 'No',
      onHold: 'NA',
    };

    return checklist_status_map[field.status];
  }

  if (field.properties.type === 'info') {
    const content = field.config?.placeholder || field.properties.placeholder || null;

    return content
      ? renderRichText(content)
      : '';
  }

  if (field.properties.type === 'long_text')
    return value_map[field.uid] || '-';

  if (field.type === 'member') {
    const members = getSubmittedFieldData(field, value_map[field.uid]);
    let members_text = '';

    members?.members?.forEach((user_uid) => {
      const user_name = getUserAvatarName(user_uid);
      members_text += `${user_name}, `;
    });

    members?.teams?.forEach(async (team_uid) => {
      const team_name = getTeamAvatarName(team_uid);
      members_text += `${team_name}, `;
    });

    // remove last semicolon
    return members_text.slice(0, -2);
  }

  if (field.type === 'attachment') {
    const attachments = getSingleFormFieldAttachment(field, value_map);
    return nodeToString(attachments);
  }

  else { return getSubmittedFieldData(field, value_map[field.uid]); }
}

function createSingleFormSection(section = {}, value_map = {}) {
  const table = document.createElement('table');
  const table_head = document.createElement('thead');

  const table_head_row = document.createElement('tr');
  const table_head_cell = document.createElement('th');

  table_head_cell.innerHTML = `Section ${section.row_index + 1}: ${section.name}`;
  table_head_cell.setAttribute('colspan', 3);
  table_head_row.appendChild(table_head_cell);
  table_head.appendChild(table_head_row);

  table.appendChild(table_head);

  const table_body = document.createElement('tbody');
  let section_fields = [];
  if (section?.fields?.length && section.type !== 'checklist')
    section_fields = section.fields?.filter(f => f.status === 'active') || [];
  else if (section.type === 'checklist')
    section_fields = section.fields?.[0]?.value || [];

  if (typeof section_fields !== 'object' || !section_fields?.length) {
    const empty_table = stringToNode(`
      <tr>
        <td class="table-single-form__no-data">
          No data
        </td>
      <tr>
    `);

    table_body.appendChild(empty_table);
    table.appendChild(table_body);
    return table;
  }

  section_fields.forEach((field, index) => {
    const field_value = getSingleFormFieldValue(field, section.type, value_map);
    const table_row = stringToNode(`
        <tr>
          <td class="table-single-form__index">
            ${index + 1}
          </td>
          <td class="table-single-form__name">
            ${field.name}
          </td>
          <td class="table-single-form__value">
            ${field_value}
          </td>
        <tr>
      `);

    table_body.appendChild(table_row);
  });

  table.appendChild(table_body);

  return table;
}

function createSingleFormTableSection(section = {}, value_map = {}) {
  const section_fields = section.fields?.filter(f => f.status === 'active') || [];

  const table_field_values = section_fields.reduce((result, field) => {
    // field (or column) values, we get these values differently, depending on the field type
    const field_data = value_map[field.uid] || field.properties.value || [];

    // initially, values are stored based first on column id, then on row id
    // we revert that
    field_data.forEach((value, value_index) => {
      if (typeof result[value_index] === 'undefined')
        result[value_index] = {};

      result[value_index][field.uid] = getSubmittedFieldData(field, value);
    });

    return result;
  }, []);

  // create columns, fields are displayed as columns
  const columns = section.fields.map(field => ({
    id: field.uid,
    header: field.name,
  }));
  const formatted_columns = createColumnGroups(columns);

  // use the default table functions
  const section_tables = createTables(
    formatted_columns,
    table_field_values,
  );

  const container = document.createElement('div');
  const container_header = document.createElement('div');
  container_header.classList.add('table-single-form__table-header');
  container_header.innerHTML = `Section ${section.row_index + 1}: ${section.name}`;
  container.appendChild(container_header);
  section_tables.forEach((table) => {
    container.appendChild(table);
  });

  return container;
}

function createSingleFormSectionContainer(sections = [], value_map = {}) {
  const container = document.createElement('div');

  sections.forEach((section, index) => {
    const section_cpy = {
      ...section,
      row_index: index,
    };
    if (section.type !== 'tablev2') {
      const section_table = createSingleFormSection(section_cpy, value_map);
      container.appendChild(section_table);
    }
    else {
      const section_table = createSingleFormTableSection(section_cpy, value_map);
      container.appendChild(section_table);
    }
  });

  return container;
}

function createSingleFormStepContainer(data = [], value_map = {}) {
  if (!data?.length)
    return '';

  const container = document.createElement('div');
  data.forEach((step, index) => {
    const step_container = document.createElement('div');
    const title = document.createElement('h4');
    title.innerHTML = `Step ${index + 1}: ${step.name}`;

    step_container.appendChild(title);

    const section_container = createSingleFormSectionContainer(step.sections || [], value_map);
    step_container.appendChild(section_container);

    container.appendChild(step_container);

    if (step.submission_data?.submitted_by) {
      const meta_data = `
        <div class="single-form-step-meta">
          <div>
            Submitted by:
          <div>
          <div>
            ${getUserAvatarName(step.submission_data?.submitted_by)}
          </div>
          <div>
            ${$date(step.submission_data?.submitted_at, 'DATETIME_LONG')}
          </div>
        </div>
      `;
      container.appendChild(stringToNode(meta_data));
    }
  });

  return nodeToString(container);
}

function createSingleFormView(chart, index) {
  const dataset = chart.dataSource?.dataset || [];

  let single_form_view = null;
  if (!dataset?.length)
    single_form_view = `
          <div>
            <p style="text-align: center;">
              ${chart.dataSource?.error_message}
            </p>
          </div>
        `;

  if (dataset?.[0]?.sections) {
    single_form_view = createSingleFormStepContainer(chart.dataSource?.dataset || [], chart.dataSource?.value_map || {});
  }
  else if (dataset.length) {
    const container = document.createElement('div');
    const section_container = createSingleFormSectionContainer(dataset, chart.dataSource?.value_map || {});
    container.appendChild(section_container);

    single_form_view = nodeToString(container);
  }

  let status_badge = '';

  if (chart?.dataSource?.current_status?.name)
    status_badge = `
          <div class="single-form-status">
            <span style="color: ${chart?.dataSource?.current_status?.color}">
              ${chart.dataSource.current_status.name}
            </span>
            <div class="single-form-status__background" style="background: ${chart?.dataSource?.current_status?.color}"></div>
          </div>
        `;

  const due_date = chart?.dataSource?.due_date ? chart?.dataSource?.due_date : '';

  // if the widget is the first one in the print,
  // add page break class so first page won't be empty
  const page_break_class = index === 0 ? 'dashboard-row--page-break' : '';

  const single_form_container = stringToNode(`
        <div class="dashboard-row ${page_break_class}">
          <div class="dashboard-row__chart">
            <h3 class="dashboard-row__name">
              ${chart.chart_name}
            </h3>
            <div class="single-form-header">
              ${status_badge}
              ${due_date}
            </div>
            ${single_form_view}
          </div>
        </div>
      `);

  return single_form_container;
}

export {
  createSingleFormView,
};
