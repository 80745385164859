const extensions = {
  '': 'unknown',
  'audio': 'audio',
  'video': 'video',
  'text': 'text',
  'archive': 'archive',
  '.audio': 'audio',
  '.video': 'video',
  '.text': 'text',
  '.archive': 'archive',

  '.jpg': 'jpg',
  '.jpe': 'jpg',
  '.jpeg': 'jpg',
  '.jfif': 'jpg',
  '.png': 'png',
  '.gif': 'gif',
  '.tiff': 'tiff',
  '.svg': 'svg',
  '.psd': 'psd',
  '.ai': 'ai',
  '.dwg': 'dwg',

  '.iso': 'iso',
  '.mdf': 'mdf',
  '.nrg': 'nrg',

  '.zip': 'zip',
  '.7z': '7z',
  '.7zip': '7z',
  '.arj': 'arj',
  '.rar': 'rar',
  '.gz': 'archive',
  '.gzip': 'archive',
  '.bz2': 'archive',
  '.bzip2': 'archive',
  '.tar': 'archive',

  '.xls': 'xls',
  '.doc': 'doc',
  '.pdf': 'pdf',
  '.ppt': 'ppt',
  '.xlsx': 'xls',
  '.docx': 'doc',
  '.pdfx': 'pdf',
  '.pptx': 'ppt',
  '.rtf': 'rtf',
  '.txt': 'txt',
  '.md': 'text',
  '.markdown': 'text',

  '.avi': 'avi',
  '.mp2': 'mp2',
  '.mp3': 'mp3',
  '.mp4': 'mp4',
  '.fla': 'fla',
  '.mxf': 'mxf',
  '.wav': 'wav',
  '.wma': 'wma',
  '.aac': 'aac',
  '.flac': 'flac',

  '.css': 'css',
  '.csv': 'csv',
  '.html': 'html',
  '.json': 'json',
  '.js': 'js',
  '.xml': 'xml',

  '.dbf': 'dbf',
  '.exe': 'exe',
};
const unknown = extensions[''];

function isString(value) {
  return (typeof value === 'string')
    || (Object.prototype.toString.call(value) === '[object String]');
}

function fileExtension(filename, options) {
  if (!options)
    options = {};
  if (!filename)
    return '';

  const pattern = /[^./\\]*$/;
  const ext = (pattern.exec(filename) || [''])[0];
  return options.preserve_case ? ext : `.${ext.toLowerCase()}`;
};

export function getFileIcon(file_name, type, preserve_case = false) {
  const extension = isString(file_name) ? fileExtension(file_name, { preserve_case }) : '';

  type = isString(type) ? type.toLowerCase() : '';
  if (type.charAt(0) === '.')
    type = type.substr(1, type.length);

  if (['svg'].includes(type))
    type = `.${type}`;

  else
    type = '';

  return preserve_case ? ((extension || unknown) + type) : ((extensions[extension] || unknown) + type);
}
