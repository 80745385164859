import { PluginKey } from 'prosemirror-state';
import Mention from '@tiptap/extension-mention';
import { suggestionListRenderer } from '~/common/components/organisms/hawk-wysiwyg-editor/editor-suggestion-list-render.js';

export function makeSuggestionExtension(type = 'tag', char = '#', items = [], filter_fn = items => items) {
  return Mention.extend({
    name: `suggestion-plugin-${type}`,
  }).configure({
    HTMLAttributes: {
      class: `${type}-suggestion`,
    },
    renderLabel({ options, node }) {
      if (type === 'formula')
        return `=${node.attrs.label ?? node.attrs.id}`;
      else if (type === 'tag')
        return `${node.attrs.label ?? node.attrs.id}`;
      else
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
    },
    suggestion: {
      char,
      pluginKey: new PluginKey(`suggestion-plugin-${type}`),
      allowSpaces: true,
      items: ({ query }) => {
        const query_lowercase = query.toLocaleLowerCase();
        return filter_fn(items.filter(item => item.label.toLowerCase().startsWith(query_lowercase)));
      },
      render: suggestionListRenderer,
    },
  });
}
