<script setup>
import { storeToRefs } from 'pinia';
import { useDashboardThermStore } from '~/dashboard/store/dashboard-therm.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import DashboardThemGroups from '~/dashboard/components/therm-groups/dashboard-therm-groups.vue';

const dashboard_therm_store = useDashboardThermStore();
const dashboard_store = useDashboardStore();

const {
  therm_configuration,
  therm_schema,
  view_options,
} = storeToRefs(dashboard_therm_store);

const {
  update_therm_configuration,
  set_views,
  set_data,
} = dashboard_therm_store;

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;

const form$ = ref(null);

function updateThermConfiguration(data, key = null) {
  if (!key) {
    update_therm_configuration({
      ...therm_configuration.value,
      ...data,
    });
  }
  else {
    const copy = {
      ...therm_configuration.value,
    };

    copy[key] = data;
    update_therm_configuration(copy);
  }

  validateForm();
}

async function validateForm() {
  // sometimes changes take longer
  if (!form$.value)
    return;

  await new Promise(resolve => setTimeout(resolve, 100));
  form$.value.validate();

  const has_groups = therm_configuration.value?.group?.length > 0;
  const is_valid = (!form$.value.hasErrors && has_groups);
  set_form_valid(is_valid);
  if (is_valid)
    set_widget_configuration({
      ...therm_configuration.value,
    });
}

watch(view_options, () => {
  if (view_options.value?.length && !therm_configuration.value?.view)
    form$.value.el$('view').update(view_options.value[0].value);
});

async function resetConfigOnAssetChange() {
  await set_views();
  if (form$.value.el$('view'))
    form$.value.el$('view').update(view_options?.value?.[0]?.value || null);
  updateThermConfiguration([], 'group');
};

watch(widget_asset, (new_val, old_val) => {
  if (new_val !== old_val) {
    resetConfigOnAssetChange();
    updateThermConfiguration(widget_asset.value, 'asset_id');
  }
});

onMounted(() => {
  set_data();
  set_views();
  validateForm();
});

onBeforeUnmount(() => {
  dashboard_therm_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <Vueform
    ref="form$"
    size="sm"
    :schema="therm_schema"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
    :should_validate_on_mount="false"
    :display-errors="false"
    :presets="['supress_errors']"
    @change="updateThermConfiguration($event)"
  />
  <DashboardThemGroups
    @update="updateThermConfiguration($event, 'group')"
  />
</template>
