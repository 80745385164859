<script setup>
// assets
import TablerFileDescription from '~icons/tabler/file-description';

const props = defineProps({
  on_submit: {
    type: Function,
    required: true,
  },

});
const emit = defineEmits(['close']);

const form$ = ref(null);
const loading = ref(false);
const is_complete = ref(false);

function onFormMounted(el$) {
  nextTick(() => {
    el$.form$.uppy.on('complete', (_result) => {
      is_complete.value = true;
    });
  });
}

function onFormUpdated(event) {
  is_complete.value = false;
  if (event.type === 'upload_completed' || event.type === undefined)
    is_complete.value = true;
}

// Popup save
async function handleSave() {
  loading.value = true;
  const form_data = form$.value.data;
  props.on_submit(form_data);
  loading.value = false;
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :should_validate_on_mount="false" :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="handleSave"
      @mounted="onFormMounted"
      @updated="onFormUpdated"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center justify-between text-lg font-semibold text-gray-800">
              <div class="flex items-center">
                <div class="flex items-center border rounded-xl p-3 mr-4 border-gray-200 text-gray-600">
                  <TablerFileDescription />
                </div>
                <div class="flex flex-col justify-start">
                  {{ $t('Upload new version') }}
                  <span class="font-normal text-sm text-gray-600">
                    {{ $t('The following files will be uploaded to the project') }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <FileElement
            name="sample"
            :drop="true"
            :auto="false"
            :add-class="{
              dnd: ['drag-n-drop mb-3 border-solid'],
            }"
          />
          <div class="p-1 mt-3">
            <TextElement :placeholder="$t('Enter name')" :rules="['required']" :label="$t('Version name')" class="mb-4" name="version-name" />
          </div>
          <div class="p-1">
            <TextElement :placeholder="$t('Enter Description')" :label="$t('Version Description')" class="mb-4" name="description" />
          </div>
          <div class="p-1">
            <ToggleElement name="toggle" :label="$t('Current version')" :default="true" />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="$slots.left_footer" #left>
            <slot name="left_footer" />
          </template>
          <!-- Footer -->
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
                :loading="loading"
                :disabled="!is_complete"
              >
                {{ $t('Upload') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
