<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { flatten, keyBy, sortBy, uniqBy } from 'lodash-es';
import DashboardScheduleWorkflowsPopup from '~/dashboard/components/schedule-activities/dashboard-schedule-workflows-popup.vue';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const emit = defineEmits(['update']);
const $services = inject('$services');
const route = useRoute();
const dashboard_store = useDashboardStore();
const dashboard_schedule_store = useDashboardScheduleStore();

const {
  schedule_configuration,
} = storeToRefs(dashboard_schedule_store);

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const column_options = [
  ['id', 'Activity ID'],
  ['status', 'Status'],
  ['lag', 'Lag %'],
  ['lag_days', 'Lag (days)'],
  ['actual_progress', '% Actual progress'],
  ['planned_progress', '% Planned progress'],
  ['remaining_progress', '% Remaining progress'],
  ['planned_duration', 'Planned duration'],
  ['actual_duration', 'Actual duration'],
  ['remaining_duration', 'Remaining duration'],
  ['absolute_remaining_duration', 'Remaining Schedule Duration', 'computed_column'],
  ['absolute_work_rate', 'Work Required (per day)', 'computed_column'],
  ['critical', 'Critical'],
  ['bl_start', 'BL Start'],
  ['bl_finish', 'BL Finish'],
  ['planned_start', 'Planned start'],
  ['planned_finish', 'Planned finish'],
  ['actual_start', 'Actual start'],
  ['actual_finish', 'Actual finish'],
  ['free_slack', 'Free float'],
  ['total_slack', 'Total float'],
  ['overdue_days', 'Days overdue'],
  ['planned_work_rate', 'Work rate (planned)', 'computed_column'],
  ['actual_work_rate', 'Work rate (actual)', 'computed_column'],
  ['bl_work_rate', 'Work rate (baseline)', 'computed_column'],
  ['remaining_work_rate', 'Work rate (remaining)', 'computed_column'],
].map(c => ({
  key: c[0],
  name: c[1],
  type: c[2] || 'column',
}));

const sub_column_options = [
  ['total_units', 'Total'],
  ['planned_units', 'Planned'],
  ['actual_units', 'Actual'],
  ['actual', '% Actual'],
  ['remaining_units', 'Remaining'],
  ['remaining', '% Remaining'],
  ['lag_units', 'Lag'],
  ['lag', '% Lag'],
].map(c => ({
  value: c[0],
  label: c[1],
}));

const workflows_tree = ref([]);
const all_workflows = ref([]);
const form_data = ref({
  column_selector: null,
  list: [
  ],
});

const form$ = ref(null);
const fields_menu_open = ref(false);

const available_column_options = computed(() => {
  const used_keys = form_data.value.list.map(item => item.key) || [];
  return column_options.filter(c => !used_keys.includes(c.key));
});

const workflows_modal = useModal({
  component: DashboardScheduleWorkflowsPopup,
  attrs: {
    onClose() {
      workflows_modal.close();
    },
    onSave(data) {
      addWorkflowColumns(data);
      workflows_modal.close();
    },
    workflows_tree,
  },
});

async function getWorkflows() {
  const { data } = await $services.terra_workflow.getAll({
    query: {
      asset: widget_asset.value,
    },
  });

  const workflows = data.map((wf) => {
    const child_items = uniqBy(
      flatten(Object.values(wf.data || {}).map(item => item.fields)),
      f => f.uid,
    );
    return {
      name: wf.name,
      uid: wf.uid,
      fields: sortBy(child_items, ['name']),
    };
  });

  workflows_tree.value = sortBy(workflows, ['name']);

  all_workflows.value = keyBy(workflows_tree.value.map(flow => flow.fields).flat(), 'uid');
}

function addColumn(column_uid) {
  const column = column_options.find(c => c.key === column_uid);
  if (!column)
    return;
  form_data.value.list.push({
    name: column.name,
    key: column.key,
    type: column.type,
  });
  emitUpdate();
}

function addWorkflowColumns(data) {
  const existing_workflow_columns_ids = [];
  form_data.value.list.forEach((column) => {
    // also check if column was unchecked inside popup
    if (column.type === 'field' && data.includes(column.key))
      existing_workflow_columns_ids.push(column.key);
  });

  // filter out unchecked columns
  const arr = form_data.value.list.filter(c => c.type !== 'field' || (c.type === 'field' && existing_workflow_columns_ids.includes(c.key)));

  // add new columns, but save the column_types configuration for the existing ones
  data.forEach((workflow_uid) => {
    if (!existing_workflow_columns_ids.includes(workflow_uid)) {
      const workflow = all_workflows.value[workflow_uid];

      arr.push({
        key: workflow.uid,
        type: 'field',
        name: workflow.name,
        column_types: ['total_units', 'planned_units'],
      });
    }
  });

  form_data.value.list = [...arr];

  emitUpdate();
}

function emitUpdate() {
  emit('update', form_data.value.list);
}

const default_value = [
  {
    key: 'id',
    name: 'Activity ID',
    type: 'column',
  }, {
    key: 'status',
    name: 'Status',
    type: 'column',
  },
];

function setInitialData() {
  if (schedule_configuration.value.columns?.length)
    form_data.value.list = [
      ...schedule_configuration.value.columns,
    ];

  else
    form_data.value.list = Object.assign([], default_value);

  emitUpdate();
}

watch(widget_asset, async (new_val, old_val) => {
  if (new_val !== old_val) {
    await getWorkflows();
    form_data.value.list = [...default_value];
    emitUpdate();
  }
});

onMounted(() => getWorkflows());
</script>

<template>
  <div>
    <div class="text-sm text-gray-700 font-medium my-4">
      {{ $t('Fields') }}<span class="text-amber-600">*</span>
    </div>
    <Vueform
      ref="form$"
      v-model="form_data"
      size="sm"
      sync
      class="mb-4"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      @mounted="setInitialData"
    >
      <ListElement
        name="list"
        :sort="true"
        :initial="0"
        :controls="{ add: false, remove: true, sort: true }"
        :default="default_value"
        @sort="emitUpdate"
        @remove="emitUpdate"
      >
        <template #default="{ index }">
          <ObjectElement
            :name="index"
          >
            <HiddenElement name="key" />
            <HiddenElement name="type" />
            <TextElement
              name="name"
              :readonly="true"
            />
            <TagsElement
              v-bind="{
                name: 'column_types',
                items: sub_column_options,
                closeOnSelect: false,
                search: true,
                native: false,
                canClear: false,
                canDeselect: false,
                inputType: 'search',
                autocomplete: 'off',
                conditions: [
                  [
                    'list.*.type',
                    'not_in',
                    [
                      'column',
                      'computed_column',
                      null,
                    ],
                  ],
                ],
                rules: [
                  {
                    required: [
                      [
                        'list.*.type',
                        'not_in',
                        [
                          'column',
                          'computed_column',
                          null,
                        ],
                      ],
                    ],
                  },
                ],
              }"
              @change="emitUpdate"
            />
          </ObjectElement>
        </template>
      </ListElement>
    </Vueform>
    <HawkMenu
      position="bottom-right"
    >
      <template #trigger>
        <HawkButton
          size="sm"
          type="text"
          class="w-fit"
          @open="fields_menu_open = true"
          @close="fields_menu_open = false"
        >
          <span class="ml-1">
            {{ $t('Add fields') }}
          </span>
          <IconHawkChevronUp v-if="fields_menu_open" />
          <IconHawkChevronDown v-else />
        </HawkButton>
      </template>
      <template #content="{ close }">
        <div class="w-[400px] p-1 scrollbar h-52">
          <HawkButton
            type="text"
            color="primary"
            siz="sm"
            class="w-full"
            @click="workflows_modal.open(); close()"
          >
            <IconHawkPlus class="!text-primary-700" />
            <span class="text-primary-700">
              {{ $t('Choose quantities') }}
            </span>
          </HawkButton>
          <div
            v-for="option in available_column_options"
            :key="option.key"
            class="flex items-center text-sm font-medium h-[42px] p-2 cursor-pointer rounded-lg text-gray-700 hover:bg-gray-50"
            @click="addColumn(option.key); close()"
          >
            {{ option.name }}
          </div>
        </div>
      </template>
    </HawkMenu>
  </div>
</template>
