<script setup>
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import DashboardScheduleActivities from '~/dashboard/components/schedule-activities/dashboard-schedule-activities.vue';
import DashboardScheduleColumns from '~/dashboard/components/schedule-activities/dashboard-schedule-columns.vue';
import DashboardScheduleFilters from '~/dashboard/components/filters/dashboard-schedule-filters.vue';
import DashboardScheduleAsOf from '~/dashboard/components/schedule-activities/dashboard-schedule-as-of.vue';

const dashboard_schedule_store = useDashboardScheduleStore();
const dashboard_store = useDashboardStore();

const {
  schedule_schema,
  schedule_configuration,
  schedule_widget_type,
  active_schedule,
  schedule_options,
  chart_type_options,
  chart_value_options,
  data_set,
  activity_history_interval_options,
  activity_history_range,
} = storeToRefs(dashboard_schedule_store);

const {
  update_schedule_configuration,
  set_schedules,
  set_data,
} = dashboard_schedule_store;

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;

const form$ = ref(null);
const report_gantt = ref(null);

const show_as_of_input = computed(() => {
  if (schedule_widget_type.value === 'project_progress')
    return true;
  else if (schedule_widget_type.value === 'activities_breakdown')
    return schedule_configuration.value.chart_value !== 'task_count';
  return false;
});

function updateScheduleConfiguration(data, key = null) {
  if (!key) {
    update_schedule_configuration({
      ...schedule_configuration.value,
      ...data,
    });
  }
  else {
    const copy = {
      ...schedule_configuration.value,
    };

    copy[key] = data;
    update_schedule_configuration(copy);
  }

  validateForm();
}

async function validateForm() {
  await new Promise(resolve => setTimeout(resolve, 100));
  form$.value.validate();
  let is_valid = !form$.value.hasErrors;
  if (['activities_metrics', 'activity_history_table'].includes(schedule_widget_type.value))
    is_valid = schedule_configuration.value.activities?.length > 0;

  if (schedule_widget_type.value === 'activities_work_combined_table')
    is_valid = (
      schedule_configuration.value.columns?.length > 0
      && schedule_configuration.value.activities?.length > 0
    );

  set_form_valid(is_valid);
  if (is_valid)
    set_widget_configuration({
      ...schedule_configuration.value,
    });
}

async function initializeGantt() {
  try {
    const { gantt } = await import('dhtmlx-gantt');
    window.gantt = gantt;

    gantt.init(report_gantt.value);
  }
  catch (error) {
    logger.error(error);
    if (error.message.includes('import') && error.message.includes('module'))
      handleAppUpdate('package: gantt', true);
  }
}

async function resetConfigOnAssetChange() {
  await set_schedules();
  if (form$.value.el$('schedule'))
    form$.value.el$('schedule').update(schedule_options.value?.[0]?.value || null);
};

watch(widget_asset, (new_val, old_val) => {
  if (new_val !== old_val) {
    resetConfigOnAssetChange();

    updateScheduleConfiguration(widget_asset.value, 'asset_id');
  }
});

watch(schedule_widget_type, (new_val, old_val) => {
  // avoid making changes when setting initial datta
  if (!old_val && new_val)
    return;
  const copy = { ...schedule_configuration.value };
  copy.chart_type = chart_type_options.value[0].value;
  form$.value.el$('chart_type').update(copy.chart_type);
  copy.chart_value = chart_value_options.value[0].value;

  updateScheduleConfiguration(copy);
});

watch(active_schedule, (new_val, old_val) => {
  if (!data_set.value || !old_val)
    return;

  const copy = { ...schedule_configuration.value };
  copy.chart_field = 'status';
  copy.activities = [];
  updateScheduleConfiguration(copy);
});

watch(schedule_options, () => {
  if (!active_schedule.value && schedule_options.value?.length)
    form$.value.el$('schedule')?.update(schedule_options.value[0].value);
});

watch(activity_history_range, (new_val, old_val) => {
  if (new_val)
    form$.value.el$('activity_history_interval')?.update(activity_history_interval_options.value[0].value);
});

watch(schedule_configuration, (new_val, old_val) => {
  if (new_val?.activity_history_custom_range?.timePeriod !== old_val?.activity_history_custom_range?.timePeriod)
    form$.value.el$('activity_history_interval')?.update(activity_history_interval_options.value[0].value);
});
onMounted(() => {
  set_schedules();
  set_data();
  initializeGantt();
  validateForm();
});

onBeforeUnmount(() => {
  dashboard_schedule_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <div ref="report_gantt" class="h-0 w-0 pointer-events-none opacity-0 absolute" />
  <Vueform
    ref="form$"
    size="sm"
    :schema="schedule_schema"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
    :should_validate_on_mount="false"
    :display-errors="false"
    class="mb-4"
    @change="updateScheduleConfiguration($event)"
  />
  <DashboardScheduleAsOf
    v-if="show_as_of_input"
    class="mb-6"
    @update="updateScheduleConfiguration($event)"
  />
  <DashboardScheduleActivities
    v-if="[
      'activities_work_combined_table',
      'activities_metrics',
      'activity_history_table',
      'project_progress',
    ].includes(schedule_widget_type)"
    class="mb-6"
    @update="updateScheduleConfiguration($event, 'activities')"
  />
  <DashboardScheduleColumns
    v-if="schedule_widget_type === 'activities_work_combined_table'"
    @update="updateScheduleConfiguration($event, 'columns')"
  />
  <DashboardScheduleFilters
    v-if="[
      's_curve',
      'activities_breakdown',
      'activities_metrics',
      'progress_history',
      'activities_work_combined_table',
    ].includes(schedule_widget_type)"
    @update="updateScheduleConfiguration($event, 'filters')"
  />
</template>
