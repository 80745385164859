import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { RRule } from 'rrule';
import { inject } from 'vue';
import localeData from 'dayjs/plugin/localeData';
import FormBuilderAutoNumberPreview from '~/forms/components/form-builder/form-builder-autonumber-preview.vue';
import FormFieldHierarchy from '~/forms/molecules/form-field-hierarchy.vue';

dayjs.extend(localeData);

export function get_default_suggestions(options) {
  const $t = inject('$t') || options.$t;
  return [
    {
      id: 'number',
      label: $t('Number'),
    },
    {
      id: 'name',
      label: $t('Name'),
    },
    {
      id: 'day',
      label: `${$t('Day')} (ex: 10)`,
    },
    {
      id: 'month_number',
      label: `${$t('Month Number')} (ex: 04)`,
      menu_item_header: 'Month',
    },
    {
      id: 'month_name_short',
      label: `${$t('Month Name short')} (ex: Apr)`,
    },
    {
      id: 'month_name_full',
      label: `${$t('Month Name full')} (ex: April)`,
    },
    {
      id: 'year_short',
      label: `${$t('Year Short')} (ex: 24)`,
      additional_item_classes: 'border-t border-gray-200',
      menu_item_header: 'Year',
    },
    {
      id: 'year_full',
      label: `${$t('Year Full')} (ex: 2024)`,
    },
    {
      id: 'category',
      label: `${$t('Category')} (Civil)`,
      additional_item_classes: 'border-t border-gray-200',
    },
    {
      id: 'due_date',
      label: `${$t('Due Date')} (02/02/2024)`,
    },
    {
      id: 'created_by',
      label: `${$t('Created By')} (John)`,
    },
    {
      id: 'created_at',
      label: `${$t('Created At')} (01/02/2024)`,
    },
    {
      id: 'submitted_by',
      label: `${$t('Submitted By')} (John)`,
    },
    {
      id: 'submitted_at',
      label: `${$t('Submitted At')} (02/02/2024)`,
    },
  ];
}

export function useAutoNumberFieldSchema(options) {
  const $t = inject('$t') || options.$t;

  return {
    auto_number_data: {
      type: 'object',
      schema: {
        html: {
          type: 'WysiwygEditor',
          options: {
            single_line: true,
            menu_enabled: false,
            enable_common_rtf_features: false,
            plugins: ['disable-enter', 'tags'],
            placeholder_text: 'Enter scheme here',
            suggestions: get_default_suggestions({ $t }),
            footer: {
              component: FormFieldHierarchy,
              component_props: {
                nested_suggestions: [
                  ...(options?.extra_suggestions || []),
                ],
                is_workflow: options?.is_workflow,
              },
            },
            nested_suggestions: [
              ...(options?.extra_suggestions || []),
            ],
          },
          native: false,
          rules: ['required'],
          columns: {
            default: { container: 12, label: 12, wrapper: 12 },
            sm: { container: 12, label: 12, wrapper: 12 },
            md: { container: 12, label: 12, wrapper: 12 },
          },
          label: $t('Scheme'),
          info: $t('Auto-numbering will pick up the fields which are only above the auto-number field.'),
          object: true,
        },
        preview: {
          type: 'static',
          columns: {
            default: { container: 12, label: 12, wrapper: 12 },
            sm: { container: 12, label: 12, wrapper: 12 },
            md: { container: 12, label: 12, wrapper: 12 },
          },
          label: $t('Preview'),
          placeholder: 'Preview',
          disabled: true,
          addClasses: {
            ElementLayout: {
              container: ['border', 'border-gray-300', 'bg-gray-50', 'p-2', 'rounded-lg'],
            },
            ElementLabel: {
              wrapper: ['font-bold', 'text-gray-700', 'mb-2'],
            },
          },
          content: FormBuilderAutoNumberPreview,
        },
        next_number: {
          type: 'text',
          inputType: 'number',
          rules: [
            'nullable',
            'numeric',
            'min:1',
          ],
          disabled: options?.field?.editable === false,
          autocomplete: 'off',
          label: $t('Next number'),
          columns: {
            container: 12,
          },
          attrs: { min: 1 },
          default: '1',
        },
        contextual: {
          type: 'toggle',
          native: false,
          columns: {
            default: { container: 12, label: 11, wrapper: 12 },
            sm: { container: 12, label: 11, wrapper: 12 },
            md: { container: 12, label: 11, wrapper: 12 },
          },
          addClasses: {
            ElementLayout: {
              innerContainer: ['-ml-2'],
            },
          },
          conditions: [function (form$) {
            const data = form$.el$('auto_number_data.html')?.value;
            const html = data?.html || data;
            return html?.indexOf('data-id="category"') >= 0;
          }],
          info: 'Increment number categorically based on the remaining part of the information',
          label: $t('Contextual numbering'),
        },
      },
    },
    properties: {
      type: 'Object',
      schema: {
        reset: {
          type: 'select',
          closeOnSelect: false,
          extendOptions: { openDirection: 'top' },
          presets: ['select_top_direction'],
          conditions: [function (form$) {
            const data = form$.el$('auto_number_data.html')?.value;
            const html = data?.html || data;
            return html?.indexOf('data-id="number"') >= 0;
          }],
          items: [
            {
              value: 'yearly',
              label: 'Yearly',
            },
            {
              value: 'monthly',
              label: 'Monthly',
            },
            {
              value: 'weekly',
              label: 'Weekly',
            },
            {
              value: 'daily',
              label: 'Daily',
            },
          ],
          label: $t('Reset'),
          native: false,
        },
        month: {
          type: 'dates',
          name: 'month',
          addClasses: {
            DatepickerWrapper: { calendarContainer: ['bottom-10', '!top-[unset]'] },
          },
          closeOnSelect: false,
          displayFormat: 'MMMM DD',
          min: '05-01',
          max: '05-31',
          items: monthArr(),
          native: false,
          columns: {
            container: 12,
          },
          label: $t('Month'),
          conditions: [
            [
              'properties.reset',
              'in',
              [
                'yearly',
              ],
            ],
          ],
        },
        day: {
          type: 'select',
          closeOnSelect: false,
          items: dayArr(),
          native: false,
          extendOptions: { openDirection: 'top' },
          presets: ['select_top_direction'],
          conditions: [
            [
              'properties.reset',
              'in',
              ['monthly'],
            ],
          ],
          columns: {
            container: 12,
          },
          label: $t('Day'),
        },
        week: {
          type: 'select',
          closeOnSelect: false,
          items: weekArr(),
          native: false,
          extendOptions: {
            openDirection: 'top',
          },
          presets: ['select_top_direction'],
          conditions: [
            [
              'properties.reset',
              'in',
              [
                'weekly',
              ],
            ],
          ],
          columns: {
            container: 12,
          },
          label: $t('Week'),
        },
        type: {
          type: 'hidden',
          default: 'auto_numbering',
        },
      },
    },
  };
}

export function formatAutoNumbering(auto_number_data) {
  const html = auto_number_data?.auto_number_data?.html;
  return {
    calculate: 'on_submit',
    next_number: Number.parseInt(auto_number_data?.auto_number_data?.next_number),
    contextual: auto_number_data?.auto_number_data?.contextual,
    html: DOMPurify.sanitize(html?.html ?? html),
    ...auto_number_data?.properties?.reset && (autoNumberRRule(auto_number_data)),
  };
}

function autoNumberRRule(auto_number_data) {
  if (auto_number_data?.properties?.reset) {
    const today = new Date();
    const rrule = new RRule({
      freq: RRule[auto_number_data?.properties?.reset?.toUpperCase()],
      interval: setInterval(auto_number_data?.properties?.reset),
      dtstart: new Date(
        Date.UTC(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0,
        ),
      ),
      ...settingRule(auto_number_data),
    }).toString();
    return { rrule };
  }
}
function settingRule(auto_number_data) {
  const { reset, month, week, day } = (auto_number_data?.properties || {});
  if (reset === 'weekly')
    return set_week_day(week);

  if (reset === 'monthly')
    return { bymonthday: [Number.parseInt(day)] };

  if (reset === 'yearly')
    return {
      bymonth: [
        Number.parseInt(dayjs(month[0]).format('M')),
      ],
      bymonthday: [Number.parseInt(month[0]?.split('-')[2])],
    };
}
function set_week_day(week) {
  const rule = [];
  if (week === 'monday')
    return { byweekday: rule.push(RRule.MO) };

  if (week === 'tuesday')
    return { byweekday: rule.push(RRule.TU) };

  if (week === 'wednesday')
    return { byweekday: rule.push(RRule.WE) };

  if (week === 'thursday')
    return { byweekday: rule.push(RRule.TH) };

  if (week === 'friday')
    return { byweekday: rule.push(RRule.FR) };
}
// auto numbering
function setInterval(frequency) {
  if (frequency === 'weekly')
    return 5;

  return 1;
}
function monthArr() {
  let obj = {};
  const selected_month = [];
  for (let i = 0; i < 12; i++) {
    const month_name = dayjs()
      .month(i)
      .format('MMMM');
    obj.value = month_name.toLowerCase();
    obj.label = month_name;

    selected_month.push(obj);
    obj = {};
  }
  return selected_month;
}
function weekArr() {
  let obj = {};
  const weekName = [];
  for (let i = 1; i < 6; i++) {
    const week_name = dayjs.weekdays()[i];
    obj.label = week_name;
    obj.value = week_name.toLowerCase();
    obj.rrule_month_name = dayjs
      .weekdays()[i]
      .substring(0, 2)
      .toUpperCase();
    weekName.push(obj);
    obj = {};
  }
  return weekName;
}
function dayArr() {
  let obj = {};
  const dayNames = [];
  for (
    let i = 1;
    i
    <= 31;
    i++
  ) {
    obj.label = i.toString();
    obj.value = i;
    dayNames.push(obj);
    obj = {};
  }
  return dayNames;
}
