<script>
import { ref } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'PhonenumberElement',
  props: {
    phone_number_options: {
      type: Object,
      default: {},
    },
  },
}, {
  setup(props, { element }) {
    const toggle_dropdown = ref(false);
    const phone_object = ref(null);
    const defaultClasses = ref({
      container: '',
      phonenumber: '',
      $phonenumber: classes => ([
        classes.phonenumber,
      ]),
    });
    const phonenumber = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        element.model.value = value;
      },
    });

    const default_options = ref({
      autoFormat: false,
      defaultCountry: '',
      disabled: false,
      autoDefaultCountry: false,
      dropdownOptions: {
        disabled: false,
        showDialCodeInList: true,
        showDialCodeInSelection: false,
        showFlags: true,
        showSearchBox: false,
        tabindex: 0,
      },
      ignoredCountries: [],
      inputOptions: {
        autofocus: false,
        id: '',
        maxlength: 25,
        name: 'telephone',
        showDialCode: false,
        placeholder: 'Enter a phone number',
        readonly: false,
        required: false,
        type: 'tel',
      },
      invalidMsg: 'Invalid phone number',
      onlyCountries: [],
      preferredCountries: [],
      validCharactersOnly: true,
    });

    const pn_options = computed(() => {
      return { ...default_options.value, ...props.phone_number_options };
    });

    const keyExists = (checkKey) => {
      return checkKey in phone_object.value || false;
    };

    return {
      default_options,
      defaultClasses,
      pn_options,
      phonenumber,
      props,
      toggle_dropdown,
      phone_object,
      keyExists,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <vue-tel-input
        :key="pn_options.defaultCountry"
        v-model="phonenumber"
        :auto-format="pn_options.autoFormat"
        :default-country="pn_options.defaultCountry"
        :disabled="pn_options.disabled"
        :auto-default-country="pn_options.autoDefaultCountry"
        :dropdown-options="pn_options.dropdownOptions"
        :ignored-countries="pn_options.ignoredCountries"
        :input-options="pn_options.inputOptions"
        :invalid-msg="pn_options.invalidMsg"
        :only-countries="pn_options.onlyCountries"
        :preferred-countries="pn_options.preferredCountries"
        :valid-characters-only="pn_options.validCharactersOnly"
        :class="{ 'has-error': phone_object && keyExists('nationalNumber') && keyExists('valid') && !(phone_object?.valid) }"
        @validate="$emit('validate', $event);phone_object = $event"
        @country-changed="$emit('countryChanged', $event)"
        @input="$emit('input')"
        @open="toggle_dropdown = true"
        @close="toggle_dropdown = false"
      >
        <template #arrow-icon>
          <IconHawkChevronUp v-if="toggle_dropdown" />
          <IconHawkChevronDown v-else />
        </template>
      </vue-tel-input>
      <div v-if="phone_object && keyExists('nationalNumber') && keyExists('valid') && !(phone_object?.valid)" class="text-[13px] text-red-500 mt-1">
        {{ pn_options.invalidMsg }}
      </div>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <!-- eslint-disable-next-line vue/valid-attribute-name -->
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
