<script setup>
import { storeToRefs } from 'pinia';

import { isEqual } from 'lodash-es';
import DocumentTable from '~/dms/components/documents/table/document-table.vue';
import { useDocumentGlobalActions } from '~/dms/composables/document-global.composable';
import { useDocumentStore } from '~/dms/store/document.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardDMSStore } from '~/dashboard/store/dashboard-dms.store.js';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
  query: {
    type: Object,
  },
});

const dms_settings_store = useDMSSettingsStore();

const document_table = ref(null);

const document_global_actions = useDocumentGlobalActions(props.id);
const document_store = useDocumentStore(props.id);
const dashboard_store = useDashboardStore();
const dashboard_dms_store = useDashboardDMSStore();
const { dms_configuration } = storeToRefs(dashboard_dms_store);
const state = reactive({ is_table_loading: false });

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  const print_dataset = document_store.documents.map((f) => {
    return {
      ...f,
      status: f.status?.name,
    };
  });
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: document_table?.value?.columns,
      activities: print_dataset,
      dashboard_index: props.data.i,
      dataset: print_dataset,

    },
  });
}

function set_custom_fields() {
  dms_settings_store.set_custom_fields({
    query: {
      allowed_in: 'documents',
    },
  });
}
async function getData() {
  state.is_table_loading = true;
  await document_global_actions.getDocuments({
    query: {
      asset: dashboard_store.widget_asset,
      files: true,
      children: true,
      folders: props.data.data.folders?.map(e => e.uid),
      scope: props.data.data.scope,
      ...props.query,

    },
    filters: {
      filters: props.data?.data?.filters?.filters,

    },
    skip_root: true,
  });
  state.is_table_loading = false;
}

if (props.id === 'preview')
  watch(() => props.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getData();
      }, 250);
  }, { immediate: true }, { deep: true });

if (props.id !== 'preview')
  watch(() => props.data.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        set_custom_fields();
        getData();
      }, 250);
  }, { immediate: true }, { deep: true });
</script>

<template>
  <div>
    <div class="w-full h-full">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
      <div v-if="state.is_table_loading">
        <hawk-loader />
      </div>
      <div v-else class="container flex 2xl:max-w-[100vw]">
        <div id="table_wrapper" class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
          <DocumentTable
            ref="document_table"
            :documents="document_store.documents"
            :refresh_documents="getData"
            :is_table_loading="state.is_table_loading"
            :is_widget="true"
            :options="{
              dropdown_items_ids: ['archive', 'delete'],
              disable_context_menu: true,
              hide_selection_checkbox: true,
              disable_custom_view_configuration: true,
              disable_header_context_menu_config: true,
              enable_pagination: true,
            }"
            @tableInstanceCreated="updatePrintMap()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
