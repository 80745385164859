<script setup>
import { groupBy, isNil, mean, round, sum } from 'lodash-es';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import { bDuration, safeDivision } from '~/terra/utils/terra.utils.js';
import TimeSeries from '~/terra/components/charts/terra-charts-timeseries.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable';

const props = defineProps({
  selected_project_for_range_metrics: {
    type: String,
  },
  selected_workflow_data: {
    type: Object,
  },
  selected_range_metrics_field: {
    type: String,
  },
  field_project_metrics: {
    type: Object,
  },
  range_project_field_hash_metrics: {
    type: Object,
  },
  status_color_map: {
    type: Object,
  },
  last_range_date: {
    type: Number,

  },
  range_data_hash_by_field: {
    type: Object,

  },
  selected_workflow: {
    type: String,
  },
  data: {
    type: Object,
  },
  terra_store_name: {
    type: String,
    default: 'terra',
  },
});
defineEmits(['selectedRangeProject']);
dayjs.extend(minMax);
dayjs.extend(timezone);
const { $t, auth_store } = useCommonImports();
// Temporary until we come up with a better solution than $date
if (auth_store?.logged_in_user_details?.timezone)
  dayjs.tz.setDefault(auth_store?.logged_in_user_details?.timezone);

const plot_by_menu = [
  {
    label: $t('Percentage'),
    value: 'Percentage',
  },
  {
    label: $t('Work complete'),
    value: 'Work complete',
  },

];
const range_summary_columns = computed(() => [
  {
    header: 'Fields',
    accessorKey: 'field',
    id: 'fields',
    cell: info => info.getValue(),
  },
  {
    header: 'Total',
    accessorKey: 'total',
    id: 'total',
    cell: info => info.getValue(),
  },
  {
    header: `Past ${props.last_range_date} days`,
    accessorKey: 'past_30_days',
    id: 'past_30_days',
    cell: info => info.getValue(),
  },

]);

const state = reactive({
  plot_by: 'Percentage',
  form: {},
});

const table_subheader_metrics = computed(() => {
  if (props.selected_project_for_range_metrics === 'Overall progress') {
    const field_hash = groupBy(props.selected_workflow_data, 'field');
    const meta = {
      planned_start: [],
      planned_finish: [],
      planned_work: [],
      actual_start: [],
      actual_finish: [],

    };
    const keys = ['planned_start', 'planned_finish', 'actual_start', 'actual_finish', 'planned_work'];
    field_hash[props.selected_range_metrics_field].forEach((item) => {
      keys.forEach((key) => {
        if (key === 'planned_work' && !isNil(item[key]) && item[key] !== '')
          meta[key].push(item[key]);
        else if (!isNil(item[key]) && item[key] !== '')
          meta[key].push(dayjs(item[key]));
      });
    });
    return {
      planned_start: meta.planned_start.length ? dayjs.min(meta?.planned_start).format('DD MMM YYYY') : '-',
      planned_finish: meta?.planned_finish.length ? dayjs.max(meta?.planned_finish).format('DD MMM YYYY') : '-',
      actual_finish: meta?.actual_finish.length ? dayjs.max(meta?.actual_finish).format('DD MMM YYYY') : '-',
      actual_start: meta?.actual_start.length ? dayjs.min(meta?.actual_start).format('DD MMM YYYY') : '-',
      planned_work: meta?.planned_work.length ? sum(meta?.planned_work) : '-',

    };
  }
  const field_data = props.field_project_metrics[`${props.selected_workflow}:${props.selected_project_for_range_metrics}:${props.selected_range_metrics_field}`];
  return {
    planned_start: field_data?.planned_start ? dayjs(field_data?.planned_start).format('DD MMM YYYY') : '-',
    planned_finish: field_data?.planned_finish ? dayjs(field_data?.planned_finish).format('DD MMM YYYY') : '-',
    actual_finish: field_data?.actual_finish ? dayjs(field_data?.actual_finish).format('DD MMM YYYY') : '-',
    actual_start: field_data?.actual_start ? dayjs(field_data?.actual_start).format('DD MMM YYYY') : '-',
    planned_work: !isNil(field_data?.planned_work) ? `${field_data?.planned_work} ${field_data.units || ''}` : '-',
  };
});
const range_summary_data_table = computed(() => {
  const data = [];
  const table_fields = [
    { name: 'Progress(%)', key: 'progress_values' },
    { name: 'Units Installed (units)', key: 'units_installed' },
    { name: 'Work Rate (units/day)', key: 'work_rate' },
    { name: 'Efficiency (%)', key: 'efficiency' },
    { name: 'Catch Up Rate', key: 'catch_up_rate' },
    { name: 'Estimated Finish Date', key: 'est_finish_date' },
    { name: 'Status', key: 'status' },
  ];
  let row_indicators = {};
  let progress_meta = {};
  let range_meta = {};
  const field_hash = groupBy(props.selected_workflow_data, 'field');
  // TODO Refactor this
  if (props.selected_project_for_range_metrics === 'Overall progress') {
    const union_of_progress_values = {
      percent_complete: [],
      actual_work: [],
      actual_work_rate: [],
      planned_work_rate: [],
      difference_work_rate: [],
      difference_percent_complete: [],
      scheduled_percent_complete: [],
      required_work_rate: [],
      est_finish: [],
      planned_finish: [],
      difference_work: [],
      scheduled_work: [],
      status: [],
      efficiency: [],
      units: field_hash[props.selected_range_metrics_field]?.[0]?.units,
    };
    const union_of_range_values = {
      cr_percent_complete: [],
      cr_work: [],
      cr_work_rate: [],
      cr_difference_work_rate: [],
      cr_difference_percent_complete: [],
      cr_scheduled_percent_complete: [],
      cr_est_finish: [],
      cr_difference_work: [],
      cr_status: [],
      cr_scheduled_work: [],
      cr_efficiency: [],
    };

    const keys = ['percent_complete', 'actual_work', 'actual_work_rate', 'difference_percent_complete', 'required_work_rate', 'est_finish', 'status', 'difference_work', 'difference_work_rate', 'planned_finish', 'scheduled_percent_complete', 'scheduled_work', 'planned_work_rate', 'efficiency'];
    const range_keys = ['cr_percent_complete', 'cr_work', 'cr_work_rate', 'cr_difference_percent_complete', 'cr_est_finish', 'cr_status', 'cr_difference_work', 'cr_difference_work_rate', 'cr_scheduled_percent_complete', 'cr_scheduled_work', 'cr_efficiency'];
    field_hash[props.selected_range_metrics_field].forEach((item) => {
      if (props.data?.filtered_projects?.length === 0 || props.data.filtered_projects_state[item.project])
        keys.forEach((key) => {
          if (((key === 'est_finish' || key === 'required_work_rate') && item.status !== 'Completed') || !isNil(item[key]))
            union_of_progress_values[key].push(item[key]);
        });
    });

    props.range_data_hash_by_field?.[props.selected_range_metrics_field]?.forEach((item) => {
      if (props.data?.filtered_projects?.length === 0 || props.data.filtered_projects_state[item.project])
        range_keys.forEach((key) => {
          if (
            (key === 'cr_est_finish' && props.field_project_metrics[`${item.workflow}:${item.project}:${item.field}`]?.status !== 'Completed')
            || !isNil(item[key])
          )
            union_of_range_values[key].push(item[key]);
        });
    });
    progress_meta = {
      progress_values: union_of_progress_values.percent_complete.length ? `${round(mean(union_of_progress_values.percent_complete) * 100)}%` : '-',
      units_installed: union_of_progress_values.actual_work.length ? round(sum(union_of_progress_values.actual_work)) : '-',
      work_rate: union_of_progress_values.actual_work_rate.length ? round(sum(union_of_progress_values.actual_work_rate)) : '-',
      catch_up_rate: '-',
      est_finish_date: union_of_progress_values.est_finish.length && union_of_progress_values.est_finish.some(item => !isNil(item)) ? dayjs.max(union_of_progress_values.est_finish.map(item => dayjs(item || undefined)))?.format('DD MMM YYYY') : '-',
      efficiency: union_of_progress_values.efficiency.length ? round(mean(union_of_progress_values.efficiency) * 100) : '-',
    };

    if (dayjs().isBefore(dayjs.max(union_of_progress_values.planned_finish)) && union_of_progress_values.required_work_rate.length && union_of_progress_values.required_work_rate.some(item => !isNil(item)))
      progress_meta.catch_up_rate = `${round(sum(union_of_progress_values.required_work_rate))} ${union_of_progress_values.units || 'units'}/day`;

    range_meta = {
      progress_values: union_of_range_values.cr_percent_complete.length ? `${round(safeDivision(sum(union_of_range_values.cr_percent_complete), props.data.unique_projects.length) * 100)}%` : '-',
      units_installed: union_of_range_values.cr_work.length ? round(sum(union_of_range_values.cr_work)) : '-',
      work_rate: union_of_range_values.cr_work_rate.length ? round(sum(union_of_range_values.cr_work_rate)) : '-',
      catch_up_rate: '-',
      est_finish_date: union_of_range_values.cr_est_finish.length && union_of_range_values.cr_est_finish.some(item => !isNil(item)) ? dayjs.max(union_of_range_values.cr_est_finish.map(item => dayjs(item)))?.format('DD MMM YYYY') : '-',
      efficiency: union_of_range_values.cr_efficiency.length ? round(mean(union_of_range_values.cr_efficiency) * 100) : '-',
    };
    if (union_of_progress_values.status.every(item => item === 'Not started'))
      progress_meta.status = 'Not started';
    else if (union_of_progress_values.status.every(item => item === 'Completed'))
      progress_meta.status = 'Completed';
    else if (union_of_progress_values.status.every(item => item === 'Behind'))
      progress_meta.status = 'Behind';
    else if (union_of_progress_values.status.every(item => item === 'Ahead'))
      progress_meta.status = 'Ahead';
    else progress_meta.status = 'Behind';

    if (union_of_range_values.cr_status.every(item => item === 'Ahead'))
      range_meta.status = 'Ahead';
    else if (union_of_range_values.cr_status.every(item => item === 'On pace'))
      range_meta.status = 'On pace';
    else
      range_meta.status = 'Behind';

    row_indicators = {
      progress: union_of_progress_values.difference_percent_complete.length ? round(mean(union_of_progress_values.difference_percent_complete) * 100) : null,
      units_installed: union_of_progress_values.difference_work.length ? round(sum(union_of_progress_values.difference_work)) : '',
      work_rate: union_of_progress_values.difference_work_rate.length ? round(sum(union_of_progress_values.difference_work_rate)) : '',
      est_finish: null,
      previous_progress: union_of_range_values.cr_difference_percent_complete.length ? round(safeDivision(sum(union_of_range_values.cr_difference_percent_complete), props.data.unique_projects.length) * 100) : '',
      previous_units_installed: union_of_range_values.cr_difference_work.length ? round(sum(union_of_range_values.cr_difference_work)) : '',
      previous_work_rate: union_of_range_values.cr_difference_work_rate.length ? round(sum(union_of_range_values.cr_difference_work_rate)) : '',
      previous_est_finish: null,
      units: field_hash[props.selected_range_metrics_field]?.[0]?.units,
    };
    row_indicators.est_finish = bDuration(dayjs.max(union_of_progress_values.planned_finish.map(item => dayjs(item))), dayjs.max(union_of_progress_values.est_finish.map(item => dayjs(item))));
    row_indicators.previous_est_finish = bDuration(dayjs.max(union_of_progress_values.planned_finish.map(item => dayjs(item))), dayjs.max(union_of_range_values.cr_est_finish.map(item => dayjs(item))));
    const row_indicator_current_and_prev_keys = ['', 'previous_'];
    row_indicator_current_and_prev_keys.forEach((key) => {
      const progress_key = `${key}progress`;
      const units_installed_key = `${key}units_installed`;
      const work_rate_key = `${key}work_rate`;
      if (row_indicators[progress_key])
        row_indicators[[`${key}progress_tooltip`]] = generateProgressTooltip(row_indicators[progress_key], key, union_of_progress_values, union_of_range_values);
      if (row_indicators[units_installed_key])
        row_indicators[[`${key}units_tooltip`]] = generateUnitsTooltip(row_indicators[units_installed_key], row_indicators.units, key, union_of_progress_values, union_of_range_values);
      if (row_indicators[work_rate_key])
        row_indicators[[`${key}work_rate_tooltip`]] = generateWorkRateTooltip(row_indicators[work_rate_key], row_indicators.units, union_of_progress_values);
      if (row_indicators[`${key}est_finish`])
        row_indicators[[`${key}est_finish_tooltip`]] = generateEstFinishTooltip(row_indicators.est_finish, key, progress_meta, range_meta, row_indicators.units);
    });
  }
  else {
    const field_data = props.field_project_metrics[`${props.selected_workflow}:${props.selected_project_for_range_metrics}:${props.selected_range_metrics_field}`];
    const range_data = props.range_project_field_hash_metrics[`${props.selected_project_for_range_metrics}:${props.selected_range_metrics_field}`];
    logger.log('Selected field and project for range metrics', field_data);
    logger.log('Select field and project range data', range_data);
    progress_meta = {
      progress_values: !isNil(field_data?.percent_complete) ? `${round(field_data.percent_complete * 100)}%` : '-',
      units_installed: !isNil(field_data?.actual_work) ? round(field_data?.actual_work) : '-',
      work_rate: !isNil(field_data?.actual_work_rate) ? round(field_data?.actual_work_rate) : '-',
      catch_up_rate: '-',
      est_finish_date: field_data?.est_finish ? field_data?.est_finish : '-',
      status: field_data?.status,
      efficiency: field_data.efficiency ? round(field_data.efficiency * 100) : '-',
    };
    if (dayjs().isBefore(dayjs(field_data?.planned_finish)) && field_data?.required_work_rate)
      progress_meta.catch_up_rate = `${round(field_data.required_work_rate)} ${field_data.units}/day`;
    range_meta = {
      progress_values: range_data?.cr_percent_complete ? `${round(range_data?.cr_percent_complete * 100)}%` : '-',
      units_installed: range_data?.cr_work ?? '-',
      work_rate: range_data?.cr_work_rate ? round(range_data?.cr_work_rate) : '-',
      catch_up_rate: '-',
      est_finish_date: range_data?.cr_est_finish ? range_data?.cr_est_finish : '-',
      status: range_data?.cr_status,
      efficiency: range_data?.cr_efficiency ? round(range_data.cr_efficiency * 100) : '-',
    };
    row_indicators = {
      progress: !isNil(field_data?.difference_percent_complete) ? round(field_data?.difference_percent_complete * 100) : '',
      units_installed: !isNil(field_data?.difference_work) ? round(field_data?.difference_work) : '',
      work_rate: !isNil(field_data?.difference_work_rate) ? round(field_data?.difference_work_rate) : '',
      est_finish: null,
      previous_progress: !isNil(range_data?.cr_difference_percent_complete) ? round(range_data?.cr_difference_percent_complete * 100) : '',
      previous_units_installed: !isNil(range_data?.cr_difference_work) ? round(range_data?.cr_difference_work) : '',
      previous_work_rate: !isNil(range_data?.cr_difference_work_rate) ? round(range_data?.cr_difference_work_rate) : '',
      previous_est_finish: null,
      units: field_data?.units,
    };
    row_indicators.est_finish = bDuration(field_data?.planned_finish, field_data?.est_finish);
    row_indicators.previous_est_finish = bDuration(field_data?.planned_finish, range_data?.cr_est_finish);
    const row_indicator_current_and_prev_keys = ['', 'previous_'];
    row_indicator_current_and_prev_keys.forEach((key) => {
      const progress_key = `${key}progress`;
      const units_installed_key = `${key}units_installed`;
      const work_rate_key = `${key}work_rate`;
      if (row_indicators[progress_key])
        row_indicators[[`${key}progress_tooltip`]] = generateProgressTooltip(row_indicators[progress_key], key, field_data, range_data, 'custom');
      if (row_indicators[units_installed_key])
        row_indicators[[`${key}units_tooltip`]] = generateUnitsTooltip(row_indicators[units_installed_key], row_indicators.units, key, field_data, range_data, 'custom');
      if (row_indicators[work_rate_key])
        row_indicators[[`${key}work_rate_tooltip`]] = generateWorkRateTooltip(row_indicators[work_rate_key], row_indicators.units, field_data, 'custom');
      if (row_indicators[`${key}est_finish`])
        row_indicators[[`${key}est_finish_tooltip`]] = generateEstFinishTooltip(row_indicators.est_finish, key, progress_meta, range_meta, row_indicators.units);
    });
  }
  table_fields.forEach((field) => {
    data.push({
      field: field.name,
      total: progress_meta[field.key],
      past_30_days: range_meta[field.key],
      row_indicators,
    });
  });

  return data;
});
function generateProgressTooltip(progress_value, key, field_data, range_data, type = 'overall') {
  let planned_percent_complete;
  if (type === 'overall')
    planned_percent_complete = round(key === '' ? mean(field_data.scheduled_percent_complete) * 100 : safeDivision(sum(range_data.cr_scheduled_percent_complete), props.data.unique_projects.length) * 100);
  else
    planned_percent_complete = key === '' ? field_data.scheduled_percent_complete * 100 : range_data.cr_scheduled_percent_complete * 100;
  const difference = Math.abs(progress_value);
  const status = progress_value > 0 ? 'more' : 'less';

  return `Completed ${difference}% ${status} than planned i.e ${planned_percent_complete}%`;
}
function generateUnitsTooltip(units_value, units_label, key, field_data, range_data, type = 'overall') {
  let planned_work = key === '' ? field_data.scheduled_work : range_data.cr_scheduled_work;
  if (type === 'overall')
    planned_work = round(sum(planned_work));
  planned_work = planned_work || 0;
  const difference = Math.abs(units_value);
  const status = units_value > 0 ? 'more' : 'less';

  return `${difference} ${units_label} ${status} than planned i.e ${planned_work} ${units_label}`;
}
function generateWorkRateTooltip(work_rate_value, units_label, field_data, type = 'overall') {
  let planned_rate_value = field_data.planned_work_rate;
  if (type === 'overall')
    planned_rate_value = sum(planned_rate_value);
  const difference = Math.abs(work_rate_value);
  const status = work_rate_value > 0 ? 'more' : 'less';

  return `${difference} ${units_label}/day ${status} than planned i.e ${planned_rate_value} ${units_label}/day`;
}
function generateEstFinishTooltip(est_finish_value, key, progress_meta, range_meta, units_label) {
  const status = est_finish_value < 0 ? 'before' : 'after';
  const workRate = key === '' ? progress_meta.work_rate : range_meta.work_rate;

  return `Estimated to finish ${Math.abs(est_finish_value)} days ${status} the planned finish date if the work rate is ${workRate} ${units_label}/day`;
}
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="text-base font-semibold">
      {{ selected_range_metrics_field }}
    </div>
  </div>
  <hr class="my-4">
  <div class="lg:flex gap-4 sm:grid sm:grid-cols-1">
    <div class="basis-60 max-h-[540px] scrollbar flex-shrink-0">
      <div
        v-for="item in data.unique_projects"
        :key="item.id" class="p-3 border border-white hover:border-gray-500 rounded-lg text-sm flex justify-between gap-2 cursor-pointer" :class="{ '!border-blue-500 font-semibold': selected_project_for_range_metrics === item }"
        @click="$emit('selectedRangeProject', item)"
      >
        <HawkText :content="item" :length="20" />
      </div>
    </div>
    <div class="w-full">
      <div class="bg-white p-5 border rounded-2xl border-gray-200">
        <div class="flex items-center justify-between mb-4">
          <div>
            <div class="text-base font-semibold mb-1">
              {{ selected_project_for_range_metrics }}
            </div>
            <div class="flex text-xs mt-3 flex-wrap">
              <div class="flex mr-2">
                <HawkText :content="$t('Scope(units)')" />:
                <HawkText class="font-semibold ml-1" :content="table_subheader_metrics?.planned_work || 'NA'" />
              </div>
              <div class="flex mr-2">
                <HawkText :content="$t('Planned Start')" />:
                <HawkText class="font-semibold ml-1" :content="table_subheader_metrics?.planned_start || 'NA'" />
              </div>
              <div class="flex mr-2">
                <HawkText :content="$t('Actual Start')" />:
                <HawkText class="font-semibold ml-1" :content="table_subheader_metrics?.actual_start || 'NA'" />
              </div>
              <div class="flex mr-2">
                <HawkText :content="$t('Planned Finish')" />:
                <HawkText class="font-semibold ml-1" :content="table_subheader_metrics?.planned_finish || 'NA'" />
              </div>
              <div class="flex">
                <HawkText :content="$t('Actual Finish')" />:
                <HawkText class="font-semibold ml-1" :content="table_subheader_metrics?.actual_finish || 'NA'" />
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4">
        <div class="bg-gray-100 h-[256px] rounded-2xl grid">
          <HawkTable
            class="scrollbar md:overflow-hidden w-full"
            :data="range_summary_data_table"
            :columns="range_summary_columns"
            additional_table_classes="h-[256px]"
            :striped="true"
            is_gapless
            :show_menu_header="false"
            :disable_resize="true"
            cell_height="30px"
          >
            <!-- Refactor this to use a cleaner way -->
            <template #total="{ data }">
              <div v-if="data.row.index === 0">
                {{ data.getValue() }}  <HawkBadge v-if="data.row.original.row_indicators.progress" :is_rounded="false" :color="data.row.original.row_indicators.progress > 0 ? 'green' : 'red'">
                  <span v-if="data.row.original.row_indicators.progress > 0"> <IconHawkArrowUp class="w-3 h-3" /></span>
                  <span v-else>
                    <IconHawkArrowDown class=" w-3 h-3" />
                  </span>
                  <span v-tippy="data.row.original.row_indicators.progress_tooltip">{{ data.row.original.row_indicators.progress }} %</span>
                </HawkBadge>
              </div>
              <div v-if="data.row.index === 1">
                {{ data.getValue() }}  <HawkBadge v-if="data.row.original.row_indicators.units_installed" :is_rounded="false" :color="data.row.original.row_indicators.units_installed > 0 ? 'green' : 'red'">
                  <span v-if="data.row.original.row_indicators.units_installed > 0">+</span>

                  <span v-tippy="data.row.original.row_indicators.units_tooltip">{{ data.row.original.row_indicators.units_installed }} {{ data.row.original.row_indicators?.units }} </span>
                </HawkBadge>
              </div>
              <div v-if="data.row.index === 2">
                {{ data.getValue() }}  <HawkBadge v-if="data.row.original.row_indicators.work_rate" :is_rounded="false" :color="data.row.original.row_indicators.work_rate > 0 ? 'green' : 'red'">
                  <span v-if="data.row.original.row_indicators.work_rate > 0">+</span>

                  <span v-tippy="data.row.original.row_indicators.work_rate_tooltip">{{ data.row.original.row_indicators.work_rate }} {{ data.row.original.row_indicators?.units }}/day</span>
                </HawkBadge>
              </div>
              <div v-if="data.row.index === 3">
                <div v-if="data.getValue()" :class="data.getValue() >= 100 ? 'text-green-700' : 'text-red-700'">
                  {{ data.getValue() }}%
                </div>
                <div v-else>
                  {{ data.getValue() }}
                </div>
              </div>
              <div v-if="data.row.index === 5">
                {{ data.getValue() }}  <HawkBadge v-if="data.row.original.row_indicators.est_finish" :is_rounded="false" :color="data.row.original.row_indicators.est_finish < 0 ? 'green' : 'red'">
                  <span v-if="data.row.original.row_indicators.est_finish > 0">+</span>

                  <span v-tippy="data.row.original.row_indicators.est_finish_tooltip">{{ data.row.original.row_indicators.est_finish }} days</span>
                </HawkBadge>
              </div>
              <div v-if="data.row.index === 6">
                <HawkBadge v-if="data.getValue() && data.getValue() !== '-'" :is_rounded="false" :color="status_color_map[data.getValue()]">
                  {{ data.getValue() }}
                </HawkBadge>
                <div v-else>
                  {{ data.getValue() }}
                </div>
              </div>
            </template>
            <template #past_30_days="{ data }">
              <div v-if="data.row.index === 0">
                {{ data.getValue() }} <HawkBadge v-if="data.row.original.row_indicators.previous_progress" :is_rounded="false" :color="data.row.original.row_indicators.previous_progress > 0 ? 'green' : 'red'">
                  <span v-if="data.row.original.row_indicators.previous_progress > 0">   <IconHawkArrowUp class=" w-3 h-3" /></span>
                  <span v-else>
                    <IconHawkArrowDown class=" w-3 h-3" />
                  </span>

                  <span v-tippy="data.row.original.row_indicators.previous_progress_tooltip">{{ round(data.row.original.row_indicators.previous_progress) }} %</span>
                </HawkBadge>
              </div>
              <div v-if="data.row.index === 1">
                {{ data.getValue() }}  <HawkBadge v-if="data.row.original.row_indicators.previous_units_installed" :is_rounded="false" :color="data.row.original.row_indicators.previous_units_installed > 0 ? 'green' : 'red'">
                  <span v-if="data.row.original.row_indicators.previous_units_installed > 0">+</span>

                  <span v-tippy="data.row.original.row_indicators.previous_units_tooltip">{{ round(data.row.original.row_indicators.previous_units_installed) }} {{ data.row.original.row_indicators?.units }} </span>
                </HawkBadge>
              </div>
              <div v-if="data.row.index === 2">
                {{ data.getValue() }}  <HawkBadge v-if="data.row.original.row_indicators.previous_work_rate" :is_rounded="false" :color="data.row.original.row_indicators.previous_work_rate > 0 ? 'green' : 'red'">
                  <span v-if="data.row.original.row_indicators.previous_work_rate > 0">    +</span>

                  <span v-tippy="data.row.original.row_indicators.previous_work_rate_tooltip">{{ round(data.row.original.row_indicators.previous_work_rate) }} {{ data.row.original.row_indicators?.units }}/day</span>
                </HawkBadge>
              </div>
              <div v-if="data.row.index === 3">
                <div v-if="data.getValue()" :class="data.getValue() >= 100 ? 'text-green-700' : 'text-red-700'">
                  {{ data.getValue() }}%
                </div>
                <div v-else>
                  {{ data.getValue() }}
                </div>
              </div>
              <div v-if="data.row.index === 5">
                {{ data.getValue() }}  <HawkBadge v-if="data.row.original.row_indicators.previous_est_finish" :is_rounded="false" :color="data.row.original.row_indicators.previous_est_finish < 0 ? 'green' : 'red'">
                  <span v-if="data.row.original.row_indicators.previous_est_finish > 0">+</span>

                  <span v-tippy="data.row.original.row_indicators.previous_est_finish_tooltip">{{ data.row.original.row_indicators.previous_est_finish }} days</span>
                </HawkBadge>
              </div>
              <div v-if="data.row.index === 6">
                <HawkBadge v-if="data.getValue() && data.getValue() !== '-'" :is_rounded="false" :color="status_color_map[data.getValue()]">
                  {{ data.getValue() }}
                </HawkBadge>
                <div v-else>
                  {{ data.getValue() }}
                </div>
              </div>
            </template>
          </HawkTable>
        </div>
      </div>
      <div class="bg-white w-full p-5 border grid mt-4 rounded-2xl border-gray-200">
        <Vueform v-show="selected_project_for_range_metrics !== 'Overall progress'" v-model="state.form" class="flex ml-auto mb-1">
          <SelectElement
            name="map_color_by"
            size="sm"
            :default="state.plot_by"
            :native="false"
            :can-deselect="false"
            :can-clear="false"
            :items="plot_by_menu"
            @select="state.plot_by = $event"
          />
        </Vueform>

        <TimeSeries
          v-if="selected_project_for_range_metrics !== 'Overall progress'"
          class="h-[500px]"
          :terra_store_name="terra_store_name"
          :options="{
            selected_workflow,
            selected_workflow_data,
            selected_project: selected_project_for_range_metrics,
            selected_field: selected_range_metrics_field,
            field_project_metrics,
            plot_by: state.plot_by,
            selected_field_project: field_project_metrics[`${selected_workflow}:${selected_project_for_range_metrics}:${selected_range_metrics_field}`],
            unique_projects: data.unique_projects,
          }"
        />
        <div v-show="selected_project_for_range_metrics === 'Overall progress'" class="h-[500px] flex justify-center items-center text-gray-600">
          Select a project
        </div>
      </div>
    </div>
  </div>
</template>
