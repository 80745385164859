import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { cloneDeep, omit, pick } from 'lodash-es';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import HawkInfoIcon from '~/common/components/atoms/hawk-info-icon.vue';

import DmsDocumentsRequestForm from '~/dms/components/documents/forms/dms-documents-request-form.vue';
import DelegateForm from '~/dms/components/transmittals/forms/files-transmittals-delegate-form.vue';
import reassignForm from '~/dms/components/transmittals/forms/files-transmittals-reassign-form.vue';

import AdditionalReviewersForm from '~/dms/components/transmittals/forms/files-transmittals-additional-reviewers-form.vue';
import SubWorkflowForm from '~/dms/components/transmittals/forms/files-transmittals-sub-workflow.vue';
import { useAuthStore } from '~/auth/stores/auth.store';

export function useTransmittalActions(state) {
  const $t = inject('$t');
  const router = useRouter();
  const transmittal_store = useTransmittalsStore();
  const transmittal = computed(() => transmittal_store.transmittal);

  const { logged_in_user_details } = useAuthStore();
  const logged_in_user_id = logged_in_user_details?.user_id;
  const is_owner = transmittal.value.owner === logged_in_user_id;

  const document_request_modal = useModal({
    component: DmsDocumentsRequestForm,
    attrs: {
      onClosed() {
        const attrs = omit(document_request_modal.options.attrs, ['is_cancel', 'content', 'header', 'sub_header', 'header_icon', 'button_text', 'is_mark_as_submitted']); // reset attrs
        document_request_modal.options.attrs = attrs;
      },
    },
  });

  const delegate_form = useModal({
    component: DelegateForm,
  });

  const reassign_form = useModal({
    component: reassignForm,
  });

  const additional_reviewers_form = useModal({
    component: AdditionalReviewersForm,
  });

  const new_transmittal_popup = useModal({
    component: SubWorkflowForm,
  });

  function createPayload(document, data, pick_by = ['uid']) {
    const documents = state.selected_documents?.length ? state.selected_documents : [document];
    const payload = documents.map(doc => ({ ...pick(doc, pick_by), ...data }));
    return {
      transmittal_uid: transmittal.value.uid,
      transmittals: {
        documents: payload,
      },
    };
  }

  function clearBulkSelection() {
    state.bulk_options = [];
    state.selected_items = [];
    state.table_instance.clearSelect();
  }

  function openNewTransmittalModal(document) {
    new_transmittal_popup.patchOptions({
      attrs: {
        title: $t('Starting a sub-workflow'),
        sub_title: $t('This action cannot be undone'),
        confirm_text: $t('This operation will put the selected document(s) on hold in the current transmittal until they are processed in the new transmittal. Are you sure you want to continue?'),
        onClose() {
          new_transmittal_popup.close();
        },
        onConfirm() {
          const cloned_documents = cloneDeep(state.selected_documents?.length ? state.selected_documents : [document]).map(doc => ({
            uid: doc.uid,
            name: doc.name,
            versions: doc.versions,
            thumbnail_large: doc.thumbnail_large,
            thumbnail_small: doc.thumbnail_small,
            thumbnail_xsmall: doc.thumbnail_xsmall,
            due_date: doc.due_date,
            service_object: doc.service_object,
          }));

          cloned_documents.forEach(doc => doc.blocking = transmittal.value.uid ? { uid: transmittal.value.uid, number: (transmittal.value.number || 'TR-Test') } : undefined);
          transmittal_store.initiate_sub_workflow(cloned_documents);
        },
      },
    });
    new_transmittal_popup.open();
  }

  function openCancelForm(document, is_cancel = true) {
    document_request_modal.patchOptions({
      attrs: {
        is_cancel,
        header_icon: is_cancel ? IconHawkAlertTriangleRedRipple : HawkInfoIcon,
        header: is_cancel ? $t('Cancel Request') : $t('Uncancel Request'),
        button_text: is_cancel ? $t('Cancel documents') : $t('Uncancel documents'),
        content: $t('Are you sure you want to close this request? If yes, please add your reason for request cancellation.'),
        confirm: async (data) => {
          const payload = createPayload(document, { message: data.comment });
          await transmittal_store.document_operations(payload, is_cancel ? 'cancel' : 'uncancel');
          document_request_modal.close();
        },
        onClose() {
          document_request_modal.close();
        },
      },
    });
    document_request_modal.open();
  }
  function openDelegateForm(document) {
    delegate_form.patchOptions({
      attrs: {
        onClose() {
          delegate_form.close();
        },
        clearSelection() {
          clearBulkSelection();
        },
        documents: state.selected_documents?.length ? state.selected_documents : [document],
      },
    });
    delegate_form.open();
  }
  function openReassignForm(document) {
    reassign_form.patchOptions({
      attrs: {
        onClose() {
          reassign_form.close();
        },
        clearSelection() {
          clearBulkSelection();
        },
        documents: state.selected_documents?.length ? state.selected_documents : [document],
      },
    });
    reassign_form.open();
  }
  function openAdditionalReviewersForm(document) {
    additional_reviewers_form.patchOptions({
      attrs: {
        documents: state.selected_documents?.length ? state.selected_documents : [document],
        onClose() {
          additional_reviewers_form.close();
        },
        clearSelection() {
          clearBulkSelection();
        },
      },
    });
    additional_reviewers_form.open();
  }
  function openMarkAsSubmittedModal(document) {
    document_request_modal.patchOptions({
      attrs: {
        is_mark_as_submitted: true,
        header: $t('Mark as submitted'),
        sub_header: $t('You can add the document yourself and mark it as submitted.'),
        content: $t('Note : You are marking the document as submitted. Please upload a document and state the reason for marking it as submitted.'),
        button_text: $t('Submit'),
        onClose() {
          document_request_modal.close();
        },
        confirm: async (data) => {
          const payload = createPayload(document, { comments: data.comment, service_object: data.file?.[0]?.service }, ['uid', 'service_object']);
          await transmittal_store.document_operations(payload, 'mark_as_submitted');
          document_request_modal.close();
        },
      },
    });
    document_request_modal.open();
  }
  function openReopenForReviewModal(document) {
    document_request_modal.patchOptions({
      attrs: {
        header: $t('Open/Unlock Request'),
        sub_header: $t('This action cannot be undone.'),
        button_text: $t('Open now'),
        content: 'Are you sure you want to open this request again? If yes, please add your reason for opening the request.',
        onClose() {
          document_request_modal.close();
        },
        confirm: async (data) => {
          const payload = createPayload(document, { message: data.comment });
          await transmittal_store.document_operations(payload, 'reopen_for_review');
          document_request_modal.close();
        },
      },
    });
    document_request_modal.open();
  }

  function dropdown_items(document = null, bulk_keys = []) {
    const {
      can_mark_as_submitted,
      can_delegate_review,
      can_delegate_submission,
      can_add_reviewers,
      can_review,
      can_cancel,
      can_uncancel,
      can_reopen_for_review,
      can_start_sub_workflow,
      can_reassign_review,
    } = document?.actions || {};

    const menu_options = [
      {
        label: $t('Add markups'),
        uid: 'can_add_markups',
        permission: can_review,
        on_click: () => {
          router.replace({ name: 'document-markup', params: { document_uid: document?.uid, transmittal_uid: transmittal.value.uid } });
        },
      },
      {
        label: $t('Delegate'),
        uid: 'can_delegate_submission_can_delegate_review',
        permission: can_delegate_submission || can_delegate_review,
        on_click: () => openDelegateForm(document),
      },
      {
        label: $t('Add additional reviewers'),
        uid: 'can_add_reviewers',
        permission: can_add_reviewers,
        on_click: () => openAdditionalReviewersForm(document),
      },
      {
        label: $t('Mark as submitted'),
        uid: 'can_mark_as_submitted',
        permission: can_mark_as_submitted,
        on_click: () => openMarkAsSubmittedModal(document),
      },
      {
        label: $t('Cancel document'),
        uid: 'can_cancel',
        permission: can_cancel,
        on_click: () => openCancelForm(document),
      },
      {
        label: $t('Uncancel document'),
        uid: 'can_uncancel',
        permission: can_uncancel,
        on_click: () => openCancelForm(document, false),
      },
      {
        label: $t('Initiate new transmittal'),
        uid: 'can_start_sub_workflow',
        permission: can_start_sub_workflow,
        on_click: () => {
          openNewTransmittalModal(document);
        },
      },
      {
        label: is_owner ? $t('Reassign') : $t('Replace'),
        uid: 'can_reassign_review',
        permission: can_reassign_review,
        on_click: () => {
          openReassignForm(document);
        },
      },
      {
        label: $t('Reopen for review'),
        uid: 'can_reopen_for_review',
        permission: can_reopen_for_review,
        on_click: () => openReopenForReviewModal(document),
      },
    ];

    if (bulk_keys.length)
      return bulk_keys.map(key => menu_options.find(menu => menu.uid.includes(key)));

    return menu_options.filter(option => option.permission);
  }

  return { dropdown_items, createPayload, clearBulkSelection };
}
