import DOMPurify from 'dompurify';
import sanitize from 'sanitize-s3-objectkey';

export default function VueFormMultiFile() {
  return {
    apply: 'FileElement',
    props: {
      rules: {
        default: 'no_blacklist_extensions',
      },
      use_uppy: {
        type: Boolean,
        required: false,
        default: true,
      },
      // The attributes that can go inside the options are:
      // 1. clickable_text: A highlighted text that opens up the file selector when clicked.
      // 2. text: Follows the clickable_text
      // 3. description
      // 4. inline (Boolean): denotes if the content is inline.
      // 5. button_type: Denotes the type hawk-button if the drop property of the FileElement is false.
      // 6. button_classes: Denotes the classes of the hawk-button if the drop property of the FileElement is false.
      options: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
    mounted() {
      if (this.use_uppy)
        this.on('change', (value) => {
          if (!value && Object.keys(this.form$.uppy.store.state.files).length)
            this.form$.uppy.removeFile(Object.keys(this.form$.uppy.store.state.files)[0]);

          if (value && value instanceof File)
            try {
              const id = this.form$.uppy.addFile({
                data: value,
                name: value?.name,
                type: value?.type,
                meta: {
                  relativePath: value.webkitRelativePath || value?.meta?.webkitRelativePath,
                },
              });
              const file = this.form$.uppy.getFile(id);
              file.name = DOMPurify.sanitize(sanitize(value.name));
              file.meta.name = file.name;
              value.sanitized_name = file.meta.name;
              value.uppy_id = id;
            }
            catch (error) {
              logger.info(`[DEBUG] vueform-file-element.js::46\n${error}`);
            }
        });
    },
    beforeDestroy() {
      if (this.use_uppy)
        this.off('change');
    },
  };
}
