<script setup>
import { ref } from 'vue';
import { cloneDeep } from 'lodash-es';
import FamTabFormRules from '~/forms-as-module/components/fam-tabs-list/fam-tab-form-rules.vue';
import FormFieldTree from '~/forms/molecules/form-field-tree.vue';

const props = defineProps({
  formatting_enabled: {
    type: Boolean,
    default: true,
  },
  filter: {
    type: Object,
    default: null,
  },
  columns: {
    type: Array,
    default: () => [],
  },
  on_save: {
    type: Function,
    default: null,
  },
  texts: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  is_filters_required: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close']);

const get_filters_payload = ref({});
const form = ref({});
const rules_invalid = ref(false);
const background_colors = ref([
  { label: 'Green', value: 'rgba(61, 213, 152, 0.5)' },
  { label: 'Yellow', value: 'rgba(248, 201, 0, 0.5)' },
  { label: 'Blue', value: 'rgba(22, 118, 238, 0.5)' },
  { label: 'Red', value: 'rgba(250, 89, 89, 0.5)' },
  { label: 'Grey', value: 'rgba(156, 159, 177, 0.5)' },
  { label: 'Black', value: 'rgba(0, 0, 0, 0.5)' },
  { label: 'White', value: 'rgba(255, 255, 255, 0.5)' },
]);
const text_colors = ref([
  { label: 'Green', value: '#3DD598' },
  { label: 'Yellow', value: '#F8C900' },
  { label: 'Blue', value: '#1676EE' },
  { label: 'Red', value: '#FA5959' },
  { label: 'Grey', value: '#9C9FB1' },
  { label: 'Black', value: '#000000' },
  { label: 'White', value: '#FFFFFF' },
]);

const get_columns = computed(() => {
  return props.columns.filter(val => val.uid !== 'table_checkbox');
});

function init() {
  if (props.filter)
    form.value = cloneDeep(props.filter);
  else
    form.value = {
      name: '',
      filter: {
        condition: {
          logic: 'AND',
          rules: [],
          format: {
            apply_to: 'Entire row',
            font_style: [],
            text_color: '#000000',
            background_color: 'rgba(255, 255, 255, 0.5)',
          },
        },
      },
    };
}

async function save() {
  form.value.filter.condition.rules = get_filters_payload.value.filters();
  await props.on_save({
    uid: crypto.randomUUID(),
    ...form.value,
  });
}

function setFormFieldTree() {
  if (props.formatting_enabled)
    form.value.filter.condition.format.apply_to = props.filter?.filter?.condition.format?.apply_to || 'Entire row';
}
init();
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[900px]">
    <Vueform
      v-model="form"
      sync size="sm"
      :display-errors="false"
      :endpoint="save"
      :columns="{
        lg: { container: 12, label: 3, wrapper: 12 },
      }"
      @mounted="setFormFieldTree"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ texts?.heading?.length ? texts?.heading : $t('Custom Filter') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-[70vh] scrollbar">
          <TextElement name="name" :label="$t('Name')" class="mb-4" rules="required" />
          <ObjectElement
            name="filter"
          >
            <div class="col-span-12 ">
              <ObjectElement
                name="condition"
              >
                <div class="col-span-12 ">
                  <RadiogroupElement
                    name="logic"
                    :label="$t('Filter records where')"
                    :items="[
                      { value: 'AND', label: $t('All conditions are met') },
                      { value: 'OR', label: $t('Any condition is met') },
                    ]"
                    default="All"
                    class="mb-4"
                  />
                  <FamTabFormRules
                    name="rules"
                    :options="{
                      label: $t('Conditions'),
                    }"
                    :is_required="is_filters_required"
                    :rules="form.filter.condition.rules"
                    :fields="get_columns"
                    :is_default_field_added="formatting_enabled"
                    @invalid="($event) => (rules_invalid = $event)"
                    @updateRules="form.filter.condition.rules = $event"
                    @getFilterPayload="get_filters_payload = $event"
                  />
                  <template v-if="formatting_enabled">
                    <hr class="my-4">
                    <div class="text-[16px] font-semibold mb-4">
                      {{ $t('Formatting') }}
                    </div>
                    <ObjectElement
                      name="format"
                    >
                      <div class="col-span-12 ">
                        <CheckboxgroupElement
                          name="font_style"
                          class="mb-4"
                          :label="$t('Text style')"
                          :items="{
                            bold: 'B',
                            italic: 'I',
                            underline: 'U',
                          }"
                          :columns="{
                            lg: {
                              container: 12,
                              label: 3,
                              wrapper: 4,
                            },
                          }"
                          view="tabs"
                        />
                        <SelectElement
                          name="text_color"
                          :label="$t('Text color')"
                          :placeholder="$t('Select color')"
                          :native="false"
                          :items="text_colors"
                          default="#000000"
                          class="mb-4"
                        >
                          <template #single-label="{ value }">
                            <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                              <div class="w-3 h-3 rounded-full mr-2" :style="{ backgroundColor: value.value, border: `1px solid ${value.label === 'White' ? '#000' : '#fff'}` }" />
                              <div class="text-[14px]">
                                {{ value.label }}
                              </div>
                            </div>
                          </template>
                          <template #option="{ option }">
                            <div class="flex items-center">
                              <span class="w-3 h-3 rounded-full mr-2 ml-1" :style="{ backgroundColor: option.value, border: `1px solid ${option.label === 'White' ? '#000' : '#fff'}` }" />
                              <span class="text-[14px]">{{ option.label }}</span>
                            </div>
                          </template>
                        </SelectElement>
                        <SelectElement
                          name="background_color"
                          :label="$t('Background color')"
                          :placeholder="$t('Select color')"
                          :native="false"
                          :items="background_colors"
                          default="rgba(255, 255, 255, 0.5)"
                          class="mb-4"
                        >
                          <template #single-label="{ value }">
                            <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                              <div class="w-3 h-3 rounded-full mr-2" :style="{ backgroundColor: value.value, border: `1px solid ${value.label === 'White' ? '#000' : '#fff'}` }" />
                              <div class="text-[14px]">
                                {{ value.label }}
                              </div>
                            </div>
                          </template>
                          <template #option="{ option }">
                            <div class="flex items-center">
                              <span class="w-3 h-3 rounded-full mr-2 ml-1" :style="{ backgroundColor: option.value, border: `1px solid ${option.label === 'White' ? '#000' : '#fff'}` }" />
                              <span class="text-[14px]">{{ option.label }}</span>
                            </div>
                          </template>
                        </SelectElement>
                        <FormFieldTree
                          class="w-full mb-4"
                          :data="[{ uid: 'Entire row', name: 'Entire row' }, ...get_columns]"
                          :tree_config="{
                            options: {
                              name: 'apply_to',
                              label: $t('Apply to'),
                              placeholder: $t('Select'),
                              object: false,
                              search: true,
                              native: false,
                              canClear: false,
                              hideSelected: true,
                              canDeselect: false,
                              removeClasses: {
                                SelectElement: {
                                  select: {
                                    optionSelectedDisabled: 'form-color-on-primary form-bg-primary',
                                  },
                                },
                              },
                            },
                            select_type: 'LEAF_PRIORITY',
                            select_view: 'SELECT',
                            show_expanded_search: true,
                          }"
                          @onSelect="form.filter.condition.format.apply_to = $event?.[0]?.uid"
                        />
                      </div>
                    </ObjectElement>
                  </template>
                </div>
              </ObjectElement>
            </div>
          </ObjectElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-4 font-bold"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true" :disabled="rules_invalid">
                {{ $t("Apply") }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
