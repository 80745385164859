<script setup>
const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
});

const state = reactive({
  items: [
    {
      icon: IconHawkAlignLeft,
      align: 'left',
    },
    {
      icon: IconHawkAlignCenter,
      align: 'center',
    },
    {
      icon: IconHawkAlignRight,
      align: 'right',
    },
    {
      icon: IconHawkAlignJustify,
      align: 'justify',
    },
  ],
});

function onClick(align) {
  props.editor.chain().focus().setTextAlign(align).run();
}
</script>

<template>
  <div class="flex">
    <div
      v-for="item of state.items"
      :key="item.align"
      class="flex rounded-lg items-center justify-between cursor-pointer p-2 text-gray-600 hover:bg-gray-50"
      :class="{ 'bg-gray-100': editor.isActive({ textAlign: item.align }) }"
      @click="onClick(item.align)"
    >
      <component :is="item.icon" />
    </div>
  </div>
</template>
