<script setup>
import DOMPurify from 'dompurify';
import { onMounted, ref, watch } from 'vue';
import { isEmpty, uniqBy } from 'lodash-es';
import { useRoute } from 'vue-router';
import sheetCommentInput from '../sheet-comment-input.vue';
import commentDisplay from '../comment-display.vue';
import { useChatStore } from '~/common/stores/chat.store';
import { usePlansStore } from '~/plans/store/plans.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  comment: {
    type: Object,
    default: null,
  },
  annotation: {
    type: Object,
    required: true,
  },
  module: {
    type: String,
    default: 'plans',
  },
  entry: {
    type: Object,
    default: null,
  },
  show_assignee_selection: {
    type: Boolean,
    default: true,
  },
  has_custom_mentions: {
    type: Boolean,
    default: false,
  },
  custom_mentions: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['deleteAnnot', 'closeContext']);

const checkbox_default_class = 'CheckboxgroupCheckbox.input--> flex-shrink-0 appearance-none cursor-pointer outline-zero transition-input duration-200 border-solid form-border-width-checkbox focus:form-ring checked:form-bg-icon-check form-shadow-handles CheckboxgroupCheckbox.input_sm--> form-w-checkbox-sm form-h-checkbox-sm form-radius-checkbox-sm form-mr-space-checkbox-sm form-mt-checkbox-sm CheckboxgroupCheckbox.input_default--> form-bg-input form-border-color-input hover:form-bg-input-hover hover:form-border-color-input-hover hover:form-shadow-handles-hover focused:form-bg-input-focus focused:form-border-color-input-focus focused:form-shadow-handles-focus focused-hover:form-shadow-handles-hover checked:form-bg-primary checked:form-border-color-checked checked-hover:form-bg-primary checked-hover:form-border-color-checked checked-focused:form-bg-primary checked:!bg-primary-50 checked:after:!bg-primary-500 checked:!border-primary-500';

const route = useRoute();
const $track_event = inject('$track_event');

const chat_store = useChatStore();
const plans_store = usePlansStore();
const { logged_in_user_details } = useAuthStore();

const current_user_id = logged_in_user_details.user_id;

const replies = reactive({
  is_loading: false,
  data: [],
});

const is_editing_comment = ref(false);
const is_editing_reply = ref(false);
const editing_item = ref(null);
const editor_contents = ref('');
const tagged_users = ref([]);
const is_assignee_selected = ref(true);
const selected_assignee_uid = ref(null);
const current_comment = ref(props.comment ?? {});
const wysiwyg_ctx = ref(null);

const is_send_allowed = computed(() => {
  return editor_contents.value.replaceAll('<br>', '').replaceAll(/<p>\s*<\/p>/gmi, '').trim().length;
});

const is_assign_controls_visible = computed(() => (
  (isEmpty(current_comment.value) || current_comment.value.user?.id === current_user_id)
          && !is_editing_comment.value
          && !is_editing_reply.value
));

const comment_menu_options = [
  {
    name: 'Edit',
    value: 'edit',
    on_click: () => {
      is_editing_comment.value = true;
      editing_item.value = current_comment.value;
    },
  },
  {
    name: 'Delete',
    value: 'delete',
    on_click: async () => {
      await onDelete(current_comment.value.id);
    },
  },
];

function getReplyMenuOptions(reply_data) {
  return [
    {
      name: 'Edit',
      value: 'edit',
      on_click: () => {
        is_editing_reply.value = true;
        editing_item.value = reply_data;
      },
    },
    {
      name: 'Delete',
      value: 'delete',
      on_click: async () => {
        await onDelete(reply_data.id);
      },
    },
  ];
}

onMounted(async () => {
  (!isEmpty(current_comment.value) && current_comment.value.reply_count) && await getReplies(true);
});

function scrollToBottom(timeout = 20) {
  setTimeout(() => {
    const element = document.getElementById('scroll-block');
    if (element)
      element.scrollTo({ top: element.scrollHeight + element.getBoundingClientRect().y, behavior: 'smooth' });
  }, timeout);
}

async function getReplies(need_loader = false) {
  replies.is_loading = need_loader;
  replies.data = await chat_store.chat_channel.getReplies(current_comment.value.id);
  replies.is_loading = false;

  scrollToBottom();
}

function toggleAssigneeSelected(event) {
  is_assignee_selected.value = event.target.checked;
}

async function planModuleComment() {
  let assignee = null;
  let data = {
    service: 'plans',
    text: editor_contents.value,
    sheet_uid: route?.params?.sheet_id,
    drawing_uid: plans_store.active_drawing_uid,
    asset_uid: route?.params?.asset_id,
    annotation_position: {
      PageNumber: props.annotation.getPageNumber(),
      X: props.annotation.getX(),
      Y: props.annotation.getY(),
    },
    parent_id: current_comment.value?.id,
    mentioned_user_list: tagged_users.value,
  };

  if (is_assign_controls_visible.value && is_assignee_selected.value) {
    assignee = tagged_users.value.length === 1 ? tagged_users.value[0].uid : selected_assignee_uid.value;
    data = { ...data, assignee };
  }

  const response = await chat_store.sendMessage(data);
  $track_event('plan_comment_added', {
    type: !isEmpty(current_comment.value) ? 'Reply' : 'Comment',
    mentioned: tagged_users.value.length,
    assigned: !isEmpty(current_comment.value) && is_assignee_selected.value,
  });

  if (isEmpty(current_comment.value)) {
    current_comment.value = response.message;
    props.annotation.setCustomData('uid', response.message.id);
  }
  else if (assignee) {
    const response = await chat_store.updateMessage({
      assignee,
      service: 'plans',
      id: current_comment.value.id,
      text: current_comment.value.text,
      sheet_uid: route?.params?.sheet_id,
      drawing_uid: plans_store.active_drawing_uid,
      asset_uid: route?.params?.asset_id,
      annotation_position: current_comment.value.annotation_position,
      parent_id: current_comment.value?.id,
      mentioned_user_list: tagged_users.value,
    });
    current_comment.value = response.message;
  }
}

async function onSendComment() {
  if (!is_send_allowed.value)
    return;

  if (props.module === 'plans') {
    await planModuleComment();
  }
  else {
    let assignee = null;
    let data = {
      service: props.module,
      text: editor_contents.value,
      entry_uid: props.entry.uid,
      block_uid: props.entry.block_uid,
      section_uid: props.entry.section_uid,
      report_name: props.entry.report_name,
      entry_name: props.entry?.name || props.entry?.synced_name,
      entry_status: props.entry?.status,
      asset_uid: route?.params?.asset_id,
      parent_id: current_comment.value?.id,
      mentioned_user_list: tagged_users.value,
    };

    if (is_assign_controls_visible.value && is_assignee_selected.value) {
      assignee = tagged_users.value.length === 1 ? tagged_users.value[0].uid : selected_assignee_uid.value;
      data = { ...data, assignee };
    }

    const response = await chat_store.sendMessage(data);

    if (isEmpty(current_comment.value))
      current_comment.value = response.message;
  }

  is_assignee_selected.value = false;
  selected_assignee_uid.value = null;
  tagged_users.value = [];
  wysiwyg_ctx.value.setEditorContent('');
  editor_contents.value = '';

  current_comment.value?.id && await getReplies();
}

function onInput({ html, mentions }) {
  editor_contents.value = DOMPurify.sanitize(html);
  tagged_users.value = mentions;

  if (tagged_users.value.length > 1)
    selected_assignee_uid.value = tagged_users.value[0].uid;
}

function onInitialized(editor) {
  wysiwyg_ctx.value = editor;
}

function onClose() {
  is_editing_comment.value = false;
  is_editing_reply.value = false;
  editing_item.value = null;
  editor_contents.value = '';
  tagged_users.value = [];

  if (isEmpty(current_comment.value))
    emit('deleteAnnot');

  emit('closeContext');
}

function onCancel() {
  is_editing_comment.value = false;
  is_editing_reply.value = false;
  editing_item.value = null;
  editor_contents.value = '';
  tagged_users.value = [];
}

async function onDelete(id) {
  await chat_store.chat.client.deleteMessage(id);
  await getReplies();
}

async function onEdit() {
  if (!editor_contents.value?.trim())
    return;

  let update_data = {
    service: props.module,
    id: editing_item.value.id,
    text: editor_contents.value,
    asset_uid: route?.params?.asset_id,
    parent_id: current_comment.value?.id,
    mentioned_user_list: tagged_users.value,
    ...(props.module === 'plans'
      ? {
          sheet_uid: route?.params?.sheet_id,
          drawing_uid: plans_store.active_drawing_uid,
          annotation_position: current_comment.value.annotation_position,
        }
      : {}),
    ...(props.module === 'cnc'
      ? {
          entry_uid: props.entry.uid,
        }
      : {}),
  };

  if (is_editing_comment.value && current_comment.value?.assignee)
    update_data = { ...update_data, assignee: current_comment.value.assignee };

  const response = await chat_store.updateMessage(update_data);

  if (is_editing_comment.value) {
    current_comment.value.text = response.message.text;
    is_editing_comment.value = false;
  }
  else if (is_editing_reply.value) {
    await getReplies();
    is_editing_reply.value = false;
  }

  is_assignee_selected.value = false;
  selected_assignee_uid.value = null;
  tagged_users.value = [];
  editing_item.value = null;
  editor_contents.value = '';
}

async function resolveComment() {
  try {
    await chat_store.updateMessage({
      id: current_comment.value.id,
      service: props.module,
      status: 'resolved',
      annotation_position: current_comment.value.annotation_position,
      sheet_uid: route?.params?.sheet_id,
      drawing_uid: plans_store.active_drawing_uid,
      asset_uid: route?.params?.asset_id,
      attachments: current_comment.value.attachments,
      html: DOMPurify.sanitize(current_comment.value.html),
      mentioned_user_list: current_comment.value.mentioned_user_list,
      user: current_comment.value.user,
      text: current_comment.value.text,
      parent_id: current_comment.value?.id,
    });
    current_comment.value.status = 'resolved';
    props.annotation.Hidden = true;
    emit('closeContext');
  }
  catch (error) {}
}

watch(() => chat_store.comments, async (comments) => {
  current_comment.value?.id && await getReplies();
}, {});
</script>

<template>
  <div class="w-[20rem] text-xs border rounded-md shadow-md bg-white mb-10" @mousedown.stop @wheel.stop>
    <!-- Header -->
    <div v-if="current_comment.assignee && module === 'plans'" class="bg-gray-50 p-4 mb-2 text-sm font-medium flex justify-between">
      <div class="flex flex-wrap items-center gap-2">
        {{ $t('Assigned to') }}
        <HawkMembers :members="current_comment.assignee" :name_truncate_length="15" type="badge" />
      </div>
      <div>
        <div class="flex ml-auto mr-0 gap-3">
          <div
            v-if="current_comment
              && (current_comment.user?.id === current_user_id || current_comment.assignee === current_user_id)
              && !is_editing_comment"
            v-tippy="$t('Resolve')" class="cursor-pointer" @click="resolveComment"
          >
            <IconHawkCheckCircle class="text-primary-500" />
          </div>
          <hawk-menu
            v-if="current_user_id === current_comment.user?.id"
            :items="comment_menu_options"
            position="fixed"
            additional_trigger_classes="ring-transparent"
          >
            <template #trigger>
              <IconHawkDotsVertical class="cursor-pointer text-gray-500" />
            </template>
            <template #item="{ item }">
              <span class="text-sm w-40">
                {{ $t(item.name) }}
              </span>
            </template>
          </hawk-menu>
        </div>
      </div>
    </div>
    <div v-if="!isEmpty(current_comment)" id="scroll-block" class="max-h-[15rem] scrollbar !overflow-x-hidden p-2" @wheel.stop>
      <!-- Comment -->
      <sheet-comment-input
        :module="module"
        class="p-2"
        comment_type="reply"
        :enable_editor="is_editing_comment"
        :comment_text="current_comment.text"
        :has_custom_mentions="has_custom_mentions"
        :custom_mentions="custom_mentions"
        @input="onInput"
        @send="onEdit"
        @initialized="onInitialized"
      >
        <template #comment>
          <comment-display
            :uid="current_comment.user?.id"
            :time_stamp="current_comment.created_at"
          >
            <template v-if="!current_comment.assignee" #comment-menu>
              <div class="flex ml-auto mr-0 gap-3">
                <div
                  v-if="module === 'plans'
                    && current_comment
                    && (current_comment.user?.id === current_user_id || current_comment.assignee === current_user_id)
                    && !is_editing_comment"
                  v-tippy="$t('Resolve')" class="cursor-pointer" @click="resolveComment"
                >
                  <IconHawkCheckCircle class="text-primary-500" />
                </div>
                <hawk-menu
                  v-if="current_user_id === current_comment.user?.id"
                  :items="comment_menu_options"
                  position="fixed"
                  additional_trigger_classes="ring-transparent"
                >
                  <template #trigger>
                    <IconHawkDotsVertical class="cursor-pointer text-gray-500" />
                  </template>
                  <template #item="{ item }">
                    <span class="text-sm w-40">
                      {{ $t(item.name) }}
                    </span>
                  </template>
                </hawk-menu>
              </div>
            </template>
          </comment-display>
        </template>
      </sheet-comment-input>
      <!-- Reply list -->
      <template v-if="current_comment">
        <template v-if="replies.is_loading">
          <HawkLoader class="!m-0" />
        </template>
        <template v-else>
          <template
            v-for="reply_comment in replies.data.messages"
            :key="reply_comment.id"
          >
            <div class="py-2">
              <div class="border-b" />
            </div>
            <sheet-comment-input
              :module="module"
              class="p-1"
              :comment_data="reply_comment"
              comment_type="reply"
              :enable_editor="is_editing_reply && editing_item.id === reply_comment.id"
              :comment_text="reply_comment.text"
              :has_custom_mentions="has_custom_mentions"
              :custom_mentions="custom_mentions"
              @input="onInput"
              @send="onEdit"
              @initialized="onInitialized"
            >
              <template #comment>
                <comment-display
                  :uid="reply_comment.user.id"
                  :time_stamp="reply_comment.created_at"
                >
                  <template #comment-menu>
                    <hawk-menu
                      v-if="current_user_id === reply_comment.user.id && reply_comment.type !== 'deleted'"
                      class="ml-auto mr-0"
                      :items="getReplyMenuOptions(reply_comment)"
                      position="fixed"
                      additional_trigger_classes="ring-transparent"
                    >
                      <template #trigger>
                        <IconHawkDotsVertical class="cursor-pointer text-gray-500" />
                      </template>
                      <template #item="{ item }">
                        <span class="text-sm w-40">
                          {{ $t(item.name) }}
                        </span>
                      </template>
                    </hawk-menu>
                  </template>
                </comment-display>
              </template>
            </sheet-comment-input>
          </template>
        </template>
      </template>
    </div>
    <!-- Bottom input -->
    <div class="p-4">
      <template v-if="!(is_editing_comment || is_editing_reply)">
        <sheet-comment-input
          :key="!isEmpty(current_comment) ? 'reply' : 'comment'"
          :module="module"
          :comment_type="!isEmpty(current_comment) ? 'reply' : 'comment'"
          :enable_editor="true"
          :has_custom_mentions="has_custom_mentions"
          :custom_mentions="custom_mentions"
          @input="onInput" @send="onSendComment" @initialized="onInitialized"
        />
        <div v-if="is_assign_controls_visible && tagged_users.length" class="mt-2">
          <div v-if="show_assignee_selection" class="flex items-center mb-2 gap-1">
            <input
              id="assign_checkbox"
              v-model="is_assignee_selected"
              type="checkbox"
              name="assign_checkbox"
              :class="[checkbox_default_class]"
              class="!mt-0"
              @change="toggleAssigneeSelected"
            >
            <label class="text-sm cursor-pointer" for="assign_checkbox">{{ current_comment.assignee ? $t('Reassign to') : $t('Assign to') }}</label>
            <span v-if="uniqBy(tagged_users, 'uid').length === 1" class="text-sm">
              <HawkMembers :members="tagged_users[0].uid" :name_truncate_length="20" type="badge" />
            </span>
            <HawkMenu
              v-else
              class="ml-1"
              additional_trigger_classes="ring-gray-100 w-48"
              :items="uniqBy(tagged_users, 'uid')"
              @select="(selected_user) => selected_assignee_uid = selected_user.uid"
            >
              <template #trigger>
                <div class="h-9 border rounded-lg flex items-center justify-between px-3 text-sm">
                  <HawkMembers :members="selected_assignee_uid ?? tagged_users[0]" size="badge" :name_truncate_length="15" type="label" />
                  <div>
                    <IconHawkChevronDown class="text-gray-500" />
                  </div>
                </div>
              </template>
              <template #item="{ item }">
                <span class="text-sm w-40">
                  <HawkMembers :members="item.uid" size="badge" :name_truncate_length="15" type="label" />
                </span>
              </template>
            </HawkMenu>
          </div>
          <hr>
          <div class="mt-2">
            <span v-if="show_assignee_selection && is_assignee_selected">{{ $t('Assignees will receive a notification and will be responsible for marking it as done') }}</span>
            <span v-else>{{ $t('Your @mention will add people to this discussion and send an email') }}</span>
          </div>
        </div>
      </template>
      <!-- Comment CTA -->
      <div class="flex justify-between gap-4 mt-2">
        <HawkButton
          v-if="!(is_editing_comment || is_editing_reply)"
          class="w-1/2 flex justify-center"
          color="primary" type="outlined"
          @click="onClose"
        >
          {{ $t('Close') }}
        </HawkButton>
        <HawkButton
          v-else
          class="w-1/2 flex justify-center"
          color="primary" type="outlined"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </HawkButton>

        <HawkButton
          v-if="is_editing_comment || is_editing_reply"
          class="w-1/2 flex justify-center"
          @click="onEdit"
        >
          {{ $t('Done') }}
        </HawkButton>
        <HawkButton
          v-else
          :disabled="!is_send_allowed"
          class="w-1/2 flex justify-center"
          @click="onSendComment"
        >
          {{ !isEmpty(current_comment) ? $t('Reply') : $t('Comment') }}
        </HawkButton>
      </div>
    </div>
  </div>
</template>
