<script setup>
import { groupBy, sortBy, uniq, uniqBy } from 'lodash-es';
import { onMounted, reactive } from 'vue';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useModal } from 'vue-final-modal';
import { load_js_css_file } from '~/common/utils/load-script.util';
import { useTerraStore } from '~/terra/store/terra.store';
import ImportSchedule from '~/terra/components/charts/terra-schedules-list-popup.vue';

import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  progress_data: {
    type: Object,
  },
});
const emit = defineEmits(['trackerUpdated']);
dayjs.extend(timezone);
const { $t, $services, $toast, auth_store, common_store, route, router } = useCommonImports();
// Temporary until we come up with a better solution than $date
if (auth_store?.logged_in_user_details?.timezone)
  dayjs.tz.setDefault(auth_store?.logged_in_user_details?.timezone);
const terra_store = useTerraStore();

const state = reactive({
  nested_headers: null,
  table_data: null,
  table_instance: null,
  show_save_changes: false,
  is_saving: false,
});
const mock_data = [];
const breadcrumb_items = computed(() => {
  return [{
    label: common_store.active_asset?.name,
  },
  {
    label: terra_store.container?.name,
  }];
});
const { open, close, patchOptions } = useModal({
  component: ImportSchedule,
  attrs: {
    onImportScheduleData: (data) => {
      prepareDataset(data);
    },
    onClose() {
      close();
    },

  },
});
function prepareDataset(import_data) {
  const progress_data = props.progress_data;
  let nested_headers = [];
  let nested_data = [];
  const column_skeleton = {};
  const workflow_group = groupBy(progress_data, 'workflow');
  const unique_projects = sortBy(uniq(progress_data?.map(item => item.project)) || [], item => item);

  const columns = [
    {
      type: 'text',
      readOnly: true,
      data: 'row_header_and_field_name',

    },
  ];
  unique_projects.forEach((project) => {
    column_skeleton[`${project}_start_date`] = '';
    column_skeleton[`${project}_finish_date`] = '';
    columns.push({
      editor: 'date',
      data: `${project}_start_date`,
    },
    {
      editor: 'date',
      data: `${project}_finish_date`,
    },
    );
  });
  Object.keys(workflow_group).forEach((workflow_name) => {
    const workflow_data = workflow_group[workflow_name];
    const unique_fields = sortBy(uniqBy(workflow_data, 'field'), ['project']);
    nested_data.push({
      row_header_and_field_name: workflow_name,
      ...column_skeleton,
      __children: unique_fields.map((item) => {
        let data = {
          row_header_and_field_name: item.field,
          project: item.project,
          ...column_skeleton,
          ...(terra_store.container?.schedule[workflow_name]?.[item.field] || {}),
        };
        Object.keys(terra_store.container?.schedule[workflow_name]?.[item.field] || {}).forEach((key) => {
          if ((key.includes('_start_date') || key.includes('_finish_date')) && data[key]?.length)
            data[key] = dayjs(data[key]).format('DD/MM/YYYY');
        });
        data = {
          ...data,
          ...(import_data?.[item.field] || {}),
        };
        return data;
      }),
    });
    nested_data = sortBy(nested_data, ['row_header_and_field_name']);
  });

  const projectNames = unique_projects;
  const dateHeaders = ['Start date', 'Finish date'];
  nested_headers = [
    ['Fields', ...projectNames.flatMap(name => [{ label: name, colspan: 2, type: 'date' }])],
    ['', ...projectNames.flatMap(() => dateHeaders)],
  ];

  state.table_data = nested_data;
  state.nested_headers = nested_headers;

  loadHandsOn(columns);
}

function loadHandsOn(columns) {
  const container = document.getElementById('schedule-tracker-table');

  state.table_instance = new Handsontable(container, {
    data: state.table_data,
    width: '100%',
    nestedRows: true,
    nestedHeaders: state.nested_headers,
    height: '100%',
    fixedColumnsLeft: 1,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    columns,
    rowHeights: 30,

  });
  state.table_instance.batch(() => {
    state.table_instance.getData().forEach((row, row_index) => {
      const has_child = state.table_instance.getSourceDataAtRow(row_index)?.__children;

      if (has_child)
        row.forEach((col, index) => {
          state.table_instance.setCellMeta(row_index, index, 'className', 'bg-gray-500 text-gray-700');
          state.table_instance.setCellMeta(row_index, index, 'readOnly', true);
        });
    });
    state.table_instance.updateSettings({
      viewportRowRenderingOffset: 100,
      viewportColumnRenderingOffset: 20,
    });

  // The table cache will be recalculated, and table render will be called once after executing the callback
  });
}
function importSchedule() {
  open();
}
onMounted(async () => {
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@13.1.0/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@13.1.0/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );
  prepareDataset();
});
async function saveChanges() {
  try {
    state.is_saving = true;
    let schedules = {};
    const parsed_data = {};
    state.table_data.forEach((workflow_row) => {
      const field_project_data = {};
      workflow_row.__children.forEach((field_row) => {
        const row_data = { ...field_row };
        Object.keys(field_row).forEach((key) => {
          if (key.includes('_start_date') && field_row[key]?.length)
            row_data[key] = dayjs(field_row[key], 'DD/MM/YYYY').set('hour', 8).set('minute', 0).set('second', 0).format();

          else if (key.includes('_finish_date') && field_row[key]?.length)
            row_data[key] = dayjs(field_row[key], 'DD/MM/YYYY').set('hour', 18).set('minute', 0).set('second', 0).format();
        });
        field_project_data[`${field_row.row_header_and_field_name}`] = row_data;
      });

      parsed_data[workflow_row.row_header_and_field_name] = field_project_data;
    });

    schedules = parsed_data;
    const response = await $services.terra.patch({
      url: `containers/${route.params.id}`,
      body: {
        schedule: schedules,
      },

    });

    await terra_store.set_container({
      container: { ...terra_store.container, schedule: response.data.schedule },
    });
    emit('trackerUpdated');
    $toast({
      text: 'Schedule updated successfully!',
      type: 'success',
    });
    terra_store.terra_track_events('Updated schedule tracker');
    state.is_saving = false;
  }
  catch (err) {
    logger.log(err);
    state.is_saving = false;
  }
}
</script>

<template>
  <div>
    <div class="py-6 px-8">
      <div class="flex">
        <IconHawkArrowLeft class="text-gray-600 mr-4 cursor-pointer" @click="$emit('close')" />
        <div>
          <HawkBreadcrumbs :truncate_length="100" :items="breadcrumb_items" />
          <div class="text-base font-semibold mt-1">
            {{ $t('Schedule tracker') }}
          </div>
        </div>
        <div class="flex ml-auto items-center">
          <HawkButton class="ml-auto mr-2" icon type="text" @click="importSchedule">
            <span v-tippy="'Import schedule'">
              <IconHawkImport />
            </span>
          </HawkButton>
          <HawkButton class="ml-auto" color="primary" size="xs" :loading="state.is_saving" @click="saveChanges">
            {{ $t('Save') }}
          </HawkButton>
        </div>
      </div>
    </div>
    <div class="h-[calc(100vh-164px)]">
      <div id="schedule-tracker-table" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.handsontable) {
  .bg-gray-500{
    pointer-events: none;
    background-color: lightgray;
  }
  .text-gray-700{
    font-weight: 600;
    color: black;
  }

}
</style>

<style>
  .htDatepickerHolder {
    margin-top: -240px !important;
    margin-left: -190px !important;
  }
</style>
