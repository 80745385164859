<script setup>
import { inject, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { debounce, throttle } from 'lodash-es';
import { useCustomAnnotationHandlers } from '~/plans/composables/useCustomAnnotationHandlers';
import { ANNOTATION_TYPES, CUSTOM_ANNOTATION_TYPES, PLANS_PERMISSION, TOOL_EVENTS } from '~/plans/constants';
import { useAnnotationsStore } from '~/plans/store/annotations.store';

import dmsTools from '~/plans/components/tool-templates/dms-tools.vue';
import planTools from '~/plans/components/tool-templates/plan-tools.vue';

import { useAuthStore } from '~/auth/stores/auth.store';
import { useDocumentTools } from '~/plans/composables/useDocumentTools';
import { usePusherStore } from '~/common/stores/pusher.store.js';

const props = defineProps({
  active_tool: {
    type: String,
  },
  has_annotation_two_clicks: {
    type: Boolean,
    default: true,
  },
  module: {
    type: String,
  },
});

const emit = defineEmits(['toolActivated']);

const document_viewer_instance = inject('document-viewer-instance');
const viewer_container_elem = inject('viewer-container-elem');

const scroll_view_elem = document.getElementById('scroll-view');

const route = useRoute();
const router = useRouter();
const auth_store = useAuthStore();
const annotations_store = useAnnotationsStore();

watchEffect(() => {
  const pusher_store = usePusherStore();
  if (pusher_store.is_initialized) {
    const current_organization = auth_store.current_organization;
    annotations_store.subscribe_pusher({ organization: current_organization?.uid });
  }
}, { flush: 'post' });

const { handleCustomAnnotHover, clear_route, handleCustomAnnotSelection } = useCustomAnnotationHandlers({ route, router });
const {
  get_selected_annotations,
  delete_annotations,
  apply_hot_keys,
  import_annotations,
  set_draw_mode,
  update_comment_annotation,
  update_stamp_image,
  create_rectangle_two_clicks,
} = useDocumentTools(document_viewer_instance);

const has_modify_annotations_role = auth_store.check_permission(PLANS_PERMISSION.MODIFY_ANNOTATIONS, route.params.asset_id);

const display_mode = document_viewer_instance.value
  .getDisplayModeManager()
  .getDisplayMode();

const custom_annotation_names = Object.values(CUSTOM_ANNOTATION_TYPES);
const annotation_manager = document_viewer_instance.value.getAnnotationManager();
const annotation_history_manager = document_viewer_instance.value.getAnnotationHistoryManager();

const show_annotation_edit = ref(false);
const show_comment_back_drop = ref(false);
const is_fullscreen_mode = ref(viewer_container_elem.value.getAttribute('fullscreen'));
const text_annots_selected = ref(false);

const custom_annot_context_menu = reactive({
  is_mouse_over: false,
  type: null,
  data: null,
  pos_x: 0,
  pos_y: 0,
  annotation: null,
});

function handleStickyNote(action, annotations) {
  const sticky_note_annotations = annotations.filter(annotation => annotation.Subject === ANNOTATION_TYPES.NOTE);
  sticky_note_annotations.forEach((note_annotation) => {
    update_stamp_image(note_annotation, ANNOTATION_TYPES.NOTE, null, action === 'selected');
  });
  show_annotation_edit.value = false;
}

function onAnnotationSelected(annotation_list, action) {
  const custom_annotations = annotation_list.filter(annot => custom_annotation_names.includes(annot.Subject));
  handleCustomAnnotSelection(action, custom_annotations, update_stamp_image, update_comment_annotation);

  if (action === 'deselected') {
    custom_annot_context_menu.is_mouse_over = false;
    const annotation_without_uid = annotation_list.filter(annotation => ((annotation.Subject === CUSTOM_ANNOTATION_TYPES.COMMENT && !annotation.getCustomData('uid')) || (annotation.Subject === ANNOTATION_TYPES.NOTE && !annotation.getCustomData('uid'))));
    delete_annotations(annotation_without_uid);

    if (route.query.sheet_comment || route.query.note)
      clear_route();
  }

  // For style editable annotations(line, ellipse, etc)
  const selected_annotations = get_selected_annotations(custom_annotation_names, false);
  if (selected_annotations.length) {
    show_annotation_edit.value = has_modify_annotations_role;
    const selected_text_annotations = get_selected_annotations(['FreeText'], true);
    text_annots_selected.value = selected_annotations.length === selected_text_annotations.length;
    (props.active_tool === TOOL_EVENTS.SETTING) && emit('toolActivated', '');
  }

  annotation_list.filter(annotation => annotation.Subject === ANNOTATION_TYPES.NOTE).length && handleStickyNote(action, annotation_list);

  // Reset annotation edit config on all editable annotations(line, ellipse, etc) deselect
  if (action === 'deselected' && !selected_annotations.length) {
    show_annotation_edit.value = false;

    if (!custom_annotations.length) {
      const prev_selected_tool = props.active_tool;
      emit('toolActivated', '');
      emit('toolActivated', prev_selected_tool);
    }
  }
}

function customAnnotationSelect() {
  // filter out custom annotations from selected annotations list
  const selected_custom_annotations = get_selected_annotations(custom_annotation_names);
  if (selected_custom_annotations.length === 1 && props.active_tool !== TOOL_EVENTS.EDIT) {
    const type = selected_custom_annotations[0].Subject;
    clear_route();

    const param_map = {
      [CUSTOM_ANNOTATION_TYPES.FORM]: () => {
        router.push({
          ...route,
          query: {
            form: btoa(JSON.stringify({
              store_key: 'plans-form',
              form_uid: selected_custom_annotations[0].getCustomData('uid'),
              teleport_to: '#viewer-container',
            })),
          },
        });
      },
      [CUSTOM_ANNOTATION_TYPES.TASK]: () => {
        router.push({
          ...route,
          query: {
            task: selected_custom_annotations[0].getCustomData('uid'),
            teleport_to: '#viewer-container',
          },
        });
      },
      [CUSTOM_ANNOTATION_TYPES.COMMENT]: () => {
        router.push({
          ...route,
          query: {
            sheet_comment: selected_custom_annotations[0].getCustomData('uid') || `annot_${selected_custom_annotations[0].Id}`,
          },
        });
      },
    };

    param_map[type]?.();
  }
}

function noteAnnotationSelect() {
  const selected_custom_annotations = get_selected_annotations([ANNOTATION_TYPES.NOTE]);
  if (selected_custom_annotations.length === 1 && props.active_tool !== TOOL_EVENTS.EDIT) {
    clear_route();

    router.replace({
      ...route,
      query: {
        note: route.query.note?.includes('new_') ? route.query.note : selected_custom_annotations[0].Id,
      },
    });
  }
}

function onAnnotationMouseUp(event) {
  const annotation = document_viewer_instance.value.getAnnotationManager().getAnnotationByMouseEvent(event);

  // Handle custom annotation mouseup behaviour
  if (annotation && custom_annotation_names.includes(annotation.Subject))
    customAnnotationSelect();
  if (annotation && annotation.Subject === ANNOTATION_TYPES.NOTE)
    noteAnnotationSelect();
}

function onViewerHover(event) {
  if (show_comment_back_drop.value)
    return;

  const annotation = document_viewer_instance.value?.getAnnotationManager().getAnnotationByMouseEvent(event);

  // Handle custom annotation hovering
  if (annotation && [CUSTOM_ANNOTATION_TYPES.FORM, CUSTOM_ANNOTATION_TYPES.TASK].includes(annotation.Subject)) {
    handleCustomAnnotHover(annotation, display_mode, custom_annot_context_menu);
  }
  else if (annotation && annotation.Subject === ANNOTATION_TYPES.NOTE) {
    const scroll_top = scroll_view_elem.scrollTop;
    const scroll_left = scroll_view_elem.scrollLeft;
    const offset_y = 0;
    const window_coordinates = display_mode.pageToWindow({
      x: annotation.getX(),
      y: annotation.getY(),
    }, 1);

    custom_annot_context_menu.pos_x = window_coordinates.x - scroll_left + 10;
    custom_annot_context_menu.pos_y = window_coordinates.y - scroll_top - offset_y;
    custom_annot_context_menu.type = ANNOTATION_TYPES.NOTE;
    custom_annot_context_menu.annotation = annotation;
  }
  else {
    if (!custom_annot_context_menu.is_mouse_over) {
      custom_annot_context_menu.pos_x = 0;
      custom_annot_context_menu.pos_y = 0;
      custom_annot_context_menu.data = null;
      custom_annot_context_menu.type = null;
    }
  }
}
const throttledOnViewerHover = throttle(onViewerHover, 500);

function exitFullscreenHandler() {
  if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
    is_fullscreen_mode.value = false;
    viewer_container_elem.value.removeAttribute('fullscreen');
    emit('toolActivated', TOOL_EVENTS.FULLSCREEN);
  }
}

function toggleFullscreen(val) {
  is_fullscreen_mode.value = val;
}
function toggleBackdrop(val) {
  show_comment_back_drop.value = val;
}
function updateCustomAnnotContext(val) {
  Object.assign(custom_annot_context_menu, { custom_annot_context_menu, ...val });
}

onMounted(() => {
  annotation_manager.setCurrentUser(auth_store.logged_in_user_details?.user_id);
  annotation_manager.addEventListener('annotationSelected', onAnnotationSelected);
  viewer_container_elem.value.addEventListener('mousemove', throttledOnViewerHover);
  viewer_container_elem.value.addEventListener('keydown', apply_hot_keys);
  viewer_container_elem.value.addEventListener('mouseup', onAnnotationMouseUp);
  document.addEventListener('fullscreenchange', exitFullscreenHandler);
  document.addEventListener('webkitfullscreenchange', exitFullscreenHandler);
  document.addEventListener('mozfullscreenchange', exitFullscreenHandler);
  document.addEventListener('MSFullscreenChange', exitFullscreenHandler);

  let tool_mode_timeout;
  document_viewer_instance.value.addEventListener('mouseLeftDown', (evt) => {
    set_draw_mode('twoClicks', props.has_annotation_two_clicks);
    tool_mode_timeout = setTimeout(() => {
      set_draw_mode('holdToDraw', props.has_annotation_two_clicks);
    }, 500);
  });
  document_viewer_instance.value.addEventListener('mouseLeftUp', (evt) => {
    clearTimeout(tool_mode_timeout);
  });

  annotation_history_manager.clear();
});

// ------------------- Refresh Normal annotation -------------------

const should_refresh_annotation = ref(false);

function refreshAnnotations() {
  if (!should_refresh_annotation.value)
    return;
  annotations_store.get_annotations.forEach(annotation => import_annotations(annotation.annotation_data));
  const realtime_annotations = annotation_manager.getAnnotationsList().filter(annotation => annotation.Subject !== CUSTOM_ANNOTATION_TYPES.LOCATION);
  delete_annotations(realtime_annotations.filter(annotation => !annotation.getCustomData('uid') && !annotations_store.annotations_map[annotation.Id]), { imported: true });
  should_refresh_annotation.value = false;
}

const debouncedRefreshAnnotation = debounce(refreshAnnotations, 1500);

document_viewer_instance.value.addEventListener('mouseMove', debouncedRefreshAnnotation);
document_viewer_instance.value.addEventListener('keyDown', debouncedRefreshAnnotation);
window.addEventListener('focus', debouncedRefreshAnnotation);

watch(annotations_store.annotations_map, () => {
  should_refresh_annotation.value = true;
});
// ----------------------------------------------------------------

watch(show_annotation_edit, () => {
  if (show_annotation_edit.value === false && props.active_tool === TOOL_EVENTS.CREATE_RECTANGLE)
    create_rectangle_two_clicks();
});

onUnmounted(() => {
  annotation_manager.removeEventListener('annotationSelected', onAnnotationSelected);
  window.removeEventListener('focus', debouncedRefreshAnnotation);
  document.removeEventListener('fullscreenchange', exitFullscreenHandler);
  document.removeEventListener('webkitfullscreenchange', exitFullscreenHandler);
  document.removeEventListener('mozfullscreenchange', exitFullscreenHandler);
  document.removeEventListener('MSFullscreenChange', exitFullscreenHandler);
});
</script>

<template>
  <template v-if="module === 'plans'">
    <plan-tools
      :active_tool="active_tool"
      :is_fullscreen_mode="is_fullscreen_mode"
      :custom_annot_context_menu="custom_annot_context_menu"
      :show_annotation_edit="show_annotation_edit"
      :show_comment_back_drop="show_comment_back_drop"
      :text_annots_selected="text_annots_selected"
      @on-toggle-fullscreen="toggleFullscreen"
      @on-toggle-backdrop="toggleBackdrop"
      @on-update-custom-annot="updateCustomAnnotContext"
    />
  </template>
  <template v-if="module === 'dms'">
    <dms-tools
      :active_tool="active_tool"
      :is_fullscreen_mode="is_fullscreen_mode"
      :show_annotation_edit="show_annotation_edit"
      :text_annots_selected="text_annots_selected"
      :show_comment_back_drop="show_comment_back_drop"
      :custom_annot_context_menu="custom_annot_context_menu"
      @on-toggle-fullscreen="toggleFullscreen"
      @on-toggle-backdrop="toggleBackdrop"
      @on-update-custom-annot="updateCustomAnnotContext"
    />
  </template>
</template>
