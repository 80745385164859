<script setup>
const props = defineProps({
  attachment: {
    type: Object,
  },
  items: {
    type: Object,
  },
});

const emits = defineEmits(['setActiveAttachment']);

function getNearestItems() {
  const index = props.items.findIndex(item => item.uid === props.attachment.uid);
  if (index === -1)
    return [];
  const total_items_visible = Math.floor((window.innerWidth - 200) / 100);
  const values = [];
  let left = index;
  let right = index;

  while (values.length < total_items_visible) {
    if (left >= 0)
      values.unshift({ ...props.items[left], original_index: left });

    if (right < props.items.length && right !== left)
      values.push({ ...props.items[right], original_index: right });

    left--;
    right++;

    if (values.length >= props.items.length || values.length >= total_items_visible)
      break;
  }

  return values.slice(0, total_items_visible);
}
</script>

<template>
  <div class="flex text-white items-center justify-center w-full p-4 gap-4 mb-4">
    <div
      v-for="(item, index) in getNearestItems()" :key="index"
      :class="{ 'border-primary-600 border-4': attachment.uid === item.uid }"
      class="rounded-xl overflow-hidden cursor-pointer"
      @click="emits('setActiveAttachment', item.original_index)"
    >
      <img
        v-if="item.thumbnail_small || (item.thumbnails?.small)" :src="item.thumbnail_small || (item.thumbnails?.small)"
        class="object-cover rounded-lg w-[80px] h-[80px]"
        alt="thumbnail"
      >
      <div
        v-else
        class="flex items-center justify-center bg-gray-100 w-[80px] h-[80px]"
      >
        <HawkFileIcon :file_name="item.file_name" />
      </div>
    </div>
  </div>
</template>
