<script setup>
import { remove } from 'lodash-es';

// composables
import { storeToRefs } from 'pinia';
import { useDocumentPermissions } from '~/dms/composables/document-permissions.composable';
import { useAuthStore } from '~/auth/stores/auth.store';

// stores
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  item: {
    type: Object,
    required: false,
    default: () => {},
  },
  has_bordered_trigger: {
    type: Boolean,
    required: false,
    default: false,
  },
  bulk_items: {
    type: Array,
    required: false,
    default: () => [],
  },
  is_file_details: {
    type: Boolean,
    required: false,
    default: false,
  },
  is_widget: {
    type: Boolean,
    required: false,
    default: false,
  },
  options: {
    type: Object,
    required: false,
    default: () => {},
  },
  element: {
    type: Object,
    required: false,
  },
  source: {
    type: String,
    required: false,
  },
  move_data: {
    type: Array,
    required: false,
    default: () => [],
  },
  active_tab: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'closeContext',
  'openContext',
  'bulkActionComplete',
  'download',
  'archive',
  'delete',
  'rename',
  'share',
  'versions',
  'transferOwnership',
  'createTask',
  'createForm',
  'move',
  'bulkUpdate',
  'autonumbering',
]);
const $t = inject('$t');

const document_store = useDocumentStore();
const document_permissions = useDocumentPermissions();
const { is_internal } = storeToRefs(document_store);

const is_archived_view = computed(() => props.active_tab === 'archive');
const archive_label = computed(() => !is_archived_view.value ? $t('Archive') : $t('Unarchive'));
const type = computed(() => props.bulk_items.length > 0 ? 'bulk' : props.item.type);
const authStore = useAuthStore();

function viewDetails() {
  document_store.set_active_item_meta({
    uid: props.item.uid,
    type: props.item.type,
  });
  document_store.set_show_details(true);
}

const items = [
  {
    id: 'view',
    label: `${$t('View details')}`,
    on_click: viewDetails,
  },
  {
    id: 'share',
    label: `${$t('Share')}`,
    on_click: () => emit('share', { item: props.item, bulk_items: props.bulk_items }),
  },
  {
    id: 'download',
    label: `${$t('Download')}`,
    on_click: () => emit('download', { item: props.item, bulk_items: props.bulk_items }),
  },
  ...(
    [authStore.check_split('files_v2_advanced') && props.item?.type === 'file'
      ? {
          id: 'edit',
          label: `${$t('Edit')}`,
          on_click: () => emit('edit', { item: props.item }),
        }
      : {
          id: 'rename',
          label: `${$t('Rename')}`,
          on_click: () => emit('rename', { item: props.item }),
        },
    ]
  ),
  {
    id: 'versions',
    label: `${$t('Versions')}`,
    on_click: () => emit('versions', { item: props.item }),
  },
  {
    id: 'transfer',
    label: `${$t('Transfer ownership')}`,
    on_click: () => emit('transferOwnership', { item: props.item }),
  },
  {
    id: 'task',
    label: `${$t('Add task')}`,
    on_click: () => emit('createTask', { item: props.item, source: props.source }),
  },
  {
    id: 'form',
    label: `${$t('Add form')}`,
    on_click: () => emit('createForm', { item: props.item, source: props.source }),
  },
  {
    id: 'move',
    label: `${$t('Move')}`,
    on_click: () => emit('move', { item: props.item, element: props.element, move_data: props.move_data, is_widget: props.is_widget }),
  },
  {
    id: 'integrate',
    label: `${$t('Integrate')}`,
    on_click: () => emit('integrate', { item: props.item, element: props.element, move_data: props.move_data, is_widget: props.is_widget }),
  },
  {
    id: 'archive',
    label: archive_label.value,
    on_click: () => emit('archive', { item: props.item, options: props.options, is_archived_view: is_archived_view.value }),
  },
  {
    id: 'delete',
    label: `${$t('Delete')}`,
    on_click: () => emit('delete', { item: props.item, options: props.options }),
  },
  {
    id: 'restore',
    label: $t('Restore'),
    on_click: () => emit('restore', { item: props.item }),
  },
  {
    id: 'bulk_update',
    label: `${$t('Bulk Update')}`,
    on_click: () => emit('bulkUpdate', { item: props.item, options: props.options }),
  },
  {
    id: 'autonumbering',
    label: `${$t('Autonumbering')}`,
    on_click: () => emit('autonumbering', { item: props.item }),
  },
  ...(authStore.check_split('files_v2_advanced')
    ? [{
        id: 'export',
        label: `${$t('Export register')}`,
        on_click: () => emit('export', !props.bulk_items.length ? [props.item] : props.bulk_items),
      }]
    : []),
];

function filterAllowed(items) {
  return props.bulk_items?.length
    ? items.filter(item => document_permissions.checkDocumentBulkPermission({ permission: item.id, bulk_items: props.bulk_items }))
    : items.filter(item => document_permissions.checkDocumentPermission({ permission: item.id, item: props.item }));
}

const dropdown_items = computed(() => {
  let final_items = items;
  if (props.options?.dropdown_items_ids?.length)
    final_items = props.options?.dropdown_items_ids.reduce((filter_items, item_id) => {
      const item = final_items.find(({ id }) => id === item_id);
      if (item)
        filter_items.push(item);
      return filter_items;
    }, []);

  if (props.item?.type !== 'file')
    final_items = final_items.filter(item => item.id !== 'versions');

  if (props.item?.type === 'file')
    final_items = remove(final_items, item => !['autonumbering', 'integrate'].includes(item.id));

  if (is_internal.value)
    final_items = final_items.filter(item => !['share', 'transfer'].includes(item.id));
  return filterAllowed(final_items);
});
</script>

<template>
  <hawk-menu
    :items="dropdown_items"
    :has_bordered_trigger="has_bordered_trigger"
    additional_dropdown_classes="scrollbar max-h-80 2xl:max-h-full"
    additional_trigger_classes="!ring-0"
    position="fixed"
    @open="emit('openContext')"
    @close="emit('closeContext')"
  >
    <template #trigger>
      <slot name="trigger" />
    </template>
  </hawk-menu>
</template>
