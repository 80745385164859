import { isString, keyBy } from 'lodash-es';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCustomViewStore } from '~/common/stores/custom-view.store.js';

export function useViewConfiguration({
  service,
  resource_type,
  resource_id,
  feature,
  name,
  store_key,
  modify_permissions = true,
} = {}) {
  const columns_options = ref([]);
  const default_columns = ref([]);
  const route = useRoute();
  const custom_view_store = store_key ? useCustomViewStore(store_key) : null;
  const visible_columns_map = ref({});

  const active_view = computed(() => custom_view_store?.views?.find(view => view.service === service && view.resource_type === resource_type && view.feature === feature && resource_id ? view.resource_id === resource_id : true));

  const active_view_columns_map = computed(() => keyBy(active_view.value?.data?.columns || [], 'field'));

  const display_filters = computed(() => active_view.value?.data?.display_filters || []);

  const groups = computed(() => active_view.value?.data?.groups || []);

  const is_custom_view_enabled = computed(() => resource_type && service && feature);

  function getVisibleColumns() {
    const columns_options_map = keyBy(columns_options.value, 'id');
    const formatted_default_columns = (default_columns.value || []).map(item => isString(item) ? columns_options_map[item] : columns_options_map[item.id]);
    if (active_view?.value?.data?.columns?.length) {
      const active_view_columns = active_view?.value?.data?.columns.reduce((cols, item, i) => {
        const item_uid = item.field || item.uid;
        if (columns_options_map[item_uid])
          cols.push({ ...columns_options_map[item_uid], size: item.size || 150, order_index: i });
        return cols;
      }, []);
      if (active_view_columns.length)
        return active_view_columns;
    }
    return (formatted_default_columns.length ? formatted_default_columns : columns_options.value).map((col, i) => ({ ...col, order_index: i }));
  }
  async function setView(options = {}, columns_options_list = [], default_columns_list = []) {
    if (is_custom_view_enabled.value)
      await custom_view_store?.set_custom_views({ query: { resource_type, service, feature } }, options);
    default_columns.value = default_columns_list;
    columns_options.value = columns_options_list;
    visible_columns_map.value = keyBy(getVisibleColumns(), 'id');
  }

  async function updateView(data = {}) {
    if (!modify_permissions)
      return;
    const data_payload = { ...data };
    if (data_payload.columns)
      data_payload.columns = data_payload.columns.map((c) => { return { field: c.id, size: c.size || active_view_columns_map.value?.[c.id]?.size || 150 }; });

    if (data_payload.display_filters)
      data_payload.display_filters = data_payload.display_filters.map((c) => { return { field: c.uid, default: null }; });

    const payload = {
      service,
      feature,
      resource_type,
      resource_id,
      name,
      asset: route?.params?.asset_id,
      data: {
        ...(active_view.value?.data || {}),
        ...data_payload,
      },
    };
    if (active_view.value && Object.keys(active_view.value).length)
      await custom_view_store?.update_view(payload, active_view.value.uid);
    else await custom_view_store?.create_view(payload);
    visible_columns_map.value = keyBy(getVisibleColumns(), 'id');
  }

  return {
    setView,
    updateView,
    getVisibleColumns,
    active_view,
    active_view_columns_map,
    visible_columns_map,
    display_filters,
    groups,
    is_custom_view_enabled,
  };
}
